import { useState, useEffect } from "react";
import { http } from "../../utils/http";
import { useParams } from "react-router-dom";
import Hidden from "@mui/material/Hidden";

import { useNavigate } from "react-router-dom";

import {
  FormGroup,
  FormControl,
  Grid,
  Button,
  styled,
  OutlinedInput,
  FormHelperText,
  TextField,
} from "@mui/material";
import { width } from "@mui/system";

const Container = styled(FormGroup)`
  width: 100%;
`;
{
  /* <Typography variant="h4">Add New Employee</Typography> */
}

const initialValues = {
  id: "",
  name: "",
};

const EditProcessorGigaHeartz = () => {


  let params = useParams();
  const navigate = useNavigate();
  
  const [ProcessorUpdate, setProcessorGigaHeartzUpdate] = useState({ ...initialValues,id: params.id });
  
  useEffect(() => {
      (async () => {
          const data: any = await http.get("/processor-gigaHeartz/" + params.id);
          setProcessorGigaHeartzUpdate(data.data.result);
        })();
    }, []);
    
       
  const onValueChange = (e: any) => {
    e.preventDefault(e);
    setProcessorGigaHeartzUpdate({ ...ProcessorUpdate, [e.target.name]: e.target.value });
// console.log('name is ', e.target.name, 'value is', e.target.value);
  };
  const EditProcessorGigaHeartzdetail = async (e: any) => {
    e.preventDefault();
    try {
    //    console.log('data',ProcessorUpdate);
      await http.patch("/processor-gigaHeartz/"+params.id, ProcessorUpdate);
      navigate("/processor-gigaHeartz");
      return;
    } catch (error: any) {
      console.log("error while calling api", error.message);
    }
  };

  return (
    <div>
      <h2> Processor ID: {params.id}</h2>
      <form onSubmit={EditProcessorGigaHeartzdetail}>
        <FormGroup>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>Processor Brand ID</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  disabled
                  sx={{ background: "white" }}
                  value={ProcessorUpdate.id}
                  onChange={(e) => onValueChange(e)}
                  name="id"
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>Name</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  sx={{ background: "white" }}
                  onChange={(e) => onValueChange(e)}
                  name="name"
                  value={ProcessorUpdate.name}
                  
                />
              </FormControl>
            </Grid>
          </Grid>

          <FormControl sx={{ my: 4 }}>
            <Button variant="contained" sx={{ maxWidth: 200 }} type="submit">
              Update
            </Button>
          </FormControl>
        </FormGroup>
      </form>
    </div>
  );
};
export default EditProcessorGigaHeartz;
