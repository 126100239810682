import * as React from "react"
import { Stack, Box, FormLabel } from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import { useState, useEffect } from "react"
import { http, serverImgUrl } from "../../utils/http"
import { useParams } from "react-router-dom"
import Hidden from "@mui/material/Hidden"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import TextareaAutosize from "@mui/material/TextareaAutosize"
import MenuItem from "@mui/material/MenuItem"
import { toast } from "react-toastify"

import CustomeSelect from "../../components/ui/CustomeSelectItem"
import EditPurchaseImageItem from "../../components/ui/EditPurchaseImageItem"
import CardMedia from "@mui/material/CardMedia"
import Checkbox from "@mui/material/Checkbox"
import PurchaseFormBlockRow from "../../components/ui/PurchaseFormBlockRow"
import FormRow from "../../components/ui/FormRow"

import Typography from "@mui/material/Typography"

import { useNavigate } from "react-router-dom"

import {
    FormGroup,
    FormControl,
    Grid,
    Button,
    styled,
    OutlinedInput,
    FormHelperText,
    TextField,
} from "@mui/material"
import FormTextEngineer from "../../components/ui/FormTextEngineer"
import EditPurchaseSelectItem from "../../components/ui/EditPurchaseSelectItem"

const Container = styled(FormGroup)`
    width: 100%;
`
const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
}
{
    /* <Typography variant="h4">Add New Employee</Typography> */
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const chargerType = {
    0: "with charger",
    1: "Without Charger",
}

const initialValues = {
    purchase_id: "",
    brand_master_id: "",
    series_master_id: "",
    model_no: "",

    processor_brand_id: "",
    processor_series_id: "",
    processor_generation_id: "",
    processor_gigaHeartz_id: "",

    graphic_brand_id: "",
    graphic_series_id: "",
    graphic_model_id: "",
    graphic_size_id: "",

    screen_size_id: "",
    screen_resolution_id: "",
    screen_spot_any_id: "",
    ips: false,
    num_lock: false,
    back_lit: false,
    ls_no: "",

    product_type: false,
    status: "",
    processor_id: "",
    processor_image: "",
    ram_image: "",
    general_image: "",
    hard_disk_image: "",
    ssd_image: "",
    external_graphic_image: "",
    screen_size_image: "",
    screen_size_image2: "",
    screen_size_image3: "",
    screen_size_image4: "",
    screen_size_image5: "",
    age_of_laptop_image: "",

    physical_condition_image: "",
    physical_condition_image2: "",
    physical_condition_image3: "",
    physical_condition_image4: "",
    physical_condition_image5: "",

    functional_condition_image: "",
    functional_condition_image2: "",
    functional_condition_image3: "",
    functional_condition_image4: "",
    functional_condition_image5: "",

    ram_id: "",
    hard_disk_id: "",
    ssd_id: "",
    external_graphics_id: "",

    age_of_laptop_id: "",
    physical_condition_id: "",
    functional_condition_id: "",
    date: "",
    price: "",
    vendor_name: "",
    shelf_no: "",
    pickup_person: "",
    charger_type: "",
    problem: "",
    payment_mode: false,
    description: "",

    is_graphic: false,
    touchpad_working: false,
    touchpad_button: false,
    keyboard: false,
    webcam: false,
    wifi: false,
    usb_working: false,
    blutooth: false,
    lan: false,
    hdmi: false,
    vga: false,
    mic: false,
    biometric_sensor: false,
    speaker_left: false,
    speaker_right: false,
    battery: false,
    received_type: false,
}

const UpdatePurchase = () => {
    //modal for select started

    const [brandMasters, setBrandMaster] = useState([])

    const [updatingLsNo, setUpdatingLsNo] = useState(false)
    const [submittedAlready, setSubmittedAlready] = useState(true)
    const [lsNoTemp, setLsNoTemp] = useState("")
    const [processorBrands, setProcessorBrands] = useState([])
    const [processorSeries, setProcessorSeries] = useState([])
    const [processorGenerations, setProcessorGeneration] = useState([])
    const [processorGigaHeartzs, setProcessorGigaHeartz] = useState([])

    const [graphicBrands, setGraphicBrand] = useState([])
    const [graphicSeries, setGraphicSeries] = useState([])
    const [graphicModels, setGraphicModel] = useState([])
    const [graphicSizes, setGraphicSize] = useState([])

    const [screenSizes, setScreenSize] = useState([])
    const [screenResolutions, setScreenResolution] = useState([])
    const [screenSpots, setScreenSpot] = useState([])
    const [isAdmin, setIsAdmin] = useState()
    const [allspec, setAllSpecs] = useState([])

    const [rams, setram] = useState([])
    const [hardDisks, setHardDisk] = useState([])
    const [externalGraphics, setExternalGraphics] = useState([])
    const [ssds, setSSD] = useState([])
    const [ageOfLaptops, setAgeOfLaptop] = useState([])
    const [physicalConditions, setPhysicalConditions] = useState([])
    const [functionalConditions, setfunctionalCondition] = useState([])
    const [employees, setEmployees] = useState([])

    const [showModal, setShowModal] = useState(false)

    const [checkExistence, setCheckExistence] = useState<string>()

    const handleCloseEditModal = () => {
        setShowModal(false)
    }

    useEffect(() => {
        ;(async () => {
            //fetch user to check if he's admin or not
            const user: any = await http.get("auth/me")
            setIsAdmin(user.data.result.isAdmin)

            const data: any = await http.get("/purchase/specs")

            const result = data.data.result
            // console.log('Spec res',result);
            setBrandMaster(result.brandMasters)

            setProcessorBrands(result.processorBrands)
            setProcessorSeries(result.processorSeries)
            setProcessorGeneration(result.processorGenerations)
            setProcessorGigaHeartz(result.processorGigaHeartzs)

            setGraphicBrand(result.graphicBrands)
            setGraphicSeries(result.graphicSeries)
            setGraphicModel(result.graphicModels)
            setGraphicSize(result.graphicSizes)

            setScreenSize(result.screenSizes)
            setScreenResolution(result.screenResolutions)
            setScreenSpot(result.screenSpots)

            setEmployees(result.employees)
            setram(result.rams)
            setHardDisk(result.hardDisks)
            setExternalGraphics(result.externalGraphics)
            setSSD(result.ssds)
            setAgeOfLaptop(result.ageOfLaptops)
            setPhysicalConditions(result.physicalConditions)
            setfunctionalCondition(result.functionalConditions)
        })()
    }, [])

    let params = useParams()
    const navigate = useNavigate()
    const imgs = "https://picsum.photos/200"
    // const [Salary, setPurchaseData] = useState({
    //   ...initialValues,
    //   purchase_id: params.id,
    //
    // });
    const [data, setPurchase] = useState({
        ...initialValues,
        purchase_id: params.id,
    })
    const [imgSrc, setImgSrc] = useState("Invalid Image Source")

    useEffect(() => {
        ;(async function () {
            const salDataRes: any = await http.get("/purchase/first/" + params.id)
            const resultantData = { ...salDataRes.data.result }
            setSubmittedAlready(resultantData.submitted_to_engineer)
            setLsNoTemp(resultantData.ls_no)
            setPurchase(resultantData)

            //  console.log("purchase 1", data);
        })()
    }, [])
    useEffect(() => {
        ;(async function () {
            try {
                const engineerData: any = await http.get("/PurchaseReportToEngineer/")
                // Find the item where ls_no matches data.ls_no
                const matchingItem = engineerData.data.result.find(
                    (item: any) => item.ls_no === data.ls_no
                )

                setCheckExistence(matchingItem.ls_no)
            } catch (error) {
                console.log("this is not in engineer data")
            }
        })()
    }, [])
    const onValueChange = (e: any) => {
        e.preventDefault(e)
        if (e.target.type === "file") {
            const file = e.target.files?.[0]
            if (file) {
                const reader = new FileReader()
                reader.onloadend = () => {
                    setPurchase((prevState) => ({ ...prevState, [e.target.name]: reader.result }))
                }
                reader.readAsDataURL(file)
            }
        } else {
            let value = e.target.value
            if (e.target.name.includes("_checkbox")) {
                value = e.target.checked
            }
            setPurchase((prevState) => ({ ...prevState, [e.target.name]: value }))
        }

        console.log("target namee", e.target.name, "is", e.target.value)
    }
    const [seriesByBrands, setSeriesByBrands] = useState([])
    // console.log("Fetched brand master id===", data.brand_master_id)

    useEffect(() => {
        if (data.brand_master_id) {
        ;(async function () {
            const seriesDataByBrands: any = await http.get(
                "/purchaseReports/series/" + data.brand_master_id
            )
            const allBrandSeries = seriesDataByBrands.data.result

            setSeriesByBrands(allBrandSeries)
        })()
        }
    }, [brandMasters, data.brand_master_id])

    const [processorSeriesByBrands, setProcessorSeriesByBrands] = useState([])

    useEffect(() => {
        if (data.processor_brand_id) {
        ;(async function () {
            const ProseriesDataByBrands: any = await http.get(
                "/purchaseReports/processor-series/" + data.processor_brand_id
            )

            setProcessorSeriesByBrands(ProseriesDataByBrands.data.result)
            // console.log("processorSeriesByBrands",ProseriesDataByBrands.data.result)
        })()
        }
    }, [processorBrands, data.processor_brand_id])

    const updateLsNumber = async (e: any) => {
        console.log("update ls no")
        if (updatingLsNo) return
        if (!lsNoTemp) toast.error("Please enter LS Number")
        setUpdatingLsNo(true)
        try {
            const res = await http.patch(`/purchase/${params.id}/ls-no`, {
                lsNo: lsNoTemp,
            })
            toast.success(res.data.message)
            window.location.reload()
        } catch (err: any) {
            toast.error(err.response.data && err.response.data.message)
            console.log("error in updating ls no.", err)
        }
        setUpdatingLsNo(false)
    }

    const updateData = async (e: any) => {
        e.preventDefault()
        ;(async function () {
            console.log("Data", data)
            let dataCopy: any = data
            const formData: any = new FormData()

            for (let itemKey in dataCopy) {
                formData.append(itemKey, dataCopy[itemKey])
                console.log({ itemKey: dataCopy[itemKey] })
            }
            console.log("Form data of engineer", formData)
            try {
                const res = await http.post("/PurchaseReportToEngineer", formData)
                toast.success(res.data.message)
                navigate("/purchase-report")
            } catch (err: any) {
                toast.error(err.response.data && err.response.data.message)
                console.log("error in loading details", err)
            }
            return
        })()
    }

    const label = { inputProps: { "aria-label": "Checkbox demo" } }

    return (
        <div className="w-full flex flex-col justify-center mx-auto">
            <Typography
                sx={{
                    fontSize: "1em",
                    fontWeight: "bold",
                    marginTop: 1,
                    marginBottom: 1,
                }}
            >
                Purchase ID: {params.id}
            </Typography>

            <form onSubmit={updateData} style={{}}>
                <FormGroup>
                    <Grid container spacing={1}>
                        <Grid item xs={9} spacing={1} sx={{ pr: 3, borderRight: "4px solid #555" }}>
                            <Grid item xs={5} spacing={1}>
                                <FormRow
                                    onValueChange={isAdmin ? onValueChange : undefined}
                                    label={"Arrival Date"}
                                    formName={"date"}
                                    formValue={data.date}
                                    textInputMode
                                />
                            </Grid>
                            <PurchaseFormBlockRow>
                                <EditPurchaseSelectItem
                                    isEditable={isAdmin}
                                    onValueChange={onValueChange}
                                    title={"Brand"}
                                    formName={"brand_master_id"}
                                    formValue={data.brand_master_id}
                                    items={brandMasters}
                                />
                                <EditPurchaseSelectItem
                                    onValueChange={onValueChange}
                                    title={"Series Masters"}
                                    formName={"series_master_id"}
                                    formValue={data.series_master_id}
                                    items={seriesByBrands}
                                />
                                <EditPurchaseSelectItem
                                    onValueChange={onValueChange}
                                    title={"Model No."}
                                    formName={"model_no"}
                                    formValue={data.model_no}
                                    textInputMode
                                />
                                <EditPurchaseImageItem
                                    title="General Image"
                                    formName={"general_image"}
                                    onValueChange={onValueChange}
                                    imageData={data.general_image}
                                />
                            </PurchaseFormBlockRow>

                            <PurchaseFormBlockRow title="Processor">
                                <EditPurchaseSelectItem
                                    onValueChange={onValueChange}
                                    title={"Brand"}
                                    formName={"processor_brand_id"}
                                    formValue={data.processor_brand_id}
                                    items={processorBrands}
                                />
                                <EditPurchaseSelectItem
                                    onValueChange={onValueChange}
                                    title={"Series"}
                                    formName={"processor_series_id"}
                                    formValue={data.processor_series_id}
                                    items={processorSeriesByBrands}
                                />
                                <EditPurchaseSelectItem
                                    onValueChange={onValueChange}
                                    title={"Generation"}
                                    formName={"processor_generation_id"}
                                    formValue={data.processor_generation_id}
                                    items={processorGenerations}
                                />
                                <EditPurchaseSelectItem
                                    onValueChange={onValueChange}
                                    title={"GigaHeartz"}
                                    formName={"processor_gigaHeartz_id"}
                                    formValue={data.processor_gigaHeartz_id}
                                    items={processorGigaHeartzs}
                                />
                                <EditPurchaseImageItem
                                    title="Processor Image"
                                    formName={"processor_image"}
                                    onValueChange={onValueChange}
                                    imageData={data.processor_image}
                                />
                            </PurchaseFormBlockRow>

                            <PurchaseFormBlockRow title="Configruation">
                                <EditPurchaseSelectItem
                                    onValueChange={onValueChange}
                                    title={"Ram"}
                                    formName={"ram_id"}
                                    formValue={data.ram_id}
                                    items={rams}
                                />
                                <EditPurchaseSelectItem
                                    onValueChange={onValueChange}
                                    title={"Hard Disks"}
                                    formName={"hard_disk_id"}
                                    formValue={data.hard_disk_id}
                                    items={hardDisks}
                                />
                                <EditPurchaseSelectItem
                                    onValueChange={onValueChange}
                                    title={"SSD"}
                                    formName={"ssd_id"}
                                    formValue={data.ssd_id}
                                    items={ssds}
                                />
                                <EditPurchaseImageItem
                                    title="Ram Image"
                                    formName={"ram_image"}
                                    onValueChange={onValueChange}
                                    imageData={data.ram_image}
                                />
                                <EditPurchaseImageItem
                                    title="Hard Disk Image"
                                    formName={"hard_disk_image"}
                                    onValueChange={onValueChange}
                                    imageData={data.hard_disk_image}
                                />
                                <EditPurchaseImageItem
                                    title="SSD Image"
                                    formName={"ssd_image"}
                                    onValueChange={onValueChange}
                                    imageData={data.ssd_image}
                                />
                            </PurchaseFormBlockRow>
                            {data.is_graphic ? (
                                <PurchaseFormBlockRow title="Dedicated Graphics">
                                    <EditPurchaseSelectItem
                                        onValueChange={onValueChange}
                                        title={"Brand"}
                                        formName={"graphic_brand_id"}
                                        formValue={data.graphic_brand_id}
                                        // checkboxFormName={"graphic_brand_id_checkbox"}
                                        // checkboxFormValue={data.graphic_brand_id_checkbox}
                                        items={graphicBrands}
                                    />
                                    <EditPurchaseSelectItem
                                        onValueChange={onValueChange}
                                        title={"Series"}
                                        formName={"graphic_series_id"}
                                        formValue={data.graphic_series_id}
                                        // checkboxFormName={"graphic_series_id_checkbox"}
                                        // checkboxFormValue={data.graphic_series_id_checkbox}
                                        items={graphicSeries}
                                    />
                                    <EditPurchaseSelectItem
                                        onValueChange={onValueChange}
                                        title={"Model"}
                                        formName={"graphic_model_id"}
                                        formValue={data.graphic_model_id}
                                        // checkboxFormName={"graphic_model_id_checkbox"}
                                        // checkboxFormValue={data.graphic_model_id_checkbox}
                                        items={graphicModels}
                                    />
                                    <EditPurchaseSelectItem
                                        onValueChange={onValueChange}
                                        title={"Size"}
                                        formName={"graphic_size_id"}
                                        formValue={data.graphic_size_id}
                                        // checkboxFormName={"graphic_size_id_checkbox"}
                                        // checkboxFormValue={data.graphic_size_id_checkbox}
                                        items={graphicSizes}
                                    />
                                    <EditPurchaseImageItem
                                        title="Graphics Image"
                                        formName={"external_graphic_image"}
                                        onValueChange={onValueChange}
                                        imageData={data.external_graphic_image}
                                    />
                                </PurchaseFormBlockRow>
                            ) : (
                                ""
                            )}

                            <PurchaseFormBlockRow title="Screen/Display">
                                <EditPurchaseSelectItem
                                    onValueChange={onValueChange}
                                    title={"Size"}
                                    formName={"screen_size_id"}
                                    formValue={data.screen_size_id}
                                    items={screenSizes}
                                />
                                <EditPurchaseSelectItem
                                    onValueChange={onValueChange}
                                    title={"Resolution"}
                                    formName={"screen_resolution_id"}
                                    formValue={data.screen_resolution_id}
                                    items={screenResolutions}
                                />
                                <EditPurchaseSelectItem
                                    onValueChange={onValueChange}
                                    title={"Spot Any"}
                                    formName={"screen_spot_any_id"}
                                    formValue={data.screen_spot_any_id}
                                    items={screenSpots}
                                />
                                <EditPurchaseImageItem
                                    title="Screen Size Image"
                                    onValueChange={onValueChange}
                                    formName={"screen_size_image"}
                                    imageData={data.screen_size_image}
                                />
                                <EditPurchaseImageItem
                                    title="Screen Size Image 2"
                                    onValueChange={onValueChange}
                                    formName={"screen_size_image2"}
                                    imageData={data.screen_size_image2}
                                />
                                <EditPurchaseImageItem
                                    title="Screen Size Image 3"
                                    onValueChange={onValueChange}
                                    formName={"screen_size_image3"}
                                    imageData={data.screen_size_image3}
                                />
                                <EditPurchaseImageItem
                                    title="Screen Size Image 4"
                                    formName={"screen_size_image4"}
                                    onValueChange={onValueChange}
                                    imageData={data.screen_size_image4}
                                />
                                <EditPurchaseImageItem
                                    title="Screen Size Image 5"
                                    formName={"screen_size_image5"}
                                    onValueChange={onValueChange}
                                    imageData={data.screen_size_image5}
                                />
                            </PurchaseFormBlockRow>

                            <PurchaseFormBlockRow>
                                <EditPurchaseSelectItem
                                    onValueChange={onValueChange}
                                    title={"Select Charger"}
                                    formName={"charger_type"}
                                    formValue={data.charger_type}
                                    items={[
                                        { id: 0, name: "Without Charger" },
                                        { id: 1, name: "With Charger" },
                                    ]}
                                />

                                <EditPurchaseSelectItem
                                    onValueChange={onValueChange}
                                    title={" Age Of Laptop"}
                                    formName={"age_of_laptop_id"}
                                    formValue={data.age_of_laptop_id}
                                    items={ageOfLaptops}
                                />
                                <EditPurchaseImageItem
                                    title="Age Of Laptop Image"
                                    formName={"age_of_laptop_image"}
                                    onValueChange={onValueChange}
                                    imageData={data.age_of_laptop_image}
                                />
                            </PurchaseFormBlockRow>

                            <PurchaseFormBlockRow>
                                <EditPurchaseSelectItem
                                    onValueChange={onValueChange}
                                    title={"Physical Condition"}
                                    formName={"physical_condition_id"}
                                    formValue={data.physical_condition_id}
                                    items={physicalConditions}
                                />

                                <EditPurchaseImageItem
                                    title="Functional Condition Image 2"
                                    formName={"functional_condition_image2"}
                                    formValue={data.functional_condition_image2}
                                    onValueChange={onValueChange}
                                    imageData={data.functional_condition_image2}
                                />
                                <EditPurchaseImageItem
                                    title="Functional Condition Image 3"
                                    formName={"functional_condition_image3"}
                                    // formValue={data.functional_condition_image3}
                                    onValueChange={onValueChange}
                                    imageData={data.functional_condition_image3}
                                />
                                <EditPurchaseImageItem
                                    title="Functional Condition Image 4"
                                    formName={"functional_condition_image4"}
                                    // formValue={data.functional_condition_image4}
                                    onValueChange={onValueChange}
                                    imageData={data.functional_condition_image4}
                                />
                                <EditPurchaseImageItem
                                    title="Functional Condition Image 5"
                                    formName={"functional_condition_image5"}
                                    // formValue={data.functional_condition_image5}
                                    onValueChange={onValueChange}
                                    imageData={data.functional_condition_image5}
                                />
                            </PurchaseFormBlockRow>
                            <div className="relative shadow-lg p-6 bg-white rounded-lg mb-8">
                                <div className="grid grid-cols-2 gap-5">
                                    <EditPurchaseSelectItem
                                        onValueChange={onValueChange}
                                        title={"Vendor Name"}
                                        formName={"vendor_name"}
                                        formValue={data.vendor_name}
                                        textInputMode
                                    />
                                    <EditPurchaseSelectItem
                                        onValueChange={onValueChange}
                                        title={"Problem (if any)"}
                                        formName={"problem"}
                                        formValue={data.problem}
                                        textInputMode
                                    />
                                </div>
                            </div>
                            <div className="relative shadow-lg p-6 bg-white rounded-lg mb-8">
                                <div className="grid grid-cols-2 gap-5">
                                    <EditPurchaseSelectItem
                                        onValueChange={onValueChange}
                                        title={"Description / Comments"}
                                        formName={"description"}
                                        formValue={data.description}
                                        textInputMode
                                        multiline={true}
                                    />
                                    <EditPurchaseSelectItem
                                        onValueChange={onValueChange}
                                        title={"Received Type"}
                                        formName={"received_type"}
                                        formValue={data.received_type}
                                        items={[
                                            { id: 0, name: "Received For Purchase" },
                                            { id: 1, name: "Received For Repair" },
                                        ]}
                                    />
                                </div>
                            </div>
                            {/* <PurchaseFormBlockRow>
                <Box
                  sx={{
                    background: "#fff",
                    width: '100%',
                    pt: 1,
                    pl: 1,
                    borderRadius: 3, 
                  }}
                >
                    <h5 style={{marginTop:0}}>Received Type</h5>
                  <Stack direction="row" sx={{ width: "100%" }}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={data.received_type}
                      name="received_type"
                      
                    >
                      <FormControlLabel
                        value="0"
                        name="received_type"
                        onChange={(e) => onValueChange(e)}
                        control={<Radio  />}
                        label="Received For Purchase"
                        sx={{ mb: 1, width: "100%" }}
                      />
                      <FormControlLabel
                        value="1"
                        name="received_type"
                        onChange={(e) => onValueChange(e)}
                        control={<Radio  />}
                        label="Received For Repair"
                      />
                    </RadioGroup>
                  </Stack>
                </Box>
              </PurchaseFormBlockRow> */}
                        </Grid>
                        <Grid item xs={3} spacing={1}>
                            <FormRow
                                onValueChange={(e: any) => setLsNoTemp(e.target.value)}
                                label={"LS No"}
                                formName={"ls_no"}
                                formValue={lsNoTemp}
                                textInputMode
                                readonly={false}
                            />
                            <div className="my-3">
                                <Button
                                    disabled={submittedAlready}
                                    variant="contained"
                                    onClick={updateLsNumber}
                                >
                                    {updatingLsNo ? "Please wait..." : "Update LS Number"}
                                </Button>
                            </div>

                            <FormRow
                                onValueChange={onValueChange}
                                label={"Shelf No"}
                                formName={"shelf_no"}
                                formValue={data.shelf_no}
                                textInputMode
                            />
                            <FormRow
                                onValueChange={onValueChange}
                                label={"Price"}
                                formName={"price"}
                                formValue={data.price}
                                textInputMode
                            />
                            <FormRow
                                onValueChange={onValueChange}
                                label={"Purchase Type"}
                                formName={"product_type"}
                                formValue={data.product_type}
                                items={[
                                    { id: 0, name: "On Approval" },
                                    { id: 1, name: "On Purchase" },
                                ]}
                            />

                            <FormRow
                                onValueChange={onValueChange}
                                label={"Pickup Person"}
                                formName={"pickup_person"}
                                formValue={data.pickup_person}
                                items={employees.map((employee: any) => ({
                                    id: employee.id,
                                    name: employee.firstName + " " + employee.lastName,
                                }))}
                            />
                            <FormRow
                                onValueChange={onValueChange}
                                label={"Select Payment Mode"}
                                formName={"payment_mode"}
                                formValue={data.payment_mode}
                                items={[
                                    {
                                        id: 0,
                                        name: "Cash",
                                    },
                                    {
                                        id: 1,
                                        name: "Online",
                                    },
                                ]}
                            />
                            {/* <FormRow
                onValueChange={onValueChange}
                label={"Status"}
                formName={"status"}
                formValue={data.status}
                items={[
                  { id: 0, name: "Test Pending" },
                  { id: 1, name: "In Process" },
                  {
                    id: 2,
                    name: "Ready to dispatch",
                  },
                ]}
              /> */}
                        </Grid>
                        <Grid item xs={3} sx={{ display: "none" }}>
                            <FormTextEngineer
                                label="is graphic"
                                formName="is_graphic"
                                formValue={data.is_graphic}
                                onValueChange={onValueChange}
                            ></FormTextEngineer>

                            <FormHelperText>
                                <h3>TouchPad Working </h3>
                            </FormHelperText>
                            <RadioGroup
                                name="touchpad_working"
                                value={data.touchpad_working}
                                aria-labelledby="demo-radio-buttons-group-label"
                            >
                                <FormControlLabel
                                    value="1"
                                    name="touchpad_working"
                                    onChange={(e) => onValueChange(e)}
                                    control={
                                        <Radio required={true} checked={data.touchpad_working} />
                                    }
                                    label="Yes"
                                />
                                <FormControlLabel
                                    value="0"
                                    name="touchpad_working"
                                    onChange={(e) => onValueChange(e)}
                                    control={
                                        <Radio required={true} checked={!data.touchpad_working} />
                                    }
                                    label="NO"
                                />
                            </RadioGroup>

                            <h3>TouchPad Button </h3>

                            <RadioGroup
                                name="touchpad_button"
                                value={data.touchpad_button}
                                aria-labelledby="demo-radio-buttons-group-label"
                                onChange={(e) => onValueChange(e)}
                            >
                                <FormControlLabel
                                    value="1"
                                    name="touchpad_button"
                                    control={
                                        <Radio required={true} checked={data.touchpad_button} />
                                    }
                                    label="Yes"
                                />
                                <FormControlLabel
                                    value="0"
                                    name="touchpad_button"
                                    onChange={(e) => onValueChange(e)}
                                    control={
                                        <Radio required={true} checked={!data.touchpad_button} />
                                    }
                                    label="No"
                                />
                            </RadioGroup>

                            <FormHelperText>
                                <h3>Keyboard </h3>{" "}
                            </FormHelperText>
                            <RadioGroup
                                name="keyboard"
                                value={data.keyboard}
                                aria-labelledby="demo-radio-buttons-group-label"
                            >
                                <FormControlLabel
                                    value="1"
                                    name="keyboard"
                                    onChange={(e) => onValueChange(e)}
                                    control={<Radio required={true} checked={data.keyboard} />}
                                    label="Yes"
                                />
                                <FormControlLabel
                                    value="0"
                                    name="keyboard"
                                    onChange={(e) => onValueChange(e)}
                                    control={<Radio required={true} checked={!data.keyboard} />}
                                    label="No"
                                />
                            </RadioGroup>

                            <FormHelperText>
                                <h3>Webcam </h3>{" "}
                            </FormHelperText>
                            <RadioGroup
                                name="webcam"
                                value={data.webcam}
                                aria-labelledby="demo-radio-buttons-group-label"
                            >
                                <FormControlLabel
                                    value="1"
                                    name="webcam"
                                    onChange={(e) => onValueChange(e)}
                                    control={<Radio required={true} checked={data.webcam} />}
                                    label="Yes"
                                />
                                <FormControlLabel
                                    value="0"
                                    name="webcam"
                                    onChange={(e) => onValueChange(e)}
                                    control={<Radio required={true} checked={!data.webcam} />}
                                    label="No"
                                />
                            </RadioGroup>

                            <FormHelperText>
                                <h3>Wifi </h3>{" "}
                            </FormHelperText>
                            <RadioGroup
                                name="wifi"
                                value={data.wifi}
                                aria-labelledby="demo-radio-buttons-group-label"
                            >
                                <FormControlLabel
                                    value="1"
                                    name="wifi"
                                    onChange={(e) => onValueChange(e)}
                                    control={<Radio required={true} checked={data.wifi} />}
                                    label="Yes"
                                />
                                <FormControlLabel
                                    value="0"
                                    name="wifi"
                                    onChange={(e) => onValueChange(e)}
                                    control={<Radio required={true} checked={!data.wifi} />}
                                    label="No"
                                />
                            </RadioGroup>

                            <FormHelperText>
                                <h3>Usb Working </h3>{" "}
                            </FormHelperText>
                            <RadioGroup
                                name="usb_working"
                                value={data.usb_working}
                                aria-labelledby="demo-radio-buttons-group-label"
                            >
                                <FormControlLabel
                                    value="1"
                                    name="usb_working"
                                    onChange={(e) => onValueChange(e)}
                                    control={<Radio required={true} checked={data.usb_working} />}
                                    label="Yes"
                                />
                                <FormControlLabel
                                    value="0"
                                    name="usb_working"
                                    onChange={(e) => onValueChange(e)}
                                    control={<Radio required={true} checked={!data.usb_working} />}
                                    label="No"
                                />
                            </RadioGroup>

                            <FormHelperText>
                                <h3>Blutooth </h3>{" "}
                            </FormHelperText>
                            <RadioGroup
                                name="blutooth"
                                value={data.blutooth}
                                aria-labelledby="demo-radio-buttons-group-label"
                            >
                                <FormControlLabel
                                    value="1"
                                    name="blutooth"
                                    onChange={(e) => onValueChange(e)}
                                    control={<Radio required={true} checked={data.blutooth} />}
                                    label="Yes"
                                />
                                <FormControlLabel
                                    value="0"
                                    name="blutooth"
                                    onChange={(e) => onValueChange(e)}
                                    control={<Radio required={true} checked={!data.blutooth} />}
                                    label="No"
                                />
                            </RadioGroup>

                            <FormHelperText>
                                <h3>LAN </h3>{" "}
                            </FormHelperText>
                            <RadioGroup
                                name="lan"
                                value={data.lan}
                                aria-labelledby="demo-radio-buttons-group-label"
                            >
                                <FormControlLabel
                                    value="1"
                                    name="lan"
                                    onChange={(e) => onValueChange(e)}
                                    control={<Radio required={true} checked={data.lan} />}
                                    label="Yes"
                                />
                                <FormControlLabel
                                    value="0"
                                    name="lan"
                                    onChange={(e) => onValueChange(e)}
                                    control={<Radio required={true} checked={!data.lan} />}
                                    label="No"
                                />
                            </RadioGroup>

                            <FormHelperText>
                                <h3>HDMI </h3>{" "}
                            </FormHelperText>
                            <RadioGroup
                                name="hdmi"
                                value={data.hdmi}
                                aria-labelledby="demo-radio-buttons-group-label"
                            >
                                <FormControlLabel
                                    value="1"
                                    name="hdmi"
                                    onChange={(e) => onValueChange(e)}
                                    control={<Radio required={true} checked={data.hdmi} />}
                                    label="Yes"
                                />
                                <FormControlLabel
                                    value="0"
                                    name="hdmi"
                                    onChange={(e) => onValueChange(e)}
                                    control={<Radio required={true} checked={!data.hdmi} />}
                                    label="No"
                                />
                            </RadioGroup>

                            <FormHelperText>
                                <h3>VGA </h3>{" "}
                            </FormHelperText>
                            <RadioGroup
                                name="vga"
                                value={data.vga}
                                aria-labelledby="demo-radio-buttons-group-label"
                            >
                                <FormControlLabel
                                    value="1"
                                    name="vga"
                                    onChange={(e) => onValueChange(e)}
                                    control={<Radio required={true} checked={data.vga} />}
                                    label="Yes"
                                />
                                <FormControlLabel
                                    value="0"
                                    name="vga"
                                    onChange={(e) => onValueChange(e)}
                                    control={<Radio required={true} checked={!data.vga} />}
                                    label="No"
                                />
                            </RadioGroup>

                            <FormHelperText>
                                <h3>MIC </h3>{" "}
                            </FormHelperText>
                            <RadioGroup
                                name="mic"
                                value={data.mic}
                                aria-labelledby="demo-radio-buttons-group-label"
                            >
                                <FormControlLabel
                                    value="1"
                                    name="mic"
                                    onChange={(e) => onValueChange(e)}
                                    control={<Radio required={true} checked={data.mic} />}
                                    label="Yes"
                                />
                                <FormControlLabel
                                    value="0"
                                    name="mic"
                                    onChange={(e) => onValueChange(e)}
                                    control={<Radio required={true} checked={!data.mic} />}
                                    label="No"
                                />
                            </RadioGroup>

                            <FormHelperText>
                                <h3>Biometric Sensor </h3>{" "}
                            </FormHelperText>
                            <RadioGroup
                                name="biometric_sensor"
                                value={data.biometric_sensor}
                                aria-labelledby="demo-radio-buttons-group-label"
                            >
                                <FormControlLabel
                                    value="1"
                                    name="biometric_sensor"
                                    onChange={(e) => onValueChange(e)}
                                    control={
                                        <Radio required={true} checked={data.biometric_sensor} />
                                    }
                                    label="Yes"
                                />
                                <FormControlLabel
                                    value="0"
                                    name="biometric_sensor"
                                    onChange={(e) => onValueChange(e)}
                                    control={
                                        <Radio required={true} checked={!data.biometric_sensor} />
                                    }
                                    label="No"
                                />
                            </RadioGroup>

                            <FormHelperText>
                                <h3>Speaker Left </h3>{" "}
                            </FormHelperText>
                            <RadioGroup
                                name="speaker_left"
                                value={data.speaker_left}
                                aria-labelledby="demo-radio-buttons-group-label"
                            >
                                <FormControlLabel
                                    value="1"
                                    name="speaker_left"
                                    onChange={(e) => onValueChange(e)}
                                    control={<Radio required={true} checked={data.speaker_left} />}
                                    label="Yes"
                                />
                                <FormControlLabel
                                    value="0"
                                    name="speaker_left"
                                    onChange={(e) => onValueChange(e)}
                                    control={<Radio required={true} checked={!data.speaker_left} />}
                                    label="No"
                                />
                            </RadioGroup>

                            <FormHelperText>
                                <h3>Speaker Right </h3>{" "}
                            </FormHelperText>
                            <RadioGroup
                                name="speaker_right"
                                value={data.speaker_right}
                                aria-labelledby="demo-radio-buttons-group-label"
                            >
                                <FormControlLabel
                                    value="1"
                                    name="speaker_right"
                                    onChange={(e) => onValueChange(e)}
                                    control={<Radio required={true} checked={data.speaker_right} />}
                                    label="Yes"
                                />
                                <FormControlLabel
                                    value="0"
                                    name="speaker_right"
                                    onChange={(e) => onValueChange(e)}
                                    control={
                                        <Radio required={true} checked={!data.speaker_right} />
                                    }
                                    label="No"
                                />
                            </RadioGroup>

                            <FormHelperText>
                                <h3>Battery </h3>{" "}
                            </FormHelperText>
                            <RadioGroup
                                name="battery"
                                value={data.battery}
                                aria-labelledby="demo-radio-buttons-group-label"
                            >
                                <FormControlLabel
                                    value="1"
                                    name="battery"
                                    onChange={(e) => onValueChange(e)}
                                    control={<Radio required={true} checked={data.battery} />}
                                    label="Yes"
                                />
                                <FormControlLabel
                                    value="0"
                                    name="battery"
                                    onChange={(e) => onValueChange(e)}
                                    control={<Radio required={true} checked={!data.battery} />}
                                    label="No"
                                />
                            </RadioGroup>

                            {/* <FormHelperText>
                <h3>IS Graphic </h3>{" "}
              </FormHelperText>
              <RadioGroup
                name="is_graphic"
                value={data.is_graphic}
                aria-labelledby="demo-radio-buttons-group-label"
              >
                <FormControlLabel
                  value="1"
                  name="is_graphic"
                  onChange={(e) => onValueChange(e)}
                  control={<Radio required={true} checked={data.is_graphic} />}
                  label="Yes"
                />
                <FormControlLabel
                  value="0"
                  name="is_graphic"
                  onChange={(e) => onValueChange(e)}
                  control={<Radio required={true} checked={!data.is_graphic} />}
                  label="No"
                />
              </RadioGroup> */}
                        </Grid>
                    </Grid>
                    <FormControl sx={{ my: 4 }}>
                        <Button
                            variant="contained"
                            sx={{ maxWidth: 300 }}
                            type="submit"
                            disabled={submittedAlready || checkExistence == data.ls_no}
                        >
                            {submittedAlready ? "Submitted Already" : "Submit to Engineer"}
                        </Button>
                    </FormControl>
                </FormGroup>
            </form>
        </div>
    )
}
export default UpdatePurchase
