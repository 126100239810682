import { useState, useEffect } from "react";
import { http } from "../../utils/http";
import { useParams } from "react-router-dom";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import MenuItem from "@mui/material/MenuItem";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { useNavigate } from "react-router-dom";

import {
  FormGroup,
  FormControl,
  Grid,
  Button,
  styled,
  OutlinedInput,
  FormHelperText,

} from "@mui/material";
import { width } from "@mui/system";

const Container = styled(FormGroup)`
  width: 100%;
`;
{
  /* <Typography variant="h4">Add New Employee</Typography> */
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const initialValues = {
  functional_id: "",
  name: "",
  description:"",
  condition:"",
};

const EditFunctionalCondition = () => {
 
  const [conditions, setCondition] = useState([]);

  useEffect(() => {
    (async () => {
      const data: any = await http.get("/conditions");
      setCondition(data.data.result);
    })();
  }, []);

  let params = useParams();
  const navigate = useNavigate();
  const [ram, setRam] = useState({ ...initialValues, functional_id: params.id });
  
  const [FunctionalUpdate, setFunctionalUpdate] = useState({ ...initialValues ,
    description:''});
  
  useEffect(() => {
      (async () => {
          const data: any = await http.get("/functional-condition/" + params.id);
          setFunctionalUpdate(data.data.result);
        })();
    }, []);
    
       
  const onValueChange = (e: any) => {
    e.preventDefault(e);
    setFunctionalUpdate({ ...FunctionalUpdate, [e.target.name]: e.target.value });
// console.log('name is ', e.target.name, 'value is', e.target.value);
  };
  const EditFunctionalConditiondetail = async (e: any) => {
    e.preventDefault();
    try {
    //    console.log('data',FunctionalUpdate);
      await http.patch("/functional-condition/"+params.id, FunctionalUpdate);
      navigate("/functional-condition");
      return;
    } catch (error: any) {
      console.log("error while calling api", error.message);
    }
  };

  return (
    <div>
      <h2>FunctionalCondition ID: {params.id}</h2>
      <form onSubmit={EditFunctionalConditiondetail}>
        <FormGroup>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>FunctionalCondition ID</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  disabled
                  sx={{ background: "white" }}
                  value={ram.functional_id}
                  onChange={(e) => onValueChange(e)}
                  name="functional_id"
                 
                 
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>Name</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  sx={{ background: "white" }}
                  onChange={(e) => onValueChange(e)}
                  name="name"
                  value={FunctionalUpdate.name}
                  
                />
              </FormControl>
            </Grid>
            
            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>Description</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  sx={{ background: "white" }}
                  onChange={(e) => onValueChange(e)}
                  name="description"
                  value={FunctionalUpdate.description}
                  
                />
              </FormControl>
            </Grid> 
            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>Condition</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  sx={{ background: "white" }}
                  onChange={(e) => onValueChange(e)}
                  name="condition"
                  value={FunctionalUpdate.condition}
                  
                />
              </FormControl>
            </Grid> 
          
          </Grid>

          <FormControl sx={{ my: 4 }}>
            <Button variant="contained" sx={{ maxWidth: 200 }} type="submit">
              Update
            </Button>
          </FormControl>
        </FormGroup>
      </form>
    </div>
  );
};
export default EditFunctionalCondition;
