import React from 'react';
import {http} from '../../utils/http'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default function DeleteModal({ showModal, setShowModal,scrapData,paramId }: {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
    scrapData: any; 
    paramId:any
  }) {

 const navigae=useNavigate()

  const handleConfirmDelete = async () => {
    try {
      // Perform the DELETE request
      const res=await http.delete(`/PurchaseReportToEngineer/${paramId}`);
      console.log(res);
      
      const scrapres=await http.post('/scrap/', scrapData);
      console.log(scrapres);
      
      setShowModal(false);
      toast.success('Item deleted and moved to scrap successfully!');
      navigae('/scraps')
    } catch (error) {
      // Handle errors
      console.error('An error occurred during the delete operation:', error);
      toast.error('An error occurred while deleting the item. Please try again.');
    }
  };
    return (
    <div className='z-[9999]'>
      {showModal && (
        <div id="deleteModal" tabIndex={-1} aria-hidden="true" className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-[rgba(0,0,0,0.75)] "></div>
            </div>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>

            <div
              className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full sm:max-w-md"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <button
                  type="button"
                  className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  onClick={() => setShowModal(!showModal)}
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
                {/* Rest of your modal content */}
                <p className="mb-4 text-gray-500 dark:text-gray-300">Are you sure you want to Scrap(deleted) this Purchase?</p>
                <div className="flex justify-center items-center space-x-4">
                  <button
                    onClick={() => setShowModal(false)}
                    type="button"
                    className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                  >
                    No, cancel
                  </button>
                  <button
                    type="submit"
                    className="py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900"
                    onClick={handleConfirmDelete} 
                >
                    Yes, I'm sure
                </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
