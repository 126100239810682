import * as React from "react";
import { useState, useEffect } from "react";
import { http } from "../../utils/http";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { NavLink, Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";


export default function BasicTable() {
    const [arrivedRepairDatas, setArrivedRepairData] = useState([]);
  useEffect(() => {
    (async function () {
      const purchaseDataRes = await http.get("/engineer-dashboard");
      setArrivedRepairData(purchaseDataRes.data.arrivedRepair);
    })();
  }, []);

  const [open, setOpen] = React.useState(false);
 
  return (
    <div>
      <h3>Arrived For Repairing </h3>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 550 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow sx={{ background: "skyBlue", fontWeight: 800 }}>
              <TableCell>ID</TableCell>
              <TableCell>Product Type</TableCell>
              <TableCell>LS No.</TableCell>

              <TableCell>Shelf No.</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {arrivedRepairDatas &&
              arrivedRepairDatas.map((row: any) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell>
                    {row.product_type ? "On Purchase" : "On Approval"}
                  </TableCell>
                  <TableCell>{row.ls_no}</TableCell>

                  <TableCell>{row.shelf_no}</TableCell>
                  <TableCell>{row.date}</TableCell>

                  <TableCell>
                    <NavLink
                      to={`/view-engineer-report/${row.id}`}
                      key={row.id}
                    >
                      <Button variant="outlined" size="small" sx={{ mb: 2 }}>
                        View
                      </Button>
                    </NavLink>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
