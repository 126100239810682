import React, { useState, useEffect } from "react";
import { http } from "../../utils/http";
import {
    Button,
    Box,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography,
    Pagination,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import AddIcon from "@mui/icons-material/Add";
import { FcClearFilters } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { isAllowed } from "../../utils/common";
import PageTitleCard from "../../components/layout/page-title-card";
import { DataGrid, GridColDef, GridEventListener, GridRenderCellParams, GridRowModel, GridRowModesModel, gridClasses } from "@mui/x-data-grid";
import { Brand, ProcessorBrand, PurchaseDataItem } from "../../types";
import { grey } from "@mui/material/colors";
import { Edit } from '@mui/icons-material';


// Error Boundary Component
class ErrorBoundary extends React.Component<{ children: React.ReactNode }, { hasError: boolean }> {
    constructor(props: { children: React.ReactNode }) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.error("Uncaught error:", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <h1>Something went wrong.</h1>;
        }
        return this.props.children;
    }
}

const PAGE_SIZE = 15;

const formatDateToDDMMYY = (date: string | Date | null): string => {
    if (!date) return 'N/A';
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = String(d.getFullYear()).slice(-2);
    return `${day}-${month}-${year}`;
};

const BasicTable = () => {
    const [purchaseData, setPurchaseData] = useState<PurchaseDataItem[]>([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [startDateFilter, setStartDateFilter] = useState<Date | null>(null);
    const [endDateFilter, setEndDateFilter] = useState<Date | null>(null);
    const [selectedSalesUser, setSelectedSalesUser] = useState<string>("");
    const [selectedProductType, setSelectedProductType] = useState<string>("");
    const [filteredPurchaseData, setFilteredPurchaseData] = useState<PurchaseDataItem[]>([]);
    const [totalRows, setTotalRows] = useState(0);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [brandMasters, setBrandMaster] = useState<Brand[]>([]);
    const [processorBrands, setProcessorBrands] = useState<ProcessorBrand[]>([]);
    const [seriesByBrands, setSeriesByBrands] = useState<Brand[]>([]);
    const [processorSeries, setProcessorSeries] = useState<ProcessorBrand[]>([]);
    const [processorGenerations, setProcessorGeneration] = useState<ProcessorBrand[]>([]);
    const [processorGigaHeartzs, setProcessorGigaHeartz] = useState<ProcessorBrand[]>([]);
    const [graphicBrands, setGraphicBrand] = useState<ProcessorBrand[]>([]);
    const [graphicSeries, setGraphicSeries] = useState<ProcessorBrand[]>([]);
    const [graphicModels, setGraphicModel] = useState<ProcessorBrand[]>([]);
    const [graphicSizes, setGraphicSize] = useState<ProcessorBrand[]>([]);
    const [screenSizes, setScreenSize] = useState<ProcessorBrand[]>([]);
    const [screenResolutions, setScreenResolution] = useState<ProcessorBrand[]>([]);
    const [screenSpots, setScreenSpot] = useState<ProcessorBrand[]>([]);
    const [rams, setram] = useState<ProcessorBrand[]>([]);
    const [hardDisks, setHardDisk] = useState<ProcessorBrand[]>([]);
    const [externalGraphics, setExternalGraphics] = useState<ProcessorBrand[]>([]);
    const [ssds, setSSD] = useState<ProcessorBrand[]>([]);
    const [ageOfLaptops, setAgeOfLaptop] = useState<ProcessorBrand[]>([]);
    const [physicalConditions, setPhysicalConditions] = useState<ProcessorBrand[]>([]);
    const [functionalConditions, setfunctionalCondition] = useState<ProcessorBrand[]>([]);
    const [employees, setEmployees] = useState<ProcessorBrand[]>([]);
    const [rows, setRows] = useState<any[]>([]);


    const [updatingLsNo, setUpdatingLsNo] = useState(false);
    const [submittedAlready, setSubmittedAlready] = useState(true);
    const [lsNoTemp, setLsNoTemp] = useState("");
    const [isAdmin, setIsAdmin] = useState();
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 15,
    })
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const auth = useSelector((state: RootState) => state.auth);

    useEffect(() => {
        const fetchData = async () => {
          setIsLoading(true);
          try {
            const response = await http.get(`/purchase?page=${paginationModel.page + 1}&itemsPerPage=${paginationModel.pageSize}`);
            setPurchaseData(response.data.purchases);
            setTotalRows(response.data.totalItems);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
          setIsLoading(false);
        };
      
        fetchData();
      }, [paginationModel]);
      

    useEffect(() => {
        const filteredData = purchaseData
            .filter((row: PurchaseDataItem) => {
                const rowDate = new Date(row.date);
                const rowDateWithoutTime = new Date(rowDate.toISOString().split("T")[0]);
                const startDateFilterWithoutTime = startDateFilter ? new Date(startDateFilter.toISOString().split("T")[0]) : null;
                const endDateFilterWithoutTime = endDateFilter ? new Date(endDateFilter.toISOString().split("T")[0]) : null;

                return (
                    (!startDateFilterWithoutTime || rowDateWithoutTime >= startDateFilterWithoutTime) &&
                    (!endDateFilterWithoutTime || rowDateWithoutTime <= endDateFilterWithoutTime) &&
                    (row.vendor_name === selectedSalesUser || selectedSalesUser === "") &&
                    (row.product_type.toString() === selectedProductType || selectedProductType === "")
                );
            });

        setFilteredPurchaseData(filteredData);
    }, [purchaseData, startDateFilter, endDateFilter, selectedSalesUser, selectedProductType]);

    const salesUsers = Array.from(new Set(purchaseData.map((item: PurchaseDataItem) => item.vendor_name)));
    const productTypes = Array.from(new Set(purchaseData.map((item: PurchaseDataItem) => item.product_type.toString())));

    const handleClearFilters = () => {
        setSelectedSalesUser("");
        setSelectedProductType("");
        setStartDateFilter(null);
        setEndDateFilter(null);
    };

    useEffect(() => {
        (async () => {
            //fetch user to check if he's admin or not
            const user: any = await http.get("auth/me");
            setIsAdmin(user.data.result.isAdmin);

            const data: any = await http.get("/purchase/specs");

            const result = data.data.result;
            setBrandMaster(result.brandMasters);
            setProcessorBrands(result.processorBrands);
            setProcessorGeneration(result.processorGenerations);
            setProcessorGigaHeartz(result.processorGigaHeartzs);
            setGraphicBrand(result.graphicBrands);
            setGraphicSeries(result.graphicSeries);
            setGraphicModel(result.graphicModels);
            setGraphicSize(result.graphicSizes);
            setScreenSize(result.screenSizes);
            setScreenResolution(result.screenResolutions);
            setScreenSpot(result.screenSpots);
            setEmployees(result.employees);
            setram(result.rams);
            setHardDisk(result.hardDisks);
            setExternalGraphics(result.externalGraphics);
            setSSD(result.ssds);
            setAgeOfLaptop(result.ageOfLaptops);
            setPhysicalConditions(result.physicalConditions);
            setfunctionalCondition(result.functionalConditions);
        })();
    }, []);

    useEffect(() => {
        (async function () {
            const seriesDataByBrands: any = await http.get(
                "/purchaseReports/series/" + purchaseData[0]?.brand_master_id
            );
            setSeriesByBrands(seriesDataByBrands.data.result);
        })();
    }, [brandMasters, purchaseData]);

    useEffect(() => {
        (async function () {
            const ProseriesDataByBrands: any = await http.get(
                "/purchaseReports/processor-series/" + purchaseData[0]?.processor_brand_id
            );
            setProcessorSeries(ProseriesDataByBrands.data.result);
        })();
    }, [processorBrands, purchaseData]);

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'product_type',
            headerName: 'Product Type',

            width: 150,
            valueFormatter: ({ value }) => value === "true" ? "On Purchase" : "On Approval"
        },
        {
            field: 'ls_no',
            headerName: 'LS No.',

            width: 130,
            // valueFormatter: ({ value }) => value || 'N/A'
        },
        {
            field: 'price',
            headerName: 'Price',

            width: 130,
            // valueFormatter: ({ value }) =>  ₹${value}
        },
        {
            field: 'shelf_no',
            headerName: 'Shelf No.',

            width: 130,
            // valueFormatter: ({ value }) => value || 'N/A'
        },
        {
            field: 'date',
            headerName: 'Date',

            width: 130,
            // valueFormatter: ({ value }) => value ? formatDateToDDMMYY(value) : 'N/A'
        },
        { field: "vendor_name", headerName: "Vendor Name", width: 150, editable: true },
        {
            field: 'brand_master_id',
            headerName: 'Brand',
            width: 150,
            editable: isAdmin,
            type: 'singleSelect',
            valueOptions: brandMasters.map((brand) => ({
                value: brand.id,
                label: brand.name,
            })),
            valueFormatter: (params: GridRenderCellParams) => {
                const brand = brandMasters.find((b: any) => b.id == params);
                return brand ? brand.name : '';
            },
        },
        {
            field: 'series_master_id',
            headerName: 'Series',
            width: 150,

            type: 'singleSelect',
            valueOptions: seriesByBrands.map((series: any) => ({
                value: series.id,
                label: series.name,
            })),
            valueFormatter: (params: GridRenderCellParams) => {
                const series = seriesByBrands.find((brand: any) => brand.id == params);
                return series ? series.name : '';
            },
        },
        {
            field: 'processor_brand_id',
            headerName: 'Processor Brand',
            width: 150,

            type: 'singleSelect',
            valueOptions: processorBrands.map((processorBrand) => ({
                value: processorBrand.id,
                label: processorBrand.name,
            })),
            valueFormatter: (params: GridRenderCellParams) => {
                const processorBrand = processorBrands.find((pb: any) => pb.id == params);
                return processorBrand ? processorBrand.name : '';
            },
        },
        {
            field: 'processor_series_id',
            headerName: 'Processor Series',
            width: 150,

            type: 'singleSelect',
            valueOptions: processorSeries.map((series) => ({
                value: series.id,
                label: series.name,
            })),
            valueFormatter: (params: GridRenderCellParams) => {
                const series = processorSeries.find((s: any) => s.id == params);
                return series ? series.name : '';
            },
        },
        {
            field: 'processor_generation_id',
            headerName: 'Processor Generation',
            width: 150,

            type: 'singleSelect',
            valueOptions: processorGenerations.map((generation) => ({
                value: generation.id,
                label: generation.name,
            })),
            valueFormatter: (params: GridRenderCellParams) => {
                const generation = processorGenerations.find((g: any) => g.id == params);
                return generation ? generation.name : '';
            },
        },
        {
            field: 'ram_id',
            headerName: 'RAM',
            width: 150,

            type: 'singleSelect',
            valueOptions: rams.map((ram) => ({
                value: ram.id,
                label: ram.name,
            })),
            renderCell: (params: GridRenderCellParams) => {
                const ram = rams.find((r) => r.id == params.row.ram_id);
                return ram ? ram.name : '';
            },
        },
        {
            field: 'hard_disk_id',
            headerName: 'Hard Disk',
            width: 150,

            type: 'singleSelect',
            valueOptions: hardDisks.map((hardDisk) => ({
                value: hardDisk.id,
                label: hardDisk.name,
            })),
            renderCell: (params: GridRenderCellParams) => {
                const hardDisk = hardDisks.find((h) => h.id == params.row.hard_disk_id);
                return hardDisk ? hardDisk.name : '';
            },
        },
        {
            field: 'ssd_id',
            headerName: 'SSD',
            width: 150,

            type: 'singleSelect',
            valueOptions: ssds.map((ssd) => ({
                value: ssd.id,
                label: ssd.name,
            })),
            renderCell: (params: GridRenderCellParams) => {
                const ssd = ssds.find((s) => s.id == params.row.ssd_id);
                return ssd ? ssd.name : '';
            },
        },
        {
            field: 'screen_size_id',
            headerName: 'Screen Size',
            width: 150,

            type: 'singleSelect',
            valueOptions: screenSizes.map((size) => ({
                value: size.id,
                label: size.name,
            })),
            renderCell: (params: GridRenderCellParams) => {
                const size = screenSizes.find((s) => s.id == params.row.screen_size_id);
                return size ? size.name : '';
            },
        },
        {
            field: 'screen_resolution_id',
            headerName: 'Screen Resolution',
            width: 150,

            type: 'singleSelect',
            valueOptions: screenResolutions.map((resolution) => ({
                value: resolution.id,
                label: resolution.name,
            })),
            renderCell: (params: GridRenderCellParams) => {
                const resolution = screenResolutions.find((r) => r.id == params.row.screen_resolution_id);
                return resolution ? resolution.name : '';
            },
        },
        {
            field: 'screen_spot_id',
            headerName: 'Screen Spot',
            width: 150,

            type: 'singleSelect',
            valueOptions: screenSpots.map((spot) => ({
                value: spot.id,
                label: spot.name,
            })),
            renderCell: (params: GridRenderCellParams) => {
                const spot = screenSpots.find((s) => s.id == params.row.screen_spot_any_id);
                return spot ? spot.name : '';
            },
        },
        {
            field: 'physical_condition_id',
            headerName: 'Physical Condition',
            width: 150,

            type: 'singleSelect',
            valueOptions: physicalConditions.map((condition) => ({
                value: condition.id,
                label: condition.name,
            })),
            renderCell: (params: GridRenderCellParams) => {
                const condition = physicalConditions.find((c) => c.id == params.row.physical_condition_id);
                return condition ? condition.name : '';
            },
        },
        {
            field: 'functional_condition_id',
            headerName: 'Functional Condition',
            width: 150,

            type: 'singleSelect',
            valueOptions: functionalConditions.map((condition) => ({
                value: condition.id,
                label: condition.name,
            })),
            renderCell: (params: GridRenderCellParams) => {
                const condition = functionalConditions.find((c) => c.id == params.row.functional_condition_id);
                return condition ? condition.name : '';
            },
        },
        {
            field: 'age_of_laptop_id',
            headerName: 'Age of Laptop',
            width: 150,

            type: 'singleSelect',
            valueOptions: ageOfLaptops.map((age) => ({
                value: age.id,
                label: age.name,
            })),
            renderCell: (params: GridRenderCellParams) => {
                const age = ageOfLaptops.find((a) => a.id == params.row.age_of_laptop_id);
                return age ? age.name : '';
            },
        },
    ];

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    return (
        <ErrorBoundary>
            <div style={{ height: 600, width: '100%' }}>
                <PageTitleCard title="Purchases">
                    <Box>
                        <Grid container justifyContent="flex-start" alignItems="center" sx={{ my: 0 }}>
                            {isAllowed(auth, "create_allowed") && (
                                <NavLink to="/add-purchase">
                                    <Button variant="contained" sx={{ mb: 0, textDecoration: "none" }}>
                                        <AddIcon /> Add Purchase
                                    </Button>
                                </NavLink>
                            )}
                            <Box sx={{ width: 50 }}></Box>
                            <FcClearFilters
                                onClick={handleClearFilters}
                                size={30}
                                style={{ cursor: "pointer", marginRight: "5px" }}
                            />
                            <FormControl sx={{ m: 0, minWidth: 100 }}>
                                <Select
                                    labelId="sales-user-filter-label"
                                    value={selectedSalesUser}
                                    displayEmpty
                                    renderValue={(selected) => selected || <span style={{ fontSize: "11px", color: "#800080" }}>Filter By Sales User</span>}
                                    onChange={(e) => setSelectedSalesUser(e.target.value as string)}
                                    sx={{
                                        height: "50px",
                                        width: "150px",
                                        textAlign: "center",
                                        "& .MuiSelect-select": {
                                            paddingTop: "6px",
                                            paddingBottom: "6px",
                                            fontSize: "0.675rem",
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            top: 0,
                                        },
                                        "& .MuiSvgIcon-root": {
                                            fontSize: "1rem",
                                        },
                                    }}
                                >
                                    {salesUsers.map((user, index) => (
                                        <MenuItem key={index} value={user}>
                                            {user}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl sx={{ m: 1, minWidth: 100 }}>
                                <Select
                                    labelId="product-type-filter-label"
                                    value={selectedProductType || ""}
                                    displayEmpty
                                    renderValue={(selected) => selected || <span style={{ fontSize: "11px", color: "#800080" }}>Filter By Product Type</span>}
                                    onChange={(e) => setSelectedProductType(e.target.value as string)}
                                    sx={{
                                        height: "50px",
                                        width: "160px",
                                        textAlign: "center",
                                        "& .MuiSelect-select": {
                                            paddingTop: "6px",
                                            paddingBottom: "6px",
                                            fontSize: "0.675rem",
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            top: 0,
                                        },
                                        "& .MuiSvgIcon-root": {
                                            fontSize: "1rem",
                                        },
                                    }}
                                >
                                    {productTypes.map((type, index) => (
                                        <MenuItem key={index} value={type}>
                                            {type === "true" ? "On Purchase" : "On Approval"}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Start Date"
                                    value={startDateFilter}
                                    onChange={(date) => setStartDateFilter(date)}
                                />
                                <DatePicker
                                    label="End Date"
                                    value={endDateFilter}
                                    onChange={(date) => setEndDateFilter(date)}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Box>
                    <div style={{ height: 680, width: '100%' }}>
                        <DataGrid
                            rows={filteredPurchaseData}
                            columns={columns}
                            editMode="row"
                            paginationMode="server"
                            rowCount={totalRows}
                            loading={isLoading}
                            pageSizeOptions={[5, 10, 25, 50]}
                            paginationModel={paginationModel}
  onPaginationModelChange={(model) => setPaginationModel(model)}
                            getRowId={(row) => row.id || row.ls_no}
                            rowModesModel={rowModesModel}
                            onRowModesModelChange={handleRowModesModelChange}
                            sx={{
                                '& .MuiDataGrid-root': {
                                    fontSize: '0.8rem', 
                                },
                                '& .MuiDataGrid-row': {
                                    maxHeight: '42px !important', 
                                    minHeight: '42px !important',
                                },
                            }}
                        />
                    </div>
                </PageTitleCard>
            </div>
        </ErrorBoundary>
    );
};

export default BasicTable;