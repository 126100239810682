import React, { useState, useEffect, useRef } from "react";
import {
    Button,
    TextField,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    Box,
    Paper,
    Typography,
    Pagination,
} from "@mui/material";
import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridRowId,
    GridRowModes,
    GridRowModesModel,
    GridActionsCellItem,
    GridRowModel,
    GridEventListener,
    GridRowEditStopReasons,
} from "@mui/x-data-grid";
import { useNavigate, useLocation } from "react-router-dom";
import PageTitleCard from "../../components/layout/page-title-card";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import ArticleIcon from '@mui/icons-material/Article';
import { http } from "../../utils/http";

const PAGE_SIZE = 15;

const getTatInDays = (tat: string): string => {
    if (!tat) return "0 days";
    const tatDate = new Date(tat);
    const currentDate = new Date();
    const differenceInTime = currentDate.getTime() - tatDate.getTime();
    const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
    return `${differenceInDays} days`;
};

export default function BasicTable() {
    const [salesData, setSalesData] = useState<any[]>([]);
    const salesDataRef = useRef<any[]>([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [selectedAllOkType, setSelectedAllOkType] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    const [totalRows, setTotalRows] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 15,
    });

    const [editedRows, setEditedRows] = useState<Set<GridRowId>>(new Set());

    const fetchData = async (page: number) => {
        try {
            const response = await http.get(
                `/purchase-sales?page=${page}&pageSize=${PAGE_SIZE}&all_ok_type=${selectedAllOkType}`
            );
            console.log(response.data);
            setSalesData(response.data.result);
            salesDataRef.current = response.data.result; // Update the ref with the latest data
            setTotalPages(Math.ceil(response.data.pageInfo.totalItems / PAGE_SIZE));
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const allOkTypeDefault = queryParams.get("all_ok_type") || "";
        setSelectedAllOkType(allOkTypeDefault);
        fetchData(currentPage);
    }, [currentPage, selectedAllOkType]);

    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        setCurrentPage(page);
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
    setEditedRows(prev => new Set(prev).add(id));
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
};

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const originalRow = salesDataRef.current.find((row) => row.id === id);
        if (originalRow) {
            setSalesData((prevData) => prevData.map((row) => (row.id === id ? originalRow : row)));
        }
    };

    const processRowUpdate = async (newRow: GridRowModel) => {
        if (editedRows.has(newRow.id)) {
            try {
                await http.patch(`/purchase-sales/${newRow.id}`, newRow);
                setEditedRows(prev => {
                    const newSet = new Set(prev);
                    newSet.delete(newRow.id);
                    return newSet;
                });
                toast.success("Data updated successfully");
            } catch (error) {
                console.error("Error updating data:", error);
                toast.error("Error updating data");
                throw error;
            }
        }
        return newRow;
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const handleShowDetail = (id: GridRowId) => () => {
        handleCancelClick(id);
        navigate(`/view-sales/${id}`);
    };
    
    const columns: GridColDef[] = [
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 100,
            cellClassName: "actions",
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{ color: "primary.main" }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                    icon={<ArticleIcon />}
                    label="Detail"
                    className="textPrimary"
                    onClick={handleShowDetail(id)}
                    color="inherit"
                />,
                ];
            },
        },
        { field: "id", headerName: "ID", width: 70 },
        {
            field: "product_type",
            headerName: "Product Type",
            width: 150,
            valueFormatter: ({ value }) => (value ? "On Purchase" : "On Approval"),
        },
        { field: "ls_no", headerName: "LS No", width: 100, editable: true },
        {
            field: "price",
            headerName: "Purchase & Repair Price",
            width: 180,
            valueFormatter: ({ value, row }) => `₹${Number(value || 0) + Number(row || 0)}`,
        },
        {
            field: "status",
            headerName: "Status",
            width: 150,
            valueFormatter: (value: any) => {
                return value ? value.split("_").slice(0, -1).join(" ") : "";
            }
        },
        { field: "sales_price", headerName: "Sales Price", width: 150, editable: true },
        { field: "shelf_no", headerName: "Shelf No", width: 100 },
        { field: "pickup_person", headerName: "Pickup Person", width: 150 },
        {
            field: "charger_type",
            headerName: "Charger",
            width: 150,
            editable: true,
            getOptionValue: (value: any) => value.value,
            getOptionLabel: (value: any) => value.name,
            valueOptions: [{ value: 1, name: "With Charger" }, { value: 0, name: "Without Charger" }].map((chargerType) => ({
                value: chargerType.value,
                label: chargerType.name,
            })),
            valueFormatter: (params: GridRenderCellParams) => {
                const chargerType = [{
                    id: 1,
                    name: "With Charger",
                }, {
                    id: 0,
                    name: "Without Charger",
                }].find((chargerType: any) => chargerType.id == params);
                return chargerType ? chargerType.name : '';
            },
        },
        {
            field: "tat",
            headerName: "TAT",
            width: 100,
            valueFormatter: ({ value }) => getTatInDays(value),
        },
        {
            field: "all_ok_type",
            headerName: "Sales Type",
            width: 150,
            valueFormatter: ({ value }) => (Number(value) ? "B2C" : "B2B"),
        },
        {
            field: "payment_type",
            headerName: "Payment Type",
            width: 150,
            valueOptions: ["Cash", "Online"],
        }
    ];

    const handleProcessRowUpdateError = (error: any) => {
        toast.error("Error updating data");
        console.error("Error updating data:", error);
    };

    return (
        <div>
            <PageTitleCard title="Sales Engineer's Task">
                <div>
                    <form style={{ width: "300px" }} method="GET" action="">
                        <FormControl size="small" fullWidth variant="standard">
                            <InputLabel id="sales-type-label">Sales Type</InputLabel>
                            <Select
                                labelId="sales-type-label"
                                name="all_ok_type"
                                value={selectedAllOkType}
                                onChange={(e: any) => setSelectedAllOkType(e.target.value)}
                            >
                                <MenuItem value="">All Types</MenuItem>
                                <MenuItem value="1">B2C</MenuItem>
                                <MenuItem value="0">B2B</MenuItem>
                            </Select>
                        </FormControl>
                        <Button
                            type="submit"
                            className=""
                            variant="outlined"
                            style={{ marginTop: 10 }}
                        >
                            Load Data
                        </Button>
                    </form>
                </div>
            </PageTitleCard>

            <Paper className="w-full h-[7000px]">
                <DataGrid
                    rows={salesData}
                    columns={columns}
                    editMode="row"
                    paginationMode="server"
                    rowCount={totalRows}
                    loading={isLoading}
                    pageSizeOptions={[5, 10, 25, 50]}
                    paginationModel={paginationModel}
                    onPaginationModelChange={(model) => setPaginationModel(model)}
                    getRowId={(row) => row.id || row.ls_no}
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    onProcessRowUpdateError={handleProcessRowUpdateError}
                    sx={{
                        '& .MuiDataGrid-root': {
                            fontSize: '0.8rem', 
                        },
                        '& .MuiDataGrid-row': {
                            maxHeight: '42px !important', 
                            minHeight: '42px !important',
                        },
                    }}
                />
            </Paper>
        </div>
    );
}
