import * as React from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import { Card, CardContent } from "@mui/material"
import { NavLink } from "react-router-dom"

export default function BoxComponent() {
    const allSettings = [
        {
            link: "/brands",
            title: "Brand Master",
        },
        {
            link: "/series-master",
            title: "Series Master",
        },
        {
            link: "/model-master",
            title: "Model Master",
        },
        {
            link: "/series-master",
            title: "Series Master",
        },
        {
            link: "/processor-brand",
            title: "Processor Brand",
        },
        {
            link: "/processor-series",
            title: "Processor Series",
        },
        {
            link: "/processor-generation",
            title: "Processor Generation",
        },
        {
            link: "/processor-gigaHeartz",
            title: "Processor GigaHeartz",
        },
        {
            link: "/ram",
            title: "Ram",
        },
        {
            link: "/hard-disk",
            title: "Hard Disk Drive(HDD)",
        },
        {
            link: "/ssd",
            title: "SSD",
        },
        {
            link: "/external-graphics",
            title: "External Graphics",
        },
        {
            link: "/graphic-brand",
            title: "Graphics Brand",
        },
        {
            link: "/graphic-series",
            title: "Graphic Series",
        },
        {
            link: "/graphic-model",
            title: "Graphics Model",
        },
        {
            link: "/graphic-size",
            title: "Graphics Size",
        },
        {
            link: "/screen-size",
            title: "Screen Size",
        },
        {
            link: "/screen-resolution",
            title: "Screen Resolution",
        },
        {
            link: "/conditions",
            title: "Spots Conditions",
        },
        {
            link: "/age-of-laptop",
            title: "Age Of Laptop",
        },
        {
            link: "/physical-condition",
            title: "Physical Condition",
        },
        {
            link: "/functional-condition",
            title: "Functional Condition",
        },
        {
            link: "/id-proof",
            title: "ID Proofs",
        },
        {
            link: "/address-proof",
            title: "Address Proofs",
        },
        // {
        //     link: "/charger-type",
        //     title: "Charger Type",
        // },
    ]

    return (
        <Grid container spacing={3}>
            {allSettings.map((item: any, idx: number) => (
                <Grid item xs={4}>
                    <NavLink to={item.link} style={{ textDecoration: "none" }}>
                        <Card
                            elevation={2}
                            variant="outlined"
                            sx={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                textDecoration: "none",
                                border: "1px solid #ccc",
                                transition: "background-color 0.1s",
                                "&:hover": {
                                    backgroundColor: "#6BAFF2", // Change to your desired hover color
                                },
                            }}
                        >
                            <CardContent sx={{ p: 2 }}>
                                ({idx + 1}) {item.title}
                            </CardContent>
                        </Card>
                    </NavLink>
                </Grid>
            ))}
        </Grid>
    )
}
