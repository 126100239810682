import React, { useEffect, useState } from 'react';
import { http } from "../../utils/http"
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import sparepart from "/public/images/38507.jpg"
import sparepart2 from "/public/images/38504.jpg"



const BuyNewSparePart = () => {
  const [name, setName] = useState('');
  const [category, setCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const [price, setPrice] = useState('');
  const [inwardDate, setInwardDate] = useState('');
  const [quantity, setQuantity] = useState('');
 
const navigate=useNavigate()

 async function fetchCategory() {
  try {
    const response=await http.get("/category")
    setCategories(response.data.result)
    console.log(categories,response);
    
  } catch (error) {
    console.log("error while fetching category",error);
    
  }
 }
 useEffect(()=>{
  fetchCategory()
 },[])
 const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      const response = await http.post('/stockSpare/buy', {
        name,
        category,
        price,
        inwardDate,
        quantity,
      });
console.log(response);

       toast.success('Spare part bought successfully!');
       navigate('/view-sparepart')
    } catch (error) {
      console.error('Error buying spare part:', error);
      toast.error('Failed to buy spare part. Please try again.');
    }
 };

 return (
  <div className="max-w-md mx-auto bg-white rounded-xl  shadow-md overflow-hidden md:max-w-3xl my-4">
  <div className="md:flex">
  <div className="md:flex-shrink-0 md:w-1/3 mx-auto">
    <img className="w-full object-contain md:w-48 my-12 " src="/images/38507.jpg" alt="Image"/>
    <img className="w-full object-contain md:w-48" src="/images/38504.jpg" alt="Image"/>
 </div>
    <div className="p-6  md:w-2/3">
      <div className="uppercase mb-5 tracking-wide text-sm text-indigo-500 font-semibold">Buy New Spare Part</div>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="name" className="block text-sm font-bold mb-2 text-gray-700">Name</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Name"
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div>
          <label htmlFor="category" className="block text-sm font-bold mb-2 text-gray-700">Category</label>
          <select
            id="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            className="w-full p-2 border rounded"
            required
          >
            {categories.map((cat:any) => (
             <option key={cat.id} value={cat.id}>
                {cat.name}
             </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="price" className="block text-sm font-bold mb-2 text-gray-700">Price</label>
          <input
            type="number"
            id="price"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            placeholder="Price"
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div>
          <label htmlFor="inwardDate" className="block text-sm font-bold mb-2 text-gray-700">Inward Date</label>
          <input
            type="date"
            id="inwardDate"
            value={inwardDate}
            onChange={(e) => setInwardDate(e.target.value)}
            placeholder="Inward Date"
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div>
          <label htmlFor="quantity" className="block text-sm font-bold mb-2 text-gray-700">Quantity</label>
          <input
            type="number"
            id="quantity"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            placeholder="Quality"
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <button type="submit" className="w-full p-2 bg-blue-500 text-white rounded">
          Buy New Spare Part
        </button>
      </form>
    </div>
  </div>
</div>
 );
};

export default BuyNewSparePart;