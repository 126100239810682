import MenuItem from "@mui/material/MenuItem";
import { useState, useEffect } from "react";
import { http } from "../../utils/http";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useNavigate } from "react-router-dom";

import {
  FormGroup,
  FormControl,
  InputLabel,
  Input,
  Typography,
  Grid,
  Button,
  styled,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";
import { width } from "@mui/system";

const Container = styled(FormGroup)`
  width: 100%;
`;
{
  /* <Typography variant="h4">Add New Employee</Typography> */
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const initialValues = {
  name: "",
};

const AddExternalGraphics = () => {
  const navigate = useNavigate();
  const [externalGraphics, setExternalGraphics] = useState(initialValues);

  const onValueChange = (e: any) => {
    e.preventDefault(e);
    setExternalGraphics({
      ...externalGraphics,
      [e.target.name]: e.target.value,
    });
  };
  const addExternalGraphicsDetail = async (e: any) => {
    e.preventDefault();
    try {
      await http.post("/external-graphics", externalGraphics);
      navigate("/external-graphics");
      return;
    } catch (error: any) {
      console.log("error while calling api", error.message);
    }
  };

  return (
    <div>
      <form onSubmit={addExternalGraphicsDetail}>
       

        <FormGroup>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>Name</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  sx={{ background: "white" }}
                  placeholder="Please enter External Graphics"
                  onChange={(e) => onValueChange(e)}
                  name="name"
                  required
                />

                {/* <InputLabel >Name</InputLabel> */}
                {/* <Input /> */}
              </FormControl>
            </Grid>
          </Grid>

          <FormControl sx={{ my: 4 }}>
            <Button variant="contained" sx={{ maxWidth: 200 }} type="submit">
              Add
            </Button>
          </FormControl>
        </FormGroup>
      </form>
    </div>
  );
};
export default AddExternalGraphics;
