import * as React from "react";
import { useState, useEffect } from "react";
import { http } from "../../utils/http";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormLabel from "@mui/material/FormLabel";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";

import InputLabel from "@mui/material/InputLabel";
import {
  FormGroup,
  FormControl,
  Grid,
  Button,
  styled,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const initialValues = {
 
  brand_master_id:"",
  name:"",
};

const AddSeries = () => {
  const navigate = useNavigate();
  const [seriesData, setSeries] = useState(initialValues);
  const [brands, setBrands] = useState([]);


  useEffect(() => {
    (async () => {
      const data: any = await http.get("/brand-master");
      setBrands(data.data.result);
    })();
  }, []);


  const onValueChange = (e: any) => {
    e.preventDefault(e);
    if (e.target.type === "file") {
      setSeries({ ...seriesData, [e.target.name]: e.target.files[0] });
    } else {
      setSeries({ ...seriesData, [e.target.name]: e.target.value });
    }
    console.log("name=",e.target.name +"Val=",e.target.value)
  };
 
  const AddSeries = async (e: any) => {
    e.preventDefault()
    try {
        await http.post("/series-master", seriesData)
        navigate("/series-master")
        return
    } catch (error: any) {
        console.log("error while calling api", error.message)
    }
}

  return (
    <div>
      <form onSubmit={AddSeries }>
        <FormGroup>
          <Grid container spacing={1}>
          
          <Grid item xs={6}>
                            <FormControl sx={{ width: "80%" }}>
                                <FormHelperText>
                                    <h3>Name</h3>{" "}
                                </FormHelperText>
                                <OutlinedInput
                                    sx={{ background: "white" }}
                                    placeholder="Please enter name"
                                    onChange={(e) => onValueChange(e)}
                                    name="name"
                                    required
                                />
                            </FormControl>
                        </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>Select Brand</h3>{" "}
                </FormHelperText>
                <Select
                  sx={{ background: "white" }}
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  name="brand_master_id"
                  value={seriesData.brand_master_id}
                  onChange={(e) => onValueChange(e)}
                  MenuProps={MenuProps}
                  required
                >
                  {brands.map((brand: any) => (
                    <MenuItem key={brand.name} value={brand.id}>
                      {brand.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
         
          </Grid>

          <FormControl sx={{ my: 4 }}>
            <Button variant="contained" sx={{ maxWidth: 200 }} type="submit">
              Add
            </Button>
          </FormControl>
        </FormGroup>
      </form>
    </div>
  );
};
export default AddSeries;
