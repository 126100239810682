import { useState, useEffect } from "react"
import { Button, Card, Box, Stack, Modal, Grid, Typography , FormGroup,
    FormControl,
    OutlinedInput} from "@mui/material"
import { http } from "../../utils/http"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"

const style = {
    background: "white",
    maxWidth: 600,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "150px",
    padding: 5,
    border: "none",
    outline: "none",
}

type AttendanceActionProps = {
   
    title: string
    id:any
    attendance: any
    
}

function AttendanceAction({
    
    title,
    id,
    attendance
    
   
}: AttendanceActionProps) {
  const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    function handleClose() {
        setOpen(false)
    }
    const initialValues = {
        note: "",
        
       
    }
    const [note, setnoteUpdate] = useState(initialValues);
    const [attendanceData, setAttendanceData] = useState({ ...initialValues});
  
  useEffect(() => {
      (async () => {
          const data: any = await http.get("/attendance/" + attendance.id);
          setAttendanceData(data.data.result);
         
          console.log("Attendance data",data.data.result)
        })();
    }, []);
      
    const onValueChange = (e: any) => {
        e.preventDefault(e);
        setAttendanceData({ ...attendanceData, [e.target.name]: e.target.value });
       
    
      };
      const submitNote = async (e: any) => {
        e.preventDefault();
        try {
     
          await http.patch("/attendance/"+attendance.id, attendanceData);
          toast.success("Attendance Note Updated Successfully", { autoClose: 1000 })
          navigate("/attendance")
          return;
        } catch (error: any) {
          console.log("error while calling api", error.message);
        }
      };
     

    return (
      <Box>
            <Modal
                
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                       Write Your Note 
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <form onSubmit={submitNote}>
                            <FormGroup>
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <FormControl sx={{ width: "100%" }}>
                                    <OutlinedInput
                                      placeholder="Please enter text"
                                      onChange={(e) => onValueChange(e)}
                                      value={attendanceData.note}
                                      name="note"
                                      sx={{ background: "white" ,width:"100%",height:"100%"}}
                                    />
                                  </FormControl>
                                </Grid>
                              </Grid>

                              <FormControl sx={{ my: 4 }}>
                                <Button
                                  variant="contained"
                                  sx={{ maxWidth: 200 }}
                                  type="submit"
                                >
                                  Submit
                                </Button>
                              </FormControl>
                            </FormGroup>
                          </form>
                    </Typography>
                </Box>
            </Modal>
           
                <Button  onClick={() => setOpen(true)}>{title}</Button>
        
        </Box>
    )
}

export default AttendanceAction
