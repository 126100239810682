import * as React from "react";
import { useState, useEffect } from "react";
import { http } from "../../utils/http";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { NavLink } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { Grid, FormControl, Select, MenuItem, TextField } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { PurchaseDataItem } from "../../types";

export default function BasicTable() {
  const [testPendingDatas, setTestPendingData] = useState<PurchaseDataItem[]>([]);
  const [dateFilter, setDateFilter] = useState<Date | null>(null);
  const [selectedSalesUser, setSelectedSalesUser] = useState("");
  const [selectedProductType, setSelectedProductType] = useState("");

  useEffect(() => {
    (async function () {
      const purchaseDataRes = await http.get("/engineer-dashboard");
      setTestPendingData(purchaseDataRes.data.readyToDispatch);
    })();
  }, []);

  const salesUsers = Array.from(new Set(testPendingDatas.map((item) => item.vendor_name)));
  const productTypes = Array.from(new Set(testPendingDatas.map((item) => item.product_type.toString())));

  return (
    <div>
      <h3>Ready To Dispatch </h3>
      <Grid container justifyContent="flex-end" alignItems="center">
        <FormControl sx={{ m: 1, minWidth: 100 }}>
          <Select
            labelId="sales-user-filter-label"
            value={selectedSalesUser}
            displayEmpty
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <span style={{ fontSize: "12px", color: "#800080" }}>Sales User Filter</span>;
              }
              return selected;
            }}
            onChange={(e) => setSelectedSalesUser(e.target.value as string)}
            sx={{ color: "#800080", '&:focus': { outline: 'none', boxShadow: '0 0 0 3px rgba(128, 0, 128, 0.5)' } }}
          >
            {salesUsers.map((user, index) => (
              <MenuItem key={index} value={user}>
                {user}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ m: 1, minWidth: 100 }}>
          <Select
            labelId="product-type-filter-label"
            value={selectedProductType}
            displayEmpty
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <span style={{ fontSize: "12px", color: "#800080" }}>Product Type Filter</span>;
              }
              return selected;
            }}
            onChange={(e) => setSelectedProductType(e.target.value as string)}
          >
            {productTypes.map((type, index) => (
              <MenuItem key={index} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label={<Typography variant="caption">Date Filter</Typography>}
            value={dateFilter}
            onChange={(newValue) => {
              setDateFilter(newValue);
            }}
          />
        </LocalizationProvider>
      </Grid>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 550 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow sx={{ background: "skyBlue", fontWeight: 800 }}>
              <TableCell>ID</TableCell>
              <TableCell>Product Type</TableCell>
              <TableCell>LS No.</TableCell>
              <TableCell>Shelf No.</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {testPendingDatas
              .filter((row) => {
                if (dateFilter) {
                  return new Date(row.date).toDateString() === dateFilter.toDateString();
                }
                return true;
              })
              .filter((row) => row.vendor_name === selectedSalesUser || selectedSalesUser === "")
              .filter((row) => row.product_type.toString() === selectedProductType || selectedProductType === "")
              .map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell>
                    {row.product_type ? "On Purchase" : "On Approval"}
                  </TableCell>
                  <TableCell>{row.ls_no}</TableCell>
                  <TableCell>{row.shelf_no}</TableCell>
                  <TableCell>{row.date}</TableCell>
                  <TableCell>
                    <NavLink to={`/view-engineer-report/${row.id}`}>
                      <Button variant="outlined" size="small" sx={{ mb: 2 }}>
                        View
                      </Button>
                    </NavLink>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
