import * as React from "react";
import Box from "@mui/material/Box";

import { useState, useEffect } from "react";
import { http, serverImgUrl } from "../../utils/http";
import { useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import EngineerDashboardRow from "../../components/ui/EngineerDashboardRow";
import FormRowEngineer from "../../components/ui/FormRowEngineer";
import { NavLink, Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import { useNavigate } from "react-router-dom";

import { FormGroup, FormControl, Grid, Button, styled } from "@mui/material";

const Container = styled(FormGroup)`
  width: 100%;
`;
const rightStyleButton = {
  background: "white",
  mb: 2,
  color: "black",
  fontSize: 15,
  borderRadius: 2,

  textTransform: "uppercase",
  "&:hover": {
    border: "1px solid #fff",
    color: "white",
    backgroundColor: "white",
  },
};

const boxStyle = {
  background: "white",

  mt: 1,
  textAlign: "center",
  color: "black",
  fontSize: 16,
  borderRadius: 2,
  textTransform: "uppercase",

  mb: 1,
};

const EngineerDashboard = () => {
  let params = useParams();
  const navigate = useNavigate();
  const [testPendingCount, setTestPending] = useState([]);
  const [inProcessCount, setInProcess] = useState([]);
  const [rtdCount, setRTD] = useState([]);

  const [testPendingDatas, setTestPendingData] = useState([]);
  const [arrivedRepairDatas, setArrivedRepairData] = useState([]);
  const [inProcessDatas, setInProcessData] = useState([]);
  const [rtdDatas, setRtdData] = useState([]);
  const [pendingFabrics, setPendingFabrics] = useState([]);
  const [pendingSpares, setPendingSpare] = useState([]);
  const [pendingRepairs, setPendingReair] = useState([]);

  useEffect(() => {
    (async function () {
      const purchaseDataRes = await http.get("/engineer-dashboard");
      // console.log("TEST PENDING", purchaseDataRes);

      setTestPending(purchaseDataRes.data.testPendingCount);
      setTestPendingData(purchaseDataRes.data.result);

      setArrivedRepairData(purchaseDataRes.data.arrivedRepair);

      setInProcessData(purchaseDataRes.data.inProcess);
      setInProcess(purchaseDataRes.data.inProcessCount);

      setRTD(purchaseDataRes.data.rtdCount);
      setRtdData(purchaseDataRes.data.readyToDispatch);

      setPendingFabrics(purchaseDataRes.data.pendingFabric);
      setPendingSpare(purchaseDataRes.data.pendingSpare);
      setPendingReair(purchaseDataRes.data.pendingRepair);
    })();
  }, []);

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} >
          <EngineerDashboardRow>
            <Box sx={{ width: "100%", textAlign:"center" }}>
              <FormRowEngineer label="" background="red">
                <h6
                  style={{
                    fontSize: 16,
                    marginTop: 0,
                    marginBottom: 1,
                    textAlign: "center",
                    textTransform: "uppercase",
                    fontWeight: 400,
                  }}
                >
                  Pending for Fabric
                </h6>

                {pendingFabrics &&
                  pendingFabrics.map((pendingFabric: any) => (
                    <p
                      style={{
                        fontSize: 10,
                        textAlign: "center",
                        marginTop: 3,
                      }}
                    >
                      {pendingFabric.id}){pendingFabric.ls_no}{" "}
                      {pendingFabric.model_no}
                    </p>
                  ))}

                <Grid container justifyContent="center">
                  <NavLink to={`/fabric-pending`}>
                    <Button
                      variant="contained"
                      size="small"
                      sx={{ background: "black", mb: 1, mt: 1, fontSize: 10 }}
                    >
                      VIEW ALL
                    </Button>
                  </NavLink>
                </Grid>
              </FormRowEngineer>
            </Box>

            <Box>
              <FormRowEngineer label="" background="#F7E98A">
                <h4
                  style={{
                    fontSize: 18,
                    marginTop: 0,
                    marginBottom: 1,
                    textAlign: "center",
                    textTransform: "uppercase",
                    fontWeight: 400,
                  }}
                >
                  Pending For Spare
                </h4>

                {pendingSpares &&
                  pendingSpares.map((pendingSpare: any) => (
                    <p
                      style={{
                        fontSize: 10,
                        textAlign: "center",
                        marginTop: 3,
                      }}
                    >
                      {pendingSpare.id}){pendingSpare.ls_no}{" "}
                      {pendingSpare.model_no}
                    </p>
                  ))}

                <Grid container justifyContent="center">
                  <NavLink to={`/spare-pending`}>
                    <Button
                      variant="contained"
                      size="small"
                      sx={{ background: "black", mb: 1, mt: 1, fontSize: 10 }}
                    >
                      VIEW ALL
                    </Button>
                  </NavLink>
                </Grid>
              </FormRowEngineer>
            </Box>

            <Box>
              <FormRowEngineer label="" background="#C5DB85">
                <h4
                  style={{
                    fontSize: 18,
                    marginTop: 0,
                    marginBottom: 1,
                    textAlign: "center",
                    textTransform: "uppercase",
                    fontWeight: 400,
                  }}
                >
                  Pending For Repair
                </h4>

                {pendingRepairs &&
                  pendingRepairs.map((pendingRepair: any) => (
                    <p
                      style={{
                        fontSize: 10,
                        textAlign: "center",
                        marginTop: 3,
                      }}
                    >
                      {pendingRepair.id}){pendingRepair.ls_no}{" "}
                      {pendingRepair.model_no}
                    </p>
                  ))}

                <Grid container justifyContent="center">
                  <NavLink to={`/repair-pending`}>
                    <Button
                      variant="contained"
                      size="small"
                      sx={{ background: "black", mb: 2, mt: 1, fontSize: 10 }}
                    >
                      VIEW ALL
                    </Button>
                  </NavLink>
                </Grid>
              </FormRowEngineer>
            </Box>
          </EngineerDashboardRow>
        </Grid>
      </Grid>
    </div>
  );
};
export default EngineerDashboard;
