import CardBox from "../layout/card-box";
import { useState, useEffect } from "react"
import {
  Card,
  Box,
  Stack,
  Grid,
  Typography,
 
} from "@mui/material";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import AttendanceAction from "./attendance-action";
import NoteAction from "./note-action";
import CheckInOutTime from "./checkInOutTime";

type AttendanceListProps = {
  employees: Array<any>;
  fetchGeneralStats: Function;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
export default function AttendanceList({
  employees,
  fetchGeneralStats,
}: AttendanceListProps) {
 
  return (
    <div>
      {employees &&
        employees.map((emp: any) => (
          <CardBox smallMargin key={emp.id}>
            <Stack direction="row" justifyContent="space-between">
              <Box sx={{ fontSize: "0.9em" }}>
                <Typography sx={{ fontWeight: "bold" }}>
                  {emp.firstName} {emp.lastName}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "normal",
                    color: "#777",
                    fontSize: "0.8em",
                  }}
                >
                    <CheckInOutTime  attendance={emp.attendance} />
                 
                </Typography>
                <Box sx={{ my: 2, display: "flex", alignItems: "center" }}>
                  <NoteAddIcon />
                  <Typography sx={{ fontSize: "0.9em", color: "#444" }}>
                  
                  
                  <NoteAction
                  title="Add NOTE"
                  id={emp.id}
                  attendance={emp.attendance}
                />
                    {/* <Button  onClick={(e: any) => e.target.stopPropagation()}>Add Note</Button> */}
                    {/* <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <Typography
                          id="modal-modal-title"
                          variant="h6"
                          component="h2"
                        >
                          Write a Note of {emp.id} {emp.firstName} {emp.lastName}
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                          
                        </Typography>
                      </Box>
                    </Modal> */}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  gap: 2,
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                }}
              >
                <AttendanceAction
                  title="Present"
                  fetchGeneralStats={fetchGeneralStats}
                  attendance={emp.attendance}
                  modeName={"mode_present"}
                  removeMode={emp.attendance.mode_present}
                  color={emp.attendance.mode_present ? "lightgreen" : "white"}
                />

                <AttendanceAction
                  title="Absent"
                  fetchGeneralStats={fetchGeneralStats}
                  attendance={emp.attendance}
                  modeName={"mode_absent"}
                  removeMode={emp.attendance.mode_absent}
                  color={emp.attendance.mode_absent ? "orange" : "white"}
                />

                <AttendanceAction
                  title="Half Day"
                  fetchGeneralStats={fetchGeneralStats}
                  attendance={emp.attendance}
                  modeName={"mode_halfDay"}
                  removeMode={emp.attendance.mode_halfDay}
                  color={emp.attendance.mode_halfDay ? "yellow" : "white"}
                />

                <AttendanceAction
                  title="Fine"
                  fetchGeneralStats={fetchGeneralStats}
                  attendance={emp.attendance}
                  modeName={"mode_fine"}
                  removeMode={emp.attendance.mode_fine}
                  color={emp.attendance.mode_fine ? "orangered" : "white"}
                />

                <AttendanceAction
                  title="Overtime"
                  fetchGeneralStats={fetchGeneralStats}
                  attendance={emp.attendance}
                  modeName={"mode_overTime"}
                  removeMode={emp.attendance.mode_overTime}
                  color={emp.attendance.mode_overTime ? "skyblue" : "white"}
                />

                <AttendanceAction
                  title="Paid Leave"
                  fetchGeneralStats={fetchGeneralStats}
                  attendance={emp.attendance}
                  modeName={"mode_paidLeave"}
                  removeMode={emp.attendance.mode_paidLeave}
                  color={emp.attendance.mode_paidLeave ? "turquoise" : "white"}
                />
              </Box>
            </Stack>
          </CardBox>
        ))}
    </div>
  );
}
