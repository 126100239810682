import * as React from "react";
import { useState, useEffect } from "react";
import { http } from "../../utils/http";

import Select, { SelectChangeEvent } from "@mui/material/Select";

import { useNavigate } from "react-router-dom";

import InputLabel from "@mui/material/InputLabel";
import {
  FormGroup,
  FormControl,
  Grid,
  Button,
  styled,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";
import { width } from "@mui/system";

const Container = styled(FormGroup)`
  width: 100%;
`;
{
  /* <Typography variant="h4">Add New Employee</Typography> */
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const initialValues = {
  processor_id: "",
  ram_id: "",
  hard_disk_id: "",
  ssd_id: "",
  external_graphics_id: "",
  screen_size_id: "",
  age_of_laptop_id: "",
  physical_condition_id: "",
  functional_condition_id: "",
 

};

const AddPurchaseImages = () => {
  const navigate = useNavigate();
  const [purchaseData, setPurchase] = useState({
    ...initialValues,
  });

  const onValueChange = (e: any) => {
    e.preventDefault(e);

    if (e.target.type === "file") {
      setPurchase({ ...purchaseData, [e.target.name]: e.target.files[0] });
    } else {
      setPurchase({ ...purchaseData, [e.target.name]: e.target.value });
    }
    console.log("target namee", e.target.name, "is", e.target.value);
  };
  const AddPurchaseImages = async (e: any) => {
    e.preventDefault();
    (async function () {
      console.log("Data", purchaseData);
      let dataCopy: any = purchaseData;
      const formData: any = new FormData();

      for (let itemKey in dataCopy) {
        formData.append(itemKey, dataCopy[itemKey]);
      }
      await http.post("/purchaseImages", formData);
      navigate("/purchase");
      // success("You've posted your form!");
      return;
    })();
  };

  return (
    <div>
      <form onSubmit={AddPurchaseImages}>
        <FormGroup>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>Processor Image</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  sx={{ background: "white" }}
                  onChange={(e) => onValueChange(e)}
                  name="processor_image"
                  //  value={data.ram_image}
                  type="file"
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>Ram Image</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  sx={{ background: "white" }}
                  onChange={(e) => onValueChange(e)}
                  name="ram_image"
                  //  value={data.ram_image}
                  type="file"
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>Hard Disk Image</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  sx={{ background: "white" }}
                  onChange={(e) => onValueChange(e)}
                  name="hard_disk_image"
                  //  value={data.ram_image}
                  type="file"
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>External Graphics Image</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  sx={{ background: "white" }}
                  onChange={(e) => onValueChange(e)}
                  name="external_graphic_image"
                  //  value={data.ram_image}
                  type="file"
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>SSD Image</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  sx={{ background: "white" }}
                  onChange={(e) => onValueChange(e)}
                  name="ssd_image"
                  //  value={data.ram_image}
                  type="file"
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>Screen Size Image</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  sx={{ background: "white" }}
                  onChange={(e) => onValueChange(e)}
                  name="screen_size_image"
                  //  value={data.ram_image}
                  type="file"
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>Age Of Image</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  sx={{ background: "white" }}
                  onChange={(e) => onValueChange(e)}
                  name="age_of_laptop_image"
                  //  value={data.ram_image}
                  type="file"
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>Physical Condition Image</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  sx={{ background: "white" }}
                  onChange={(e) => onValueChange(e)}
                  name="physical_condition_image"
                  //  value={data.ram_image}
                  type="file"
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>Functional Condition Image</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  sx={{ background: "white" }}
                  onChange={(e) => onValueChange(e)}
                  name="functional_condition_image"
                  //  value={data.ram_image}
                  type="file"
                />
              </FormControl>
            </Grid>
          </Grid>

          <FormControl sx={{ my: 4 }}>
            <Button variant="contained" sx={{ maxWidth: 200 }} type="submit">
              Add
            </Button>
          </FormControl>
        </FormGroup>
      </form>
    </div>
  );
};
export default AddPurchaseImages;
