import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useState, useEffect } from "react"
import { http } from "../../utils/http"
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom"
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import {
    FormGroup,
    FormControl,
   
    Grid,
    Button,
    styled,
    OutlinedInput,
    FormHelperText,
} from "@mui/material"
import { width } from "@mui/system"

const Container = styled(FormGroup)`
    width: 100%;
`
{
    /* <Typography variant="h4">Add New Employee</Typography> */
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const initialValues = {
    name: "",
    description: "",
    condition:"",
   
   
}


const AddFunctionalCondition = () => {

   

    const navigate = useNavigate()
    const [conditions, setCondition] = useState([]);

useEffect(() => {
  (async () => {
    const data: any = await http.get("/conditions");
    setCondition(data.data.result);
  })();
}, []);
    const [FunctionalCondition, setFunctionalCondition] = useState(initialValues)
    

    const onValueChange = (e: any) => {
        e.preventDefault(e)
        setFunctionalCondition({ ...FunctionalCondition, [e.target.name]: e.target.value })
    }
    const AddFunctionalConditiondetail = async (e: any) => {
        e.preventDefault()
        try {
            await http.post("/functional-condition", FunctionalCondition)
            navigate("/functional-condition")
            return
        } catch (error: any) {
            console.log("error while calling api", error.message)
        }
    }
 

    return (
        <div>
            <form onSubmit={AddFunctionalConditiondetail}>
                <FormGroup>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <FormControl sx={{ width: "80%" }}>
                                <FormHelperText>
                                    <h3>Name</h3>{" "}
                                </FormHelperText>
                                <OutlinedInput
                                    sx={{ background: "white" }}
                                    placeholder="Please enter name"
                                    onChange={(e) => onValueChange(e)}
                                    name="name"
                                    required
                                />

                                {/* <InputLabel >Name</InputLabel> */}
                                {/* <Input /> */}
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl sx={{ width: "80%" }}>
                                <FormHelperText>
                                    <h3>Description</h3>{" "}
                                </FormHelperText>
                                <OutlinedInput
                                    sx={{ background: "white" }}
                                    placeholder="Please enter name"
                                    onChange={(e) => onValueChange(e)}
                                    name="description"
                                   
                                />

                                {/* <InputLabel >Name</InputLabel> */}
                                {/* <Input /> */}
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl sx={{ width: "80%" }}>
                                <FormHelperText>
                                    <h3>Condition</h3>{" "}
                                </FormHelperText>
                                <OutlinedInput
                                    sx={{ background: "white" }}
                                    placeholder="Please enter condition"
                                    onChange={(e) => onValueChange(e)}
                                    name="condition"
                                    required
                                />
                            </FormControl>
                        </Grid>
                     
             
                        {/* <Grid item xs={6}>
                            <FormControl sx={{ width: "80%" }}>
                                <FormHelperText>
                                    <h3>Description</h3>{" "}
                                </FormHelperText>
                                <OutlinedInput
                                    sx={{ background: "white" }}
                                    placeholder="Please enter Description"
                                    onChange={(e) => onValueChange(e)}
                                    name="description"
                                    required
                                />

                                
                            </FormControl>
                        </Grid> */}
                       
                    
                    </Grid>
                 
                    <FormControl sx={{ my: 4 }}>
                        <Button variant="contained" sx={{ maxWidth: 200 }} type="submit">
                            Add
                        </Button>
                    </FormControl>
                </FormGroup>
            </form>
        </div>
    )
}
export default AddFunctionalCondition
