import { useState, useEffect } from "react";
import { http } from "../../utils/http";
import { useParams } from "react-router-dom";
import Hidden from "@mui/material/Hidden";

import { useNavigate } from "react-router-dom";

import {
  FormGroup,
  FormControl,
  Grid,
  Button,
  styled,
  OutlinedInput,
  FormHelperText,
  TextField,
} from "@mui/material";
import { width } from "@mui/system";

const Container = styled(FormGroup)`
  width: 100%;
`;
{
  /* <Typography variant="h4">Add New Employee</Typography> */
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const initialValues = {
  age_of_laptop_id: "",
  name: "",
  date:""
};

const EditPhysicalCondition = () => {
  
  let params = useParams();
  const navigate = useNavigate();
 
  const [PhysicalConditionUpdate, setPhysicalCondition] = useState({ ...initialValues,
    age_of_laptop_id: params.id,description:"" });
  
  useEffect(() => {
      (async () => {
          const data: any = await http.get("/physical-condition/" + params.id);
          setPhysicalCondition(data.data.result);
        })();
    }, []);
    
       
  const onValueChange = (e: any) => {
    e.preventDefault(e);
    setPhysicalCondition({ ...PhysicalConditionUpdate, [e.target.name]: e.target.value });
// console.log('name is ', e.target.name, 'value is', e.target.value);
  };
  const EditPhysicalConditiondetail = async (e: any) => {
    e.preventDefault();
    try {
    //    console.log('data',PhysicalConditionUpdate);
      await http.patch("/physical-condition/"+params.id, PhysicalConditionUpdate);
      navigate("/physical-condition");
      return;
    } catch (error: any) {
      console.log("error while calling api", error.message);
    }
  };

  return (
    <div>
      <h2>Physical Condition ID: {params.id}</h2>
      <form onSubmit={EditPhysicalConditiondetail}>
        <FormGroup>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>Physical Condition ID</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  disabled
                  sx={{ background: "white" }}
                  value={PhysicalConditionUpdate.age_of_laptop_id}
                  onChange={(e) => onValueChange(e)}
                  name="age_of_laptop_id"
                 
                 
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>Name</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  sx={{ background: "white" }}
                  onChange={(e) => onValueChange(e)}
                  name="name"
                  value={PhysicalConditionUpdate.name}
                  
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>Description</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  sx={{ background: "white" }}
                  onChange={(e) => onValueChange(e)}
                  name="description"
                  value={PhysicalConditionUpdate.description}
                  
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
            <FormControl sx={{ width: "80%" }}>
              <FormHelperText>
                <h3>Date</h3>
              </FormHelperText>
              <TextField
                type="date"
                sx={{ background: "white" }}
                onChange={(e) => onValueChange(e)}
                name="date"
                // Assuming you have a state to hold the date value
                value={PhysicalConditionUpdate.date || ""}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
          </Grid>

          <FormControl sx={{ my: 4 }}>
            <Button variant="contained" sx={{ maxWidth: 200 }} type="submit">
              Update
            </Button>
          </FormControl>
        </FormGroup>
      </form>
    </div>
  );
};
export default EditPhysicalCondition;
