import { useState, useEffect } from "react";
import { Typography, FormGroup } from "@mui/material";
import { http } from "../../utils/http";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const style = {
  background: "white",
  maxWidth: 600,
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: "150px",
  padding: 5,
  border: "none",
  outline: "none",
};

type AttendanceActionProps = {
  attendance: any;
};

function AttendanceAction({ attendance }: AttendanceActionProps) {
  const initialValues = {
    mode_present: false,
    createdAt: "",
  };

  const [attendanceData, setAttendanceData] = useState({ ...initialValues });

  useEffect(() => {
    (async () => {
      const data: any = await http.get("/attendance/" + attendance.id);
      setAttendanceData(data.data.result);
    })();
  }, []);

  return (
    <Typography
      sx={{
        fontWeight: "normal",
        color: "#777",
        fontSize: "0.8em",
      }}
    >
      {attendanceData.mode_present
        ? attendanceData.createdAt.toString().slice(11, -5)
        : "Not Checked In yet"}
    </Typography>
  );
}

export default AttendanceAction;
