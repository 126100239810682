import * as React from "react";
import { useState, useEffect } from "react";
import { http } from "../../utils/http";
import { useParams, Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

interface ScrapData {
  id: number;
  ls_no: string;
  price: number;
  status: string;
  tat: number;
}

export default function ViewScrap() {
  const { id } = useParams();
  console.log(id);
  
  const [scrapData, setScrapData] = useState<ScrapData | null>(null);
console.log('scrap');

  const fetchData = async () => {
    try {
      const response = await http.get(`/scrap/${id}`);
      console.log(response);
      setScrapData(response.data.result);
      
    } catch (error) {
      console.error("Error fetching scrap data:", error);
    }
  };

  useEffect(() => {
    fetchData()
  }, [id]);

  return (
    <div className="max-w-md mx-auto my-8 p-6  bg-zinc-50 rounded-lg shadow-md">
      <Link to="/scraps" className="text-blue-500 hover:text-blue-700">
        <div className="flex items-center">
          <ArrowBackIcon className="mr-2" />
          <span className="font-medium">Back to List</span>
        </div>
      </Link>
      {scrapData ? (
        <div className="mt-6">
          <h2 className="text-2xl font-bold text-gray-800">Scrap Details</h2>
          <div className="mt-4">
            <p className="text-lg text-gray-700">
              <strong>ID:</strong> {scrapData.id}
            </p>
            <p className="text-lg text-gray-700">
              <strong>LS No.:</strong> {scrapData.ls_no}
            </p>
            <p className="text-lg text-gray-700">
              <strong>Price:</strong> ₹{scrapData.price}
            </p>
            <p className="text-lg text-gray-700">
              <strong>Status:</strong>{" "}
              {scrapData.status
                ? scrapData.status.split("_").slice(0, -1).join(" ")
                : ""}
            </p>
            <p className="text-lg text-gray-700">
              <strong>TAT:</strong> {scrapData.tat ? scrapData.tat : 0} days
            </p>
          </div>
        </div>
      ) : (
        <p className="mt-6 text-lg text-gray-700">Loading...</p>
      )}
    </div>
  );
}
