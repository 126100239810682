import * as React from "react"
import { useState, useEffect } from "react"
import { http } from "../../utils/http"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import FormLabel from "@mui/material/FormLabel"
import MenuItem from "@mui/material/MenuItem"
import { useNavigate } from "react-router-dom"

import { useParams } from "react-router-dom"
import InputLabel from "@mui/material/InputLabel"
import {
    FormGroup,
    FormControl,
    Grid,
    Button,
    styled,
    OutlinedInput,
    FormHelperText,
} from "@mui/material"
import { width } from "@mui/system"

const Container = styled(FormGroup)`
    width: 100%;
`
{
    /* <Typography variant="h4">Add New Employee</Typography> */
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const initialValues = {
    functional_condition_id: "",
    physical_condition_id: "",
    touchpad_working: "",
    touchpad_button: "",
    keyboard: "",
    webcam: "",
    wifi: "",
    usb_working: "",
    blutooth: "",
    lan: "",
    hdmi: "",
    vga: "",
    mic: "",
    biometric_sensor: "",
    speaker_left: "",
    speaker_right: "",
    battery: "",
}

const AddPurchaseStep2 = () => {
    const navigate = useNavigate()
    let params = useParams()
    //master brand,model,series & General Image
    const [brandMasters, setBrandMaster] = useState([])
    const [seriesMasters, setSeriesMaster] = useState([])
    //start master
    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/brand-master")
            setBrandMaster(data.data.result)
        })()
    }, [])
    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/series-master")
            setSeriesMaster(data.data.result)
        })()
    }, [])

    //end
    //end//
    //processor brand,series,generation,gigaHeartz
    const [processorBrands, setProcessorBrand] = useState([])
    const [processorSeries, setProcessorSeries] = useState([])
    const [processorGenerations, setProcessorGeneration] = useState([])
    const [processorGigaHeartzs, setProcessorGigaHeartz] = useState([])

    //start processor
    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/processor-brand")
            setProcessorBrand(data.data.result)
        })()
    }, [])

    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/processor-series")
            setProcessorSeries(data.data.result)
        })()
    }, [])

    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/processor-generation")
            setProcessorGeneration(data.data.result)
        })()
    }, [])

    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/processor-generation")
            setProcessorGigaHeartz(data.data.result)
        })()
    }, [])

    // end
    //end

    //graphic
    const [graphicBrands, setGraphicBrand] = useState([])
    const [graphicSeries, setGraphicSeries] = useState([])
    const [graphicModels, setGraphicModel] = useState([])
    const [graphicSizes, setGraphicSize] = useState([])
    //start Graphic
    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/graphic-brand")
            setGraphicBrand(data.data.result)
        })()
    }, [])

    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/graphic-series")
            setGraphicSeries(data.data.result)
        })()
    }, [])

    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/graphic-model")
            setGraphicModel(data.data.result)
        })()
    }, [])

    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/graphic-size")
            setGraphicSize(data.data.result)
        })()
    }, [])

    //end
    //graphic end

    //screen
    const [screenSizes, setScreenSize] = useState([])
    const [screenResolutions, setScreenResolution] = useState([])
    const [screenSpots, setScreenSpot] = useState([])

    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/screen-size")
            setScreenSize(data.data.result)
        })()
    }, [])

    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/screen-resolution")
            setScreenResolution(data.data.result)
        })()
    }, [])
    //   /condition means spot any
    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/conditions")
            setScreenSpot(data.data.result)
        })()
    }, [])

    // screen end
    const [purchaseData, setPurchase] = useState(initialValues)
    const [employees, setEmployees] = useState([])
    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/employees")
            setEmployees(data.data.result)
        })()
    }, [])
    const [processors, setProcessor] = useState([])
    const [rams, setram] = useState([])
    const [hardDisks, setHardDisk] = useState([])
    const [externalGraphics, setExternalGraphics] = useState([])
    const [ssds, setSSD] = useState([])
    const [ageOfLaptops, setAgeOfLaptop] = useState([])
    const [physicalConditions, setPhysicalConditions] = useState([])
    const [functionalConditions, setfunctionalCondition] = useState([])
    const [age, setAge] = React.useState("")

    const handleChange = (event: SelectChangeEvent) => {
        setAge(event.target.value as string)
    }

    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/processor")
            setProcessor(data.data.result)
        })()
    }, [])

    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/ram")
            setram(data.data.result)
        })()
    }, [])

    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/hard-disk")
            setHardDisk(data.data.result)
        })()
    }, [])

    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/external-graphics")
            setExternalGraphics(data.data.result)
        })()
    }, [])

    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/ssd")
            setSSD(data.data.result)
        })()
    }, [])

    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/age-of-laptop")
            setAgeOfLaptop(data.data.result)
        })()
    }, [])

    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/physical-condition")
            setPhysicalConditions(data.data.result)
        })()
    }, [])
    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/functional-condition")
            setfunctionalCondition(data.data.result)
        })()
    }, [])

    const onValueChange = (e: any) => {
        e.preventDefault(e)
        if (e.target.type === "file") {
            setPurchase({ ...purchaseData, [e.target.name]: e.target.files[0] })
        } else {
            setPurchase({ ...purchaseData, [e.target.name]: e.target.value })
        }
        console.log("name=", e.target.name + "Val=", e.target.value)
    }

    const AddPurchaseStep2 = async (e: any) => {
        e.preventDefault()
        ;(async function () {
            // console.log("Data", data);
            let dataCopy: any = purchaseData
            const formData: any = new FormData()

            for (let itemKey in dataCopy) {
                formData.append(itemKey, dataCopy[itemKey])
            }
            await http.patch("/purchase/" + params.id, formData)

            navigate("/purchase")
            // success("You've posted your form!");

            console.log("purchase data", formData)
            return
        })()

    }

    return (
        <div>
            <form onSubmit={AddPurchaseStep2}  className="rounded-md bg-blue-200 p-5">
                <FormGroup>
                    <Grid container spacing={1}>
                        {/* touchpad working touchpad button start */}

                        <Grid item xs={3}>
                            <FormControl>
                                <FormHelperText>
                                    <h3 className="text-xl text-black">TouchPad Working </h3>
                                </FormHelperText>
                                <RadioGroup
                                    name="touchpad_working"
                                    value={purchaseData.touchpad_working}
                                    aria-labelledby="demo-radio-buttons-group-label"
                                >
                                    <FormControlLabel
                                        value="1"
                                        name="touchpad_working"
                                        onChange={(e) => onValueChange(e)}
                                        control={<Radio required={true} />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="0"
                                        name="touchpad_working"
                                        onChange={(e) => onValueChange(e)}
                                        control={<Radio required={true} />}
                                        label="NO"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl>
                                <FormHelperText>
                                    <h3 className="text-xl text-black">TouchPad Button </h3>{" "}
                                </FormHelperText>
                                <RadioGroup
                                    name="touchpad_button"
                                    value={purchaseData.touchpad_button}
                                    aria-labelledby="demo-radio-buttons-group-label"
                                >
                                    <FormControlLabel
                                        value="1"
                                        name="touchpad_button"
                                        onChange={(e) => onValueChange(e)}
                                        control={<Radio required={true} />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="0"
                                        name="touchpad_button"
                                        onChange={(e) => onValueChange(e)}
                                        control={<Radio required={true} />}
                                        label="No"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        {/* touchpad working touchpad button end */}
                        {/* keyboard  start */}
                        <Grid item xs={3}>
                            <FormControl>
                                <FormHelperText>
                                    <h3 className="text-xl text-black">Keyboard </h3>{" "}
                                </FormHelperText>
                                <RadioGroup
                                    name="keyboard"
                                    value={purchaseData.keyboard}
                                    aria-labelledby="demo-radio-buttons-group-label"
                                >
                                    <FormControlLabel
                                        value="1"
                                        name="keyboard"
                                        onChange={(e) => onValueChange(e)}
                                        control={<Radio required={true} />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="0"
                                        name="keyboard"
                                        onChange={(e) => onValueChange(e)}
                                        control={<Radio required={true} />}
                                        label="No"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl>
                                <FormHelperText>
                                    <h3 className="text-xl text-black">Webcam </h3>{" "}
                                </FormHelperText>
                                <RadioGroup
                                    name="webcam"
                                    value={purchaseData.webcam}
                                    aria-labelledby="demo-radio-buttons-group-label"
                                >
                                    <FormControlLabel
                                        value="1"
                                        name="webcam"
                                        onChange={(e) => onValueChange(e)}
                                        control={<Radio required={true} />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="0"
                                        name="webcam"
                                        onChange={(e) => onValueChange(e)}
                                        control={<Radio required={true} />}
                                        label="No"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {/* keyboard end */}
                        {/* wifi usb start */}
                        <Grid item xs={3}>
                            <FormControl>
                                <FormHelperText>
                                    <h3 className="text-xl text-black">Wifi </h3>{" "}
                                </FormHelperText>
                                <RadioGroup
                                    name="wifi"
                                    value={purchaseData.wifi}
                                    aria-labelledby="demo-radio-buttons-group-label"
                                >
                                    <FormControlLabel
                                        value="1"
                                        name="wifi"
                                        onChange={(e) => onValueChange(e)}
                                        control={<Radio required={true} />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="0"
                                        name="wifi"
                                        onChange={(e) => onValueChange(e)}
                                        control={<Radio required={true} />}
                                        label="No"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl>
                                <FormHelperText>
                                    <h3 className="text-xl text-black">Usb Working </h3>{" "}
                                </FormHelperText>
                                <RadioGroup
                                    name="usb_working"
                                    value={purchaseData.usb_working}
                                    aria-labelledby="demo-radio-buttons-group-label"
                                >
                                    <FormControlLabel
                                        value="1"
                                        name="usb_working"
                                        onChange={(e) => onValueChange(e)}
                                        control={<Radio required={true} />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="0"
                                        name="usb_working"
                                        onChange={(e) => onValueChange(e)}
                                        control={<Radio required={true} />}
                                        label="No"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {/* wifi usb end */}

                        {/* blutooth lan start */}
                        <Grid item xs={3}>
                            <FormControl>
                                <FormHelperText>
                                    <h3 className="text-xl text-black">Blutooth </h3>{" "}
                                </FormHelperText>
                                <RadioGroup
                                    name="blutooth"
                                    value={purchaseData.blutooth}
                                    aria-labelledby="demo-radio-buttons-group-label"
                                >
                                    <FormControlLabel
                                        value="1"
                                        name="blutooth"
                                        onChange={(e) => onValueChange(e)}
                                        control={<Radio required={true} />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="0"
                                        name="blutooth"
                                        onChange={(e) => onValueChange(e)}
                                        control={<Radio required={true} />}
                                        label="No"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl>
                                <FormHelperText>
                                    <h3 className="text-xl text-black">LAN </h3>{" "}
                                </FormHelperText>
                                <RadioGroup
                                    name="lan"
                                    value={purchaseData.lan}
                                    aria-labelledby="demo-radio-buttons-group-label"
                                >
                                    <FormControlLabel
                                        value="1"
                                        name="lan"
                                        onChange={(e) => onValueChange(e)}
                                        control={<Radio required={true} />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="0"
                                        name="lan"
                                        onChange={(e) => onValueChange(e)}
                                        control={<Radio required={true} />}
                                        label="No"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {/* blutooth lan end */}
                        {/* hdmi vga start */}
                        <Grid item xs={3}>
                            <FormControl>
                                <FormHelperText>
                                    <h3 className="text-xl text-black">HDMI </h3>{" "}
                                </FormHelperText>
                                <RadioGroup
                                    name="hdmi"
                                    value={purchaseData.hdmi}
                                    aria-labelledby="demo-radio-buttons-group-label"
                                >
                                    <FormControlLabel
                                        value="1"
                                        name="hdmi"
                                        onChange={(e) => onValueChange(e)}
                                        control={<Radio required={true} />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="0"
                                        name="hdmi"
                                        onChange={(e) => onValueChange(e)}
                                        control={<Radio required={true} />}
                                        label="No"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl>
                                <FormHelperText>
                                    <h3 className="text-xl text-black">VGA </h3>{" "}
                                </FormHelperText>
                                <RadioGroup
                                    name="vga"
                                    value={purchaseData.vga}
                                    aria-labelledby="demo-radio-buttons-group-label"
                                >
                                    <FormControlLabel
                                        value="1"
                                        name="vga"
                                        onChange={(e) => onValueChange(e)}
                                        control={<Radio required={true} />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="0"
                                        name="vga"
                                        onChange={(e) => onValueChange(e)}
                                        control={<Radio required={true} />}
                                        label="No"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {/* hdmi vga end */}
                        {/* mic senso end */}
                        <Grid item xs={3}>
                            <FormControl>
                                <FormHelperText>
                                    <h3 className="text-xl text-black">MIC </h3>{" "}
                                </FormHelperText>
                                <RadioGroup
                                    name="mic"
                                    value={purchaseData.mic}
                                    aria-labelledby="demo-radio-buttons-group-label"
                                >
                                    <FormControlLabel
                                        value="1"
                                        name="mic"
                                        onChange={(e) => onValueChange(e)}
                                        control={<Radio required={true} />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="0"
                                        name="mic"
                                        onChange={(e) => onValueChange(e)}
                                        control={<Radio required={true} />}
                                        label="No"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl>
                                <FormHelperText>
                                    <h3 className="text-xl text-black">Biometric Sensor </h3>{" "}
                                </FormHelperText>
                                <RadioGroup
                                    name="biometric_sensor"
                                    value={purchaseData.biometric_sensor}
                                    aria-labelledby="demo-radio-buttons-group-label"
                                >
                                    <FormControlLabel
                                        value="1"
                                        name="biometric_sensor"
                                        onChange={(e) => onValueChange(e)}
                                        control={<Radio required={true} />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="0"
                                        name="biometric_sensor"
                                        onChange={(e) => onValueChange(e)}
                                        control={<Radio required={true} />}
                                        label="No"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {/* mic sensor end */}

                        {/* speaker start */}
                        <Grid item xs={3}>
                            <FormControl>
                                <FormHelperText>
                                    <h3 className="text-xl text-black">Speaker Left </h3>{" "}
                                </FormHelperText>
                                <RadioGroup
                                    name="speaker_left"
                                    value={purchaseData.speaker_left}
                                    aria-labelledby="demo-radio-buttons-group-label"
                                >
                                    <FormControlLabel
                                        value="1"
                                        name="speaker_left"
                                        onChange={(e) => onValueChange(e)}
                                        control={<Radio required={true} />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="0"
                                        name="speaker_left"
                                        onChange={(e) => onValueChange(e)}
                                        control={<Radio required={true} />}
                                        label="No"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl>
                                <FormHelperText>
                                    <h3 className="text-xl text-black">Speaker Right </h3>{" "}
                                </FormHelperText>
                                <RadioGroup
                                    name="speaker_right"
                                    value={purchaseData.speaker_right}
                                    aria-labelledby="demo-radio-buttons-group-label"
                                >
                                    <FormControlLabel
                                        value="1"
                                        name="speaker_right"
                                        onChange={(e) => onValueChange(e)}
                                        control={<Radio required={true} />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="0"
                                        name="speaker_right"
                                        onChange={(e) => onValueChange(e)}
                                        control={<Radio required={true} />}
                                        label="No"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {/* speaker end */}

                        <Grid item xs={3}>
                            <FormControl>
                                <FormHelperText>
                                    <h3 className="text-xl text-black">Battery </h3>{" "}
                                </FormHelperText>
                                <RadioGroup
                                    name="battery"
                                    value={purchaseData.battery}
                                    aria-labelledby="demo-radio-buttons-group-label"
                                >
                                    <FormControlLabel
                                        value="1"
                                        name="battery"
                                        onChange={(e) => onValueChange(e)}
                                        control={<Radio required={true} />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="0"
                                        name="battery"
                                        onChange={(e) => onValueChange(e)}
                                        control={<Radio required={true} />}
                                        label="No"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <FormControl sx={{ my: 4 }}>
                        <Button variant="contained" sx={{ maxWidth: 200 }} type="submit">
                            Submit Purchase
                        </Button>
                    </FormControl>
                </FormGroup>
            </form>
        </div>
    )
}
export default AddPurchaseStep2
