import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Stack, Button, Avatar } from "@mui/material";
import { Outlet, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { setAuth, AuthState } from "../../redux/auth/authSlice";
import { isAllowed } from "../../utils/common";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { RootState } from "../../redux/store";
import "./layout.styles.css";

const fullDrawerWidth = 240;
const compactDrawerWidth = 80;

interface Props {
    window?: () => Window;
}

export default function ResponsiveDrawer(props: Props) {
    const navigate = useNavigate();
    const auth: AuthState = useSelector((state: RootState) => state.auth);

    const sidebarLinks = [
        {
            name: "All Employees",
            link: "/employees",
            show: isAllowed(auth, "all_employee_allowed"),
            icon: <InboxIcon />,
        },
        {
            name: "Attendance",
            link: "/attendance",
            show: isAllowed(auth, "attendance_allowed"),
            icon: <MailIcon />,
        },
        {
            name: "Purchase List",
            link: "/purchase",
            show: isAllowed(auth, "purchase_allowed"),
            icon: <InboxIcon />,
        },
        {
            name: "Admin Tasks",
            link: "/purchase-report",
            show: isAllowed(auth, "admin_report_allowed"),
            icon: <MailIcon />,
        },
        {
            name: "Engineer Tasks",
            link: "/engineer-report-dashboard",
            show: isAllowed(auth, "engineer_report_allowed"),
            icon: <InboxIcon />,
        },
        {
            name: "Sales List",
            link: "/sells",
            show: isAllowed(auth, "sales_report_allowed"),
            icon: <MailIcon />,
        },
        {
            name: "Scraps",
            link: "/scraps",
            show: isAllowed(auth, "sales_report_allowed"),
            icon: <InboxIcon />,
        },
        {
            name: "All Reports",
            link: "/all-reports",
            show: isAllowed(auth, "all_report_allowed"),
            icon: <MailIcon />,
        },
        {
            name: "Designations",
            link: "/designations",
            show: isAllowed(auth, "designation_allowed"),
            icon: <InboxIcon />,
        },
        {
            name: "Masters Settings",
            link: "/setting",
            show: isAllowed(auth, "settings_allowed"),
            icon: <MailIcon />,
        },
    ];

    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [showDetailedView, setShowDetailedView] = React.useState(true);
    const dispatch = useDispatch();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const toggleView = () => {
        setShowDetailedView(!showDetailedView);
    };

    
const drawer = ( 
    <div className="relative mt-20 flex flex-col justify-between overflow-hidden ">
      <div className="absolute -top-10 -right-10 p-4  z-10">
      <Toolbar>
        <IconButton onClick={toggleView} sx={{ ml: 'auto',bgcolor:'GrayText',mt:'18px' }}>
          {showDetailedView ? <MenuOpenIcon className="text-white " /> : <MenuIcon className="text-white" />}
        </IconButton>
      </Toolbar>
      </div>
      <Divider />
      <List>
        {sidebarLinks
          .filter((item) => item.show)
          .map((sidebarLink, index) => (
            <NavLink
              to={`${sidebarLink.link}`}
              style={{ textDecoration: "none", color: "#444" }}
              className="sidebar-link"
              key={sidebarLink.name}
            >
              <ListItem key={sidebarLink.name} disablePadding>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: showDetailedView ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: showDetailedView ? 56 : 0,
                      mr: showDetailedView ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    {sidebarLink.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={sidebarLink.name}
                    sx={{ opacity: showDetailedView ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </NavLink>
          ))}
      </List>
    </div>
  );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: "none" } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Stack
                        sx={{ width: "100%" }}
                        flexDirection="row"
                        justifyContent="space-between"
                    >
                        <Typography variant="h6" noWrap component="div">
                            Lapshop ERP
                        </Typography>

                        <NavLink
                            style={{ textDecoration: "none" }}
                            to={`/my-profile/${auth.user.id}`}
                        >
                            <Button
                                sx={{ textDecoration: "none", ml: 100 }}
                                variant="contained"
                                color="primary"
                            >
                                My Profile
                            </Button>
                        </NavLink>

                        <Button
                            onClick={() => dispatch(logout())}
                            variant="contained"
                            color="primary"
                        >
                            Logout
                        </Button>
                    </Stack>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: showDetailedView ? fullDrawerWidth : compactDrawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: "block", sm: "none" },
                        "& .MuiDrawer-paper": { boxSizing: "border-box", width: showDetailedView ? fullDrawerWidth : compactDrawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: "none", sm: "block" },
                        "& .MuiDrawer-paper": { boxSizing: "border-box", width: showDetailedView ? fullDrawerWidth : compactDrawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                    width: { sm: `calc(100% - ${showDetailedView ? fullDrawerWidth : compactDrawerWidth}px)` },
                    background: "#eee",
                    minHeight: "100vh",
                }}
            >
                <Toolbar />
                <Outlet />
            </Box>
        </Box>
    );
}
