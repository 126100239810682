import React, { useEffect } from "react"
import "./App.css"
import type { RootState } from "./redux/store"
import { initUser } from "./redux/auth/authSlice"
import { useDispatch, useSelector } from "react-redux"
import { CircularProgress, Stack } from "@mui/material"

import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import AppRouter from "./AppRouter"
import { BrowserRouter } from "react-router-dom"

function App() {
    const dispatch: any = useDispatch()
    const authInitialized = useSelector((state: RootState) => state.auth.authInitialized)
    useEffect(() => {
        dispatch(initUser())
    }, [])
    return (
        <BrowserRouter basename="/">
            {authInitialized ? (
                <AppRouter />
            ) : (
                <Stack direction="row" justifyContent="center" mt={40}>
                    <CircularProgress />
                </Stack>
            )}
            <ToastContainer />
        </BrowserRouter>
    )
}

export default App
