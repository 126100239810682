import { useState, useEffect } from "react";
import { http } from "../../utils/http";
import { useParams } from "react-router-dom";
import Hidden from "@mui/material/Hidden";

import Checkbox from "@mui/material/Checkbox";

import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useNavigate } from "react-router-dom";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  FormGroup,
  FormControl,
  Grid,
  Button,
  styled,
  OutlinedInput,
  FormHelperText,
  TextField,
} from "@mui/material";
import { width } from "@mui/system";

const Container = styled(FormGroup)`
  width: 100%;
`;
{
  /* <Typography variant="h4">Add New Employee</Typography> */
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const initialValues = {
  ram_id: "",
  name: "",
  create_allowed: false,
  edit_allowed: false,
  update_allowed: false,
  delete_allowed: false,

  all_employee_allowed: false,
  attendance_allowed: false,
  purchase_allowed: false,
  admin_report_allowed: false,
  engineer_report_allowed: false,
  all_report_allowed: false,
  designation_allowed: false,
  settings_allowed: false,
};

const EditDesignation = () => {
  //     const [sal, setSal] = useState({  ...initialValues}
  //      );

  //   useEffect(() => {
  //     (async function () {
  //       const salDataRes = await http.get("/salary/"+params.id);
  //       if(salDataRes ){
  //       setSal(salDataRes.data.result);
  //     }
  //       console.log('slaryyy',sal);
  //     })();
  //   }, []);

  let params = useParams();
  const navigate = useNavigate();

  const [desData, setdesData] = useState({ ...initialValues });

  useEffect(() => {
    (async () => {
      const data: any = await http.get("/designations/" + params.id);
      setdesData(data.data.result);
    })();
  }, []);

  const onValueChange = (e: any) => {
    e.preventDefault(e);
    setdesData({ ...desData, [e.target.name]: e.target.value });
    console.log("name is ", e.target.name, "value is", e.target.value);
  };
  const EditDesignationdetail = async (e: any) => {
    e.preventDefault();
    try {
      console.log("data", desData);
      await http.patch("/designations/" + params.id, desData);
      navigate("/designations");
      return;
    } catch (error: any) {
      console.log("error while calling api", error.message);
    }
  };

  return (
    <div>
      <h2>designations ID: {params.id}</h2>
      <form onSubmit={EditDesignationdetail}>
        <FormGroup>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h4>Name</h4>
                </FormHelperText>
                <OutlinedInput
                  sx={{ background: "white", mb: 1 }}
                  onChange={(e) => onValueChange(e)}
                  name="name"
                  value={desData.name}
                  size="small"
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <h4>
                Create Allowed
                <Checkbox
                  onChange={(e) => onValueChange(e)}
                  name="create_allowed"
                  checked={desData.create_allowed}
                  value="1"
                />
              </h4>
            </Grid>
            <Grid item xs={6}>
              <h4>
                Edit Allowed
                <Checkbox
                  onChange={(e) => onValueChange(e)}
                  name="edit_allowed"
                  checked={desData.edit_allowed}
                  value="1"
                />
              </h4>
            </Grid>
            <Grid item xs={6}>
              <h4>
                Update Allowed
                <Checkbox
                  onChange={(e) => onValueChange(e)}
                  name="update_allowed"
                  checked={desData.update_allowed}
                  value="1"
                  
                />
              </h4>
            </Grid>
            <Grid item xs={6}>
              <h4>
                Delete Allowed
                <Checkbox
                  onChange={(e) => onValueChange(e)}
                  name="delete_allowed"
                  checked={desData.delete_allowed}
                  value="1"
                />
              </h4>
            </Grid>
            <Grid item xs={3}>
              <h4>
                All Employees Allowed
                <Checkbox
                  onChange={(e) => onValueChange(e)}
                  name="all_employee_allowed"
                  checked={desData.all_employee_allowed}
                  value="1"
                />
              </h4>
            </Grid>
            <Grid item xs={3}>
              <h4>
                Attendance Allowed
                <Checkbox
                  onChange={(e) => onValueChange(e)}
                  name="attendance_allowed"
                  checked={desData.attendance_allowed}
                  value="1"
                />
              </h4>
            </Grid>
            <Grid item xs={3}>
              <h4>
                Purchase Allowed
                <Checkbox
                  onChange={(e) => onValueChange(e)}
                  name="purchase_allowed"
                  checked={desData.purchase_allowed}
                  value="1"
                />
              </h4>
            </Grid>
            <Grid item xs={3}>
              <h4>
                Admin Report Allowed
                <Checkbox
                  onChange={(e) => onValueChange(e)}
                  name="admin_report_allowed"
                  checked={desData.admin_report_allowed}
                  value="1"
                />
              </h4>
            </Grid>
            <Grid item xs={3}>
              <h4>
               Engineer Report Allowed
                <Checkbox
                  onChange={(e) => onValueChange(e)}
                  name="engineer_report_allowed"
                  checked={desData.engineer_report_allowed}
                  value="1"
                />
              </h4>
            </Grid>
            <Grid item xs={3}>
              <h4>
                All Report Allowed
                <Checkbox
                  onChange={(e) => onValueChange(e)}
                  name="all_report_allowed"
                  checked={desData.all_report_allowed}
                  value="1"
                />
              </h4>
            </Grid>
            <Grid item xs={3}>
              <h4>
                Designation Allowed
                <Checkbox
                  onChange={(e) => onValueChange(e)}
                  name="designation_allowed"
                  checked={desData.designation_allowed}
                  value="1"
                />
              </h4>
            </Grid>
            <Grid item xs={3}>
              <h4>
                Setting Allowed
                <Checkbox
                  onChange={(e) => onValueChange(e)}
                  name="settings_allowed"
                  checked={desData.settings_allowed}
                  value="1"
                />
              </h4>
            </Grid>
          </Grid>
          {/* <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Create</TableCell>
                  <TableCell>Edit</TableCell>
                  <TableCell>Update</TableCell>
                  <TableCell>Delete</TableCell>
                 
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    All Employees
                  </TableCell>

                  <TableCell>
                  
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="create_allowed"
                      value="1"
                    />
                  </TableCell>

                  <TableCell>
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="edit_allowed"
                      value="1"
                      checked={desData.edit_allowed}
                    />
                  </TableCell>

                  <TableCell>
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="update_allowed"
                      value="1"
                    />
                  </TableCell>

                  <TableCell>
                    
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="delete_allowed"
                      value="1"
                    />
                  </TableCell>

                 
                </TableRow>

                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Attendance
                  </TableCell>
                  <TableCell>
                  
                  <Checkbox
                    onChange={(e) => onValueChange(e)}
                    name="create_allowed"
                    value="1"
                  />
                </TableCell>
                  <TableCell>
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="edit_allowed"
                      checked={desData.edit_allowed}
                      value="1"
                    />
                  </TableCell>

                  <TableCell>
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="update_allowed"
                      value="1"
                    />
                  </TableCell>

                  <TableCell>
                   
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="delete_allowed"
                      value="1"
                    />
                  </TableCell>
                 
                </TableRow>

                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    CheckInOuts
                  </TableCell>
                  <TableCell>
                  
                  <Checkbox
                    onChange={(e) => onValueChange(e)}
                    name="create_allowed"
                    value="1"
                  />
                </TableCell>
                  <TableCell>
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="edit_allowed"
                      checked={desData.edit_allowed}
                      value="1"
                    />
                  </TableCell>

                  <TableCell>
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="update_allowed"
                      value="1"
                    />
                  </TableCell>

                  <TableCell>
                    
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="delete_allowed"
                      value="1"
                    />
                  </TableCell>
                 
                </TableRow>

                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Reports
                  </TableCell>
                  <TableCell>
                  
                  <Checkbox
                    onChange={(e) => onValueChange(e)}
                    name="create_allowed"
                    value="1"
                  />
                </TableCell>
                  <TableCell>
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="edit_allowed"
                      checked={desData.edit_allowed}
                      value="1"
                    />
                  </TableCell>

                  <TableCell>
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="update_allowed"
                      value="1"
                    />
                  </TableCell>

                  <TableCell>
                    
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="delete_allowed"
                      value="1"
                    />
                  </TableCell>
                 
                </TableRow>

                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Purchase
                  </TableCell>
                  <TableCell>
                  
                  <Checkbox
                    onChange={(e) => onValueChange(e)}
                    name="create_allowed"
                    value="1"
                  />
                </TableCell>
                  <TableCell>
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="edit_allowed"
                      checked={desData.edit_allowed}
                      value="1"
                    />
                  </TableCell>

                  <TableCell>
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="update_allowed"
                      value="1"
                    />
                  </TableCell>

                  <TableCell>
                    
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="delete_allowed"
                      value="1"
                    />
                  </TableCell>
                 
                </TableRow>

                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Admin Report
                  </TableCell>
                  <TableCell>
                  
                  <Checkbox
                    onChange={(e) => onValueChange(e)}
                    name="create_allowed"
                    value="1"
                  />
                </TableCell>
                  <TableCell>
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="edit_allowed"
                      checked={desData.edit_allowed}
                      value="1"
                    />
                  </TableCell>

                  <TableCell>
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="update_allowed"
                      value="1"
                    />
                  </TableCell>

                  <TableCell>
                    
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="delete_allowed"
                      value="1"
                    />
                  </TableCell>
                 
                </TableRow>

                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Engineer Report
                  </TableCell>
                  <TableCell>
                  
                  <Checkbox
                    onChange={(e) => onValueChange(e)}
                    name="create_allowed"
                    value="1"
                  />
                </TableCell>
                  <TableCell>
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="edit_allowed"
                      checked={desData.edit_allowed}
                      value="1"
                    />
                  </TableCell>

                  <TableCell>
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="update_allowed"
                      value="1"
                    />
                  </TableCell>

                  <TableCell>
                    
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="delete_allowed"
                      value="1"
                    />
                  </TableCell>
                 
                </TableRow>

                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    All Reports
                  </TableCell>
                  <TableCell>
                  
                  <Checkbox
                    onChange={(e) => onValueChange(e)}
                    name="create_allowed"
                    value="1"
                  />
                </TableCell>
                  <TableCell>
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="edit_allowed"
                      checked={desData.edit_allowed}
                      value="1"
                    />
                  </TableCell>

                  <TableCell>
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="update_allowed"
                      value="1"
                    />
                  </TableCell>

                  <TableCell>
                    
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="delete_allowed"
                      value="1"
                    />
                  </TableCell>
                 
                </TableRow>

                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Designations
                  </TableCell>
                  <TableCell>
                  
                  <Checkbox
                    onChange={(e) => onValueChange(e)}
                    name="create_allowed"
                    value="1"
                  />
                </TableCell>
                  <TableCell>
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="edit_allowed"
                      checked={desData.edit_allowed}
                      value="1"
                    />
                  </TableCell>

                  <TableCell>
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="update_allowed"
                      value="1"
                    />
                  </TableCell>

                  <TableCell>
                    
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="delete_allowed"
                      value="1"
                    />
                  </TableCell>
                 
                </TableRow>

                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Settings
                  </TableCell>
                  <TableCell>
                  
                  <Checkbox
                    onChange={(e) => onValueChange(e)}
                    name="create_allowed"
                    value="1"
                  />
                </TableCell>
                  <TableCell>
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="edit_allowed"
                      checked={desData.edit_allowed}
                      value="1"
                    />
                  </TableCell>

                  <TableCell>
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="update_allowed"
                      value="1"
                    />
                  </TableCell>

                  <TableCell>
                    
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="delete_allowed"
                      value="1"
                    />
                  </TableCell>
                 
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer> */}

          <FormControl sx={{ my: 4 }}>
            <Button variant="contained" sx={{ maxWidth: 200 }} type="submit">
              Update
            </Button>
          </FormControl>
        </FormGroup>
      </form>
    </div>
  );
};
export default EditDesignation;
