import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import {http} from "../../utils/http"

const PAGE_SIZE = 10;

type viewSparePart = {
 id: number;
 name: string;
 category: string;
 price: number;
 inwardDate: Date;
 quantity: string;
 active: boolean;
 used: boolean;
};

const ViewSparePart = () => {
 const [sparePartData, setSparePartData] = useState([]);
 const [totalPages, setTotalPages] = useState(0);
 const [currentPage, setCurrentPage] = useState(1);
 const navigate = useNavigate();
 const location = useLocation();
 const searchParams = new URLSearchParams(location.search);
 const nameFilter = searchParams.get("name")?.trim().replace(/^"(.*)"$/, '$1') || "";
 
 const fetchData = async (page: number) => {
    try {
      const response = await http.get(`/stockSpare?page=${page}&limit=${PAGE_SIZE}`);
      console.log(response);

      setSparePartData(response.data.data);
      setTotalPages(Math.ceil(response.data.totalItems / PAGE_SIZE));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
 };

 useEffect(() => {
    fetchData(currentPage);
 }, [currentPage]);

 const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
 };

 const filteredSparePartData = sparePartData.filter((item:any) => item.name.toLowerCase().includes(nameFilter.toLowerCase()));
    return (
        <div>
            <h3 className="p-5 text-black">Spare Part Stock Task</h3>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow sx={{ background: "skyBlue" }}>
                            <TableCell>ID</TableCell>
                            <TableCell> Name</TableCell>
                            <TableCell>Category</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>In WardDate</TableCell>
                            <TableCell>Quality</TableCell>
                            <TableCell>Active</TableCell>
                            <TableCell>Used</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sparePartData &&
                        filteredSparePartData.map((row: viewSparePart) => (
                                <TableRow
                              onClick={() => navigate(`/update-spare-part/${row.id}`)}
                              key={row.id}
                              sx={{ 
                                 "&:hover": {
                                   backgroundColor: '#f5f5f5', 
                                 },
                                 cursor: 'pointer', 
                                 "&:last-child td, &:last-child th": { border: 0 } 
                              }}
                             >
                                <TableCell component="th" scope="row">
                                        {row.id}
                                    </TableCell>
                                    <TableCell >
                                        {row.name}
                                    </TableCell>
                                    <TableCell>
                                        {row.category }
                                    </TableCell>
                                    <TableCell>₹{row.price}</TableCell>
                                    <TableCell>{new Date(row.inwardDate).toLocaleDateString("en-US",{year:"numeric",month:"long",day:"numeric"})}</TableCell>
                                    <TableCell>{row.quantity}</TableCell>
                                    <TableCell>{row.active?"Active":"Offline"}</TableCell>
                                    <TableCell>{row.used?"Used":"Not Used"}</TableCell>

                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
                    {/* Pagination Controls */}
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={(event, newPage) => handlePageChange(newPage)}
          color="primary"
        />
      </Box>
        </div>
    )
}


export default ViewSparePart;