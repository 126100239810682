import React from "react"
import { Box } from "@mui/material"

type Props = {
    children: any
    smallMargin?: boolean
}
export default function CardBox({ children, smallMargin = false }: Props) {
    return (
        <Box sx={{ boxShadow: 2, background: "white", px: 5, py: 3, mb: smallMargin ? 1 : 3 }}>
            {children}
        </Box>
    )
}
