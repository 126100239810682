import { useState, useEffect } from "react"
import { http } from "../../utils/http"
import { useParams } from "react-router-dom"
import Hidden from "@mui/material/Hidden"
import { Paper } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import {
    FormGroup,
    FormControl,
    Grid,
    Button,
    styled,
    OutlinedInput,
    FormHelperText,
    TextField,
} from "@mui/material"
import { width } from "@mui/system"

const Container = styled(FormGroup)`
    width: 100%;
`
{
    /* <Typography variant="h4">Add New Employee</Typography> */
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const initialValues = {
    emp_id: "",
    basic: "",
    hra: "",
    medical_allowance: "",
    special_allowance: "",
    travel_allowance: "",
    meal_allowance: "",
    leave_allowance: "",
    bonus: "",
    gross_pay: "",
    deduction: "",
}

const ViewSalary = () => {
    function getDaysInMonth(year: any, month: any) {
        return new Date(year, month, 0).getDate()
    }
    const date = new Date()
    const currentYear = date.getFullYear()
    const currentMonth = date.getMonth() + 1
    const month = date.toLocaleString("default", { month: "long" }) + " " + currentYear
    console.log("currentMonth", month)
    //  months are 0-based

    // Current Month
    const daysInCurrentMonth = getDaysInMonth(currentYear, currentMonth)
    console.log("daysInCurrentMonth", daysInCurrentMonth)

    let params = useParams()
    const navigate = useNavigate()
    const [Salary, setSalary] = useState({ ...initialValues, emp_id: params.id })
    const [SalaryAdd, setSalaryAdd] = useState({ ...initialValues })
    const [empSalary, salcheckdata] = useState({ ...initialValues, emp_id: params.id })
    const [netPay, setNetPay] = useState(0)
    const [grossPay, setGrossPay] = useState(0)

    useEffect(() => {
        ;(async function () {
            const salcheckRes = await http.get("/salary/" + params.id)
            const result = salcheckRes.data.result
            if (result) {
                salcheckdata(result)
                setNetPay(result.netPay)
                setGrossPay(result.grossPay)
            }
        })()
    }, [])

    const onValueChange = (e: any) => {
        let name = e.target.name
        let value = e.target.value

        setSalaryAdd({ ...SalaryAdd, [name]: value })
        calc_total(SalaryAdd)
        e.preventDefault(e)
        // setSalary({...SalaryAdd,})
        salcheckdata({ ...empSalary, [e.target.name]: e.target.value })
        // console.log(e.target.name, 'is', e.target.value);
    }
    const [total, set_total] = useState(0)

    const calc_total = (SalaryAdd: any) => {
        var aux: any = 0
        for (var key in SalaryAdd) {
            aux += SalaryAdd[key]
        }
        set_total(aux)
    }

    const updateSalary = async (e: any) => {
        e.preventDefault()
        try {
            const updateData = await http.patch("/salary/" + params.id, empSalary)
            toast.success(updateData.data.message)
            navigate("/employees")
            return
        } catch (error: any) {
            console.log("error while calling api", error.message)
        }
    }

    return (
        <div>
            <h3>View Salary</h3>
            {/* <h2>Employee ID: {params.id}</h2> */}
            <Paper elevation={5} sx={{ p: 2 }}>
                <form onSubmit={updateSalary}>
                    <FormGroup>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FormControl sx={{ width: "60%" }}>
                                    <FormHelperText>
                                        <h3>EMP ID</h3>
                                    </FormHelperText>
                                    <OutlinedInput
                                        sx={{ background: "white" }}
                                        value={Salary.emp_id}
                                        onChange={(e) => onValueChange(e)}
                                        name="emp_id"
                                        disabled
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <h3>Earnings</h3>
                            </Grid>

                            <Grid item xs={4}>
                                <FormHelperText>
                                    <h3>Basic + DA</h3>
                                </FormHelperText>
                            </Grid>
                            <Grid item xs={8}>
                                <FormControl sx={{ width: "60%" }}>
                                    <OutlinedInput
                                        sx={{ background: "white" }}
                                        placeholder="Please enter Amount"
                                        onChange={(e) => onValueChange(e)}
                                        name="basic"
                                        value={empSalary.basic}
                                        id="basicId"
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={4}>
                                <FormHelperText>
                                    <h3>HRA</h3>
                                </FormHelperText>
                            </Grid>
                            <Grid item xs={8}>
                                <FormControl sx={{ width: "60%" }}>
                                    <OutlinedInput
                                        sx={{ background: "white" }}
                                        placeholder="Please enter Amount"
                                        onChange={(e) => onValueChange(e)}
                                        name="hra"
                                        value={empSalary.hra}
                                        id="hraId"
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={4}>
                                <FormHelperText>
                                    <h3>Medical Allowance</h3>
                                </FormHelperText>
                            </Grid>
                            <Grid item xs={8}>
                                <FormControl sx={{ width: "60%" }}>
                                    <OutlinedInput
                                        sx={{ background: "white" }}
                                        placeholder="Please enter Amount"
                                        onChange={(e) => onValueChange(e)}
                                        name="medical_allowance"
                                        value={empSalary.medical_allowance}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormHelperText>
                                    <h3>Special Allowance</h3>
                                </FormHelperText>
                            </Grid>
                            <Grid item xs={8}>
                                <FormControl sx={{ width: "60%" }}>
                                    <OutlinedInput
                                        sx={{ background: "white" }}
                                        placeholder="Please enter Amount"
                                        onChange={(e) => onValueChange(e)}
                                        name="special_allowance"
                                        value={empSalary.special_allowance}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={4}>
                                <FormHelperText>
                                    <h3>Travel Allowance</h3>
                                </FormHelperText>
                            </Grid>
                            <Grid item xs={8}>
                                <FormControl sx={{ width: "60%" }}>
                                    <OutlinedInput
                                        sx={{ background: "white" }}
                                        placeholder="Please enter Amount"
                                        onChange={(e) => onValueChange(e)}
                                        name="travel_allowance"
                                        value={empSalary.travel_allowance}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={4}>
                                <FormHelperText>
                                    <h3>Meal Allowance </h3>
                                </FormHelperText>
                            </Grid>
                            <Grid item xs={8}>
                                <FormControl sx={{ width: "60%" }}>
                                    <OutlinedInput
                                        sx={{ background: "white" }}
                                        placeholder="Please enter Amount"
                                        onChange={(e) => onValueChange(e)}
                                        name="meal_allowance"
                                        value={empSalary.meal_allowance}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormHelperText>
                                    <h3>Leave Travel Allowance </h3>
                                </FormHelperText>
                            </Grid>
                            <Grid item xs={8}>
                                <FormControl sx={{ width: "60%" }}>
                                    <OutlinedInput
                                        sx={{ background: "white" }}
                                        placeholder="Please enter Amount"
                                        onChange={(e) => onValueChange(e)}
                                        name="leave_allowance"
                                        value={empSalary.leave_allowance}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={4}>
                                <FormHelperText>
                                    <h3> Bonus</h3>
                                </FormHelperText>
                            </Grid>
                            <Grid item xs={8}>
                                <FormControl sx={{ width: "60%" }}>
                                    <OutlinedInput
                                        sx={{ background: "white" }}
                                        placeholder="Please enter Amount"
                                        onChange={(e) => onValueChange(e)}
                                        name="bonus"
                                        value={empSalary.bonus}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <hr />
                            </Grid>
                            <Grid item xs={4}>
                                <FormHelperText>
                                    <h3>Deduction(-)</h3>
                                </FormHelperText>
                            </Grid>
                            <Grid item xs={8}>
                                <FormControl sx={{ width: "60%" }}>
                                    <OutlinedInput
                                        sx={{ background: "white" }}
                                        placeholder="Please enter Amount"
                                        onChange={(e) => onValueChange(e)}
                                        name="deduction"
                                        value={empSalary.deduction}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <h3>Gross pay</h3>
                            </Grid>
                            <Grid item xs={8}>
                                <FormControl sx={{ width: "60%" }}>
                                    <OutlinedInput
                                        sx={{ background: "white" }}
                                        placeholder="Please enter Amount"
                                        onChange={(e) => onValueChange(e)}
                                        name="gross_pay"
                                        value={"₹ " + grossPay}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <h3>Net Pay in Hand</h3>
                                <div>₹ {netPay}</div>
                            </Grid>
                        </Grid>
                        {/* //container grid close */}

                        <FormControl sx={{ my: 4 }}>
                            <Button variant="contained" sx={{ maxWidth: 200 }} type="submit">
                                Update Salary
                            </Button>
                        </FormControl>
                    </FormGroup>
                </form>
            </Paper>
        </div>
    )
}
export default ViewSalary
