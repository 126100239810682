import { Typography, Stack, Box } from "@mui/material"

export default function FormRow({ label,background, children }: any) {
    return (
        <Box sx={{ background: background, width: "100%", p: 1, pl: 2, mb: 2, borderRadius: 3 }}>
            <Stack direction="row" sx={{ width: "100%" }}>
                <Box>
                    <Typography sx={{ fontSize: 12 }}>{label}</Typography>
                </Box>
                <Box>{children}</Box>
            </Stack>
        </Box>
    )
}
