import * as React from "react"
import { useState, useEffect } from "react"
import { http } from "../../utils/http"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import FormLabel from "@mui/material/FormLabel"
import MenuItem from "@mui/material/MenuItem"
import { useNavigate } from "react-router-dom"
import TextareaAutosize from "@mui/material/TextareaAutosize"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import Step1 from "./add-purchase-step-1"
import Step2 from "./add-purchase-step-2"
import { NavLink, Link } from "react-router-dom"
import InputLabel from "@mui/material/InputLabel"
import { StyledEngineProvider } from "@mui/material"
import { FaImage } from "react-icons/fa"
import Zoom from "react-medium-image-zoom"
import { MdCancel } from "react-icons/md"

import {
    FormGroup,
    FormControl,
    Grid,
    Button,
    styled,
    OutlinedInput,
    FormHelperText,
} from "@mui/material"
import { width } from "@mui/system"
import PurchaseFormBlockRow from "../../components/ui/PurchaseFormBlockRow"

const Container = styled(FormGroup)`
    width: 100%;
`
{
    /* <Typography variant="h4">Add New Employee</Typography> */
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const initialValues = {
    brand_master_id: "",
    series_master_id: "",
    model_no: "",
    general_image: "",
    screen_size_image: "",
    functional_condition_image: "",
    physical_condition_image: "",
    age_of_laptop_image: "",

    processor_brand_id: "",
    processor_series_id: "",
    processor_generation_id: "",
    processor_gigaHeartz_id: "",

    graphic_brand_id: "",
    graphic_series_id: "",
    graphic_model_id: "",
    graphic_size_id: "",

    screen_size_id: "",
    screen_resolution_id: "",
    screen_spot_any_id: "",

    num_lock: "",
    back_lit: "",

    product_type: "",
    processor_id: "",
    ram_id: "",
    hard_disk_id: "",
    ssd_id: "",
    external_graphics_id: "",

    age_of_laptop_id: "",
    physical_condition_id: "",
    functional_condition_id: "",

    date: "",
    price: "",
    vendor_name: "",
    shelf_no: "",
    pickup_person: "",
    charger_type: "",
    problem: "",

    payment_mode: "",
    description: "",
}

const AddPurchase = () => {
    const [isActive, setIsActive] = useState(false)

    const handleClick = () => {
        setIsActive((current) => !current)
    }
    const handleClick2 = () => {
        setIsActive(false)
    }

    const [page, setPage] = useState(0)

    const formTitles = ["Step1", "step2"]
    const pageDisplay = () => {
        if (page === 1) {
            return <Step2 />
        }
    }
    const navigate = useNavigate()
    //master brand,model,series & General Image
    const [brandMasters, setBrandMaster] = useState([])
    const [seriesMasters, setSeriesMaster] = useState([])
    //start master
    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/brand-master")
            setBrandMaster(data.data.result)
        })()
    }, [])
    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/series-master")
            setSeriesMaster(data.data.result)
        })()
    }, [])

    //end
    //end//
    //processor brand,series,generation,gigaHeartz
    const [processorBrands, setProcessorBrand] = useState([])
    const [processorSeries, setProcessorSeries] = useState([])
    const [processorGenerations, setProcessorGeneration] = useState([])
    const [processorGigaHeartzs, setProcessorGigaHeartz] = useState([])

    //start processor
    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/processor-brand")
            setProcessorBrand(data.data.result)
        })()
    }, [])

    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/processor-series")
            setProcessorSeries(data.data.result)
        })()
    }, [])

    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/processor-generation")
            setProcessorGeneration(data.data.result)
        })()
    }, [])

    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/processor-gigaHeartz")
            setProcessorGigaHeartz(data.data.result)
        })()
    }, [])

    // end
    //end

    //graphic
    const [graphicBrands, setGraphicBrand] = useState([])
    const [graphicSeries, setGraphicSeries] = useState([])
    const [graphicModels, setGraphicModel] = useState([])
    const [graphicSizes, setGraphicSize] = useState([])
    //start Graphic
    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/graphic-brand")
            setGraphicBrand(data.data.result)
        })()
    }, [])

    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/graphic-series")
            setGraphicSeries(data.data.result)
        })()
    }, [])

    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/graphic-model")
            setGraphicModel(data.data.result)
        })()
    }, [])

    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/graphic-size")
            setGraphicSize(data.data.result)
        })()
    }, [])

    //end
    //graphic end

    //screen
    const [screenSizes, setScreenSize] = useState([])
    const [screenResolutions, setScreenResolution] = useState([])
    const [screenSpots, setScreenSpot] = useState([])

    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/screen-size")
            setScreenSize(data.data.result)
        })()
    }, [])

    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/screen-resolution")
            setScreenResolution(data.data.result)
        })()
    }, [])
    //   /condition means spot any
    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/conditions")
            setScreenSpot(data.data.result)
        })()
    }, [])

    // screen end
    const [purchaseData, setPurchase] = useState(initialValues)
    const [employees, setEmployees] = useState([])
    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/employees")
            setEmployees(data.data.result)
        })()
    }, [])
    const [processors, setProcessor] = useState([])
    const [rams, setram] = useState([])
    const [hardDisks, setHardDisk] = useState([])
    const [externalGraphics, setExternalGraphics] = useState([])
    const [ssds, setSSD] = useState([])
    const [ageOfLaptops, setAgeOfLaptop] = useState([])
    const [physicalConditions, setPhysicalConditions] = useState([])
    const [functionalConditions, setfunctionalCondition] = useState([])
    const [age, setAge] = React.useState("")
    const [chargerTypes, setChargerTypes] = useState([])
    const [generalImagePreview, setGeneralImagePreview] = useState("")
    const [processorImagePreview, setProcessorImagePreview] = useState("")
    const [ageOfLaptopImagePreview, setAgeOfLaptopImagePreview] = useState("")
    const [ramImagePreview, setRamImagePreview] = useState("")
    const [ssdImagePreview, setSsdImagePreview] = useState("")
    const [hardDiskImagePreview, setHardDiskImagePreview] = useState("")
    const [functionalConditionImagePreview, setFunctionalConditionImagePreview] = useState("")
    const [screenSizeImagePreview, setScreenSizeImagePreview] = useState("")
    const [physicalConditionImagePreview, setPhysicalConditionImagePreview] = useState("")
    const [externalGraphicsImagePreview, setExternalGraphicsImagePreview] = useState("")

    const handleChange = (event: SelectChangeEvent) => {
        setAge(event.target.value as string)
    }
    const FileInput = styled("input")({
        display: "none",
    })
    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/processor")
            setProcessor(data.data.result)
        })()
    }, [])

    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/ram")
            setram(data.data.result)
        })()
    }, [])

    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/hard-disk")
            setHardDisk(data.data.result)
        })()
    }, [])

    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/external-graphics")
            setExternalGraphics(data.data.result)
        })()
    }, [])

    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/ssd")
            setSSD(data.data.result)
        })()
    }, [])

    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/age-of-laptop")
            setAgeOfLaptop(data.data.result)
        })()
    }, [])

    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/physical-condition")
            setPhysicalConditions(data.data.result)
        })()
    }, [])
    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/functional-condition")
            setfunctionalCondition(data.data.result)
        })()
    }, [])

    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/charger-type")
            setChargerTypes(data.data.result)
        })()
    }, [])
    useEffect(() => {
        console.log("purchased11", purchaseData)
    }, [purchaseData])
    const onValueChange = (e: any) => {
        e.preventDefault(e)
        let updatedValue
        if (e.target.type === "file") {
            const file = e.target.files[0]
            if (e.target.name === "general_image") {
                setGeneralImagePreview(URL.createObjectURL(file))
            } else if (e.target.name === "processor_image") {
                setProcessorImagePreview(URL.createObjectURL(file))
            } else if (e.target.name === "age_of_laptop_image") {
                setAgeOfLaptopImagePreview(URL.createObjectURL(file))
            } else if (e.target.name === "ram_image") {
                const previewUrl = URL.createObjectURL(file)
                setRamImagePreview(previewUrl)
            } else if (e.target.name === "ssd_image") {
                const previewUrl = URL.createObjectURL(file)
                setSsdImagePreview(previewUrl)
            } else if (e.target.name === "hard_disk_image") {
                const previewUrl = URL.createObjectURL(file)
                setHardDiskImagePreview(previewUrl)
            } else if (e.target.name === "functional_condition_image") {
                const previewUrl = URL.createObjectURL(file)
                setFunctionalConditionImagePreview(previewUrl)
            } else if (e.target.name === "physical_condition_image") {
                const previewUrl = URL.createObjectURL(file)
                setPhysicalConditionImagePreview(previewUrl)
            } else if (e.target.name === "screen_size_image") {
                const previewUrl = URL.createObjectURL(file)
                setScreenSizeImagePreview(previewUrl)
            } else if (e.target.name === "external_graphic_image") {
                const previewUrl = URL.createObjectURL(file)
                setExternalGraphicsImagePreview(previewUrl)
            }
            console.log("update22", [e.target.name][0], file)

            setPurchase((prevState) => ({ ...prevState, [e.target.name]: file }))
            return
        } else if (e.target.name === "description") {
            updatedValue = e.target.value
        } else {
            updatedValue =
                e.target.type === "radio" ||
                e.target.type === "text" ||
                e.target.type === "date" ||
                e.target.type === "number"
                    ? e.target.value
                    : parseInt(e.target.value, 10)
        }
        setPurchase({ ...purchaseData, [e.target.name]: updatedValue })
        console.log("name=", e.target.name + "Val=", e.target.value, e.target.type)
    }

    const SubmitPurchase = async (e: any) => {
        e.preventDefault()

        // Log the data before submitting
        console.log("Form Data:", purchaseData)
        ;(async function () {
            let dataCopy: any = purchaseData
            const formData: any = new FormData()

            for (let itemKey in dataCopy) {
                formData.append(itemKey, dataCopy[itemKey])
            }

            try {
                const purchaseId = await http.post("/purchase", formData)
                console.log("Purchase ID:", purchaseId.data.result.dataValues.id)

                navigate(`/purchase-step2/${purchaseId.data.result.dataValues.id}`)
            } catch (error) {
                console.error("Error submitting data:", error)
                // Handle the error as needed
            }
        })()
    }
    const getDisplayValue = (
        selectedValue: string | number,
        options: any[],
        placeholder: string
    ) => {
        if (selectedValue === "" || selectedValue === null || selectedValue === undefined) {
            return placeholder
        }
        const selectedOption = options.find((option) => option.id === selectedValue)
        return selectedOption ? selectedOption.name : ""
    }
    const getDisplayEmployeeName = (
        selectedValue: string | number,
        options: any[],
        placeholder: string
    ) => {
        if (selectedValue === "" || selectedValue === null || selectedValue === undefined) {
            return placeholder
        }
        const selectedOption = options.find((option) => option.id === selectedValue)
        return selectedOption ? `${selectedOption.firstName} ${selectedOption.lastName}` : ""
    }

    return (
        <div
            style={{ background: "#fff" }}
            className="flex flex-col justify-center items-center p-4 rounded-md shadow-md "
        >
            <form onSubmit={SubmitPurchase} className="w-full">
                <FormControl>
                    <h3 className=" md:text-medium text-sm font-semibold">Select Purchase Type</h3>

                    <RadioGroup
                        name="product_type"
                        onChange={(e) => onValueChange(e)}
                        aria-labelledby="demo-radio-buttons-group-label"
                    >
                        <div className="flex gap-5 p-2">
                            <FormControlLabel
                                value="0"
                                control={<Radio className="text-black" required={true} />}
                                label="On Approval"
                            />
                            <FormControlLabel
                                value="1"
                                control={<Radio className="text-black" required={true} />}
                                label="On Purchase"
                            />
                        </div>
                    </RadioGroup>
                </FormControl>
                <hr></hr>
                <div className="grid grid-cols-1 my-4 md:grid-cols-2 lg:grid-cols-4 w-full gap-4 mb-4 justify-center items-center bg-blend-overlay">
                    {/* Column 1 */}
                    <div className="flex flex-col">
                        {/* Brand Master */}
                        <div className="">
                            <h3 className="leading-normal text-gray-900 my-2">Select Brand</h3>
                            <FormControl style={{ width: "100%" }}>
                                {/*  Brand Master Select component */}
                                <Select
                                    className="text-black min-w-52 h-11 focus:outline-none focus:shadow-outline-blue"
                                    displayEmpty
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    name="brand_master_id"
                                    value={purchaseData.brand_master_id}
                                    onChange={(e) => onValueChange(e)}
                                    input={<OutlinedInput />}
                                    renderValue={(selected) =>
                                        getDisplayValue(selected, brandMasters, "Select Brand")
                                    }
                                    MenuProps={MenuProps}
                                    required
                                >
                                    {brandMasters.map((brandMaster: any) => (
                                        <MenuItem key={brandMaster.name} value={brandMaster.id}>
                                            <span className="text-normal text-black">
                                                {brandMaster.name}
                                            </span>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    {/* Column 2 */}
                    <div className="flex flex-col">
                        {/* Series Master */}
                        <div className="">
                            <h3 className="leading-normal text-gray-900 my-2">
                                Select Series Masters
                            </h3>
                            <FormControl style={{ width: "100%" }}>
                                {/*  Series Master Select component */}
                                <Select
                                    className="text-black min-w-52 h-11 focus:outline-none focus:shadow-outline-blue"
                                    displayEmpty
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    name="series_master_id"
                                    value={purchaseData.series_master_id}
                                    onChange={(e) => onValueChange(e)}
                                    MenuProps={MenuProps}
                                    input={<OutlinedInput />}
                                    renderValue={(selected) =>
                                        getDisplayValue(selected, seriesMasters, "Series Masters")
                                    }
                                    required
                                >
                                    {seriesMasters.map((seriesMaster: any) => (
                                        <MenuItem key={seriesMaster.name} value={seriesMaster.id}>
                                            <span className="text-normal text-black">
                                                {seriesMaster.name}
                                            </span>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    {/* Column 3 */}
                    <div className="flex flex-col">
                        <h3 className="leading-normal text-gray-900">Enter Model NO</h3>
                        {/* Model No. */}
                        <FormControl style={{ width: "100%" }}>
                            {/*  Model No. TextField component */}
                            <TextField
                                className="w-full h-11 placeholder:text-black"
                                placeholder="Please enter Model No."
                                onChange={(e) => onValueChange(e)}
                                name="model_no"
                                required
                                InputProps={{
                                    style: { fontSize: "smaller" },
                                }}
                            />
                        </FormControl>
                    </div>
                    {/* Column 4 */}
                    <div className="">
                        <h3 className="leading-normal text-gray-900 my-2">Select General Image</h3>
                        {generalImagePreview ? (
                            <div className="relative">
                                <Zoom>
                                    <img
                                        src={generalImagePreview}
                                        alt="General Image Preview"
                                        style={{ width: "100px", height: "100px" }}
                                    />
                                </Zoom>
                                <button
                                    className=" absolute z-100 rounded-full top-0 left-0 "
                                    onClick={() => setGeneralImagePreview("")}
                                >
                                    <MdCancel size={20} color="#dc2626" />
                                </button>
                            </div>
                        ) : (
                            <FormControl>
                                <FileInput
                                    accept="image/*"
                                    className="text-black"
                                    id="contained-button-file"
                                    name="general_image"
                                    type="file"
                                    onChange={(e) => onValueChange(e)}
                                />
                                <label htmlFor="contained-button-file">
                                    <Button
                                        variant="outlined"
                                        className="w-full h-12 text-black"
                                        component="span"
                                    >
                                        General Image <FaImage size={30} />
                                    </Button>
                                </label>
                            </FormControl>
                        )}
                    </div>
                </div>

                <hr></hr>
                {/* processor */}
                <div className="flex w-full flex-col gap-1 justify-center mt-4 mb-4">
                    <div className="flex">
                        <p className="md:text-medium text-xs font-semibold">Select Processor</p>
                    </div>
                    <div className="flex flex-row w-full gap-1 2xl:gap-4 xl:2px">
                        <div className="flex-grow">
                            <FormControl style={{ width: "100%" }}>
                                <h3 className="leading-normal text-gray-900 my-2">
                                    Processor Brand
                                </h3>
                                <Select
                                    className="text-black min-w-52 h-11 focus:outline-none focus:shadow-outline-blue"
                                    sx={{
                                        color: "#000",
                                        "&:focus": {
                                            outline: "none",
                                            boxShadow: "0 0 0 3px rgba(0, 123, 255, 0.5)",
                                        },
                                    }}
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    name="processor_brand_id"
                                    value={purchaseData.processor_brand_id}
                                    onChange={(e) => onValueChange(e)}
                                    MenuProps={MenuProps}
                                    displayEmpty
                                    input={<OutlinedInput />}
                                    renderValue={(selected) =>
                                        getDisplayValue(
                                            selected,
                                            processorBrands,
                                            "Processor's Brand"
                                        )
                                    }
                                    required
                                >
                                    {processorBrands.map((processorBrand: any) => (
                                        <MenuItem
                                            key={processorBrand.name}
                                            value={processorBrand.id}
                                        >
                                            {processorBrand.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="flex-grow">
                            <FormControl style={{ width: "100%" }}>
                                <h3 className="leading-normal text-gray-900 my-2">
                                    Processor Series
                                </h3>
                                <Select
                                    className="text-black min-w-52 h-11 focus:outline-none focus:shadow-outline-blue"
                                    sx={{
                                        color: "#000",
                                        "&:focus": {
                                            outline: "none",
                                            boxShadow: "0 0 0 3px rgba(0, 123, 255, 0.5)",
                                        },
                                    }}
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    name="processor_series_id"
                                    value={purchaseData.processor_series_id}
                                    onChange={(e) => onValueChange(e)}
                                    MenuProps={MenuProps}
                                    required
                                    displayEmpty
                                    input={<OutlinedInput />}
                                    renderValue={(selected) =>
                                        getDisplayValue(
                                            selected,
                                            processorSeries,
                                            "Processor's Series"
                                        )
                                    }
                                >
                                    {processorSeries.map((proseries: any) => (
                                        <MenuItem key={proseries.name} value={proseries.id}>
                                            {proseries.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="flex-grow">
                            <FormControl style={{ width: "100%" }}>
                                <h3 className="leading-normal text-gray-900 my-2">
                                    Processor Generation
                                </h3>
                                <Select
                                    className="text-black min-w-52 h-11 focus:outline-none focus:shadow-outline-blue"
                                    sx={{
                                        color: "#000",
                                        "&:focus": {
                                            outline: "none",
                                            boxShadow: "0 0 0 3px rgba(0, 123, 255, 0.5)",
                                        },
                                    }}
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    name="processor_generation_id"
                                    value={purchaseData.processor_generation_id}
                                    onChange={(e) => onValueChange(e)}
                                    MenuProps={MenuProps}
                                    required
                                    displayEmpty
                                    input={<OutlinedInput />}
                                    renderValue={(selected) =>
                                        getDisplayValue(
                                            selected,
                                            processorGenerations,
                                            "Generation's Series"
                                        )
                                    }
                                >
                                    {processorGenerations.map((processorGeneration: any) => (
                                        <MenuItem
                                            key={processorGeneration.name}
                                            value={processorGeneration.id}
                                        >
                                            {processorGeneration.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="flex-grow">
                            <FormControl style={{ width: "100%" }}>
                                <h3 className="leading-normal text-gray-900 my-2">
                                    Processor GigaHeartz
                                </h3>
                                <Select
                                    className="text-black min-w-52 h-11 focus:outline-none focus:shadow-outline-blue"
                                    sx={{
                                        color: "#000",
                                        "&:focus": {
                                            outline: "none",
                                            boxShadow: "0 0 0 3px rgba(0, 123, 255, 0.5)",
                                        },
                                    }}
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    name="processor_gigaHeartz_id"
                                    value={purchaseData.processor_gigaHeartz_id}
                                    onChange={(e) => onValueChange(e)}
                                    MenuProps={MenuProps}
                                    required
                                    displayEmpty
                                    input={<OutlinedInput />}
                                    renderValue={(selected) =>
                                        getDisplayValue(
                                            selected,
                                            processorGigaHeartzs,
                                            "Processor's GigaHeartz"
                                        )
                                    }
                                >
                                    {processorGigaHeartzs.map((processorGigaHeartz: any) => (
                                        <MenuItem
                                            key={processorGigaHeartz.name}
                                            value={processorGigaHeartz.id}
                                        >
                                            {processorGigaHeartz.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="flex-grow">
                            {processorImagePreview ? (
                                <div className="relative">
                                    <Zoom>
                                        <img
                                            src={processorImagePreview}
                                            alt="processor Image Preview"
                                            style={{ width: "100px", height: "100px" }}
                                        />
                                    </Zoom>
                                    <button
                                        className=" absolute z-100 rounded-full top-0 left-0 "
                                        onClick={() => setProcessorImagePreview("")}
                                    >
                                        <MdCancel size={20} color="#dc2626" />
                                    </button>
                                </div>
                            ) : (
                                <FormControl>
                                    <h3 className="leading-normal text-gray-900 my-2">
                                        Processor Image
                                    </h3>
                                    <FileInput
                                        accept="image/*"
                                        className="text-black"
                                        name="processor_image"
                                        id="processor-image-upload"
                                        type="file"
                                        onChange={(e) => onValueChange(e)}
                                    />
                                    <label htmlFor="processor-image-upload">
                                        <Button
                                            variant="outlined"
                                            className="w-full text-black h-11"
                                            component="span"
                                        >
                                            <span className="text-xs whitespace-nowrap">
                                                {" "}
                                                Processor Image{" "}
                                            </span>{" "}
                                            <FaImage size={30} className="ml-1" />
                                        </Button>
                                    </label>
                                </FormControl>
                            )}
                        </div>
                    </div>
                </div>

                <hr></hr>
                {/* processor end */}

                {/* cofigruation */}
                <div className="grid gap-4 mt-4">
                    {/* Configuration */}
                    <div className="col-span-3">
                        <h3 className="md:text-medium text-sm font-semibold">Configuration</h3>
                    </div>

                    {/* Ram */}
                    <div className="flex md:gap-5 gap-2 flex-col">
                        <div className="col-span-1">
                            <h3 className="  leading-normal  text-gray-900  my-2">Select Ram</h3>{" "}
                            <FormControl className="w-full">
                                <Select
                                    className="text-black min-w-52 h-11 focus:outline-none focus:shadow-outline-blue"
                                    labelId="ram-select-label"
                                    id="ram-select"
                                    name="ram_id"
                                    value={purchaseData.ram_id}
                                    onChange={(e) => onValueChange(e)}
                                    MenuProps={MenuProps}
                                    required
                                    displayEmpty
                                    input={<OutlinedInput />}
                                    renderValue={(selected) =>
                                        getDisplayValue(selected, rams, "Select Ram")
                                    }
                                >
                                    {rams.map((ram: any) => (
                                        <MenuItem key={ram.name} value={ram.id}>
                                            {ram.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>

                        <div className="col-span-1 mb-4">
                            {ramImagePreview ? (
                                <div className="relative">
                                    <Zoom>
                                        <img
                                            src={ramImagePreview}
                                            alt="Ram Image Preview"
                                            style={{ width: "100px", height: "100px" }}
                                        />
                                    </Zoom>
                                    <button
                                        className=" absolute z-100 rounded-full top-0 left-0 "
                                        onClick={() => setRamImagePreview("")}
                                    >
                                        <MdCancel size={20} color="#dc2626" />
                                    </button>
                                </div>
                            ) : (
                                <FormControl>
                                    <h3 className="  leading-normal  text-gray-900  my-2">
                                        Select Ram Image
                                    </h3>{" "}
                                    <FileInput
                                        className="min-w-52 h-11 text-black"
                                        accept="image/*"
                                        name="ram_image"
                                        id="ram-image-upload"
                                        type="file"
                                        onChange={(e) => onValueChange(e)}
                                    />
                                    <label htmlFor="ram-image-upload">
                                        <Button
                                            variant="outlined"
                                            className="min-w-52 h-11 text-black"
                                            component="span"
                                        >
                                            Ram Image
                                            <FaImage size={30} className="ml-4" />
                                        </Button>
                                    </label>
                                </FormControl>
                            )}
                        </div>
                    </div>

                    {/* SSD */}
                    <div className="flex md:gap-5 gap-2 flex-col">
                        <div className="col-span-1">
                            <FormControl className="w-full">
                                <h3 className="  leading-normal  text-gray-900  my-2">
                                    Select SSD
                                </h3>{" "}
                                <Select
                                    className="text-black min-w-52 h-11 focus:outline-none focus:shadow-outline-blue"
                                    labelId="ssd-select-label"
                                    id="ssd-select"
                                    name="ssd_id"
                                    value={purchaseData.ssd_id}
                                    onChange={(e) => onValueChange(e)}
                                    MenuProps={MenuProps}
                                    required
                                    displayEmpty
                                    renderValue={(selected) =>
                                        getDisplayValue(selected, ssds, "Select SSD")
                                    }
                                >
                                    {ssds.map((ssd: any) => (
                                        <MenuItem key={ssd.name} value={ssd.id}>
                                            {ssd.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>

                        <div className="col-span-1">
                            {ssdImagePreview ? (
                                <div className="relative">
                                    <Zoom>
                                        <img
                                            src={ssdImagePreview}
                                            alt="Ram Image Preview"
                                            style={{ width: "100px", height: "100px" }}
                                        />
                                    </Zoom>
                                    <button
                                        className=" absolute z-100 rounded-full top-0 left-0 "
                                        onClick={() => setSsdImagePreview("")}
                                    >
                                        <MdCancel size={20} color="#dc2626" />
                                    </button>
                                </div>
                            ) : (
                                <FormControl>
                                    <h3 className="  leading-normal  text-gray-900  my-2">
                                        Select SSD Image
                                    </h3>{" "}
                                    <FileInput
                                        accept="image/*"
                                        name="ssd_image"
                                        id="ssd-image-upload"
                                        className="text-black"
                                        type="file"
                                        onChange={(e) => onValueChange(e)}
                                    />
                                    <label htmlFor="ssd-image-upload">
                                        <Button
                                            variant="outlined"
                                            className="min-w-52 h-11"
                                            component="span"
                                        >
                                            SSD Image <FaImage size={30} className="ml-4" />
                                        </Button>
                                    </label>
                                </FormControl>
                            )}
                        </div>
                    </div>

                    {/* Hard Disk */}
                    <div className="flex md:gap-5 gap-2 flex-col">
                        <div className="col-span-1">
                            <h3 className="  leading-normal  text-gray-900  my-2">
                                Select Hard Disk
                            </h3>{" "}
                            <FormControl className="w-full">
                                <Select
                                    className="text-black min-w-52 h-11 focus:outline-none focus:shadow-outline-blue"
                                    labelId="hard-disk-select-label"
                                    id="hard-disk-select"
                                    name="hard_disk_id"
                                    value={purchaseData.hard_disk_id}
                                    onChange={(e) => onValueChange(e)}
                                    MenuProps={MenuProps}
                                    required
                                    displayEmpty
                                    input={<OutlinedInput />}
                                    renderValue={(selected) =>
                                        getDisplayValue(selected, hardDisks, "Select Hard Disks")
                                    }
                                >
                                    {hardDisks.map((hardDisk: any) => (
                                        <MenuItem key={hardDisk.name} value={hardDisk.id}>
                                            {hardDisk.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>

                        <div className="col-span-1">
                            {hardDiskImagePreview ? (
                                <div className="relative">
                                    <Zoom>
                                        <img
                                            src={hardDiskImagePreview}
                                            alt="Ram Image Preview"
                                            style={{ width: "100px", height: "100px" }}
                                        />
                                    </Zoom>
                                    <button
                                        className=" absolute z-100 rounded-full top-0 left-0 "
                                        onClick={() => setHardDiskImagePreview("")}
                                    >
                                        <MdCancel size={20} color="#dc2626" />
                                    </button>
                                </div>
                            ) : (
                                <FormControl>
                                    <h3 className="  leading-normal  text-gray-900  my-2">
                                        Select Hard Disk Image
                                    </h3>{" "}
                                    <FileInput
                                        accept="image/*"
                                        name="hard_disk_image"
                                        id="hard-disk-image-upload"
                                        className="text-black"
                                        type="file"
                                        onChange={(e) => onValueChange(e)}
                                    />
                                    <label htmlFor="hard-disk-image-upload">
                                        <Button
                                            variant="outlined"
                                            className="min-w-52 h-11"
                                            component="span"
                                        >
                                            Hard Disk Image <FaImage size={30} className="ml-4" />
                                        </Button>
                                    </label>
                                </FormControl>
                            )}
                        </div>
                    </div>
                </div>

                <hr></hr>

                <Grid item xs={12} className="my-5">
                    <h3 className=" md:text-medium text-sm font-semibold">Screen/Display</h3>
                </Grid>
                <div className="grid grid-cols-3 gap-4 justify-center items-center mb-5">
                    <div className="w-full">
                        <h3 className="  leading-normal  text-gray-900  ">Select Screen Size</h3>{" "}
                        <FormControl className="min-w-80">
                            <Select
                                className="text-black h-11 focus:outline-none focus:shadow-outline-blue"
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                name="screen_size_id"
                                value={purchaseData.screen_size_id}
                                onChange={(e) => onValueChange(e)}
                                MenuProps={MenuProps}
                                displayEmpty
                                input={<OutlinedInput />}
                                renderValue={(selected) =>
                                    getDisplayValue(selected, screenSizes, "Screen Size")
                                }
                            >
                                {screenSizes.map((screenSize: any) => (
                                    <MenuItem key={screenSize.name} value={screenSize.id}>
                                        {screenSize.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    {/* Functional Condition */}
                    {/* <div className="w-full">
                        <h3 className="  leading-normal  text-gray-900  ">
                            Select Functional Condition
                        </h3>{" "}
                        <FormControl className="w-80">
                            <Select
                                className="text-black  h-11 focus:outline-none focus:shadow-outline-blue"
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                name="functional_condition_id"
                                value={purchaseData.functional_condition_id}
                                onChange={(e) => onValueChange(e)}
                                MenuProps={MenuProps}
                                required
                                displayEmpty
                                renderValue={(selected) =>
                                    getDisplayValue(
                                        selected,
                                        functionalConditions,
                                        "Functional Condition"
                                    )
                                }
                            >
                                {functionalConditions.map((functionalCondition: any) => (
                                    <MenuItem
                                        key={functionalCondition.name}
                                        value={functionalCondition.id}
                                    >
                                        {functionalCondition.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        {functionalConditionImagePreview ? (
                            <div className="relative">
                                <Zoom>
                                    <img
                                        src={functionalConditionImagePreview}
                                        alt="Ram Image Preview"
                                        style={{ width: "100px", height: "100px" }}
                                    />
                                </Zoom>
                                <button
                                    className=" absolute z-100 rounded-full top-0 left-0 "
                                    onClick={() => setFunctionalConditionImagePreview("")}
                                >
                                    <MdCancel size={20} color="#dc2626" />
                                </button>
                            </div>
                        ) : (
                            <FormControl sx={{ width: "70%" }}>
                                <h3 className="  leading-normal  text-gray-900 ">
                                    Select Functional Image
                                </h3>{" "}
                                <FileInput
                                    accept="image/*"
                                    name="functional_condition_image"
                                    id="functional-image-upload"
                                    multiple
                                    type="file"
                                    onChange={(e) => onValueChange(e)}
                                />
                                <label htmlFor="functional-image-upload">
                                    <Button variant="outlined" component="span">
                                        <span className="text-xs whitespace-nowrap">
                                            {" "}
                                            Functional Condition Image{" "}
                                        </span>
                                        <FaImage size={30} className="ml-4" />
                                    </Button>
                                </label>
                            </FormControl>
                        )}
                    </div> */}

                    {/* Screen Size Image */}
                    <div className="w-full">
                        {screenSizeImagePreview ? (
                            <div className="relative">
                                <Zoom>
                                    <img
                                        src={screenSizeImagePreview}
                                        alt="Ram Image Preview"
                                        style={{ width: "100px", height: "100px" }}
                                    />
                                </Zoom>
                                <button
                                    className=" absolute z-100 rounded-full top-0 left-0 "
                                    onClick={() => setScreenSizeImagePreview("")}
                                >
                                    <MdCancel size={20} color="#dc2626" />
                                </button>
                            </div>
                        ) : (
                            <FormControl>
                                <h3 className="  leading-normal  text-gray-900  my-2">
                                    Screen Resolution Image
                                </h3>{" "}
                                <FileInput
                                    accept="image/*"
                                    name="screen_size_image"
                                    id="screenSize-Image-upload"
                                    className="text-black"
                                    multiple
                                    type="file"
                                    onChange={(e) => onValueChange(e)}
                                />
                                <label htmlFor="screenSize-Image-upload">
                                    <Button variant="outlined" component="span">
                                        {" Screen Size Image  "}
                                        <FaImage size={30} className="ml-4" />
                                    </Button>
                                </label>
                            </FormControl>
                        )}
                    </div>
                    {/* Screen Resolution */}
                    <div className="w-full">
                        <FormControl className="w-80">
                            <h3 className="  leading-normal  text-gray-900  my-2">
                                Select Screen Resolution
                            </h3>{" "}
                            <Select
                                className="text-black  h-11 focus:outline-none focus:shadow-outline-blue"
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                name="screen_resolution_id"
                                value={purchaseData.screen_resolution_id}
                                onChange={(e) => onValueChange(e)}
                                MenuProps={MenuProps}
                                required
                                displayEmpty
                                input={<OutlinedInput />}
                                renderValue={(selected) =>
                                    getDisplayValue(
                                        selected,
                                        screenResolutions,
                                        "Screen Resolution"
                                    )
                                }
                            >
                                {screenResolutions.map((screenResolution: any) => (
                                    <MenuItem key={screenResolution.id} value={screenResolution.id}>
                                        {screenResolution.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>

                    {/* Screen Spot Any */}
                    <div className="w-full">
                        <h3 className="  leading-normal  text-gray-900  my-2">Select Spot Any</h3>{" "}
                        <FormControl className="w-80">
                            <Select
                                className="text-black  h-11 focus:outline-none focus:shadow-outline-blue"
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                name="screen_spot_any_id"
                                value={purchaseData.screen_spot_any_id}
                                onChange={(e) => onValueChange(e)}
                                MenuProps={MenuProps}
                                required
                                displayEmpty
                                input={<OutlinedInput />}
                                renderValue={(selected) =>
                                    getDisplayValue(selected, screenSpots, "Spot Any")
                                }
                            >
                                {screenSpots.map((screenSpot: any) => (
                                    <MenuItem key={screenSpot.name} value={screenSpot.id}>
                                        {screenSpot.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <hr></hr>
                {/* screen size end */}

                <hr></hr>
                {/* Age of Laptop */}
                <div className="flex gap-5 my-5">
                    <div className="">
                        <h3 className="  leading-normal  text-gray-900  my-2">
                            Select Age of Laptop
                        </h3>{" "}
                        <FormControl>
                            <Select
                                className="text-black  h-11 focus:outline-none focus:shadow-outline-blue"
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                name="age_of_laptop_id"
                                value={purchaseData.age_of_laptop_id}
                                onChange={(e) => onValueChange(e)}
                                MenuProps={MenuProps}
                                required
                                displayEmpty
                                renderValue={(selected) =>
                                    getDisplayValue(selected, ageOfLaptops, "Age of Laptop")
                                }
                            >
                                {ageOfLaptops.map((ageOfLaptop: any) => (
                                    <MenuItem key={ageOfLaptop.name} value={ageOfLaptop.id}>
                                        {ageOfLaptop.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        {ageOfLaptopImagePreview ? (
                            <div className="relative">
                                <Zoom>
                                    <img
                                        src={ageOfLaptopImagePreview}
                                        alt="Age of Laptop Image Preview"
                                        style={{ width: "100px", height: "100px" }}
                                    />
                                </Zoom>
                                <button
                                    className=" absolute z-100 rounded-full top-0 right-0 "
                                    onClick={() => setAgeOfLaptopImagePreview("")}
                                >
                                    <MdCancel size={20} color="#dc2626" />
                                </button>
                            </div>
                        ) : (
                            <FormControl sx={{ width: "100%" }}>
                                <h3 className="leading-normal text-gray-900 my-2">
                                    Select Age of Laptop Image
                                </h3>
                                <FileInput
                                    accept="image/*"
                                    className="text-black"
                                    name="age_of_laptop_image"
                                    id="age_of_laptop-image-upload"
                                    type="file"
                                    onChange={(e) => onValueChange(e)}
                                />
                                <label htmlFor="age_of_laptop-image-upload">
                                    <Button variant="outlined" component="span">
                                        Age Of Laptop <FaImage size={30} className="ml-4" />
                                    </Button>
                                </label>
                            </FormControl>
                        )}
                    </div>
                </div>
                <hr></hr>

                <div className="grid grid-cols-3 gap-2 mb-4 mt-4">
                    {/* Charger Type */}
                    <div className="w-full">
                        <h3 className="  leading-normal  text-gray-900  my-2">Select Charger</h3>{" "}
                        <FormControl>
                            <Select
                                className="text-black min-w-52 h-11 focus:outline-none focus:shadow-outline-blue"
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                name="charger_type"
                                value={purchaseData.charger_type}
                                onChange={(e) => onValueChange(e)}
                                MenuProps={MenuProps}
                                required
                                displayEmpty
                                renderValue={(value) =>
                                    value == "1" ? "With Charger" : "With Out Charger"
                                }
                            >
                                <MenuItem key="With Out Charger" value="0">
                                    With Out Charger
                                </MenuItem>
                                <MenuItem key="With Charger" value="1">
                                    With Charger
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    <div className="w-full">
                        <h3 className="  leading-normal  text-gray-900  my-2">
                            Select Physical Condition
                        </h3>{" "}
                        <FormControl className="">
                            <Select
                                className="text-black min-w-52 h-11  focus:outline-none focus:shadow-outline-blue"
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                name="physical_condition_id"
                                value={purchaseData.physical_condition_id}
                                onChange={(e) => onValueChange(e)}
                                MenuProps={MenuProps}
                                required
                                displayEmpty
                                renderValue={(selected) =>
                                    getDisplayValue(
                                        selected,
                                        physicalConditions,
                                        "Physical Condition"
                                    )
                                }
                            >
                                {physicalConditions.map((physicalCondition: any) => (
                                    <MenuItem
                                        key={physicalCondition.name}
                                        value={physicalCondition.id}
                                    >
                                        {physicalCondition.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        {physicalConditionImagePreview ? (
                            <div className="relative">
                                <Zoom>
                                    <img
                                        src={physicalConditionImagePreview}
                                        alt="physicalCondition Image Preview"
                                        style={{ width: "100px", height: "100px" }}
                                    />
                                </Zoom>
                                <button
                                    className=" absolute z-100 rounded-full top-0 left-0 "
                                    onClick={() => setPhysicalConditionImagePreview("")}
                                >
                                    <MdCancel size={20} color="#dc2626" />
                                </button>
                            </div>
                        ) : (
                            <FormControl sx={{ width: "70%" }}>
                                <h3 className="  leading-normal  text-gray-900  my-2">
                                    Select Physical Condition Image
                                </h3>{" "}
                                <FileInput
                                    accept="image/*"
                                    name="physical_condition_image"
                                    className="text-black"
                                    id="physical_condition_image-upload"
                                    multiple
                                    type="file"
                                    onChange={(e) => onValueChange(e)}
                                />
                                <label htmlFor="physical_condition_image-upload">
                                    <Button variant="outlined" component="span">
                                        <span className="whitespace-nowrap">
                                            {" "}
                                            Physical Condition Image{" "}
                                        </span>
                                        <FaImage size={30} className="ml-4" />
                                    </Button>
                                </label>
                            </FormControl>
                        )}
                    </div>
                </div>
                <hr></hr>
                <div className="flex gap-8 items-center w-full my-5 ">
                    {/* NumLock */}
                    <FormControl className="">
                        <FormHelperText>
                            <h3 className="  text-gray-900 my-2">NumLock</h3>
                        </FormHelperText>
                        <RadioGroup
                            name="num_lock"
                            onChange={(e) => onValueChange(e)}
                            aria-labelledby="demo-radio-buttons-group-label"
                        >
                            <div className="flex gap-4">
                                <FormControlLabel
                                    value="1"
                                    onChange={(e) => onValueChange(e)}
                                    control={<Radio required={true} className="text-black" />}
                                    label="Yes"
                                />
                                <FormControlLabel
                                    value="0"
                                    onChange={(e) => onValueChange(e)}
                                    control={<Radio required={true} className="text-black" />}
                                    label="No"
                                />
                            </div>
                        </RadioGroup>
                    </FormControl>

                    {/* BackLit */}
                    <FormControl className="">
                        <FormHelperText>
                            <h3 className="  text-gray-900 my-2">BackLit</h3>
                        </FormHelperText>
                        <RadioGroup
                            name="back_lit"
                            onChange={(e) => onValueChange(e)}
                            aria-labelledby="demo-radio-buttons-group-label"
                        >
                            <div className="flex gap-4">
                                <FormControlLabel
                                    value="1"
                                    onChange={(e) => onValueChange(e)}
                                    control={<Radio required={true} className="text-black" />}
                                    label="Yes"
                                />
                                <FormControlLabel
                                    value="0"
                                    onChange={(e) => onValueChange(e)}
                                    control={<Radio required={true} className="text-black" />}
                                    label="No"
                                />
                            </div>
                        </RadioGroup>
                    </FormControl>

                    {/* Dedicated Graphics */}
                    <div className="">
                        <h3 className="  text-gray-900 my-2">Dedicated Graphics</h3>
                        <FormControl>
                            <RadioGroup
                                name="is_graphic"
                                onChange={(e) => onValueChange(e)}
                                aria-labelledby="demo-radio-buttons-group-label"
                            >
                                <div className="flex gap-4 justify-center items-center">
                                    <FormControlLabel
                                        value="1"
                                        onClick={handleClick}
                                        control={<Radio className="text-black" />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="0"
                                        onClick={handleClick2}
                                        control={<Radio className="text-black" />}
                                        label="No"
                                    />
                                </div>
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
                <div>
                    <Grid
                        item
                        className=" grid-cols-3 gap-3"
                        style={{ display: isActive ? "grid" : "none" }}
                    >
                        <FormControl>
                            <FormHelperText>
                                <h3 className="  leading-normal  text-gray-900  my-2">
                                    Graphic's Brand
                                </h3>{" "}
                            </FormHelperText>
                            <Select
                                className="text-black min-w-52 h-11  focus:outline-none focus:shadow-outline-blue"
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                name="graphic_brand_id"
                                value={purchaseData.graphic_brand_id}
                                onChange={(e) => onValueChange(e)}
                                MenuProps={MenuProps}
                                renderValue={(selected) =>
                                    getDisplayValue(selected, graphicBrands, "Select Graphic Brand")
                                }
                            >
                                {graphicBrands.map((graphicBrand: any) => (
                                    <MenuItem
                                        key={graphicBrand.name}
                                        className=""
                                        value={graphicBrand.id}
                                    >
                                        {graphicBrand.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl sx={{ width: "80%" }}>
                            <FormHelperText>
                                <h3 className="  leading-normal  text-gray-900  my-2">
                                    Graphic's Series
                                </h3>{" "}
                            </FormHelperText>
                            <Select
                                className="text-black min-w-52 h-11  focus:outline-none focus:shadow-outline-blue"
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                name="graphic_series_id"
                                value={purchaseData.graphic_series_id}
                                onChange={(e) => onValueChange(e)}
                                MenuProps={MenuProps}
                                renderValue={(selected) =>
                                    getDisplayValue(selected, graphicSeries, "Select GraphSeries")
                                }
                            >
                                {graphicSeries.map((GraphSeries: any) => (
                                    <MenuItem key={GraphSeries.name} value={GraphSeries.id}>
                                        {GraphSeries.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl sx={{ width: "80%" }}>
                            <FormHelperText>
                                <h3 className="  leading-normal  text-gray-900  my-2">
                                    Graphic's Model
                                </h3>{" "}
                            </FormHelperText>
                            <Select
                                className="text-black min-w-52 h-11  focus:outline-none focus:shadow-outline-blue"
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                name="graphic_model_id"
                                value={purchaseData.graphic_model_id}
                                onChange={(e) => onValueChange(e)}
                                MenuProps={MenuProps}
                                renderValue={(selected) =>
                                    getDisplayValue(
                                        selected,
                                        graphicModels,
                                        "Select Graphic Models"
                                    )
                                }
                            >
                                {graphicModels.map((graphicModel: any) => (
                                    <MenuItem key={graphicModel.name} value={graphicModel.id}>
                                        {graphicModel.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{ width: "80%" }}>
                            <FormHelperText>
                                <h3 className="  leading-normal  text-gray-900  my-2">
                                    Graphic's size
                                </h3>{" "}
                            </FormHelperText>
                            <Select
                                className="text-black min-w-52 h-11  focus:outline-none focus:shadow-outline-blue"
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                name="graphic_size_id"
                                value={purchaseData.graphic_size_id}
                                onChange={(e) => onValueChange(e)}
                                MenuProps={MenuProps}
                                renderValue={(selected) =>
                                    getDisplayValue(selected, graphicSizes, "Select Graphic Sizes")
                                }
                            >
                                {graphicSizes.map((graphicSize: any) => (
                                    <MenuItem key={graphicSize.name} value={graphicSize.id}>
                                        {graphicSize.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <div>
                            {externalGraphicsImagePreview ? (
                                <div className="relative">
                                    <Zoom>
                                        <img
                                            src={externalGraphicsImagePreview}
                                            alt="externalGraphicsImagePreview Image Preview"
                                            style={{ width: "100px", height: "100px" }}
                                        />
                                    </Zoom>
                                    <button
                                        className=" absolute z-100 rounded-full top-0 left-0 "
                                        onClick={() => setExternalGraphicsImagePreview("")}
                                    >
                                        <MdCancel size={20} color="#dc2626" />
                                    </button>
                                </div>
                            ) : (
                                <FormControl sx={{ width: "70%" }}>
                                    <h3 className="  leading-normal  text-gray-900  my-2">
                                        External Graphics Image
                                    </h3>{" "}
                                    <FileInput
                                        accept="image/*"
                                        name="external_graphic_image"
                                        className="text-black"
                                        id="external_graphic_image-upload"
                                        multiple
                                        type="file"
                                        onChange={(e) => onValueChange(e)}
                                    />
                                    <label htmlFor="external_graphic_image-upload">
                                        <Button variant="outlined" component="span">
                                            <span className="whitespace-nowrap">
                                                {" "}
                                                External Graphics Image{" "}
                                            </span>
                                            <FaImage size={30} className="ml-4" />
                                        </Button>
                                    </label>
                                </FormControl>
                            )}
                        </div>
                    </Grid>
                </div>

                {/* numlock end */}
                {/* age of laptop & charger starts */}

                <div className="flex gap-2 my-5"></div>

                <div className="flex gap-2">
                    <FormControl sx={{ width: "80%" }}>
                        <FormHelperText>
                            <h3 className="  leading-normal  text-gray-900  my-2">Date</h3>{" "}
                        </FormHelperText>
                        <OutlinedInput
                            className="w-52  h-11"
                            sx={{
                                color: "#000",
                                "&:focus": {
                                    outline: "none",
                                    boxShadow: "0 0 0 3px rgba(0, 123, 255, 0.5)",
                                },
                            }}
                            placeholder="Please enter text"
                            onChange={(e) => onValueChange(e)}
                            name="date"
                            type="date"
                            required
                        />

                        {/* <InputLabel >Name</InputLabel> */}
                        {/* <Input /> */}
                    </FormControl>

                    <FormControl sx={{ width: "80%" }}>
                        <FormHelperText>
                            <h3 className="  leading-normal  text-gray-900  my-2">Price</h3>{" "}
                        </FormHelperText>
                        <OutlinedInput
                            className=" h-11"
                            sx={{
                                color: "#000",
                                "&:focus": {
                                    outline: "none",
                                    boxShadow: "0 0 0 3px rgba(0, 123, 255, 0.5)",
                                },
                            }}
                            placeholder="Please enter price ₹"
                            onChange={(e) => onValueChange(e)}
                            name="price"
                            required
                        />
                    </FormControl>
                    <FormControl sx={{ width: "80%" }}>
                        <FormHelperText>
                            <h3 className="  leading-normal  text-gray-900  my-2">Vendor Name</h3>{" "}
                        </FormHelperText>
                        <OutlinedInput
                            className=" h-11"
                            sx={{
                                color: "#000",
                                "&:focus": {
                                    outline: "none",
                                    boxShadow: "0 0 0 3px rgba(0, 123, 255, 0.5)",
                                },
                            }}
                            placeholder="Please enter Vendor Name"
                            onChange={(e) => onValueChange(e)}
                            name="vendor_name"
                            required
                        />
                    </FormControl>
                </div>
                <div className="flex gap-2 my-5 justify-center items-center ">
                    <FormControl sx={{ width: "80%" }}>
                        <FormHelperText>
                            <h3 className="  leading-normal  text-gray-900  my-2">Shelf No.</h3>{" "}
                        </FormHelperText>
                        <OutlinedInput
                            className=" h-11"
                            sx={{
                                color: "#000",
                                "&:focus": {
                                    outline: "none",
                                    boxShadow: "0 0 0 3px rgba(0, 123, 255, 0.5)",
                                },
                            }}
                            placeholder="Please enter shelf no."
                            onChange={(e) => onValueChange(e)}
                            name="shelf_no"
                            required
                        />
                    </FormControl>
                    <FormControl sx={{ width: "80%" }}>
                        <FormHelperText>
                            <h3 className="  leading-normal  text-gray-900  my-2">Pickup Person</h3>{" "}
                        </FormHelperText>
                        <Select
                            className="min-w-52 h-11"
                            sx={{
                                color: "#000",
                                "&:focus": {
                                    outline: "none",
                                    boxShadow: "0 0 0 3px rgba(0, 123, 255, 0.5)",
                                },
                            }}
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            name="pickup_person"
                            value={purchaseData.pickup_person}
                            onChange={(e) => onValueChange(e)}
                            MenuProps={MenuProps}
                            required
                            renderValue={(selected) =>
                                getDisplayEmployeeName(selected, employees, "Select Pickup Person")
                            }
                        >
                            {employees.map((employee: any) => (
                                <MenuItem key={employee.name} value={employee.id}>
                                    {employee.firstName} {employee.lastName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl sx={{ width: "80%" }}>
                        <FormHelperText>
                            <h3 className="  leading-normal  text-gray-900  my-2">Problem</h3>{" "}
                        </FormHelperText>
                        <OutlinedInput
                            className=" h-11"
                            sx={{
                                color: "#000",
                                "&:focus": {
                                    outline: "none",
                                    boxShadow: "0 0 0 3px rgba(0, 123, 255, 0.5)",
                                },
                            }}
                            placeholder="Please enter problem"
                            onChange={(e) => onValueChange(e)}
                            name="problem"
                            required
                        />
                    </FormControl>
                </div>

                <hr></hr>

                <Grid item xs={4}>
                    <FormControl>
                        <FormHelperText>
                            <h3 className="  leading-normal  text-gray-900  my-2">
                                Select Payment Mode{" "}
                            </h3>{" "}
                        </FormHelperText>
                        <RadioGroup
                            name="payment_mode"
                            value={purchaseData.payment_mode}
                            aria-labelledby="demo-radio-buttons-group-label"
                        >
                            <div className="flex gap-4">
                                <FormControlLabel
                                    value="0"
                                    name="payment_mode"
                                    onChange={(e) => onValueChange(e)}
                                    control={<Radio className="text-black" required={true} />}
                                    label="Cash"
                                />
                                <FormControlLabel
                                    value="1"
                                    name="payment_mode"
                                    onChange={(e) => onValueChange(e)}
                                    control={<Radio className="text-black" required={true} />}
                                    label="Online"
                                />
                            </div>
                        </RadioGroup>
                    </FormControl>
                </Grid>

                <Grid item xs={6} className="mt-5">
                    <h3 className="  leading-normal  text-gray-900  my-2">Description</h3>{" "}
                    <FormControl className="w-full ">
                        <TextareaAutosize
                            minRows={5}
                            placeholder="Please enter description"
                            className="w-full rounded-lg shadow-lg transition duration-300 hover:shadow-xl flex flex-col justify-center items-center bg-[#eef2ff] focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-50 p-6"
                            onChange={(e) => onValueChange(e)}
                            aria-label="minimum height"
                            name="description"
                            required
                        />
                    </FormControl>
                </Grid>

                <FormControl sx={{ my: 4 }}>
                    <Button
                        variant="contained"
                        className="bg-black"
                        sx={{ maxWidth: 200 }}
                        type="submit"
                    >
                        SUBMIT TO Next
                    </Button>
                </FormControl>
            </form>
        </div>
    )
}
export default AddPurchase
