import React from "react";

const PurchaseFormBlockRow = ({ children, title = "", gridnum = 3 }: any) => {
    return (
        <div className="mb-8">
            {!!title ? (
                <h2 className="text-xl font-bold mb-4 uppercase text-blue-500">{title}</h2>
            ) : (
                <div className="h-2 bg-gray-300 mb-4"></div>
            )}
            <div className="relative shadow-lg p-6 bg-white rounded-lg">
                <div className={`grid grid-cols-${gridnum} gap-5`}>{children}</div>
            </div>
        </div>
    );
};


export default PurchaseFormBlockRow;
