import React, { useState, useEffect } from "react";

const FormRow = ({
  label,
  textInputMode = false,
  onValueChange,
  formName,
  formValue,
  items = [],
  textInputType = "text",
  multiline = false,
  readonly=false
}: any) => {
  const [activeValue, setActiveValue] = useState("");

  useEffect(() => {
    const itemFound = items.find((item: any) => item.id === formValue);
    if (itemFound) {
      setActiveValue(itemFound.name || "");
    } else {
      setActiveValue(formValue);
    }
  }, [items]);

  return (
    <div className="bg-white w-full mb-4 p-2 rounded-md">
      <div className="flex items-center w-full">
        <div className="w-36">
          <p className="text-xs px-2 font-semibold">{label}</p>
        </div>
        <div className="flex-grow w-full">
          {textInputMode ? (
            <input
              type={textInputType}
              className="w-full bg-blue-500 text-white p-2 rounded text-xs"
              placeholder={`Please enter ${label}`}
              onChange={(e) => onValueChange(e)}
              name={formName}
              value={formValue}
              readOnly={readonly}
              {...(multiline && { rows: 3 })}
            />
          ) : (
            <select
              className="w-36 h-10 bg-blue-500 text-white p-2 rounded"
              name={formName}
              value={formValue}
              onChange={(e) => onValueChange(e)}
            >
              {items.map((item: any) => (
                <option key={item.name} value={item.id} className="border-1 rounded border-blue-200">
                  {item.name}
                </option>
              ))}
            </select>
          )}
        </div>
      </div>
    </div>
  );
};

export default FormRow;
