import { useState, useEffect } from "react";
import { http } from "../../utils/http";
import { useParams } from "react-router-dom";
import Hidden from "@mui/material/Hidden";

import { useNavigate } from "react-router-dom";

import {
  FormGroup,
  FormControl,
  Grid,
  Button,
  styled,
  OutlinedInput,
  FormHelperText,
  TextField,
} from "@mui/material";
import { width } from "@mui/system";

const Container = styled(FormGroup)`
  width: 100%;
`;
{
  /* <Typography variant="h4">Add New Employee</Typography> */
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const initialValues = {
  hard_disk_id: "",
  name: "",
};

const EditHardDisk = () => {
  //     const [sal, setSal] = useState({  ...initialValues}
  //      );

  //   useEffect(() => {
  //     (async function () {
  //       const salDataRes = await http.get("/salary/"+params.id);
  //       if(salDataRes ){
  //       setSal(salDataRes.data.result);
  //     }
  //       console.log('slaryyy',sal);
  //     })();
  //   }, []);

  let params = useParams();
  const navigate = useNavigate();
  const [ram, setRam] = useState({ ...initialValues, hard_disk_id: params.id });
  
  const [HardDiskUpdate, setHardDiskUpdate] = useState({ ...initialValues });
  
  useEffect(() => {
      (async () => {
          const data: any = await http.get("/hard-disk/" + params.id);
          setHardDiskUpdate(data.data.result);
        })();
    }, []);
    
       
  const onValueChange = (e: any) => {
    e.preventDefault(e);
    setHardDiskUpdate({ ...HardDiskUpdate, [e.target.name]: e.target.value });
console.log('name is ', e.target.name, 'value is', e.target.value);
  };
  const EditHardDiskdetail = async (e: any) => {
    e.preventDefault();
    try {
       console.log('data',HardDiskUpdate);
      await http.patch("/hard-disk/"+params.id, HardDiskUpdate);
      navigate("/hard-disk");
      return;
    } catch (error: any) {
      console.log("error while calling api", error.message);
    }
  };

  return (
    <div>
      <h2>HardDisk ID: {params.id}</h2>
      <form onSubmit={EditHardDiskdetail}>
        <FormGroup>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>HardDisk ID</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  disabled
                  sx={{ background: "white" }}
                  value={ram.hard_disk_id}
                  onChange={(e) => onValueChange(e)}
                  name="hard_disk_id"
                 
                 
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>Name</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  sx={{ background: "white" }}
                  onChange={(e) => onValueChange(e)}
                  name="name"
                  value={HardDiskUpdate.name}
                  
                />
              </FormControl>
            </Grid>
          </Grid>

          <FormControl sx={{ my: 4 }}>
            <Button variant="contained" sx={{ maxWidth: 200 }} type="submit">
              Update
            </Button>
          </FormControl>
        </FormGroup>
      </form>
    </div>
  );
};
export default EditHardDisk;
