import * as React from "react";
import { useState, useEffect } from "react";
import { http } from "../../utils/http";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { NavLink, Link } from "react-router-dom";
import { PurchaseDataItem } from "../../types";
import { Grid, FormControl, InputLabel, Typography, Select, MenuItem, TextField } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";



export default function BasicTable() {
  const [testPendingDatas, setTestPendingData] = useState([]);

  // Add state variable for each filter
  const [dateFilter, setDateFilter] = useState<Date | null>(null);
  const [selectedSalesUser, setSelectedSalesUser] = useState("");
  const [selectedProductType, setSelectedProductType] = useState("");

  useEffect(() => {
    (async function () {
      const purchaseDataRes = await http.get("/engineer-dashboard");
      setTestPendingData(purchaseDataRes.data.result);
    })();
  }, []);

  const salesUsers = Array.from(new Set(testPendingDatas.map((item: PurchaseDataItem) => item.vendor_name)));
  const productTypes = Array.from(new Set(testPendingDatas.map((item: PurchaseDataItem) => item.product_type.toString())));


  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <h3>Test Pending </h3>
      <Grid container justifyContent="flex-end" alignItems="center">
        <FormControl sx={{ m: 1, minWidth: 100 }}>

          <Select
            labelId="sales-user-filter-label"
            value={selectedSalesUser}
            displayEmpty
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <span style={{ fontSize: "12px", color: "#800080" }}>Sales User Filter</span>;
              }

              return selected;
            }}
            onChange={(e) => setSelectedSalesUser(e.target.value)}
            sx={{ color: "#800080", '&:focus': { outline: 'none', boxShadow: '0 0 0 3px rgba(128, 0, 128, 0.5)' } }}
          >
            {salesUsers.map((user, index) => (
              <MenuItem key={index} value={user}>
                {user}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ m: 1, minWidth: 100 }}>
          <Select
            labelId="product-type-filter-label"
            value={selectedProductType}
            displayEmpty
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <span style={{ fontSize: "12px", color: "#800080" }}>Sales User Filter</span>;
              }

              return selected;
            }}
            onChange={(e) => setSelectedProductType(e.target.value)}
          >
            {productTypes.map((type, index) => (
              <MenuItem key={index} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label={<Typography variant="caption">Date Filter</Typography>}
            value={dateFilter}
            onChange={(newValue: Date | null) => {
              setDateFilter(newValue);
            }}
          />

        </LocalizationProvider>
      </Grid>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 550 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow sx={{ background: "skyBlue", fontWeight: 800 }}>
              <TableCell>ID</TableCell>
              <TableCell>Product Type</TableCell>
              <TableCell>LS No.</TableCell>

              <TableCell>Shelf No.</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {testPendingDatas &&
              testPendingDatas
                .filter((row: PurchaseDataItem) => {
                  if (dateFilter) {
                    return row.date.includes(dateFilter.toISOString().split('T')[0]); // Adjust format as needed
                  }
                  return true;
                })
                .filter((row: PurchaseDataItem) => row.vendor_name === selectedSalesUser || selectedSalesUser === "")
                .filter((row: PurchaseDataItem) => row.product_type.toString() === selectedProductType || selectedProductType === "")
                .map((row: any) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell>
                      {row.product_type ? "On Purchase" : "On Approval"}
                    </TableCell>
                    <TableCell>{row.ls_no}</TableCell>
                    <TableCell>{row.shelf_no}</TableCell>
                    <TableCell>{row.date}</TableCell>
                    <TableCell>
                      <NavLink to={`/view-engineer-report/${row.id}`} key={row.id}>
                        <Button variant="outlined" size="small" sx={{ mb: 2 }}>
                          View
                        </Button>
                      </NavLink>
                    </TableCell>
                  </TableRow>
                ))}

          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
