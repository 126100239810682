function isAllowed(auth: any, permission: any) {
    if (!auth || !auth.user) return false
    return auth.user.isAdmin ? true : auth.user.designation[permission]
}

const MONTH_NAMES: any = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
}

function getLastDateOfMonth(month: any) {
    // month should be between 1-12
    const year = 2008
    const d = new Date(year, month + 1, 0)
    return d.getDate()
}

export { isAllowed, MONTH_NAMES, getLastDateOfMonth }
