import * as React from "react"
import { useState, useEffect } from "react"
import { http } from "../../utils/http"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import FormLabel from "@mui/material/FormLabel"
import MenuItem from "@mui/material/MenuItem"
import { useNavigate } from "react-router-dom"
import TextareaAutosize from "@mui/material/TextareaAutosize"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import Step1 from "./add-purchase-step-1"
import Step2 from "./add-purchase-step-2"
import InputLabel from "@mui/material/InputLabel"
import {
    FormGroup,
    FormControl,
    Grid,
    Button,
    styled,
    OutlinedInput,
    FormHelperText,
} from "@mui/material"
import { width } from "@mui/system"

const Container = styled(FormGroup)`
    width: 100%;
`
{
    /* <Typography variant="h4">Add New Employee</Typography> */
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const initialValues = {
    brand_master_id: "",
    series_master_id: "",
    model_no: "",
    general_image: "",

    processor_brand_id: "",
    processor_series_id: "",
    processor_generation_id: "",
    processor_gigaHeartz_id: "",

    graphic_brand_id: "",
    graphic_series_id: "",
    graphic_model_id: "",
    graphic_size_id: "",

    screen_size_id: "",
    screen_resolution_id: "",
    screen_spot_any_id: "",

    num_lock: "",
    back_lit: "",

    product_type: "",
    processor_id: "",
    ram_id: "",
    hard_disk_id: "",
    ssd_id: "",
    external_graphics_id: "",

    age_of_laptop_id: "",
    physical_condition_id: "",
    functional_condition_id: "",
    date: "",
    price: "",
    vendor_name: "",
    shelf_no: "",
    pickup_person: "",
    charger_type: "",
    problem: "",
    payment_mode: "",
    ips: "",
    description: "",
}

const AddPurchase = () => {
    //   const [page, setPage] = useState(0);

    //   const formTitles = [ "Step1" ,"step2"];
    //   const pageDisplay = ()=>{
    //     if(page === 0){
    //       return <Step1 />;
    //     }
    //     else {
    //         return <Step2 />;
    //     }
    //   }
    const navigate = useNavigate()

    return (
        <div>
            <Step1 />

            {/* {pageDisplay()}
    <h1>{ <form onSubmit={AddPurchase}>
        <FormGroup>
          <FormControl sx={{ my: 4 }}>
            <Button variant="contained" 
            sx={{ maxWidth: 200 }} 
            onClick={()=>{
              setPage((currPage)=>currPage+1);
            }}>
              Add purchase
            </Button>
          </FormControl>
        </FormGroup> */}
        </div>
    )
}
export default AddPurchase
