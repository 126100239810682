import * as React from "react"
import { useState, useEffect } from "react"
import { http } from "../../utils/http"
import Button from "@mui/material/Button"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { NavLink } from "react-router-dom"
import AddIcon from "@mui/icons-material/Add"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import { setAuth, AuthState } from "../../redux/auth/authSlice"
import { useSelector } from "react-redux"
import { RootState } from "../../redux/store"
import { isAllowed } from "../../utils/common"

export default function BasicTable() {
    const [designations, setDesignations] = useState([])
    useEffect(() => {
        ;(async function () {
            const designationsDataRes = await http.get("/designations")
            setDesignations(designationsDataRes.data.result)
        })()
    }, [])

    async function deleteDesignation(designationId: any) {
        await http.delete("/designations/" + designationId)
        setDesignations(designations.filter((item: any) => item.id != designationId))
    }
    const auth: AuthState = useSelector((state: RootState) => state.auth)

    return (
        <div>
            {isAllowed(auth, "create_allowed") ? (
                <NavLink to="/add-designation">
                    <Button variant="contained" sx={{ mb: 2 }}>
                        <AddIcon /> Add Designation
                    </Button>
                </NavLink>
            ) : (
                ""
            )}
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {designations &&
                            designations.map((row: any) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.id}
                                    </TableCell>
                                    <TableCell align="left">{row.name}</TableCell>
                                    <TableCell align="left">
                                        {isAllowed(auth, "edit_allowed") ? (
                                            <NavLink
                                                to={`/edit-designation/${row.id}`}
                                                key={row.id}
                                            >
                                                <EditIcon sx={{ color: "green" }}></EditIcon>
                                            </NavLink>
                                        ) : (
                                            ""
                                        )}
                                        {isAllowed(auth, "delete_allowed") ? (
                                            <Button onClick={() => deleteDesignation(row.id)}>
                                                <DeleteIcon sx={{ color: "red" }} />
                                            </Button>
                                        ) : (
                                            ""
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
