import { useState, useEffect } from "react";
import { http } from "../../utils/http";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormLabel from "@mui/material/FormLabel";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";

import {
  FormGroup,
  FormControl,
  Grid,
  Button,
  styled,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";
import { width } from "@mui/system";

const Container = styled(FormGroup)`
  width: 100%;
`;
{
  /* <Typography variant="h4">Add New Employee</Typography> */
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const initialValues = {
  name: "",
};

const AddCharger = () => {
  const navigate = useNavigate();

  const [chargerData, setCharger] = useState(initialValues);
  useEffect(() => {
    (async () => {
      const data: any = await http.get("/charger-type");
      setCharger(data.data.result);
    })();
  }, []);
  const onValueChange = (e: any) => {
    e.preventDefault(e);
    setCharger({ ...chargerData, [e.target.name]: e.target.value });
  };
  const AddChargerdetail = async (e: any) => {
    e.preventDefault();
    try {
      await http.post("/charger-type", chargerData);
      navigate("/charger-type");
      return;
    } catch (error: any) {
      console.log("error while calling api", error.message);
    }
  };

  return (
    <div>
      <form onSubmit={AddChargerdetail}>
        <FormGroup>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>Condition</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  sx={{ background: "white" }}
                  placeholder="Please enter charger condition"
                  onChange={(e) => onValueChange(e)}
                  name="name"
                  required
                />
              </FormControl>
            </Grid>
          </Grid>

          <FormControl sx={{ my: 4 }}>
            <Button variant="contained" sx={{ maxWidth: 200 }} type="submit">
              Add
            </Button>
          </FormControl>
        </FormGroup>
      </form>
    </div>
  );
};
export default AddCharger;
