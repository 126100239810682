import React from "react"
import { Routes, Navigate, Route, useLocation } from "react-router-dom"
import Home from "./pages/home"
import Layout from "./components/layout/layout"
import Designation from "./pages/designation/designation"
import AddDesignation from "./pages/designation/add-designation"
import EditDesignation from "./pages/designation/edit-designation"
import Employee from "./pages/employee/employee"
import Reports from "./pages/reports"

import AddEmployee from "./pages/employee/add-employee"
import AddSalary from "./pages/employee/add-salary"
import ViewSalary from "./pages/employee/view-salary"
import ViewEmployee from "./pages/employee/view-employee"
import ViewEmployeeDetails from "./pages/employee/view-employee-details"
import Attendance from "./pages/attendance"

import Ram from "./pages/ram/ram"
import EditRam from "./pages/ram/edit-ram"
import AddRam from "./pages/ram/add-ram"

import HardDisk from "./pages/hard-disk/hard-disk"
import EditHardDisk from "./pages/hard-disk/edit-hard-disk"
import AddHardDisk from "./pages/hard-disk/add-hard-disk"

import ExternalGraphics from "./pages/external-graphics/externalGraphics"
import EditExternalGraphics from "./pages/external-graphics/edit-external-graphics"
import AddExternalGraphics from "./pages/external-graphics/add-externalgraphics"

import SSD from "./pages/ssd/ssd"
import AddSSD from "./pages/ssd/add-ssd"
import EditSSD from "./pages/ssd/edit-ssd"

import AgeOfLaptop from "./pages/ageOfLaptop/age_of_laptop"
import AddAgeOfLaptop from "./pages/ageOfLaptop/add-age-of-laptop"
import EditAgeOfLaptop from "./pages/ageOfLaptop/edit-age-of-laptop"

import ScreenSize from "./pages/screen-size/screen_size"
import AddScreenSize from "./pages/screen-size/add-screen-size"
import EditScreenSize from "./pages/screen-size/edit-screen-size"

import PhysicalCondition from "./pages/physical-condition/physical_condition"
import AddPhysicalCondition from "./pages/physical-condition/add-physical-condition"
import EditPhysicalCondition from "./pages/physical-condition/edit-physical-condition"

import FunctionalCondition from "./pages/functional-condition/functional_condition"
import AddFunctionalCondition from "./pages/functional-condition/add-functional-condition"
import EditFunctionalCondition from "./pages/functional-condition/edit-functional-condition"

import Setting from "./pages/setting"

import Processor from "./pages/processor/processor"
import AddProcessor from "./pages/processor/add-processor"
import EditProcessor from "./pages/processor/edit-processor"

import Purchase from "./pages/purchase/purchase"
import AddPurchase from "./pages/purchase/add-purchase-step-1"
import AddPurchaseSecond from "./pages/purchase/add-purchase-step-2"
import AddPurchaseNormal from "./pages/purchase/add-purchase"
import ViewPurchase from "./pages/purchase/view-purchase"
import Details from "./pages/purchase-report/details"
import PurchaseReport from "./pages/purchase-report/index"
import Sell from "./pages/sell/index"
import ViewSales from "./pages/sell/view-sales"

import Condition from "./pages/condition/index"
import AddCondition from "./pages/condition/add-condition"

import PurchaseImages from "./pages/purchaseImages/add-purchase-images"
import CheckInOut from "./pages/checkInOuts/index"
import EditCheckInOut from "./pages/checkInOuts/edit"

import Login from "./pages/login"

import AllReports from "./pages/all-reports/index"
import ProcessorBrand from "./pages/processor-brand/index"
import AddProcessorBrand from "./pages/processor-brand/add-processorBrand"
import EditProcessorBrand from "./pages/processor-brand/edit-processorBrand"

import ProcessorSeries from "./pages/processor-series/index"
import AddProcessorSeries from "./pages/processor-series/add-processorSeries"

import ProcessorGeneration from "./pages/processor-generation/index"
import AddProcessorGeneration from "./pages/processor-generation/add-processorGeneration"
import EditProcessorGeneration from "./pages/processor-generation/edit-ProcessorGeneration"

import ProcessorGigaHeartz from "./pages/processor-gigaHeartz/index"
import AddProcessorGigaHeartz from "./pages/processor-gigaHeartz/add-processorGigaHeartz"
import EditProcessorGigaHeartz from "./pages/processor-gigaHeartz/edit-processorGigaHeartz"

import GraphicBrand from "./pages/graphic-brand/index"
import AddGraphicBrand from "./pages/graphic-brand/add-graphicBrand"
import EditGraphicBrand from "./pages/graphic-brand/edit-graphicBrand"

import GraphicSeries from "./pages/graphic-series/index"
import AddGraphicSeries from "./pages/graphic-series/add-graphicSeries"
import EditGraphicSeries from "./pages/graphic-series/edit-graphicSeries"

import GraphicModel from "./pages/graphic-model/index"
import AddGraphicModel from "./pages/graphic-model/add-graphicModel"
import EditGraphicModel from "./pages/graphic-model/edit-graphicModel"

import IdProof from "./pages/id-proof/index"
import AddIdProof from "./pages/id-proof/add-idProof"
import EditIdProof from "./pages/id-proof/edit-idProof"

import AddressProof from "./pages/address-proof/index"
import AddAddressProof from "./pages/address-proof/add-addressProof"
import EditAddressProof from "./pages/address-proof/edit-addressProof"

import GraphicSize from "./pages/graphic-size/index"
import AddGraphicSize from "./pages/graphic-size/add-graphicSize"
import EditGraphicSize from "./pages/graphic-size/edit-graphicSize"

import ScreenResolution from "./pages/resolution/index"
import AddScreenResolution from "./pages/resolution/add-resolution"
import EditScreenResolution from "./pages/resolution/edit-resolution"

import Brand from "./pages/brand-master/brand"
import AddBrand from "./pages/brand-master/add-brand"
import EditBrand from "./pages/brand-master/edit-brand"

import Model from "./pages/model-master/index"
import AddModel from "./pages/model-master/add-model"
import EditModel from "./pages/model-master/edit-model"

import Series from "./pages/series-master/index"
import AddSeries from "./pages/series-master/add-series-master"
import EditSeriesData from "./pages/series-master/edit-series-master"

import EngineerReport from "./pages/engineer-report/index"
import EngineerReportDashboard from "./pages/engineer-report/dashboard"
import ViewEngineer from "./pages/engineer-report/view-report"
import TestPending from "./pages/test-pending/index"
import ArrivedRepair from "./pages/arrived-repair/index"

import ReadyToDispatch from "./pages/ready-to-dispatch/index"
import InProcess from "./pages/in-process/index"
import PendingFabric from "./pages/fabric-pending/index"
import PendingSpare from "./pages/spare-pending/index"
import PendingRepair from "./pages/repair-pending/index"

import ChargerType from "./pages/charger-type/index"
import AddChargerType from "./pages/charger-type/add-charger"

import MyProfile from "./pages/my-profile/index"
import { RootState } from "./redux/store"
import { useSelector } from "react-redux"
import EngineerDashboard from "./pages/engineer-report/engneerReportDashboard"

import ViewSparePart from "./pages/sparepart/view-sparepart"
import BuyNewSparePart from "./pages/sparepart/buyNewSparepart"
import UpdatePartComponent from "./pages/sparepart/updatePartComponent"
import ScrapTable from "./pages/scrap"
import ViewScrap from "./pages/scrap/view-scrap"

function RequireAuth({ children }: { children: JSX.Element }) {
    let location = useLocation()
    let auth = useSelector((state: RootState) => state.auth)

    if (!auth.authenticated) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{ from: location }} replace />
    }
    return children
}

export default function AppRouter() {
    return (
        <div>
            <Routes>
                <Route
                    path="/"
                    element={
                        <RequireAuth>
                            <Layout />
                        </RequireAuth>
                    }
                >
                    <Route index element={<Home />} />

                    <Route path="/test-pending" element={<TestPending />} />

                    <Route path="/employees" element={<Employee />} />
                    <Route path="/attendance" element={<Attendance />} />
                    <Route path="/designations" element={<Designation />} />
                    <Route path="/add-designation" element={<AddDesignation />} />
                    <Route path="/edit-designation/:id" element={<EditDesignation />} />
                    <Route path="/reports" element={<Reports />} />

                    <Route path="/add-employee" element={<AddEmployee />} />
                    <Route path="/add-salary/:id" element={<AddSalary />} />
                    <Route path="/view-employee/:id" element={<ViewEmployee />} />
                    <Route path="/view-employee-details/:id" element={<ViewEmployeeDetails />} />

                    <Route path="/view-salary/:id" element={<ViewSalary />} />

                    <Route path="/ram" element={<Ram />} />
                    <Route path="/add-ram" element={<AddRam />} />
                    <Route path="/edit-ram/:id" element={<EditRam />} />
                    <Route path="/hard-disk" element={<HardDisk />} />
                    <Route path="/add-hard-disk" element={<AddHardDisk />} />
                    <Route path="/edit-hard-disk/:id" element={<EditHardDisk />} />

                    <Route path="/external-graphics" element={<ExternalGraphics />} />
                    <Route path="/add-external-graphics" element={<AddExternalGraphics />} />
                    <Route path="/edit-external-graphics/:id" element={<EditExternalGraphics />} />

                    <Route path="/ssd" element={<SSD />} />
                    <Route path="/add-ssd" element={<AddSSD />} />
                    <Route path="/edit-ssd/:id" element={<EditSSD />} />

                    <Route path="/screen-size" element={<ScreenSize />} />
                    <Route path="/add-screen-size" element={<AddScreenSize />} />
                    <Route path="/edit-screen-size/:id" element={<EditScreenSize />} />

                    <Route path="/age-of-laptop" element={<AgeOfLaptop />} />
                    <Route path="/add-age-of-laptop" element={<AddAgeOfLaptop />} />
                    <Route path="/edit-age-of-laptop/:id" element={<EditAgeOfLaptop />} />

                    <Route path="/physical-condition" element={<PhysicalCondition />} />
                    <Route path="/add-physical-condition" element={<AddPhysicalCondition />} />
                    <Route
                        path="/edit-physical-condition/:id"
                        element={<EditPhysicalCondition />}
                    />

                    <Route path="/functional-condition" element={<FunctionalCondition />} />
                    <Route path="/add-functional-condition" element={<AddFunctionalCondition />} />
                    <Route
                        path="/edit-functional-condition/:id"
                        element={<EditFunctionalCondition />}
                    />

                    <Route path="/setting" element={<Setting />} />

                    <Route path="/processor" element={<Processor />} />
                    <Route path="/add-processor" element={<AddProcessor />} />
                    <Route path="/edit-processor/:id" element={<EditProcessor />} />

                    <Route path="/purchase" element={<Purchase />} />
                    <Route path="/add-purchase" element={<AddPurchaseNormal />} />
                    <Route path="/purchase-step2/:id" element={<AddPurchaseSecond />} />
                    <Route path="/view-purchase/:id" element={<ViewPurchase />} />
                    <Route path="/purchase-report" element={<PurchaseReport />} />
                    <Route path="/add-purchase-images" element={<PurchaseImages />} />
                    <Route path="/edit-purchase/:id" element={<Details />} />
                    <Route path="/view-sales/:id" element={<ViewSales />} />

                    <Route path="/sells" element={<Sell />} />
                    <Route path="/conditions" element={<Condition />} />
                    <Route path="/add-conditions" element={<AddCondition />} />
                    <Route path="/check-in-out" element={<CheckInOut />} />
                    <Route path="/view-check-in-out/:id" element={<EditCheckInOut />} />
                    <Route path="/all-reports" element={<AllReports />} />

                    <Route path="/processor-brand" element={<ProcessorBrand />} />
                    <Route path="/add-processor-brand" element={<AddProcessorBrand />} />
                    <Route path="/edit-processor-brand/:id" element={<EditProcessorBrand />} />

                    <Route path="/processor-series" element={<ProcessorSeries />} />
                    <Route path="/add-processor-series" element={<AddProcessorSeries />} />

                    <Route path="/processor-generation" element={<ProcessorGeneration />} />
                    <Route path="/add-processor-generation" element={<AddProcessorGeneration />} />
                    <Route
                        path="/edit-processor-generation/:id"
                        element={<EditProcessorGeneration />}
                    />

                    <Route path="/processor-gigaHeartz" element={<ProcessorGigaHeartz />} />
                    <Route path="/add-processor-gigaHeartz" element={<AddProcessorGigaHeartz />} />
                    <Route
                        path="/edit-processor-gigaHeartz/:id"
                        element={<EditProcessorGigaHeartz />}
                    />

                    <Route path="/graphic-brand" element={<GraphicBrand />} />
                    <Route path="/add-graphic-brand" element={<AddGraphicBrand />} />
                    <Route path="/edit-graphic-brand/:id" element={<EditGraphicBrand />} />

                    <Route path="/graphic-series" element={<GraphicSeries />} />
                    <Route path="/add-graphic-series" element={<AddGraphicSeries />} />
                    <Route path="/edit-graphic-series/:id" element={<EditGraphicSeries />} />

                    <Route path="/graphic-model" element={<GraphicModel />} />
                    <Route path="/add-graphic-model" element={<AddGraphicModel />} />
                    <Route path="/edit-graphic-model/:id" element={<EditGraphicModel />} />

                    <Route path="/graphic-size" element={<GraphicSize />} />
                    <Route path="/add-graphic-size" element={<AddGraphicSize />} />
                    <Route path="/edit-graphic-size/:id" element={<EditGraphicSize />} />

                    <Route path="/screen-resolution" element={<ScreenResolution />} />
                    <Route path="/add-screen-resolution" element={<AddScreenResolution />} />
                    <Route path="/edit-screen-resolution/:id" element={<EditScreenResolution />} />

                    <Route path="/brands" element={<Brand />} />
                    <Route path="/add-brand" element={<AddBrand />} />
                    <Route path="/edit-brand/:id" element={<EditBrand />} />

                    <Route path="/model-master" element={<Model />} />
                    <Route path="/add-model-master" element={<AddModel />} />
                    <Route path="/edit-model-master/:id" element={<EditModel />} />

                    <Route path="/series-master" element={<Series />} />
                    <Route path="/add-series-master" element={<AddSeries />} />
                    <Route path="/edit-series-master/:id" element={<EditSeriesData />} />

                    <Route path="/id-proof" element={<IdProof />} />
                    <Route path="/add-id-proof" element={<AddIdProof />} />
                    <Route path="/edit-id-proof/:id" element={<EditIdProof />} />

                    <Route path="/address-proof" element={<AddressProof />} />
                    <Route path="/add-address-proof" element={<AddAddressProof />} />
                    <Route path="/edit-address-proof/:id" element={<EditAddressProof />} />
                    <Route path="/engineer-report-dashboard" element={<EngineerDashboard />} />
                    {/* <Route path="/engineer-report-dashboard" element={<EngineerReportDashboard />} /> */}
                    <Route path="/engineer-report" element={<EngineerReport />} />
                    <Route path="/view-engineer-report/:id" element={<ViewEngineer />} />

                    <Route path="/arrived-for-repairing" element={<ArrivedRepair />} />
                    <Route path="/ready-to-dispatch" element={<ReadyToDispatch />} />
                    <Route path="/in-process" element={<InProcess />} />

                    <Route path="/fabric-pending" element={<PendingFabric />} />
                    <Route path="/spare-pending" element={<PendingSpare />} />
                    <Route path="/repair-pending" element={<PendingRepair />} />

                    <Route path="/charger-type" element={<ChargerType />} />
                    <Route path="/add-charger" element={<AddChargerType />} />

                    <Route path="/my-profile/:id" element={<MyProfile />} />

                    <Route path="/view-sparepart" element={<ViewSparePart />} />
                    <Route path="/buy-new-spare-part" element={<BuyNewSparePart />} />
                    <Route path="/update-spare-part/:id" element={<UpdatePartComponent />} />

                    <Route path="/scraps" element={<ScrapTable />} />
                    <Route path="/view-scraps/:id" element={<ViewScrap />} />
                </Route>
                <Route path="/login" element={<Login />} />
            </Routes>
        </div>
    )
}
