import { useState, useEffect } from "react";
import { http } from "../../utils/http";
import { useParams } from "react-router-dom";
import Hidden from "@mui/material/Hidden";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";

import {
  FormGroup,
  FormControl,
  Grid,
  Button,
  styled,
  OutlinedInput,
  FormHelperText,
  TextField,
} from "@mui/material";
import { width } from "@mui/system";

const Container = styled(FormGroup)`
  width: 100%;
`;
{
  /* <Typography variant="h4">Add New Employee</Typography> */
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const initialValues = {
  emp_id: "",
  firstName: "",
  lastName: "",
  employeeCode: "",
  email: "",
  phone: "",
  status: "",
  dob: "",
  designationId: ""
};

const ViewEmployeeDetails = () => {
  let params = useParams();
  const navigate = useNavigate();
  const [designation, setDesignations] = useState([]);
  useEffect(() => {
    (async () => {
      const data: any = await http.get("/designations");
      setDesignations(data.data.result);
    })();
  }, []);
  const [EmpData, setEmpData] = useState({
    ...initialValues,
    emp_id: params.id,
  });
  const [EmpDataDes, setEmpDataDes] = useState({
    ...initialValues,
    emp_id: params.id,
    firstName: "",
    
  });
  useEffect(() => {
    (async function () {
      const employeesDataRes = await http.get("/employees/" + params.id);
      setEmpData(employeesDataRes.data.result);
      //   setEmpDataDes(employeesDataRes.data.result.designation);
      // console.log("EMployee details from edit", EmpData);

      // console.log('emps',employees.length);
    })();
  }, []);

  const onValueChange = (e: any) => {
    e.preventDefault(e);
    setEmpData({ ...EmpData, [e.target.name]: e.target.value });
    // console.log('name is ', e.target.name, 'value is', e.target.value);
  };
  const ViewEmployeeDetailsdetail = async (e: any) => {
    e.preventDefault();
    try {
        //  console.log('data',EmpData);
      await http.patch("/employees/" + params.id, EmpData);
      navigate("/employees");
      return;
    } catch (error: any) {
      console.log("error while calling api", error.message);
    }
  };

  return (
    <div>
      <h2>Employee: {params.id}</h2>
      <form onSubmit={ViewEmployeeDetailsdetail}>
        <FormGroup>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>First Name</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  sx={{ background: "white" }}
                  onChange={(e) => onValueChange(e)}
                  name="firstName"
                  value={EmpData.firstName}
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>Last Name</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  sx={{ background: "white" }}
                  onChange={(e) => onValueChange(e)}
                  name="lastName"
                  value={EmpData.lastName}
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>Employee Code</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  sx={{ background: "white" }}
                  onChange={(e) => onValueChange(e)}
                  name="lastName"
                  value={EmpData.employeeCode}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>Email</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  sx={{ background: "white" }}
                  onChange={(e) => onValueChange(e)}
                  name="email"
                  value={EmpData.email}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>Update Password</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  sx={{ background: "white" }}
                  onChange={(e) => onValueChange(e)}
                  name="password"
                placeholder="*******"
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>Mobile No.</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  sx={{ background: "white" }}
                  onChange={(e) => onValueChange(e)}
                  name="phone"
                  value={EmpData.phone}
                />
              </FormControl>
            </Grid>
          
            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>DOB</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  sx={{ background: "white" }}
                  onChange={(e) => onValueChange(e)}
                  name="dob"
                 value={EmpData.dob}
                  type="date"
                />
              </FormControl>
            </Grid>
          
            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3> Designation</h3>{" "}
                </FormHelperText>
                <Select
                  sx={{ background: "white" }}
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  name="designationId"
                  value={EmpData.designationId}
                  onChange={(e) => onValueChange(e)}
                  MenuProps={MenuProps}
                >
                  {designation.map((desig: any) => (
                    <MenuItem
                      key={desig.name}
                      value={desig.id}
                    >
                      {desig.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          
          </Grid>

          {/* <FormControl sx={{ my: 4 }}>
            <Button variant="contained" sx={{ maxWidth: 200 }} type="submit">
              Update
            </Button>
          </FormControl> */}
        </FormGroup>
      </form>
    </div>
  );
};
export default ViewEmployeeDetails;
