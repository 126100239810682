import { serverImgUrl, http } from "../../utils/http"
import { Button } from "@mui/material"
import PrintIcon from "@mui/icons-material/Print"

function PurchasePDFDownloadBtn({ purchaseId, text = "Download / Print" }: any) {
    function handleBtnClick() {
        window.open(`${serverImgUrl}/api/purchase/${purchaseId}/pdf`, "_blank")
    }
    return (
        <Button variant="contained" onClick={handleBtnClick}>
            <PrintIcon sx={{ height: "18px" }} />
            {text}
        </Button>
    )
}

export default PurchasePDFDownloadBtn
