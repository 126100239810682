import * as React from "react"
import { useState, useEffect } from "react"
import { http } from "../../utils/http"
import Button from "@mui/material/Button"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { NavLink, Link } from "react-router-dom"
import AddIcon from "@mui/icons-material/Add"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"
import DeleteIcon from "@mui/icons-material/Delete"
import Box from "@mui/material/Box"

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    p: 3,
}
export default function BasicTable() {
    const [purchaseData, setPurchaseData] = useState([])
    useEffect(() => {
        ;(async function () {
            const purchaseDataRes = await http.get("/purchaseReports")
            setPurchaseData(purchaseDataRes.data.result)
        })()
    }, [])

    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    return (
        <div>
            <h3>Engineer Task</h3>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow sx={{ background: "skyBlue" }}>
                            <TableCell>ID</TableCell>
                            <TableCell>Product Type</TableCell>
                            <TableCell>LS No.</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Shelf No.</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {purchaseData &&
                            purchaseData.map((row: any) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.id}
                                    </TableCell>
                                    <TableCell>
                                        {row.product_type ? "On Purchase" : "On Approval"}
                                    </TableCell>
                                    <TableCell>{row.ls_no}</TableCell>
                                    <TableCell>₹{row.price}</TableCell>
                                    <TableCell>{row.shelf_no}</TableCell>
                                    <TableCell>{row.date}</TableCell>

                                    <TableCell>
                                        <NavLink
                                            to={`/view-engineer-report/${row.id}`}
                                            key={row.id}
                                        >
                                            <Button variant="outlined" size="small" sx={{ mb: 2 }}>
                                                View
                                            </Button>
                                        </NavLink>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
