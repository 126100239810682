import * as React from "react";
import { useState, useEffect } from "react";
import { http } from "../../utils/http";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { NavLink } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MenuItem from "@mui/material/MenuItem";
export default function BasicTable() {
  const [conditions, setCondition] = useState([]);

  useEffect(() => {
    (async () => {
      const data: any = await http.get("/conditions");
      
      setCondition(data.data.result);
    })();
  }, []);
  const [FunctionalCondition, setFunctionalCondition] = useState([]);
  useEffect(() => {
    (async function () {
      const FunctionalConditionDataRes = await http.get(
        "/functional-condition"
      );
      setFunctionalCondition(FunctionalConditionDataRes.data.result);
    })();
  }, []);

  async function deleteData(dataId: any) {
    // if (confirm("Do you really want to delete?")) {
    await http.delete("/functional-condition/" + dataId);
    setFunctionalCondition(
      FunctionalCondition.filter((item: any) => item.id !== dataId)
    );
    // }
  }
 

  return (
    <div>
      <NavLink to="/add-functional-condition">
        <Button variant="contained" sx={{ mb: 2 }}>
          <AddIcon /> Add Functional Condition
        </Button>
      </NavLink>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell> Name</TableCell>
              <TableCell> Condition</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {FunctionalCondition &&
              FunctionalCondition.map((row: any) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                
                  <TableCell> {row.id}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>
                   <ul><li>{row.condition}</li></ul></TableCell>
                
                {/* * <TableCell>{companyName(row.description)}</TableCell>   */}
{/*                  
                  <TableCell>
                 
                    { row.description  ? "condition.name": "Inactive"}
                  </TableCell>
                  */}
                  <TableCell>
                    <NavLink to={`/edit-functional-condition/${row.id}`} key={row.id}>
                      <EditIcon sx={{ color: "green" }}></EditIcon>
                    </NavLink>
                    <Button onClick={() => deleteData(row.id)}>
                      <DeleteIcon sx={{ color: "red" }} />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
