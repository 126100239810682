import { useState, useEffect } from "react"
import { http } from "../../utils/http"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import FormLabel from "@mui/material/FormLabel"
import MenuItem from "@mui/material/MenuItem"
import { useNavigate } from "react-router-dom"

import {
    FormGroup,
    FormControl,
    Grid,
    Button,
    styled,
    OutlinedInput,
    FormHelperText,
} from "@mui/material"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const initialValues = {
    name: "",
    graphic_brand_id: "",
}

const AddGraphicSeries = () => {
    const navigate = useNavigate()
    const [graphics, setGraphicSeries] = useState(initialValues)
    const [graphicBrands, setGraphicBrands] = useState([])

    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/graphic-brand")
            setGraphicBrands(data.data.result)
        })()
    }, [])

    const onValueChange = (e: any) => {
        e.preventDefault(e)
        setGraphicSeries({ ...graphics, [e.target.name]: e.target.value })
    }
    const AddGraphicSeriesDetail = async (e: any) => {
        e.preventDefault()
        try {
            await http.post("/graphic-series", graphics)
            navigate("/graphic-series")
            return
        } catch (error: any) {
            console.log("error while calling api", error.message)
        }
    }

    return (
        <div>
            <form onSubmit={AddGraphicSeriesDetail}>
                <FormGroup>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <FormControl sx={{ width: "80%" }}>
                                <FormHelperText>
                                    <h3>Name</h3>{" "}
                                </FormHelperText>
                                <OutlinedInput
                                    sx={{ background: "white" }}
                                    placeholder="Please enter name"
                                    onChange={(e) => onValueChange(e)}
                                    name="name"
                                    required
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl sx={{ width: "80%" }}>
                                <FormHelperText>
                                    <h3>Select Graphics Brand</h3>{" "}
                                </FormHelperText>
                                <Select
                                    sx={{ background: "white" }}
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    name="graphic_brand_id"
                                    value={graphics.graphic_brand_id}
                                    onChange={(e) => onValueChange(e)}
                                    MenuProps={MenuProps}
                                    required
                                >
                                    {graphicBrands.map((graphicBrand: any) => (
                                        <MenuItem key={graphicBrand.name} value={graphicBrand.id}>
                                            {graphicBrand.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <FormControl sx={{ my: 4 }}>
                        <Button variant="contained" sx={{ maxWidth: 200 }} type="submit">
                            Add
                        </Button>
                    </FormControl>
                </FormGroup>
            </form>
        </div>
    )
}
export default AddGraphicSeries
