import { useEffect, useState } from "react";
import Popover from '@mui/material/Popover';
import EditIcon from "@mui/icons-material/Edit";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Stack, Typography, OutlinedInput, FormHelperText } from "@mui/material";

export default function ({
    textInputMode = false,
    onValueChange,
    title,
    formName,
    formValue,
    items = [],
    textInputType = "text",
    multiline = false,
    editable = true
}: any) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [activeValue, setActiveValue] = useState("");

    useEffect(() => {
        const itemFound = items.find((item: any) => item.id == formValue);
        if (itemFound) {
            setActiveValue((itemFound && itemFound.name) || "");
        } else {
            setActiveValue(formValue);
        }
    }, [items, formValue]);

    const handleClick = (event: React.MouseEvent<SVGSVGElement>) => {
        setAnchorEl(event.currentTarget as unknown as HTMLElement);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div style={{ background: "white", borderRadius: "10px" }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <FormHelperText>
                    <Typography
                        sx={{
                            padding: "2px 4px",
                            fontSize: { xl: "0.9em", xs: "0.72em" },
                            fontWeight: "bold",
                            color: "#333",
                            textTransform: "uppercase",
                        }}
                    >
                        {title}
                    </Typography>
                </FormHelperText>
            </Stack>
            {/* <Stack
                direction="row"
                alignItems="center"
                sx={{ background: "#6acdeb", borderRadius: 3, p: 1, fontWeight: "bold" }}
            >
                <div style={{ fontSize: ".6em" }}>{activeValue}</div>
                {editable && textInputMode ? (
                    <EditIcon
                        sx={{ width: 24, height: 24, ml: 1, cursor: "pointer", color: "#3f51b5" }}
                        onClick={handleClick}
                    />
                ) : (
                    ""
                )}
            </Stack> */}
            {textInputMode ? (
                <OutlinedInput
                    size="small"
                    sx={{ background: "#fff", fontSize: "0.8em", width: "100%", borderRadius: 4 }}
                    placeholder={`Please enter ${title}`}
                    onChange={(e) => onValueChange(e)}
                    name={formName}
                    value={formValue}
                    type={textInputType}
                    multiline={multiline}
                    minRows={multiline ? 3 : 1}
                />
            ) : (
                <div>
                    <Select
                        sx={{ background: "#6acdeb", fontSize: "0.8em", width: "100%", borderRadius: 4}}
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        name={formName}
                        value={formValue}
                        onChange={(e) => onValueChange(e)}
                        size="small"
                    >
                        {items.map((item: any) => (
                            <MenuItem key={item.name} value={item.id}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </Select>
                    <Popover
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        PaperProps={{
                            style: {
                                position: 'absolute',
                                top: 'calc(50% - 150px)',
                                left: 'calc(50% - 150px)',
                                minWidth: 300,
                                maxWidth: "300px",
                                maxHeight: '80vh',
                                overflowY: 'auto',
                            },
                        }}
                    >
                        <div >
                            <h5 style={{ marginBottom: 16, fontSize: '1.2em', fontWeight: 'bold' }}>{title}</h5>
                            <Select
                                sx={{ background: "#fff", fontSize: "0.8em", width: "100%", borderRadius: 4 }}
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                name={formName}
                                value={formValue}
                                onChange={(e) => onValueChange(e)}
                                size="small"
                            >
                                {items.map((item: any) => (
                                    <MenuItem key={item.name} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    </Popover>
                </div>
            )}
        </div>
    );
}
