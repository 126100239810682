import MenuItem from "@mui/material/MenuItem";
import { useState, useEffect } from "react";
import { http } from "../../utils/http";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useNavigate } from "react-router-dom";

import {
  FormGroup,
  FormControl,
  InputLabel,
  Input,
  Typography,
  Grid,
  Button,
  styled,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";
import { width } from "@mui/system";

const Container = styled(FormGroup)`
  width: 100%;
`;
{
  /* <Typography variant="h4">Add New Employee</Typography> */
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  password: "",
  designationId: "",
  dob: "",
};

const AddEmployee = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(initialValues);
  const [designations, setDesignations] = useState([]);

  useEffect(() => {
    (async () => {
      const data: any = await http.get("/designations");
      setDesignations(data.data.result);
    })();
  }, []);

  const onValueChange = (e: any) => {
    e.preventDefault(e);
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  
  const addUserDetail = async (e: any) => {
    e.preventDefault();
    try {
      await http.post("/employees", user);
      navigate("/employees");
      return;
    } catch (error: any) {
      console.log("error while calling api", error.message);
    }
  };

  return (
    <div>
      <form onSubmit={addUserDetail}>
        <FormGroup>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>First Name</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  sx={{ background: "white" }}
                  placeholder="Please enter text"
                  onChange={(e) => onValueChange(e)}
                  name="firstName"
                  required
                />

                {/* <InputLabel >Name</InputLabel> */}
                {/* <Input /> */}
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>Last Name</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  sx={{ background: "white" }}
                  placeholder="Please enter text"
                  onChange={(e) => onValueChange(e)}
                  name="lastName"
                  required
                />

                {/* <InputLabel >Name</InputLabel> */}
                {/* <Input /> */}
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>Email</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  sx={{ background: "white" }}
                  placeholder="Please enter Email"
                  onChange={(e) => onValueChange(e)}
                  name="email"
                  type="email"
                  required
                />

                {/* <InputLabel >Name</InputLabel> */}
                {/* <Input /> */}
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>Mobile</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  sx={{ background: "white" }}
                  placeholder="Please enter text"
                  onChange={(e) => onValueChange(e)}
                  name="phone"
                  required
                  type="number"
                />

                {/* <InputLabel >Name</InputLabel> */}
                {/* <Input /> */}
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>Password</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  sx={{ background: "white" }}
                  placeholder="Please enter text"
                  onChange={(e) => onValueChange(e)}
                  name="password"
                  type="password"
                  required
                />

                {/* <InputLabel >Name</InputLabel> */}
                {/* <Input /> */}
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>Designation</h3>{" "}
                </FormHelperText>
                <Select
                  sx={{ background: "white" }}
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  name="designationId"
                  value={user.designationId}
                  onChange={(e) => onValueChange(e)}
                  MenuProps={MenuProps}
                  required
                >
                  {designations.map((designation: any) => (
                    <MenuItem key={designation.name} value={designation.id}>
                      {designation.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>Date Of Birth</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  sx={{ background: "white" }}
                  placeholder="Please enter text"
                  onChange={(e) => onValueChange(e)}
                  name="dob"
                  type="date"
                  required
                />

                {/* <InputLabel >Name</InputLabel> */}
                {/* <Input /> */}
              </FormControl>
            </Grid>
          </Grid>

          <FormControl sx={{ my: 4 }}>
            <Button variant="contained" sx={{ maxWidth: 200 }} type="submit">
              Add
            </Button>
          </FormControl>
        </FormGroup>
      </form>
    </div>
  );
};
export default AddEmployee;
