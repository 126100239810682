import React, { useState, useEffect } from 'react';
import { http } from "../../utils/http";
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

const UpdatePartComponent = () => {
 const [name, setName] = useState('');
 const [category, setCategory] = useState('');
 const [price, setPrice] = useState('');
 const [inwardDate, setInwardDate] = useState('');
 const [quality, setQuality] = useState('');
 const [active, setActive] = useState(true);

 const params = useParams();

 useEffect(() => {
   const fetchSparePartDetails = async () => {
     try {
       const response = await http.get(`/stockSpare/${params.id}`);
       console.log(response);
       
       const { name, category, price, inwardDate, quality, active } = response.data.result;
       setName(name);
       setCategory(category);
       setPrice(price);
       setInwardDate(inwardDate);
       setQuality(quality);
       setActive(active);
     } catch (error) {
       console.error('Error fetching spare part details:', error);
       toast.error('Failed to fetch spare part details. Please try again.');
     }
   };

   fetchSparePartDetails();
 }, [params.id]);

 const handleUpdate = async (event:React.FormEvent) => {
   event.preventDefault();

   try {
     await http.patch(`/stockSpare/${params.id}`, {
       name,
       category,
       price,
       inwardDate,
       quality,
       active,
     });

     toast.success('Spare part updated successfully!');
   } catch (error) {
     console.error('Error updating spare part:', error);
     toast.error('Failed to update spare part. Please try again.');
   }
 };

 return (

    <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl m-4">
      <div className="md:flex">
      <div className="md:flex-shrink-0 md:w-1/3 mx-auto">
      <img className="w-full object-contain md:w-48 my-12 " src="/images/38507.jpg" alt="Image"/>
      <img className="w-full object-contain md:w-48" src="/images/38504.jpg" alt="Image"/>
  </div>
        <div className="p-8 md:w-2/3">
          <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">Buy New Spare Part</div>
    <form onSubmit={handleUpdate} className="space-y-4">
      <input type="text" value={name} onChange={e => setName(e.target.value)} placeholder="Name" className="w-full p-2 border rounded" required />
      <input type="text" value={category} onChange={e => setCategory(e.target.value)} placeholder="Category" className="w-full p-2 border rounded" required />
      <input type="number" value={price} onChange={e => setPrice(e.target.value)} placeholder="Price" className="w-full p-2 border rounded" required />
      <input type="date" value={inwardDate} onChange={e => setInwardDate(e.target.value)} placeholder="Inward Date" className="w-full p-2 border rounded" required />
      <input type="text" value={quality} onChange={e => setQuality(e.target.value)} placeholder="Quality" className="w-full p-2 border rounded" required />
      <label>
        <input type="checkbox" checked={active} onChange={e => setActive(e.target.checked)} className="mr-2" /> Active
      </label>
      <button type="submit" className="w-full p-2 bg-blue-500 text-white rounded">
        Update Spare Part
      </button>
    </form>
    </div>
    </div>
    </div>
 );
};

export default UpdatePartComponent;