import React from "react"
import { Card, CardContent, Typography } from "@mui/material"

function PageTitleCard({ title = "", children, flexMode = false }: any) {
    let flexStyles: { [p: string]: any } = { paddingBottom: 0 }
    let titleStyles: { [p: string]: any } = {
        fontSize: "20px",
        borderLeft: "5px solid dodgerblue",
        color: "#1977D3",
        fontWeight: 600,
        padding: "10px",
        background: "#B2D9FF",
    }
    if (flexMode) {
        flexStyles = {
            ...flexStyles,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        }
    } else {
        titleStyles.marginBottom = "10px"
    }
    return (
        <Card elevation={2} style={{ marginBottom: "15px" }}>
            <CardContent sx={flexStyles}>
                <Typography sx={titleStyles} variant="h1" gutterBottom>
                    {title.toUpperCase()}
                </Typography>
                <div>{children}</div>
            </CardContent>
        </Card>
    )
}

export default PageTitleCard
