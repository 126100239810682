import { useState } from "react";

export function CustomeModal({ isOpen, onClose,linkName="" }:{isOpen:boolean,onClose:()=>void,linkName:string}) {
 
  return (
     <div> 
       {isOpen && (
        <div className="modal-over fixed inset-0 bg-[rgba(0,0,0,0.75)] flex justify-center items-center">
           <div id="crypto-modal" tabIndex={-1} aria-hidden="true" className="absolute left-[25%] top-[25%] z-50 justify-center items-center w-full h-screen">
           <div className="relative p-4 w-1/2 max-h-full">
             <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
               <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                 <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  Buy from Stock
                 </h3>
                 <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={onClose}>
                  Close
                 </button>
               </div>
               <div className="p-4 md:p-5">
                 <p className="text-sm font-normal text-gray-500 dark:text-gray-400">Connect with one of our available Stock providers.</p>
                 <ul className="my-4 space-y-3">
                  <li>
                     <a href="/buy-new-spare-part" className="flex items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
                       Buy from our Stock
                     </a>
                  </li>
                  <li>
                     <a href={`/view-sparepart?name=${linkName}`} className="flex items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
                       Buy from external Stock
                     </a>
                  </li>
                 </ul>
               </div>
             </div>
           </div>
         </div>
        </div>
       )}
     </div>
  );
 }