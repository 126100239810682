import { useState, useEffect } from "react";
import { http } from "../../utils/http";
import { useParams } from "react-router-dom";
import Hidden from "@mui/material/Hidden";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import MenuItem from "@mui/material/MenuItem";

import { useNavigate } from "react-router-dom";

import {
  FormGroup,
  FormControl,
  Grid,
  Button,
  styled,
  OutlinedInput,
  FormHelperText,
  TextField,
} from "@mui/material";
import { width } from "@mui/system";

const Container = styled(FormGroup)`
  width: 100%;
`;
{
  /* <Typography variant="h4">Add New Employee</Typography> */
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



const initialValues = {
  id: "",
  name: "",
  grahic_brand_id:""
};


const EditGraphicSeries = () => {

  const [graphicBrands, setGraphicBrands] = useState([]);
 
  useEffect(() => {
    (async () => {
      const data: any = await http.get("/graphic-brand");
      setGraphicBrands(data.data.result);
    })();
  }, []);
  let params = useParams();
  const navigate = useNavigate();
  
  const [graphicSeries, setGraphicSeriesUpdate] = useState({ ...initialValues,
    id: params.id,
    graphic_brand_id:"" });
  
  useEffect(() => {
      (async () => {
          const data: any = await http.get("/graphic-series/" + params.id);
          setGraphicSeriesUpdate(data.data.result);
        })();
    }, []);
    
       
  const onValueChange = (e: any) => {
    e.preventDefault(e);
    setGraphicSeriesUpdate({ ...graphicSeries, [e.target.name]: e.target.value });

  };
  const EditGraphicSeriesDetail = async (e: any) => {
    e.preventDefault();
    try {
 
      await http.patch("/graphic-series/"+params.id, graphicSeries);
      navigate("/graphic-series");
      return;
    } catch (error: any) {
      console.log("error while calling api", error.message);
    }
  };

  return (
    <div>
      <h2> Graphic ID: {params.id}</h2>
      <form onSubmit={EditGraphicSeriesDetail}>
        <FormGroup>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>Graphic Series ID</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  disabled
                  sx={{ background: "white" }}
                  value={graphicSeries.id}
                  onChange={(e) => onValueChange(e)}
                  name="id"
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>Name</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  sx={{ background: "white" }}
                  onChange={(e) => onValueChange(e)}
                  name="name"
                  value={graphicSeries.name}
                  
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3> Brand Master</h3>{" "}
                </FormHelperText>
                <Select
                  sx={{ background: "white" }}
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  name="graphic_brand_id"
                  value={graphicSeries.graphic_brand_id}
                  onChange={(e) => onValueChange(e)}
                  MenuProps={MenuProps}
                >
                  {graphicBrands.map((graphicBrand: any) => (
                    <MenuItem key={graphicBrand.name} value={graphicBrand.id}>
                      {graphicBrand.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <FormControl sx={{ my: 4 }}>
            <Button variant="contained" sx={{ maxWidth: 200 }} type="submit">
              Update
            </Button>
          </FormControl>
        </FormGroup>
      </form>
    </div>
  );
};
export default EditGraphicSeries;
