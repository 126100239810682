import * as React from "react";
import { useState, useEffect } from "react";
import { http } from "../../utils/http";
import {
    Box,
    Button,
    TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import PageTitleCard from "../../components/layout/page-title-card";
import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridApiCommon,
    GridRowId,
    GridEventListener,
    GridRowEditStopReasons,
    GridRowModes,
    GridRowModel,
    GridRowModesModel,
    GridActionsCellItem,
    GridRowParams,
} from "@mui/x-data-grid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { toast } from "react-toastify";
import { Brand, ProcessorBrand, ProcessorSeries } from "../../types";
import ArticleIcon from '@mui/icons-material/Article';

const formatDateToDDMMYY = (date: string | Date): string => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0"); // Since Months are zero-based
    const year = String(d.getFullYear()).slice(-2); // Get last two digits of the year
    return `${day}/${month}/${year}`;
};

interface DateEditComponentProps {
    value?: any;
    api: GridApiCommon;
    id: GridRowId;
    field: string;
}

const DateEditComponent: React.FC<DateEditComponentProps> = ({ value, api, id, field }) => {
    const handleDateChange = (newValue: any) => {
        api.setEditCellValue({ id, field, value: newValue });
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                value={value || null}
                onChange={handleDateChange}
                slots={{
                    textField: (textFieldProps) => <TextField {...textFieldProps} />
                }}
            />
        </LocalizationProvider>
    );
};

export default function BasicTable() {
    const [purchaseData, setPurchaseData] = useState<any[]>([]);
    const [brandMasters, setBrandMaster] = useState<Brand[]>([]);
    const [processorBrands, setProcessorBrands] = useState<ProcessorBrand[]>([]);
    const [seriesByBrands, setSeriesByBrands] = useState<Brand[]>([]);
    const [processorSeries, setProcessorSeries] = useState<ProcessorBrand[]>([]);
    const [processorGenerations, setProcessorGeneration] = useState<ProcessorBrand[]>([]);
    const [processorGigaHeartzs, setProcessorGigaHeartz] = useState<ProcessorBrand[]>([]);
    const [graphicBrands, setGraphicBrand] = useState<ProcessorBrand[]>([]);
    const [graphicSeries, setGraphicSeries] = useState<ProcessorBrand[]>([]);
    const [graphicModels, setGraphicModel] = useState<ProcessorBrand[]>([]);
    const [graphicSizes, setGraphicSize] = useState<ProcessorBrand[]>([]);
    const [screenSizes, setScreenSize] = useState<ProcessorBrand[]>([]);
    const [screenResolutions, setScreenResolution] = useState<ProcessorBrand[]>([]);
    const [screenSpots, setScreenSpot] = useState<ProcessorBrand[]>([]);
    const [rams, setram] = useState<ProcessorBrand[]>([]);
    const [hardDisks, setHardDisk] = useState<ProcessorBrand[]>([]);
    const [externalGraphics, setExternalGraphics] = useState<ProcessorBrand[]>([]);
    const [ssds, setSSD] = useState<ProcessorBrand[]>([]);
    const [ageOfLaptops, setAgeOfLaptop] = useState<ProcessorBrand[]>([]);
    const [physicalConditions, setPhysicalConditions] = useState<ProcessorBrand[]>([]);
    const [functionalConditions, setfunctionalCondition] = useState<ProcessorBrand[]>([]);
    const [employees, setEmployees] = useState<ProcessorBrand[]>([]);
    const [rows, setRows] = useState<any[]>([]);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [totalRows, setTotalRows] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 15,
    });
    const navigate = useNavigate();
    const auth = useSelector((state: RootState) => state.auth);
    const [updatingLsNo, setUpdatingLsNo] = useState(false);
    const [submittedAlready, setSubmittedAlready] = useState(true);
    const [lsNoTemp, setLsNoTemp] = useState("");
    const [isAdmin, setIsAdmin] = useState();
    const [editingRows, setEditingRows] = useState<Set<GridRowId>>(new Set());

    const fetchData = async (page: number, pageSize: number) => {
        setIsLoading(true);
        try {
            const response = await http.get(`/purchase?page=${page + 1}&itemsPerPage=${pageSize}`);
            setPurchaseData(response.data.purchases);
            setTotalRows(response.data.totalItems);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        (async () => {
            //fetch user to check if he's admin or not
            const user: any = await http.get("auth/me");
            setIsAdmin(user.data.result.isAdmin);

            const data: any = await http.get("/purchase/specs");

            const result = data.data.result;
            setBrandMaster(result.brandMasters);
            setProcessorBrands(result.processorBrands);
            setProcessorGeneration(result.processorGenerations);
            setProcessorGigaHeartz(result.processorGigaHeartzs);
            setGraphicBrand(result.graphicBrands);
            setGraphicSeries(result.graphicSeries);
            setGraphicModel(result.graphicModels);
            setGraphicSize(result.graphicSizes);
            setScreenSize(result.screenSizes);
            setScreenResolution(result.screenResolutions);
            setScreenSpot(result.screenSpots);
            setEmployees(result.employees);
            setram(result.rams);
            setHardDisk(result.hardDisks);
            setExternalGraphics(result.externalGraphics);
            setSSD(result.ssds);
            setAgeOfLaptop(result.ageOfLaptops);
            setPhysicalConditions(result.physicalConditions);
            setfunctionalCondition(result.functionalConditions);
        })();
    }, []);


    useEffect(() => {
        (async function () {
            const seriesDataByBrands: any = await http.get(
                "/purchaseReports/series/" + purchaseData[0]?.brand_master_id
            );
            setSeriesByBrands(seriesDataByBrands.data.result);
        })();
    }, [brandMasters, purchaseData]);

    useEffect(() => {
        (async function () {
            const ProseriesDataByBrands: any = await http.get(
                "/purchaseReports/processor-series/" + purchaseData[0]?.processor_brand_id
            );
            setProcessorSeries(ProseriesDataByBrands.data.result);
        })();
    }, [processorBrands, purchaseData]);

    useEffect(() => {
        fetchData(paginationModel.page, paginationModel.pageSize);
    }, [paginationModel]);

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason == GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

   const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    setEditingRows(new Set(editingRows).add(id));
};

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleCancelClick = (id: GridRowId) => () => {

        setRowModesModel({

            ...rowModesModel,

            [id]: { mode: GridRowModes.View, ignoreModifications: true },

        });

    

        // Remove the row from editingRows if it exists

        setEditingRows(prev => {

            const newSet = new Set(prev);

            newSet.delete(id);

            return newSet;

        });

    

        // Reset the submittedAlready state

        setSubmittedAlready(false);

    

        const editedRow = rows.find((row) => row.id == id);

        if (editedRow?.isNew) {

            setRows(rows.filter((row) => row.id !== id));

        }

    };


    const handleShowDetail = (id: GridRowId) => () => {
        handleCancelClick(id);
        navigate(`/edit-purchase/${id}`);
    };
    
    const processRowUpdate = async (newRow: GridRowModel) => {
        const updatedRow = { ...newRow, isNew: false, submitted_to_engineer: true };
        setPurchaseData(purchaseData.map((row) => (row.id === newRow.id ? updatedRow : row)));
      
        try {
          const response = await http.post(`/PurchaseReportToEngineer`, updatedRow);
          toast.success("Data updated successfully");
        } catch (error: any) {
          console.error("Error updating data:", error.message);
        }
        
        return updatedRow;
      };
      
const handleRowModesModelChange = async (newRowModesModel: GridRowModesModel) => {    
    setRowModesModel(newRowModesModel);
};

const getRowClassName = (params: GridRowParams) => {
    return params.row.submitted_to_engineer ? 'bg-green-300' : '';
  };

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'product_type',
            headerName: 'Product Type',
            width: 150,
            valueFormatter: ({ value }) => (value ? "On Purchase" : "On Approval")
        },
        { field: 'ls_no', headerName: 'LS No', width: 130, editable: true },
        { field: 'price', headerName: 'Price', width: 130, renderCell: (params) => `₹${params.value}`, editable: true },
        { field: 'shelf_no', headerName: 'Shelf No', width: 130, editable: true },
        {
            field: 'date',
            headerName: 'Arrival Date',
            width: 150,
            renderEditCell: (params) => <DateEditComponent {...params} />,
            editable: true,
        },
        {
            field: 'brand_master_id',
            headerName: 'Brand',
            width: 150,
            editable: isAdmin,
            type: 'singleSelect',
            valueOptions: brandMasters.map((brand) => ({
                value: brand.id,
                label: brand.name,
            })),
            valueFormatter: (params: GridRenderCellParams) => {
                const brand = brandMasters.find((b:any) => b.id == params);
                return brand ? brand.name : '';
            },
        },
        {
            field: 'series_master_id',
            headerName: 'Series',
            width: 150,
            editable: true,
            type: 'singleSelect',
            valueOptions: seriesByBrands.map((series: any) => ({
                value: series.id,   
                label: series.name, 
            })),
            valueFormatter: (params: GridRenderCellParams) => {
                const series = seriesByBrands.find((brand:any) => brand.id == params);
                return series ? series.name : '';
            },
        },
        {
            field: 'processor_brand_id',
            headerName: 'Processor Brand',
            width: 150,
            editable: true,
            type: 'singleSelect',
            valueOptions: processorBrands.map((processorBrand) => ({
                value: processorBrand.id,
                label: processorBrand.name,
            })),
            valueFormatter: (params: GridRenderCellParams) => {
                const processorBrand = processorBrands.find((pb:any) => pb.id == params);
                return processorBrand ? processorBrand.name : '';
            },
        },
        {
            field: 'processor_series_id',
            headerName: 'Processor Series',
            width: 150,
            editable: true,
            type: 'singleSelect',
            valueOptions: processorSeries.map((series) => ({
                value: series.id,
                label: series.name,
            })),
            valueFormatter: (params: GridRenderCellParams) => {
                const series = processorSeries.find((s:any) => s.id == params);
                return series ? series.name : '';
            },
        },
        {
            field: 'processor_generation_id',
            headerName: 'Processor Generation',
            width: 150,
            editable: true,
            type: 'singleSelect',
            valueOptions: processorGenerations.map((generation) => ({
                value: generation.id,
                label: generation.name,
            })),
            valueFormatter: (params: GridRenderCellParams) => {
                const generation = processorGenerations.find((g:any) => g.id == params);
                return generation ? generation.name : '';
            },
        },
        {
            field: 'ram_id',
            headerName: 'RAM',
            width: 150,
            editable: true,
            type: 'singleSelect',
            valueOptions: rams.map((ram) => ({
                value: ram.id,
                label: ram.name,
            })),
            renderCell: (params: GridRenderCellParams) => {
                const ram = rams.find((r) => r.id == params.row.ram_id);
                return ram ? ram.name : '';
            },
        },
        {
            field: 'hard_disk_id',
            headerName: 'Hard Disk',
            width: 150,
            editable: true,
            type: 'singleSelect',
            valueOptions: hardDisks.map((hardDisk) => ({
                value: hardDisk.id,
                label: hardDisk.name,
            })),
            renderCell: (params: GridRenderCellParams) => {
                const hardDisk = hardDisks.find((h) => h.id == params.row.hard_disk_id);
                return hardDisk ? hardDisk.name : '';
            },
        },
        {
            field: 'ssd_id',
            headerName: 'SSD',
            width: 150,
            editable: true,
            type: 'singleSelect',
            valueOptions: ssds.map((ssd) => ({
                value: ssd.id,
                label: ssd.name,
            })),
            renderCell: (params: GridRenderCellParams) => {
                const ssd = ssds.find((s) => s.id == params.row.ssd_id);
                return ssd ? ssd.name : '';
            },
        },
        {
            field: 'screen_size_id',
            headerName: 'Screen Size',
            width: 150,
            editable: true,
            type: 'singleSelect',
            valueOptions: screenSizes.map((size) => ({
                value: size.id,
                label: size.name,
            })),
            renderCell: (params: GridRenderCellParams) => {
                const size = screenSizes.find((s) => s.id == params.row.screen_size_id);
                return size ? size.name : '';
            },
        },
        {
            field: 'screen_resolution_id',
            headerName: 'Screen Resolution',
            width: 150,
            editable: true,
            type: 'singleSelect',
            valueOptions: screenResolutions.map((resolution) => ({
                value: resolution.id,
                label: resolution.name,
            })),
            renderCell: (params: GridRenderCellParams) => {
                const resolution = screenResolutions.find((r) => r.id == params.row.screen_resolution_id);
                return resolution ? resolution.name : '';
            },
        },
        {
            field: 'screen_spot_id',
            headerName: 'Screen Spot',
            width: 150,
            editable: true,
            type: 'singleSelect',
            valueOptions: screenSpots.map((spot) => ({
                value: spot.id,
                label: spot.name,
            })),
            renderCell: (params: GridRenderCellParams) => {
                const spot = screenSpots.find((s) => s.id.toString() == params.row.screen_spot_any_id);
                return spot ? spot.name : '';
            },
        },
        {
            field: 'physical_condition_id',
            headerName: 'Physical Condition',
            width: 150,
            editable: true,
            type: 'singleSelect',
            valueOptions: physicalConditions.map((condition) => ({
                value: condition.id,
                label: condition.name,
            })),
            renderCell: (params: GridRenderCellParams) => {
                const condition = physicalConditions.find((c) => c.id == params.row.physical_condition_id);
                return condition ? condition.name : '';
            },
        },
        {
            field: 'functional_condition_id',
            headerName: 'Functional Condition',
            width: 150,
            editable: true,
            type: 'singleSelect',
            valueOptions: functionalConditions.map((condition) => ({
                value: condition.id,
                label: condition.name,
            })),
            renderCell: (params: GridRenderCellParams) => {
                console.log(params.row)
                const condition = functionalConditions.find((c) => c.id == params.row.functional_condition_id);
                return condition ? condition.name : '';
            },
        },
        {
            field: 'age_of_laptop_id',
            headerName: 'Age of Laptop',
            width: 150,
            editable: true,
            type: 'singleSelect',
            valueOptions: ageOfLaptops.map((age) => ({
                value: age.id,
                label: age.name,
            })),
            renderCell: (params: GridRenderCellParams) => {
                const age = ageOfLaptops.find((a) => a.id == params.row.age_of_laptop_id);
                return age ? age.name : '';
            },
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 180, // Increased width to accommodate the button
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode == GridRowModes.Edit;
                
                if (isInEditMode) {
                    return [
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={handleSaveClick(id)}
                            style={{ fontSize: '0.7rem', padding: '2px 8px' }}
                        >
                            Submit to Engineer
                        </Button>,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />
                    ];
                }
            
                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<ArticleIcon />}
                        label="Detail"
                        onClick={handleShowDetail(id)}
                        color="inherit"
                    />
                ];
            }
            
        }
        
    ];

    const handleProcessRowUpdateError = (error: any) => {
        toast.error("Error updating data:", error);
        console.error("Error updating data:", error);
    };

    return (
        <div className="max-h-screen overflow-hidded">
            <PageTitleCard title="Admin Purchase Tasks"></PageTitleCard>
            <div className="w-full h-[700px]" >
                <DataGrid
                    rows={purchaseData}
                    columns={columns}
                    editMode="row"
                    getRowClassName={getRowClassName}
                    isCellEditable={(params) => !params.row.submitted_to_engineer}
                    paginationMode="server"
                    rowCount={totalRows}
                    loading={isLoading}
                    pageSizeOptions={[5, 10, 25, 50]}
                    paginationModel={paginationModel}
                    onPaginationModelChange={(model) => setPaginationModel(model)}
                    getRowId={(row) => row.id || row.ls_no}
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    onProcessRowUpdateError={handleProcessRowUpdateError}
                    className="text-sm"
                    sx={{
                        '& .MuiDataGrid-root': {
                            fontSize: '0.8rem', 
                        },
                        '& .MuiDataGrid-row': {
                            maxHeight: '42px !important', 
                            minHeight: '42px !important',
                        },
                    }}
                />
            </div>
        </div>
    );
}
