import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { http } from "../../utils/http";

export const initUser = createAsyncThunk("auth/init", async () => {
    const response = await http.get("/auth/me");
    return response.data;
});

export interface AuthState {
    authenticated: boolean;
    user: any;
    authInitialized: boolean;
}

const initialState: AuthState = {
    authenticated: false,
    user: null,
    authInitialized: false,
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setAuth: (state, action: PayloadAction<{ user: any; token: any }>) => {
            localStorage.setItem("AUTH_TOKEN", action.payload.token);
            state.user = action.payload.user;
            state.authenticated = true;
        },
        logout: (state) => {
            localStorage.setItem("AUTH_TOKEN", "");
            state.user = null;
            state.authenticated = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(initUser.fulfilled, (state, action) => {
            state.user = action.payload.result;
            state.authenticated = true;
            state.authInitialized = true;
        });
        builder.addCase(initUser.rejected, (state) => {
            state.user = null;
            state.authenticated = false;
            state.authInitialized = true;
        });
    },
});

export const { setAuth, logout } = authSlice.actions;

export default authSlice.reducer;
