import MenuItem from "@mui/material/MenuItem";
import { useState, useEffect } from "react";
import { http } from "../../utils/http";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useNavigate } from "react-router-dom";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import {
  FormGroup,
  FormControl,
  InputLabel,
  Input,
  Typography,
  Grid,
  Button,
  styled,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";
import { width } from "@mui/system";

const Container = styled(FormGroup)`
  width: 100%;
`;
{
  /* <Typography variant="h4">Add New Employee</Typography> */
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function createData(name: string) {
  return { name };
}

const rows = [
  createData("All Employees"),
  createData("Attendance"),
  createData("CheckInOuts"),
  createData("Reports"),
  createData("Admin Report"),
  createData("Engineer Report"),
  createData("All Reports"),
  createData("Designations"),
  createData("Settings"),
];
const initialValues = { name: "" };

const AddDesignation = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(initialValues);

  const onValueChange = (e: any) => {
    e.preventDefault(e);
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const addUserDetail = async (e: any) => {
    e.preventDefault();
    try {
      await http.post("/designations", user);
      navigate("/designations");
      return;
    } catch (error: any) {
      console.log("error while calling api", error.message);
    }
  };

  return (
    <div>
      <form onSubmit={addUserDetail}>
        <FormGroup>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <FormControl sx={{ width: "50%" }}>
                <FormHelperText>
                  <h3>Designation Name</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  placeholder="Please enter text"
                  onChange={(e) => onValueChange(e)}
                  name="name"
                  required
                  size="small"
                  sx={{ background: "white",mb:2 }}
                />

                {/* <InputLabel >Name</InputLabel> */}
                {/* <Input /> */}
              </FormControl>
            </Grid>
            <Grid item xs={6}></Grid>
            {/* <Grid item xs={6}>
              <h3>
                Create Allowed
                <Checkbox
                  onChange={(e) => onValueChange(e)}
                  name="create_allowed"
                  value="1"
                />
              </h3>{" "}
            </Grid>
            <Grid item xs={6}>
              <h3>
                Edit Allowed{" "}
                <Checkbox
                  onChange={(e) => onValueChange(e)}
                  name="edit_allowed"
                  value="1"
                />
              </h3>
            </Grid>
            <Grid item xs={6}>
              <h3>
                Update Allowed{" "}
                <Checkbox
                  onChange={(e) => onValueChange(e)}
                  name="update_allowed"
                  value="1"
                />
              </h3>
            </Grid>
            <Grid item xs={6}>
              <h3>
                Delete Allowed{" "}
                <Checkbox
                  onChange={(e) => onValueChange(e)}
                  name="delete_allowed"
                  value="1"
                />
              </h3>
            </Grid> */}
          </Grid>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Create</TableCell>
                  <TableCell>Edit</TableCell>
                  <TableCell>Update</TableCell>
                  <TableCell>Delete</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    All Employees{" "}
                  </TableCell>

                  <TableCell>
                  
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="create_allowed"
                      value="1"
                    />
                  </TableCell>

                  <TableCell>
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="edit_allowed"
                      value="1"
                    />
                  </TableCell>

                  <TableCell>
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="update_allowed"
                      value="1"
                    />
                  </TableCell>

                  <TableCell>
                    {" "}
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="delete_allowed"
                      value="1"
                    />
                  </TableCell>

                  <TableCell>None</TableCell>
                </TableRow>

                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Attendance{" "}
                  </TableCell>
                  <TableCell>
                  
                  <Checkbox
                    onChange={(e) => onValueChange(e)}
                    name="create_allowed"
                    value="1"
                  />
                </TableCell>
                  <TableCell>
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="edit_allowed"
                      value="1"
                    />
                  </TableCell>

                  <TableCell>
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="update_allowed"
                      value="1"
                    />
                  </TableCell>

                  <TableCell>
                   
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="delete_allowed"
                      value="1"
                    />
                  </TableCell>
                  <TableCell>None</TableCell>
                </TableRow>

                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    CheckInOuts{" "}
                  </TableCell>
                  <TableCell>
                  
                  <Checkbox
                    onChange={(e) => onValueChange(e)}
                    name="create_allowed"
                    value="1"
                  />
                </TableCell>
                  <TableCell>
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="edit_allowed"
                      value="1"
                    />
                  </TableCell>

                  <TableCell>
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="update_allowed"
                      value="1"
                    />
                  </TableCell>

                  <TableCell>
                    {" "}
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="delete_allowed"
                      value="1"
                    />
                  </TableCell>
                  <TableCell>None</TableCell>
                </TableRow>

                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Reports{" "}
                  </TableCell>
                  <TableCell>
                  
                  <Checkbox
                    onChange={(e) => onValueChange(e)}
                    name="create_allowed"
                    value="1"
                  />
                </TableCell>
                  <TableCell>
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="edit_allowed"
                      value="1"
                    />
                  </TableCell>

                  <TableCell>
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="update_allowed"
                      value="1"
                    />
                  </TableCell>

                  <TableCell>
                    {" "}
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="delete_allowed"
                      value="1"
                    />
                  </TableCell>
                  <TableCell>None</TableCell>
                </TableRow>

                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Purchase
                  </TableCell>
                  <TableCell>
                  
                  <Checkbox
                    onChange={(e) => onValueChange(e)}
                    name="create_allowed"
                    value="1"
                  />
                </TableCell>
                  <TableCell>
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="edit_allowed"
                      value="1"
                    />
                  </TableCell>

                  <TableCell>
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="update_allowed"
                      value="1"
                    />
                  </TableCell>

                  <TableCell>
                    {" "}
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="delete_allowed"
                      value="1"
                    />
                  </TableCell>
                  <TableCell>None</TableCell>
                </TableRow>

                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Admin Report
                  </TableCell>
                  <TableCell>
                  
                  <Checkbox
                    onChange={(e) => onValueChange(e)}
                    name="create_allowed"
                    value="1"
                  />
                </TableCell>
                  <TableCell>
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="edit_allowed"
                      value="1"
                    />
                  </TableCell>

                  <TableCell>
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="update_allowed"
                      value="1"
                    />
                  </TableCell>

                  <TableCell>
                    {" "}
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="delete_allowed"
                      value="1"
                    />
                  </TableCell>
                  <TableCell>None</TableCell>
                </TableRow>

                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Engineer Report
                  </TableCell>
                  <TableCell>
                  
                  <Checkbox
                    onChange={(e) => onValueChange(e)}
                    name="create_allowed"
                    value="1"
                  />
                </TableCell>
                  <TableCell>
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="edit_allowed"
                      value="1"
                    />
                  </TableCell>

                  <TableCell>
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="update_allowed"
                      value="1"
                    />
                  </TableCell>

                  <TableCell>
                    {" "}
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="delete_allowed"
                      value="1"
                    />
                  </TableCell>
                  <TableCell>None</TableCell>
                </TableRow>

                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    All Reports
                  </TableCell>
                  <TableCell>
                  
                  <Checkbox
                    onChange={(e) => onValueChange(e)}
                    name="create_allowed"
                    value="1"
                  />
                </TableCell>
                  <TableCell>
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="edit_allowed"
                      value="1"
                    />
                  </TableCell>

                  <TableCell>
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="update_allowed"
                      value="1"
                    />
                  </TableCell>

                  <TableCell>
                    {" "}
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="delete_allowed"
                      value="1"
                    />
                  </TableCell>
                  <TableCell>None</TableCell>
                </TableRow>

                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Designations
                  </TableCell>
                  <TableCell>
                  
                  <Checkbox
                    onChange={(e) => onValueChange(e)}
                    name="create_allowed"
                    value="1"
                  />
                </TableCell>
                  <TableCell>
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="edit_allowed"
                      value="1"
                    />
                  </TableCell>

                  <TableCell>
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="update_allowed"
                      value="1"
                    />
                  </TableCell>

                  <TableCell>
                    {" "}
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="delete_allowed"
                      value="1"
                    />
                  </TableCell>
                  <TableCell>None</TableCell>
                </TableRow>

                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Settings
                  </TableCell>
                  <TableCell>
                  
                  <Checkbox
                    onChange={(e) => onValueChange(e)}
                    name="create_allowed"
                    value="1"
                  />
                </TableCell>
                  <TableCell>
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="edit_allowed"
                      value="1"
                    />
                  </TableCell>

                  <TableCell>
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="update_allowed"
                      value="1"
                    />
                  </TableCell>

                  <TableCell>
                    {" "}
                    <Checkbox
                      onChange={(e) => onValueChange(e)}
                      name="delete_allowed"
                      value="1"
                    />
                  </TableCell>
                  <TableCell>None</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <FormControl sx={{ my: 4 }}>
            <Button variant="contained" sx={{ maxWidth: 200 }} type="submit">
              Add
            </Button>
          </FormControl>
        </FormGroup>
      </form>
    </div>
  );
};
export default AddDesignation;
