import * as React from "react"
import Dashboard from "../components/layout/dashboard"
import { setAuth, AuthState } from "../redux/auth/authSlice"
import { useSelector } from "react-redux"
import { RootState } from "../redux/store"

export default function BasicCard() {
    const auth: AuthState = useSelector((state: RootState) => state.auth)
    if (auth.authenticated) {
        //  console.log("logged in user FirstName",auth.user.firstName)
        //  console.log("logged in user Last Name",auth.user)
    }
    return (
        <div>
            <Dashboard />
        </div>
    )
}
