import { Typography, Stack, Box, Button } from "@mui/material"
import { useState, useEffect } from "react"
import { CustomeModal } from "./customeModal"

export default function FormRow({
    label,
    onValueChange,
    formName,
    formValue = false,
    items = [],
}: any) {
    const [isActive, setIsActive] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen)
    }

    const handleClick = () => {
        setIsActive(!isActive) // Toggle isActive state

        onValueChange({ target: { name: formName, value: 0 } }, formName)
    }

    return (
        <Box sx={{ background: "#fff", py: "2px", width: "100%", mb: 2, borderRadius: 3 }}>
            <Stack direction="column" sx={{ width: "100%" }} alignItems="center">
                <Box sx={{ width: 120 }}>
                    <Typography sx={{ fontSize: "0.75rem" }}>{label}</Typography>
                </Box>
                <Box sx={{ display: "flex", gap: 1 }}>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={(e) => {
                            setIsActive(false)
                            onValueChange({ target: { name: formName, value: 1 } }, formName)
                        }}
                        sx={{
                            background: formValue == 1 ? "green" : "grey",
                            fontSize: 8,
                            ml: 0,
                            mr: 1,
                        }}
                    >
                        Working
                    </Button>
                    {!isActive && (
                        <Button
                            variant="contained"
                            size="small"
                            onClick={handleClick}
                            sx={{
                                backgroundColor: formValue == 1 ? "grey" : "red",
                                fontSize: "0.75rem",
                                "&:hover": {
                                    backgroundColor: "darkgreen",
                                },
                            }}
                        >
                            Problem
                        </Button>
                    )}
                    {isActive && (
                        <>
                            <Button
                                variant="contained"
                                size="small"
                                onClick={toggleModal}
                                sx={{
                                    backgroundColor: "red",
                                    fontSize: "0.55rem",
                                    "&:hover": {
                                        backgroundColor: "darkred",
                                    },
                                }}
                            >
                                Part Order
                            </Button>
                            <Button
                                variant="contained"
                                size="small"
                                onClick={handleClick} // Assuming you want to toggle back
                                sx={{
                                    backgroundColor: "red",
                                    fontSize: "0.55rem",
                                    "&:hover": {
                                        backgroundColor: "darkred",
                                    },
                                }}
                            >
                                Not Available
                            </Button>
                        </>
                    )}
                </Box>
            </Stack>
            <CustomeModal isOpen={isModalOpen} onClose={toggleModal} linkName={formName} />
        </Box>
    )
}
