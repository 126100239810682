import { useState, useEffect } from "react"
import { http, serverImgUrl, localImgUrl } from "../../utils/http"
import { useParams } from "react-router-dom"
import Hidden from "@mui/material/Hidden"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import TextareaAutosize from "@mui/material/TextareaAutosize"
import MenuItem from "@mui/material/MenuItem"
import ImageList from "@mui/material/ImageList"
import ImageListItem from "@mui/material/ImageListItem"
import CardMedia from "@mui/material/CardMedia"
import Zoom from "react-medium-image-zoom"

import notfoudound from "/public/images/not-found.png"
import { useNavigate } from "react-router-dom"

import {
    FormGroup,
    FormControl,
    Grid,
    Button,
    styled,
    OutlinedInput,
    FormHelperText,
    TextField,
    Typography,
    FormLabel,
    Card,
    Box,
} from "@mui/material"
import { width } from "@mui/system"

const Container = styled(FormGroup)`
    width: 100%;
`
{
    /* <Typography variant="h4">Add New Employee</Typography> */
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const initialValues = {
    id: "",
    sold_status: false,
    product_type: false,
    purchase_engineer_id: "",
    processor_id: "",
    processor_image: "",
    ram_image: "",
    general_image: "",
    hard_disk_image: "",
    ssd_image: "",
    external_graphic_image: "",
    screen_size_image: "",
    age_of_laptop_image: "",
    physical_condition_image: "",
    functional_condition_image: "",
    ls_no: "",
    ram_id: "",
    hard_disk_id: "",
    ssd_id: "",
    external_graphics_id: "",
    screen_size_id: "",
    age_of_laptop_id: "",
    physical_condition_id: "",
    functional_condition_id: "",
    date: "",
    sales_price: "",
    all_ok_type: "",
    vendor_name: "",
    shelf_no: "",
    pickup_person: "",
    charger_type: "",
    problem: "",
    payment_mode: false,
    description: "",
    payment_type: "",
    part_price: 0,
    price: "",
}

const AddSell = () => {
    const [processors, setProcessor] = useState([])
    const [rams, setram] = useState([])
    const [hardDisks, setHardDisk] = useState([])
    const [externalGraphics, setExternalGraphics] = useState([])
    const [ssds, setSSD] = useState([])
    const [screenSizes, setScreenSize] = useState([])
    const [ageOfLaptops, setAgeOfLaptop] = useState([])
    const [physicalConditions, setPhysicalConditions] = useState([])
    const [functionalConditions, setfunctionalCondition] = useState([])

    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/processor")
            setProcessor(data.data.result)
        })()
    }, [])
    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/ram")
            setram(data.data.result)
        })()
    }, [])

    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/hard-disk")
            setHardDisk(data.data.result)
        })()
    }, [])

    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/external-graphics")
            setExternalGraphics(data.data.result)
        })()
    }, [])

    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/ssd")
            setSSD(data.data.result)
        })()
    }, [])

    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/screen-size")
            setScreenSize(data.data.result)
        })()
    }, [])

    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/age-of-laptop")
            setAgeOfLaptop(data.data.result)
        })()
    }, [])

    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/physical-condition")
            setPhysicalConditions(data.data.result)
        })()
    }, [])
    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/functional-condition")
            setfunctionalCondition(data.data.result)
        })()
    }, [])

    let params = useParams()
    const navigate = useNavigate()

    const [data, setPurchase] = useState({
        ...initialValues,
        purchase_engineer_id: params.id,
    })

    useEffect(() => {
        ;(async function () {
            const salesDataRes = await http.get("/purchase-sales/" + params.id)
            setPurchase(salesDataRes.data.result)
            console.log("sales data", salesDataRes)
        })()
    }, [])

    const onValueChange = (e: any) => {
        e.preventDefault(e)

        if (e.target.type === "file") {
            setPurchase({ ...data, [e.target.name]: e.target.files[0] })
        } else {
            setPurchase({ ...data, [e.target.name]: e.target.value })
        }

        // console.log("target namee", e.target.name, "is", e.target.value);
    }
    // const addSell = async (e: any) => {
    //   e.preventDefault();
    //   (async function () {
    //     let dataCopy: any = data;
    //     const formData: any = new FormData();

    //     for (let itemKey in dataCopy) {
    //       formData.append(itemKey, dataCopy[itemKey]);
    //     }
    //     // console.log("Form data for sales", formData);
    //     await http.post("/purchase-sales", data);
    //     navigate("/sell");
    //     // success("You've posted your form!");
    //     return;
    //   })();
    // };
    const salesId = params.id

    useEffect(() => {
        (async () => {
            const endpoint = "/processor";
            console.log(`Fetching data from ${endpoint}`);
            try {
                const data = await http.get(endpoint);
                setProcessor(data.data.result);
            } catch (error) {
                console.error(`Error fetching data from ${endpoint}:`, error);
            }
        })();
    }, []);

    
    const updateSalesPrice = async (e: any) => {
        e.preventDefault()
        ;(async function () {
            console.log("Params ID", data.id)
            console.log("Data of sales", data)
            let dataCopy: any = data
            const formData: any = new FormData()

            for (let itemKey in dataCopy) {
                formData.append(itemKey, dataCopy[itemKey])
            }
            await http.patch(`/purchase-sales/${salesId}`, data)
            navigate("/sells")
            // success("You've posted your form!");
            return
        })()
    }

    return (
        <div className="rounded ring-0 drop-shadow-md mx-auto p-5 bg-[#b9f6ca]">
            <h2 className="mb-6 text-xl font-bold">Enginner Data ID: {params.id}</h2>

            <form onSubmit={updateSalesPrice} className="text-black">
                <FormGroup>
                    <Grid container spacing={1}>
                        {/* <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3 className="text-lg text-black">Image test.</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                      sx={{
        background: "#69f0ae",
        "&.Mui-disabled": {
          color: "rgba(0, 0, 0, 0.87)", 
          WebkitTextFillColor: "rgba(0, 0, 0, 0.87)", 
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none", 
          },
        },
      }}
                
                  onChange={(e) => onValueChange(e)}
                  name="ram_image"
                //  value={data.ram_image}
                  type="file"
                />
              </FormControl>
            </Grid> */}
                        {/* <Grid item xs={6}>
<TextField
  name="purchase_engineer_id"
  value={data.id}
  
  disabled
/>


</Grid> */}

                        <Grid item xs={6}>
                            <FormControl variant="outlined" fullWidth>
                                <FormHelperText>
                                    <h3 className="text-lg text-black">Purchase Engineer ID</h3>
                                </FormHelperText>
                                <OutlinedInput
                                    sx={{
                                        width: "80%",
                                        background: "#69f0ae",
                                        "&.Mui-disabled": {
                                            color: "rgba(0, 0, 0)",
                                            WebkitTextFillColor: "rgba(0, 0, 0, 0.87)",
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                border: "none",
                                            },
                                        },
                                    }}
                                    value={data.id}
                                    name="purchase_engineer_id"
                                    disabled
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl variant="outlined" fullWidth sx={{ width: "80%" }}>
                                <FormHelperText>
                                    <h3 className="text-lg text-black">LS No </h3>
                                </FormHelperText>
                                <OutlinedInput
                                    sx={{
                                        background: "#69f0ae",
                                        "&.Mui-disabled": {
                                            color: "rgba(0, 0, 0, 0.87)",
                                            WebkitTextFillColor: "rgba(0, 0, 0, 0.87)",
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                border: "none",
                                            },
                                        },
                                    }}
                                    value={data.ls_no}
                                    name="ls_no"
                                    disabled
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">
                                    <h3 className="text-lg text-black">Purchase Type</h3>
                                </FormLabel>
                                <RadioGroup
                                    row
                                    name="product_type"
                                    value={data.product_type ? "1" : "0"}
                                    onChange={(e) => onValueChange(e)}
                                    sx={{
                                        "& .MuiFormControlLabel-root": {
                                            marginRight: "30px",
                                        },
                                        "& .MuiTypography-root": {
                                            fontSize: "1rem",
                                        },
                                        "& .Mui-disabled": {
                                            color: "rgba(0, 0, 0, 0.7)",
                                            "& .MuiSvgIcon-root": {
                                                color: "rgba(0, 0, 0, 0.7)",
                                            },
                                        },
                                    }}
                                >
                                    <FormControlLabel
                                        value="0"
                                        control={<Radio />}
                                        label="On Approval"
                                        disabled
                                    />
                                    <FormControlLabel
                                        value="1"
                                        control={<Radio />}
                                        label="On Purchase"
                                        disabled
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth sx={{ width: "80%", margin: "auto" }}>
                                <FormLabel component="legend" sx={{ marginBottom: 2 }}>
                                    <Typography
                                        variant="h6"
                                        component="div"
                                        sx={{ fontWeight: "medium" }}
                                    >
                                        General Image
                                    </Typography>
                                </FormLabel>
                                <Zoom>
                                    <Card sx={{ maxWidth: 245, boxShadow: 3 }}>
                                        <CardMedia
                                            component="img"
                                            image={serverImgUrl + data.general_image}
                                            alt="General Image"
                                            sx={{
                                                height: "auto",
                                                maxWidth: "100%",
                                                borderRadius: "4px",
                                            }}
                                        />
                                    </Card>
                                </Zoom>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl variant="outlined" fullWidth sx={{ width: "80%" }}>
                                <FormLabel component="legend">
                                    <Typography variant="h6" component="h3"></Typography>
                                    <h3 className="text-lg text-black"> Processor </h3>
                                </FormLabel>
                                <Select
                                    sx={{
                                        background: "#69f0ae",
                                        "&.Mui-disabled": {
                                            color: "rgba(0, 0, 0, 0.87)", // Text color
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                border: "none", // Remove border
                                            },
                                        },
                                    }}
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    name="processor_id"
                                    value={data.processor_id}
                                    onChange={(e) => onValueChange(e)}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                width: 250,
                                            },
                                        },
                                    }}
                                    disabled
                                >
                                    {processors.map((processor: any) => (
                                        <MenuItem key={processor.id} value={processor.id}>
                                            {processor.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth sx={{ width: "80%", margin: "auto" }}>
                                <FormLabel component="legend" sx={{ marginBottom: 2 }}>
                                    <Typography
                                        variant="h6"
                                        component="div"
                                        sx={{ fontWeight: "medium" }}
                                    >
                                        Processor Image
                                    </Typography>
                                </FormLabel>
                                <Zoom>
                                    <Card sx={{ maxWidth: 245, boxShadow: 3 }}>
                                        <CardMedia
                                            component="img"
                                            image={
                                                data.processor_image
                                                    ? serverImgUrl + data.processor_image
                                                    : "/public/images/not-found.png"
                                            }
                                            alt="Processor"
                                            sx={{
                                                height: "auto",
                                                maxWidth: "100%",
                                                borderRadius: "4px",
                                            }}
                                        />
                                    </Card>
                                </Zoom>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl sx={{ width: "80%" }}>
                                <FormHelperText>
                                    <h3 className="text-lg text-black"> Ram</h3>{" "}
                                </FormHelperText>
                                <Select
                                    sx={{
                                        background: "#69f0ae",
                                        "&.Mui-disabled": {
                                            color: "rgba(0, 0, 0, 0.87)",
                                            WebkitTextFillColor: "rgba(0, 0, 0, 0.87)",
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                border: "none",
                                            },
                                        },
                                    }}
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    name="ram_id"
                                    value={data.ram_id}
                                    onChange={(e) => onValueChange(e)}
                                    disabled
                                    MenuProps={MenuProps}
                                >
                                    {rams.map((ram: any) => (
                                        <MenuItem key={ram.name} value={ram.id}>
                                            {ram.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth sx={{ width: "80%", margin: "auto" }}>
                                <FormLabel component="legend" sx={{ marginBottom: 2 }}>
                                    <Typography
                                        variant="h6"
                                        component="div"
                                        sx={{ fontWeight: "medium" }}
                                    >
                                        Ram Image
                                    </Typography>
                                </FormLabel>
                                <Zoom>
                                    <Card sx={{ maxWidth: 245, boxShadow: 3 }}>
                                        <CardMedia
                                            component="img"
                                            image={
                                                data.ram_image
                                                    ? serverImgUrl + data.ram_image
                                                    : "/public/images/not-found.png"
                                            }
                                            alt="Ram"
                                            sx={{
                                                height: "auto",
                                                maxWidth: "100%",
                                                borderRadius: "4px",
                                            }}
                                        />
                                    </Card>
                                </Zoom>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl sx={{ width: "80%" }}>
                                <FormHelperText>
                                    <h3 className="text-lg text-black"> Hard Disks</h3>{" "}
                                </FormHelperText>
                                <Select
                                    sx={{
                                        background: "#69f0ae",
                                        "&.Mui-disabled": {
                                            color: "rgba(0, 0, 0, 0.87)",
                                            WebkitTextFillColor: "rgba(0, 0, 0, 0.87)",
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                border: "none",
                                            },
                                        },
                                    }}
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    name="hard_disk_id"
                                    value={data.hard_disk_id}
                                    onChange={(e) => onValueChange(e)}
                                    MenuProps={MenuProps}
                                    disabled
                                >
                                    {hardDisks.map((hardDisk: any) => (
                                        <MenuItem key={hardDisk.name} value={hardDisk.id}>
                                            {hardDisk.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth sx={{ width: "80%", margin: "auto" }}>
                                <FormLabel component="legend" sx={{ marginBottom: 2 }}>
                                    <Typography
                                        variant="h6"
                                        component="div"
                                        sx={{ fontWeight: "medium" }}
                                    >
                                        Ram Image
                                    </Typography>
                                </FormLabel>
                                <Zoom>
                                    <Card sx={{ maxWidth: 245, boxShadow: 3 }}>
                                        <CardMedia
                                            component="img"
                                            image={
                                                data.ram_image
                                                    ? serverImgUrl + data.ram_image
                                                    : "/public/images/not-found.png"
                                            }
                                            alt="Ram"
                                            sx={{
                                                height: "auto",
                                                maxWidth: "100%",
                                                borderRadius: "4px",
                                            }}
                                        />
                                    </Card>
                                </Zoom>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl sx={{ width: "80%" }}>
                                <FormHelperText>
                                    <h3 className="text-lg text-black"> External Graphics</h3>{" "}
                                </FormHelperText>
                                <Select
                                    sx={{
                                        background: "#69f0ae",
                                        "&.Mui-disabled": {
                                            color: "rgba(0, 0, 0, 0.87)",
                                            WebkitTextFillColor: "rgba(0, 0, 0, 0.87)",
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                border: "none",
                                            },
                                        },
                                    }}
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    name="external_graphics_id"
                                    value={data.external_graphics_id}
                                    onChange={(e) => onValueChange(e)}
                                    disabled
                                    MenuProps={MenuProps}
                                >
                                    {externalGraphics.map((externalGraphic: any) => (
                                        <MenuItem
                                            key={externalGraphic.name}
                                            value={externalGraphic.id}
                                        >
                                            {externalGraphic.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth sx={{ width: "80%", margin: "auto" }}>
                                <FormLabel component="legend" sx={{ marginBottom: 2 }}>
                                    <Typography
                                        variant="h6"
                                        component="div"
                                        sx={{ fontWeight: "medium" }}
                                    >
                                        External Graphics Image
                                    </Typography>
                                </FormLabel>
                                <Zoom>
                                    <Card sx={{ maxWidth: 245, boxShadow: 3 }}>
                                        <CardMedia
                                            component="img"
                                            image={
                                                data.external_graphic_image
                                                    ? serverImgUrl + data.external_graphic_image
                                                    : "/public/images/not-found.png"
                                            }
                                            alt="External Graphics"
                                            sx={{
                                                height: "auto",
                                                maxWidth: "100%",
                                                borderRadius: "4px",
                                            }}
                                        />
                                    </Card>
                                </Zoom>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl sx={{ width: "80%" }}>
                                <FormHelperText>
                                    <h3 className="text-lg text-black"> SSD</h3>{" "}
                                </FormHelperText>
                                <Select
                                    sx={{
                                        background: "#69f0ae",
                                        "&.Mui-disabled": {
                                            color: "rgba(0, 0, 0, 0.87)",
                                            WebkitTextFillColor: "rgba(0, 0, 0, 0.87)",
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                border: "none",
                                            },
                                        },
                                    }}
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    name="ssd_id"
                                    value={data.ssd_id}
                                    onChange={(e) => onValueChange(e)}
                                    MenuProps={MenuProps}
                                    disabled
                                >
                                    {ssds.map((ssd: any) => (
                                        <MenuItem key={ssd.name} value={ssd.id}>
                                            {ssd.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth sx={{ width: "80%", margin: "auto" }}>
                                <FormLabel component="legend" sx={{ marginBottom: 2 }}>
                                    <Typography
                                        variant="h6"
                                        component="div"
                                        sx={{ fontWeight: "medium" }}
                                    >
                                        SSD Image
                                    </Typography>
                                </FormLabel>
                                <Zoom>
                                    <Card sx={{ maxWidth: 245, boxShadow: 3 }}>
                                        <CardMedia
                                            component="img"
                                            image={
                                                data.ssd_image
                                                    ? serverImgUrl + data.ssd_image
                                                    : "/public/images/not-found.png"
                                            }
                                            alt="SSD"
                                            sx={{
                                                height: "auto",
                                                maxWidth: "100%",
                                                borderRadius: "4px",
                                            }}
                                        />
                                    </Card>
                                </Zoom>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl sx={{ width: "80%" }}>
                                <FormHelperText>
                                    <h3 className="text-lg text-black"> Screen Size</h3>{" "}
                                </FormHelperText>
                                <Select
                                    sx={{
                                        background: "#69f0ae",
                                        "&.Mui-disabled": {
                                            color: "rgba(0, 0, 0, 0.87)",
                                            WebkitTextFillColor: "rgba(0, 0, 0, 0.87)",
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                border: "none",
                                            },
                                        },
                                    }}
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    name="screen_size_id"
                                    value={data.screen_size_id}
                                    onChange={(e) => onValueChange(e)}
                                    MenuProps={MenuProps}
                                    disabled
                                >
                                    {screenSizes.map((screenSize: any) => (
                                        <MenuItem key={screenSize.name} value={screenSize.id}>
                                            {screenSize.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth sx={{ width: "80%", margin: "auto" }}>
                                <FormLabel component="legend" sx={{ marginBottom: 2 }}>
                                    <Typography
                                        variant="h6"
                                        component="div"
                                        sx={{ fontWeight: "medium" }}
                                    >
                                        Screen Size Image
                                    </Typography>
                                </FormLabel>
                                <Zoom>
                                    <Card sx={{ maxWidth: 245, boxShadow: 3 }}>
                                        <CardMedia
                                            component="img"
                                            image={
                                                data.screen_size_image
                                                    ? serverImgUrl + data.screen_size_image
                                                    : "/public/images/not-found.png"
                                            }
                                            alt="Screen Size"
                                            sx={{
                                                height: "auto",
                                                maxWidth: "100%",
                                                borderRadius: "4px",
                                            }}
                                        />
                                    </Card>
                                </Zoom>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl sx={{ width: "80%" }}>
                                <FormHelperText>
                                    <h3 className="text-lg text-black"> Age Of Laptop</h3>{" "}
                                </FormHelperText>
                                <Select
                                    sx={{
                                        background: "#69f0ae",
                                        "&.Mui-disabled": {
                                            color: "rgba(0, 0, 0, 0.87)",
                                            WebkitTextFillColor: "rgba(0, 0, 0, 0.87)",
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                border: "none",
                                            },
                                        },
                                    }}
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    name="age_of_laptop_id"
                                    value={data.age_of_laptop_id}
                                    onChange={(e) => onValueChange(e)}
                                    MenuProps={MenuProps}
                                    disabled
                                >
                                    {ageOfLaptops.map((ageOfLaptop: any) => (
                                        <MenuItem key={ageOfLaptop.name} value={ageOfLaptop.id}>
                                            {ageOfLaptop.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth sx={{ width: "80%", margin: "auto" }}>
                                <FormLabel component="legend" sx={{ marginBottom: 2 }}>
                                    <Typography
                                        variant="h6"
                                        component="div"
                                        sx={{ fontWeight: "medium" }}
                                    >
                                        Age Of Laptop Image
                                    </Typography>
                                </FormLabel>
                                <Zoom>
                                    <Card sx={{ maxWidth: 245, boxShadow: 3 }}>
                                        <CardMedia
                                            component="img"
                                            image={
                                                data.age_of_laptop_image
                                                    ? serverImgUrl + data.age_of_laptop_image
                                                    : "/public/images/not-found.png"
                                            }
                                            alt="Age Of Laptop"
                                            sx={{
                                                height: "auto",
                                                maxWidth: "100%",
                                                borderRadius: "4px",
                                            }}
                                        />
                                    </Card>
                                </Zoom>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl sx={{ width: "80%" }}>
                                <FormHelperText>
                                    <h3 className="text-lg text-black"> Physical Condition</h3>{" "}
                                </FormHelperText>
                                <Select
                                    sx={{
                                        background: "#69f0ae",
                                        "&.Mui-disabled": {
                                            color: "rgba(0, 0, 0, 0.87)",
                                            WebkitTextFillColor: "rgba(0, 0, 0, 0.87)",
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                border: "none",
                                            },
                                        },
                                    }}
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    name="physical_condition_id"
                                    // value={data.physical_condition_id}
                                    onChange={(e) => onValueChange(e)}
                                    MenuProps={MenuProps}
                                    disabled
                                >
                                    {physicalConditions.map((physicalCondition: any) => (
                                        <MenuItem
                                            key={physicalCondition.name}
                                            value={physicalCondition.id}
                                        >
                                            {physicalCondition.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth sx={{ width: "80%", margin: "auto" }}>
                                <FormLabel component="legend" sx={{ marginBottom: 2 }}>
                                    <Typography
                                        variant="h6"
                                        component="div"
                                        sx={{ fontWeight: "medium" }}
                                    >
                                        Physical Condition Image
                                    </Typography>
                                </FormLabel>
                                <Zoom>
                                    <Card sx={{ maxWidth: 245, boxShadow: 3 }}>
                                        <CardMedia
                                            component="img"
                                            image={
                                                data.physical_condition_image
                                                    ? serverImgUrl + data.physical_condition_image
                                                    : "/public/images/not-found.png"
                                            }
                                            alt="Physical Condition"
                                            sx={{
                                                height: "auto",
                                                maxWidth: "100%",
                                                borderRadius: "4px",
                                            }}
                                        />
                                    </Card>
                                </Zoom>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl sx={{ width: "80%" }}>
                                <FormHelperText>
                                    <h3 className="text-lg text-black"> Functional Condition</h3>{" "}
                                </FormHelperText>
                                <Select
                                    sx={{
                                        background: "#69f0ae",
                                        "&.Mui-disabled": {
                                            color: "rgba(0, 0, 0, 0.87)",
                                            WebkitTextFillColor: "rgba(0, 0, 0, 0.87)",
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                border: "none",
                                            },
                                        },
                                    }}
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    name="functional_condition_id"
                                    value={data.functional_condition_id}
                                    onChange={(e) => onValueChange(e)}
                                    MenuProps={MenuProps}
                                    disabled
                                >
                                    {functionalConditions.map((functionalCondition: any) => (
                                        <MenuItem
                                            key={functionalCondition.name}
                                            value={functionalCondition.id}
                                        >
                                            {functionalCondition.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl sx={{ width: "80%" }}>
                                <FormHelperText>
                                    <h3 className="text-lg text-black">
                                        Functional Condition Image{" "}
                                    </h3>{" "}
                                </FormHelperText>
                                <Zoom>
                                    <CardMedia
                                        component="img"
                                        alt="Physical Condition"
                                        image={
                                            data.functional_condition_id
                                                ? serverImgUrl + data.functional_condition_image
                                                : "/public/images/not-found.png"
                                        }
                                        sx={{
                                            height: "auto",
                                            maxWidth: "100%",
                                            borderRadius: "4px",
                                        }}
                                    />
                                </Zoom>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl sx={{ width: "80%" }}>
                                <FormHelperText>
                                    <h3 className="text-lg text-black">Date</h3>{" "}
                                </FormHelperText>
                                <OutlinedInput
                                    sx={{
                                        background: "#69f0ae",
                                        "&.Mui-disabled": {
                                            color: "rgba(0, 0, 0, 0.87)",
                                            WebkitTextFillColor: "rgba(0, 0, 0, 0.87)",
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                border: "none",
                                            },
                                        },
                                    }}
                                    placeholder="Please enter text"
                                    onChange={(e) => onValueChange(e)}
                                    name="date"
                                    type="date"
                                    value={data.date}
                                    disabled
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl variant="outlined" fullWidth>
                                <FormHelperText>
                                    <h3 className="text-lg text-black">Sales Type </h3>
                                </FormHelperText>
                                <OutlinedInput
                                    sx={{
                                        width: "80%",
                                        background: "#69f0ae",
                                        "&.Mui-disabled": {
                                            color: "rgba(0, 0, 0)",
                                            WebkitTextFillColor: "rgba(0, 0, 0, 0.87)",
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                border: "none",
                                            },
                                        },
                                    }}
                                    value={Number(data.all_ok_type) ? "B2C" : "B2B"}
                                    name="purchase_engineer_id"
                                    disabled
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl sx={{ width: "80%" }}>
                                <FormHelperText>
                                    <h3 className="text-lg text-black">Price</h3>{" "}
                                </FormHelperText>
                                <OutlinedInput
                                    sx={{
                                        background: "#fff",
                                        "&.Mui-disabled": {
                                            color: "rgba(0, 0, 0, 0.87)",
                                            WebkitTextFillColor: "rgba(0, 0, 0, 0.87)",
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                border: "none",
                                            },
                                        },
                                    }}
                                    placeholder="Please enter sales price ₹"
                                    onChange={(e) => onValueChange(e)}
                                    name="sales_price"
                                    value={data.sales_price}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl sx={{ width: "80%" }}>
                                <FormHelperText>
                                    <h3 className="text-lg text-black">Vendor Name</h3>{" "}
                                </FormHelperText>
                                <OutlinedInput
                                    sx={{
                                        background: "#69f0ae",
                                        "&.Mui-disabled": {
                                            color: "rgba(0, 0, 0, 0.87)",
                                            WebkitTextFillColor: "rgba(0, 0, 0, 0.87)",
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                border: "none",
                                            },
                                        },
                                    }}
                                    placeholder="Please enter Vendor Name"
                                    onChange={(e) => onValueChange(e)}
                                    name="vendor_name"
                                    value={data.vendor_name}
                                    disabled
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl sx={{ width: "80%" }}>
                                <FormHelperText>
                                    <h3 className="text-lg text-black">
                                        Purchase and Repair Price
                                    </h3>{" "}
                                </FormHelperText>
                                <OutlinedInput
                                    sx={{
                                        background: "#69f0ae",
                                        "&.Mui-disabled": {
                                            color: "rgba(0, 0, 0, 0.87)",
                                            WebkitTextFillColor: "rgba(0, 0, 0, 0.87)",
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                border: "none",
                                            },
                                        },
                                    }}
                                    placeholder="Please enter Purchase and Repair Price"
                                    onChange={(e) => onValueChange(e)}
                                    name="part_and_repair_price"
                                    value={Number(data.part_price | 0) + Number(data.price)}
                                    disabled
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl sx={{ width: "80%" }}>
                                <FormHelperText>
                                    <h3 className="text-lg text-black">Shelf No.</h3>{" "}
                                </FormHelperText>
                                <OutlinedInput
                                    sx={{
                                        background: "#69f0ae",
                                        "&.Mui-disabled": {
                                            color: "rgba(0, 0, 0, 0.87)",
                                            WebkitTextFillColor: "rgba(0, 0, 0, 0.87)",
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                border: "none",
                                            },
                                        },
                                    }}
                                    placeholder="Please enter shelf no."
                                    onChange={(e) => onValueChange(e)}
                                    disabled
                                    name="shelf_no"
                                    value={data.shelf_no}
                                />
                            </FormControl>
                        </Grid>

                        {/* <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3 className="text-lg text-black">Pickup Person</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                      sx={{
        background: "#69f0ae",
        "&.Mui-disabled": {
          color: "rgba(0, 0, 0, 0.87)", 
          WebkitTextFillColor: "rgba(0, 0, 0, 0.87)", 
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none", 
          },
        },
      }}
                  placeholder="Please enter pickup person"
                  onChange={(e) => onValueChange(e)}
                  name="pickup_person"
                  value={data.pickup_person}
                />
              </FormControl>
            </Grid> */}

                        <Grid item xs={6}>
                            <FormControl sx={{ width: "80%" }}>
                                <FormHelperText>
                                    <h3 className="text-lg text-black"> Charger</h3>{" "}
                                </FormHelperText>
                                <Select
                                    sx={{
                                        background: "#69f0ae",
                                        "&.Mui-disabled": {
                                            color: "rgba(0, 0, 0, 0.87)",
                                            WebkitTextFillColor: "rgba(0, 0, 0, 0.87)",
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                border: "none",
                                            },
                                        },
                                    }}
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    name="charger_type"
                                    value={data.charger_type}
                                    onChange={(e) => onValueChange(e)}
                                    MenuProps={MenuProps}
                                    disabled
                                >
                                    <MenuItem value={1}>With Charger</MenuItem>
                                    <MenuItem value={0}>Without Charger</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl sx={{ width: "80%" }}>
                                <FormHelperText>
                                    <h3 className="text-lg text-black"> Problem</h3>{" "}
                                </FormHelperText>
                                <OutlinedInput
                                    sx={{
                                        background: "#69f0ae",
                                        "&.Mui-disabled": {
                                            color: "rgba(0, 0, 0, 0.87)",
                                            WebkitTextFillColor: "rgba(0, 0, 0, 0.87)",
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                border: "none",
                                            },
                                        },
                                    }}
                                    placeholder="Please enter problem"
                                    onChange={(e) => onValueChange(e)}
                                    name="problem"
                                    value={data.problem}
                                    disabled
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl sx={{ width: "80%" }}>
                                <FormHelperText>
                                    <h3 className="text-lg text-black">Sales Payment Type</h3>{" "}
                                </FormHelperText>
                                <Select
                                    sx={{
                                        background: "#ffe",
                                        "&.Mui-disabled": {
                                            color: "rgba(0, 0, 0, 0.87)",
                                            WebkitTextFillColor: "rgba(0, 0, 0, 0.87)",
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                border: "none",
                                            },
                                        },
                                    }}
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    name="payment_type"
                                    value={data.payment_type}
                                    onChange={(e) => onValueChange(e)}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value={"Cash"}>Cash</MenuItem>
                                    <MenuItem value={"Online"}>Online</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl sx={{ width: "80%" }}>
                                <FormHelperText>
                                    <h3 className="text-lg text-black">Description/Comments</h3>{" "}
                                </FormHelperText>
                                <TextareaAutosize
                                    minRows={5}
                                    placeholder="Please enter description"
                                    style={{ width: 400, background: "#69f0ae", padding: "20px" }}
                                    onChange={(e) => onValueChange(e)}
                                    aria-label="minimum height"
                                    name="description"
                                    value={data.description}
                                    disabled
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Box
                        sx={{
                            mt: "20px",
                        }}
                    >
                        <Button
                            variant="contained"
                            size="small"
                            sx={{
                                background: "#009E60",
                                mr: 2,
                                maxWidth: "auto",
                            }}
                            // onClick={() => handleSubmit("spare_status")}
                        >
                            Ready to Dispatch
                        </Button>
                        <Button
                            variant="contained"
                            size="small"
                            sx={{
                                background: "#009E60",
                                mr: 2,
                                maxWidth: "auto",
                            }}
                            // onClick={() => handleSubmit("repair_status")}
                        >
                            Return from Customer
                        </Button>

                        <Button
                            variant="contained"
                            size="small"
                            sx={{
                                background: "#009E60",
                                mr: 2,
                                maxWidth: "auto",
                            }}
                            // onClick={() => handleSubmit("fabric_status")}
                        >
                            Upgrade Request by Customer
                        </Button>
                    </Box>
                    <FormControl sx={{ my: 3 }}>
                        <Button variant="contained" sx={{ maxWidth: 200 }} type="submit">
                            Update Price
                        </Button>
                    </FormControl>
                </FormGroup>
            </form>
            {/*<form onSubmit={updateSold}>
        <FormGroup>
          <FormControl>
            <FormHelperText>
              <h3 className="text-lg text-black"> Sold</h3>{" "}
            </FormHelperText>
            <RadioGroup
              name="sold_status"
              onChange={(e) => onValueChange(e)}
              aria-labelledby="demo-radio-buttons-group-label"
            >
              <FormControlLabel
                value="1"
                onChange={(e) => onValueChange(e)}
                control={<Radio checked={data.sold_status} />}
                label="SOLD"
              />
            </RadioGroup>
          </FormControl>
         <FormControl sx={{ my: 4 }}>
            <Button variant="contained" sx={{ maxWidth: 200 }} type="submit">
              Update Sold
            </Button>
          </FormControl> 
        </FormGroup>
      </form>*/}
        </div>
    )
}
export default AddSell
