import { Box, Stack, Typography } from "@mui/material"
import "./engineer-dashboard-row.css"

export default function EngineerDashboardRow({ children, title = "" }: any) {
    return (
        <div>
            {!!title ? (
                <Typography
                    sx={{
                        py: "1px",
                        px: 4,
                        fontWeight: "600",
                        fontSize: "0.8em",
                        textTransform: "uppercase",
                    }}
                >
                    {title}
                </Typography>
            ) : (
                <div style={{ padding: 1 }}></div>
            )}
            <Box
                sx={{ boxShadow: 5, mb: 1, p: 1, px: 3, borderRadius: 3, border: "1px solid #bbb" }}
            >
                <div className="engineer-dashboard-row">{children}</div>
            </Box>
        </div>
    )
}
