import * as React from "react"
import { useState, useEffect } from "react"
import { http } from "../../utils/http"
import { MONTH_NAMES, getLastDateOfMonth } from "../../utils/common"
import { useParams } from "react-router-dom"
import Hidden from "@mui/material/Hidden"
import { Paper } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { Divider } from "@mui/material"
import SummarizeIcon from "@mui/icons-material/Summarize"
import CardMembershipIcon from "@mui/icons-material/CardMembership"
import ContentCutIcon from "@mui/icons-material/ContentCut"
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee"
import PaymentIcon from "@mui/icons-material/Payment"
import { NavLink, Link } from "react-router-dom"
import { styled } from "@mui/material/styles"
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp"
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion"
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary"
import MuiAccordionDetails from "@mui/material/AccordionDetails"
import Typography from "@mui/material/Typography"
import SalaryExtraModal from "./salary-extra-modal"

import {
    FormGroup,
    FormControl,
    Grid,
    Button,
    OutlinedInput,
    FormHelperText,
    TextField,
} from "@mui/material"
import { width } from "@mui/system"
import { CenterFocusStrong } from "@mui/icons-material"

const Container = styled(FormGroup)`
    width: 100%;
`
{
    /* <Typography variant="h4">Add New Employee</Typography> */
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const initialValues = {
    lastName: "",
    firstName: "",
    emp_id: "",
    email: "",
    phone: "",
    designation: "",
    name: "",
    basic: "",
    grossPay: "",
}

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
        borderBottom: 0,
    },
    "&:before": {
        display: "none",
    },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === "dark" ? "rgba(255, 255, 255, 255)" : "rgba(255, 255, 255, 255)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(1),
    },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
}))

const ViewEmployee = () => {
    const [expanded, setExpanded] = React.useState<string | false>("panel1")
    const [initialized, setInitialized] = React.useState(false)

    const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false)
    }
    let params = useParams()
    const navigate = useNavigate()
    const [Salary, setSalary] = useState({ ...initialValues, emp_id: params.id })
    const [EmpData, setEmpData] = useState({
        ...initialValues,
        emp_id: params.id,
    })
    const [salcheck, salcheckdata] = useState({
        ...initialValues,
    })
    const [salaryRecords, setSalaryRecords]: any = useState([])
    const [currentSalaryRecord, setCurrentSalaryRecord]: any = useState(null)

    useEffect(() => {
        ;(async function () {
            const salcheckRes = await http.get("/salary/" + params.id)
            if (salcheckRes) {
                salcheckdata(salcheckRes.data.result)
            }

            console.log("salcheck details", salcheckRes.data.redult)

            // console.log('emps',employees.length);
        })()
    }, [])

    const [EmpDataDes, setEmpDataDes] = useState({
        ...initialValues,
        emp_id: params.id,
        name: "",
    })

    const handleSalaryRecordUpdate: any = function (result: any) {
        if (result) {
            setCurrentSalaryRecord(result)
            const updatedSalaryRecords: any = salaryRecords.map((item: any) => {
                let updatedItem: any = item
                if (item.id == result.id) {
                    updatedItem = result
                }
                return updatedItem
            })
            setSalaryRecords(updatedSalaryRecords)
        }
    }
    async function fetchSalaryRecords() {
        try {
            const salaryRecordRes = await http.get(`/salary-records/employee/${params.id}`)
            if (salaryRecordRes) {
                setSalaryRecords(salaryRecordRes.data.result)
                setCurrentSalaryRecord(salaryRecordRes.data.current_salary_record)
            }
        } catch (err) {
            console.log("error in fetching salary records", err)
        }
    }
    useEffect(() => {
        ;(async function () {
            if (!initialized) {
                const employeesDataRes = await http.get("/employees/" + params.id)
                setEmpData(employeesDataRes.data.result)
                setEmpDataDes(employeesDataRes.data.result.designation)
                await fetchSalaryRecords()
                setInitialized(true)
            }
        })()
    }, [])

    const onValueChange = (e: any) => {
        e.preventDefault(e)
        setSalary({ ...Salary, [e.target.name]: e.target.value })
    }
    return (
        <div>
            <h2>Employee ID: {params.id}</h2>

            <Grid item xs={6} sx={{ textAlign: "left", pb: 1 }}>
                {salcheck.basic == "" ? (
                    <NavLink to={`/add-salary/${params.id}`}>
                        <Button variant="outlined" size="small" sx={{ mb: 2 }}>
                            Add Salary
                        </Button>
                    </NavLink>
                ) : (
                    <NavLink to={`/view-salary/${params.id}`}>
                        <Button variant="outlined" size="small" sx={{ mb: 2 }}>
                            Update Salary
                        </Button>
                    </NavLink>
                )}

                {/* {salcheck ?  (<NavLink to={`/view-salary/${params.id}`} >
          <Button variant="outlined" size="small" sx={{ mb: 2 }}>
               view Salary
                 </Button>
          </NavLink>): (<NavLink to={`/add-salary/${params.id}`} >
          <Button variant="outlined" size="small" sx={{ mb: 2 }}>
               Add Salary
                 </Button>
                    </NavLink>)
                    
                    }  */}

                {/* <Button variant="contained">Add Salary</Button> */}
            </Grid>
            <Paper elevation={5} sx={{ pl: 2 }}>
                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <h4>
                            {EmpData.firstName} {EmpData.lastName}
                        </h4>
                        <FormHelperText>
                            <h3>Mobile No</h3>
                        </FormHelperText>
                        <h5>{EmpData.phone}</h5>
                    </Grid>

                    <Grid item xs={3}>
                        <FormHelperText sx={{ mt: 8 }}>
                            <h3>Staff type </h3>
                        </FormHelperText>

                        <h5>{EmpDataDes.name}</h5>
                    </Grid>
                    <Grid item xs={3}>
                        <FormHelperText sx={{ mt: 8 }}>
                            <h3>Monthly Gross Salary </h3>
                        </FormHelperText>
                        <h5> ₹ {salcheck.grossPay}</h5>
                    </Grid>

                    <Grid item xs={3} sx={{ textAlign: "right", p: 2 }}>
                        <NavLink
                            to={`/view-employee-details/${params.id}`}
                            style={{ textDecoration: "none" }}
                        >
                            <Button variant="outlined">View Employee Details</Button>
                        </NavLink>
                    </Grid>
                </Grid>
            </Paper>

            <Paper elevation={5} sx={{ pl: 2, mt: 5 }}>
                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <FormHelperText sx={{ fontSize: 15, textAlign: "center" }}>
                            <h3>
                                <NavLink to="/" style={{ textDecoration: "none", color: "#444" }}>
                                    <SummarizeIcon fontSize="small"></SummarizeIcon> Summary
                                </NavLink>
                            </h3>
                        </FormHelperText>
                    </Grid>

                    <Grid item xs={3}>
                        <FormHelperText sx={{ fontSize: 15, textAlign: "center" }}>
                            <h3>
                                <NavLink to="/" style={{ textDecoration: "none", color: "#444" }}>
                                    Attendance
                                </NavLink>
                            </h3>
                        </FormHelperText>
                    </Grid>
                    <Grid item xs={3}>
                        <FormHelperText sx={{ fontSize: 15, textAlign: "center" }}>
                            <h3>
                                <NavLink to="/" style={{ textDecoration: "none", color: "#444" }}>
                                    <PaymentIcon fontSize="small"></PaymentIcon> Payments
                                </NavLink>
                            </h3>
                        </FormHelperText>
                    </Grid>
                    <Grid item xs={3}>
                        <FormHelperText sx={{ fontSize: 15, textAlign: "center" }}>
                            <h3>
                                <NavLink to="/" style={{ textDecoration: "none", color: "#444" }}>
                                    <CurrencyRupeeIcon /> Pay Slip
                                </NavLink>
                            </h3>
                        </FormHelperText>
                    </Grid>
                </Grid>
                <Divider sx={{ p: 1 }}></Divider>
                <Grid container spacing={4} sx={{ pt: 2 }}>
                    <Grid item xs={3}>
                        <SalaryExtraModal
                            title="Overtime"
                            icon="SummarizeIcon"
                            recordName="overtime"
                            isDeduction={false}
                            onUpdate={handleSalaryRecordUpdate}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <SalaryExtraModal
                            title="Allowances/Bonus"
                            icon="ContentCutIcon"
                            recordName="allowance/bonus"
                            isDeduction={false}
                            onUpdate={handleSalaryRecordUpdate}
                        />
                    </Grid>
                    {/* <Divider orientation="vertical" flexItem></Divider> */}

                    <Grid item xs={3}>
                        <SalaryExtraModal
                            title="Deductions"
                            icon="ContentCutIcon"
                            recordName="deductions"
                            isDeduction={true}
                            onUpdate={handleSalaryRecordUpdate}
                        />
                    </Grid>
                    {/* <Divider orientation="vertical" flexItem></Divider> */}
                    <Grid item xs={3}>
                        {" "}
                        <SalaryExtraModal
                            title="Loan"
                            icon="CardMembershipIcon"
                            recordName="loan"
                            isDeduction={false}
                            onUpdate={handleSalaryRecordUpdate}
                        />
                    </Grid>
                </Grid>
            </Paper>

            <Paper elevation={5} sx={{ pl: 2, mt: 5 }}>
                {!!currentSalaryRecord && (
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <FormHelperText sx={{ fontSize: 15 }}>
                                <h3> Total Overall Balance </h3>
                            </FormHelperText>
                        </Grid>
                        <Grid item xs={2}>
                            <h4>₹ {currentSalaryRecord.balance}</h4>
                        </Grid>

                        <Grid item xs={6} sx={{ textAlign: "right", p: 3 }}>
                            {currentSalaryRecord.balance > 0 && (
                                <SalaryExtraModal
                                    onUpdate={handleSalaryRecordUpdate}
                                    useSimpleBtn={true}
                                    title="Payment"
                                    recordName="PAYMENT"
                                    defaultAmount={currentSalaryRecord.balance}
                                    defaultPlaceholder={`Pending balance ₹${currentSalaryRecord.balance}`}
                                />
                            )}
                        </Grid>
                    </Grid>
                )}
                <Divider flexItem />
                {salaryRecords.map((salaryRecord: any, i: any) => {
                    const monthNum = salaryRecord.issue_date_month
                    const issueMonth = MONTH_NAMES[monthNum].slice(0, 3)
                    const issueYear = salaryRecord.issue_date_year

                    return (
                        <Accordion
                            expanded={expanded === `panel${i}`}
                            onChange={handleChange(`panel${i}`)}
                        >
                            <AccordionSummary
                                style={{ backgroundColor: "lightyellow" }}
                                aria-controls={`panel${i}d-content`}
                                id={`panel${i}d-header`}
                            >
                                <Typography>
                                    <h4>
                                        {MONTH_NAMES[monthNum]} Payments
                                        {currentSalaryRecord.id == salaryRecord.id
                                            ? "(Current Month)"
                                            : ""}
                                    </h4>
                                    <FormHelperText>
                                        Grand Total: ₹ {salaryRecord.grand_total}
                                    </FormHelperText>
                                    <FormHelperText>
                                        Paid Amount: ₹ {salaryRecord.paidAmount}
                                    </FormHelperText>
                                    <FormHelperText>{`1st ${issueMonth}, ${issueYear} - ${getLastDateOfMonth(
                                        monthNum
                                    )} ${issueMonth},${issueYear}`}</FormHelperText>
                                </Typography>
                            </AccordionSummary>
                            {!!salaryRecord.salary_bonus_and_deduction_records.length && (
                                <AccordionDetails>
                                    {salaryRecord.salary_bonus_and_deduction_records.map(
                                        (sbadr: any) => (
                                            <Typography>
                                                <FormHelperText>
                                                    <span
                                                        style={{
                                                            textTransform: "capitalize",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        {sbadr.record_name}
                                                    </span>
                                                </FormHelperText>
                                                <FormHelperText>
                                                    {sbadr.is_deduction ? <span>-</span> : null} ₹
                                                    {sbadr.amount}
                                                </FormHelperText>
                                            </Typography>
                                        )
                                    )}
                                </AccordionDetails>
                            )}
                        </Accordion>
                    )
                })}
            </Paper>
        </div>
    )
}
export default ViewEmployee
