import * as React from "react";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import { http, serverImgUrl, localImgUrl } from "../../utils/http";
import { useParams } from "react-router-dom";
import Hidden from "@mui/material/Hidden";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import MenuItem from "@mui/material/MenuItem";

import { useNavigate } from "react-router-dom";

import {
  FormGroup,
  FormControl,
  Grid,
  Button,
  styled,
  OutlinedInput,
  FormHelperText,
  TextField,
} from "@mui/material";
import { width } from "@mui/system";

const Container = styled(FormGroup)`
  width: 100%;
`;
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
{
  /* <Typography variant="h4">Add New Employee</Typography> */
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const initialValues = {

    brand_master_id:"",
    name:"",
};

const UpdatePurchase = () => {
  

  const [brands, setBrands] = useState([]);
 
  useEffect(() => {
    (async () => {
      const data: any = await http.get("/brand-master");
      setBrands(data.data.result);
    })();
  }, []);

  let params = useParams();
  const navigate = useNavigate();

  const [data, setSeriesUpdate] = useState({
    ...initialValues,
  name:"",
  id:"",
  brand_master_id:""
  });
  
  useEffect(() => {
    (async function () {
      const salDataRes = await http.get("/series-master/" + params.id);
      setSeriesUpdate(salDataRes.data.result);
      
    })();
  }, []);

  const onValueChange = (e: any) => {
    e.preventDefault(e);
    if (e.target.type === "file") {
      setSeriesUpdate({ ...data, [e.target.name]: e.target.files[0] });
    } else {
      setSeriesUpdate({ ...data, [e.target.name]: e.target.value });
    }
}

//     console.log("target namee", e.target.name, "is", e.target.value);
//   };
const updateSeries = async (e: any) => {
    e.preventDefault();
    try {
    //    console.log('data',ResolutionUpdate);
      await http.patch("/series-master/"+params.id, data);
      navigate("/series-master");
      return;
    } catch (error: any) {
      console.log("error while calling api", error.message);
    }
  };

  return (
    <div>
      <h2>Series ID: {params.id}</h2>

      <form onSubmit={updateSeries}>
        <FormGroup>
          <Grid container spacing={1}>
          <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>Name</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  sx={{ background: "white" }}
                  onChange={(e) => onValueChange(e)}
                  name="name"
                  value={data.name}
                  
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3> Brand Master</h3>{" "}
                </FormHelperText>
                <Select
                  sx={{ background: "white" }}
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  name="brand_master_id"
                  value={data.brand_master_id}
                  onChange={(e) => onValueChange(e)}
                  MenuProps={MenuProps}
                >
                  {brands.map((brand: any) => (
                    <MenuItem key={brand.name} value={brand.id}>
                      {brand.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            
          </Grid>
          <FormControl sx={{ my: 4 }}>
            <Button variant="contained" sx={{ maxWidth: 200 }} type="submit">
              Submit 
            </Button>
          </FormControl>
        </FormGroup>
      </form>
    </div>
  );

};
export default UpdatePurchase;
