import React, { useState, useEffect } from "react";
import {
    Button,
    TextField,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    Box,
    Typography,
    Paper,
    IconButton,
    Menu,
    MenuItem as MenuItemUI,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import ArticleIcon from '@mui/icons-material/Article';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridRowId,
    GridRowModes,
    GridRowModesModel,
    GridActionsCellItem,
    GridRowModel,
    GridCellEditStopParams,
    GridEventListener,
    GridRowEditStopReasons,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import CustomStatusDropdown from "../../components/ui/CustomStatusDropdown";
import PageTitleCard from "../../components/layout/page-title-card";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { toast } from "react-toastify";
import axios from "axios";
import DeleteModal from "../../components/ui/DeleteModal";
import EditPurchaseSelectItem from "../../components/ui/EditPurchaseSelectItem";
import { http } from "../../utils/http";

const PAGE_SIZE = 15;

const formatDateToDDMMYY = (date: string | Date): string => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = String(d.getFullYear()).slice(-2);
    return `${day}/${month}/${year}`;
};

interface DateEditComponentProps {
    value?: any;
    api: any;
    id: GridRowId;
    field: string;
}

const DateEditComponent: React.FC<DateEditComponentProps> = ({ value, api, id, field }) => {
    const handleDateChange = (newValue: any) => {
        api.setEditCellValue({ id, field, value: newValue });
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                value={value || null}
                onChange={handleDateChange}
                format="dd/MM/yy"
                slots={{
                    textField: (textFieldProps) => <TextField {...textFieldProps} />
                }}
            />
        </LocalizationProvider>
    );
};


const getGradeCategory = (createdAt: Date): string => {
    const createdAtDate = new Date(createdAt);
    const currentDate = new Date();
    const differenceInTime = currentDate.getTime() - createdAtDate.getTime();
    const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));

    if (differenceInDays <= 5) {
        return "A Grade";
    } else if (differenceInDays <= 7) {
        return "B Grade";
    } else {
        return "C Grade";
    }
};

const UpdatePurchase = () => {
    const [purchaseData, setPurchaseData] = useState<any[]>([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [filterShelfNo, setFilterShelfNo] = useState("");
    const [filterLsnNo, setFilterLsnNo] = useState("");
    const [filterPrice, setFilterPrice] = useState("");
    const [filterStatus, setFilterStatus] = useState("");
    const [rows, setRows] = useState(purchaseData);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedRowId, setSelectedRowId] = useState<GridRowId | null>(null);
    const [brandMasters, setBrandMaster] = useState<any[]>([]);
    const [seriesMasters, setSeriesMaster] = useState<any[]>([]);
    const [processorBrands, setProcessorBrand] = useState<any[]>([]);
    const [processorSeries, setProcessorSeries] = useState<any[]>([]);
    const [processorGenerations, setProcessorGeneration] = useState<any[]>([]);
    const [processorGigaHeartzs, setProcessorGigaHeartz] = useState<any[]>([]);
    const [graphicBrands, setGraphicBrand] = useState<any[]>([]);
    const [graphicSeries, setGraphicSeries] = useState<any[]>([]);
    const [graphicModels, setGraphicModel] = useState<any[]>([]);
    const [graphicSizes, setGraphicSize] = useState<any[]>([]);
    const [screenSizes, setScreenSize] = useState<any[]>([]);
    const [screenResolutions, setScreenResolution] = useState<any[]>([]);
    const [screenSpots, setScreenSpot] = useState<any[]>([]);
    const [rams, setram] = useState<any[]>([]);
    const [hardDisks, setHardDisk] = useState<any[]>([]);
    const [externalGraphics, setExternalGraphics] = useState<any[]>([]);
    const [ssds, setSSD] = useState<any[]>([]);
    const [ageOfLaptops, setAgeOfLaptop] = useState<any[]>([]);
    const [physicalConditions, setPhysicalConditions] = useState<any[]>([]);
    const [functionalConditions, setfunctionalCondition] = useState<any[]>([]);
    const [employees, setEmployees] = useState<any[]>([]);
    const [tatcal, setTatCal] = useState<string | undefined>()
    const [tat, setTat] = useState<string | undefined>()
    const [totalRows, setTotalRows] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 15,
    });
    const [showModal, setShowModal] = useState<boolean>(false)
    const [isValueSelected, setIsValueSelected] = useState(false)
    const [showAllOkModal, setShowAllOkModal] = useState(false);

    useEffect(() => {
        ; (async () => {
            const data: any = await http.get("/purchase/specs")

            const result = data.data.result

            setBrandMaster(result.brandMasters)
            setSeriesMaster(result.seriesMasters)

            setProcessorBrand(result.processorBrands)
            setProcessorSeries(result.processorSeries)
            setProcessorGeneration(result.processorGenerations)
            setProcessorGigaHeartz(result.processorGigaHeartzs)

            setGraphicBrand(result.graphicBrands)
            setGraphicSeries(result.graphicSeries)
            setGraphicModel(result.graphicModels)
            setGraphicSize(result.graphicSizes)

            setScreenSize(result.screenSizes)
            setScreenResolution(result.screenResolutions)
            setScreenSpot(result.screenSpots)

            setEmployees(result.employees)
            setram(result.rams)
            setHardDisk(result.hardDisks)
            setExternalGraphics(result.externalGraphics)
            setSSD(result.ssds)
            setAgeOfLaptop(result.ageOfLaptops)
            setPhysicalConditions(result.physicalConditions)
            setfunctionalCondition(result.functionalConditions)
        })()
    }, [])

    const navigate = useNavigate();

    const fetchData = async (page: number, pageSize: number) => {
        setIsLoading(true);
        try {
            const response = await http.get(
                `/PurchaseReportToEngineer?page=${page + 1}&pageSize=${PAGE_SIZE}`
            );
            setPurchaseData(response.data.result);
            setTotalRows(response.data.pageInfo.totalItems);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData(paginationModel.page, paginationModel.pageSize);
    }, [paginationModel]);

    const handlePageChange = (model: any) => {
        setCurrentPage(model.page + 1);
    };

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason == GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>, id: GridRowId) => {
        setAnchorEl(event.currentTarget);
        setSelectedRowId(id);
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedRowId(null);
    };

    const handleSaveClick = async () => {
        try {
            if (selectedRowId !== null) {
                const updatedRow = purchaseData.find((row) => row.id === selectedRowId);
                await http.patch(`/PurchaseReportToEngineer/${selectedRowId}`, updatedRow);
                setRowModesModel({ ...rowModesModel, [selectedRowId]: { mode: GridRowModes.View } });
                // toast.success("Data updated successfully");
            }
        } catch (error) {
            console.error("Error updating data:", error);
            toast.error("Error updating data");
        } finally {
            handleMenuClose();
        }
    };

    const handleCancelClick = () => {
        if (selectedRowId !== null) {
            setRowModesModel({
                ...rowModesModel,
                [selectedRowId]: { mode: GridRowModes.View, ignoreModifications: true },
            });

            const originalRow = purchaseData.find((row) => row.id === selectedRowId);
            if (originalRow) {
                setPurchaseData((prevData) => prevData.map((row) => (row.id === selectedRowId ? originalRow : row)));
            }
        }
        handleMenuClose();
    };

    const handleSubmit = async (status: string, allOkType?: string) => {
        try {
            if (selectedRowId !== null) {
                const updatedRow = purchaseData.find((row) => row.id === selectedRowId);
                const updatePayload = {
                    ...updatedRow,
                    status,
                    all_ok_type: allOkType,
                };
                await updateData(updatePayload);
                setRowModesModel({ ...rowModesModel, [selectedRowId]: { mode: GridRowModes.View } });
                toast.success(`Status updated to ${status}`);
            }
        } catch (error) {
            console.error("Error updating status:", error);
            toast.error("Error updating status");
        } finally {
            handleMenuClose();
        }
    };

    const processRowUpdate = async (newRow: GridRowModel) => {
        const updatedRow = { ...newRow, isNew: false };
        console.log('New row data to be updated:', newRow);
        setRows(rows.map((row) => (row.id == newRow.id ? updatedRow : row)));
        await updateData(newRow);
        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };
    const handleShowDetail = (id: GridRowId) => () => {
        navigate(`/view-engineer-report/${id}`);
    };

    const updateData = async (updatedRow: any) => {
        try {
            const today = new Date().toISOString().split('T')[0];

            // Format dates before sending to backend
            if (updatedRow.qc1_date !== undefined && updatedRow.qc1_date !== null) {
                if (updatedRow.qc1_date instanceof Date) {
                    updatedRow.qc1_date = updatedRow.qc1_date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
                } else {
                    updatedRow.qc1_date = new Date(updatedRow.qc1_date).toISOString().split('T')[0]; // Convert to date if string
                }
            } else {
                updatedRow.qc1_date = today; // Set to today's date if null or undefined
            }

            if (updatedRow.rtd_date !== undefined && updatedRow.rtd_date !== null) {
                if (updatedRow.rtd_date instanceof Date) {
                    updatedRow.rtd_date = updatedRow.rtd_date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
                } else {
                    updatedRow.rtd_date = new Date(updatedRow.rtd_date).toISOString().split('T')[0]; // Convert to date if string
                }
            } else {
                updatedRow.rtd_date = today; // Set to today's date if null or undefined
            }

            const formData: any = new FormData();
            for (let itemKey in updatedRow) {
                formData.append(itemKey, updatedRow[itemKey]);
            }
            const endpoint = updatedRow.status === "all_ok_status" ? "/purchase-sales" : "/PurchaseReportToEngineer";
            const res = await http.patch(`${endpoint}/${updatedRow.id}`, formData);
            if (endpoint === "/purchase-sales") {
                await http.post("/purchase-sales", formData);
                navigate("/sells");
                toast.success("Sell created Successfully");
            } else {
                toast.success("Purchase Report To Engineer Updated Successfully");
            }
            navigate("/engineer-report-dashboard");
        } catch (err: any) {
            console.error('Error updating data:', err);
            toast.error(err.response.data && err.response.data.message);
        }
    };

    const columns: GridColDef[] = [
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 100,
            cellClassName: "actions",
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                return [
                    <IconButton onClick={(event) => handleMenuClick(event, id)}>
                        <MoreVertIcon />
                        <GridActionsCellItem
                            icon={<ArticleIcon />}
                            label="Detail"
                            className="textPrimary"
                            onClick={handleShowDetail(id)}
                            color="inherit"
                        />
                    </IconButton>,
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl && selectedRowId === id)}
                        onClose={handleMenuClose}
                    >
                        <MenuItemUI onClick={handleSaveClick}>Save</MenuItemUI>
                        <MenuItemUI onClick={handleCancelClick}>Cancel</MenuItemUI>
                        <MenuItemUI onClick={() => handleSubmit("spare_status")}>Pending for Spare</MenuItemUI>
                        <MenuItemUI onClick={() => handleSubmit("repair_status")}>Pending for Repair</MenuItemUI>
                        <MenuItemUI onClick={() => handleSubmit("fabric_status")}>Pending for Fabrication</MenuItemUI>
                        <MenuItemUI onClick={() => setShowModal(true)}>Scrap</MenuItemUI>
                        <MenuItemUI onClick={() => setShowAllOkModal(true)}>All Ok</MenuItemUI>
                    </Menu>
                ];
            },
        },
        { field: "id", headerName: "ID", width: 70 },
        {
            field: "condition",
            headerName: "Condition",
            width: 100,
            renderCell: (params) => (
                <Typography variant="body2">{getGradeCategory(params.row.createdAt)}</Typography>
            ),
        },
        {
            field: "qc1_date",
            headerName: "QC1 Date",
            width: 150,
            editable: true,
            type: 'date',
            valueFormatter: (params: any) => formatDateToDDMMYY(params),
            renderEditCell: (params: GridRenderCellParams) => <DateEditComponent {...params} />,
        },
        {
            field: "rtd_date",
            headerName: "RTD Date",
            width: 150,
            editable: true,
            type: 'date',
            valueFormatter: (params: any) => formatDateToDDMMYY(params),
            renderEditCell: (params: GridRenderCellParams) => <DateEditComponent {...params} />,
        },
        {
            field: "status",
            headerName: "Status",
            width: 150,
            editable: true,
            type: 'singleSelect',
            valueOptions: [
                { value: 'spare_status', label: 'Spare' },
                { value: 'fabric_status', label: 'Fabric' },
                { value: 'all_ok_status', label: 'All OK' },
                { value: 'repair_status', label: 'Repair' }
            ],
            renderCell: (params: GridRenderCellParams) => {
                const statusMap: { [key: string]: string } = {
                    'spare_status': 'Spare',
                    'fabric_status': 'Fabric',
                    'all_ok_status': 'All OK',
                    'repair_status': 'Repair'
                };
                return statusMap[params.value] || params.value;
            }
        },
        { field: "ls_no", headerName: "LS No", width: 100, editable: true },
        { field: "shelf_no", headerName: "Shelf No", width: 100, editable: true },
        { field: "model_no", headerName: "Model No", width: 100, editable: true },
        { field: "price", headerName: "Price", width: 100, editable: true },
        { field: "vendor_name", headerName: "Vendor Name", width: 150, editable: true },
        {
            field: 'brand_master_id',
            headerName: 'Brand',
            width: 150,
            editable: true,
            type: 'singleSelect',
            valueOptions: brandMasters.map((brand: any) => ({
                value: brand.id,
                label: brand.name,
            })),
            valueFormatter: (params: GridRenderCellParams) => {
                const brand = brandMasters.find((b: any) => b.id == params);
                return brand ? brand?.name : '';
            },
        },
        {
            field: 'series_master_id',
            headerName: 'Series Master',
            width: 150,
            editable: true,
            type: 'singleSelect',
            valueOptions: seriesMasters.map((brand: any) => ({
                value: brand.id,
                label: brand.name,
            })),
            valueFormatter: (params: GridRenderCellParams) => {
                const brand = brandMasters.find((b: any) => b.id == params);
                return brand ? brand?.name : '';
            },
        },
        {
            field: 'processor_brand_id',
            headerName: 'Processor Brand',
            width: 150,
            editable: true,
            type: 'singleSelect',
            valueOptions: processorBrands.map((processorBrand) => ({
                value: processorBrand.id,
                label: processorBrand.name,
            })),
            valueFormatter: (params: GridRenderCellParams) => {
                const processorBrand = processorBrands.find((pb) => pb.id == params);
                return processorBrand ? processorBrand.name : '';
            },
        },
        {
            field: 'processor_series_id',
            headerName: 'Processor Series',
            width: 150,
            editable: true,
            type: 'singleSelect',
            valueOptions: processorSeries.map((processorSerie) => ({
                value: processorSerie.id,
                label: processorSerie.name,
            })),
            valueFormatter: (params: GridRenderCellParams) => {
                const processorSerie = processorSeries.find((pb) => pb.id == params);
                return processorSerie ? processorSerie.name : '';
            },
        },
        {
            field: 'processor_generation_id',
            headerName: 'Processor Generation',
            width: 150,
            editable: true,
            type: 'singleSelect',
            valueOptions: processorGenerations.map((generation) => ({
                value: generation.id,
                label: generation.name,
            })),
            valueFormatter: (params: GridRenderCellParams) => {
                const generation = processorGenerations.find((g) => g.id == params);
                return generation ? generation.name : '';
            },
        },
        {
            field: 'ram_id',
            headerName: 'RAM',
            width: 150,
            editable: true,
            type: 'singleSelect',
            valueOptions: rams.map((ram) => ({
                value: ram.id,
                label: ram.name,
            })),
            renderCell: (params: GridRenderCellParams) => {
                const ram = rams.find((r) => r.id == params.row.ram_id);
                return ram ? ram.name : '';
            },
        },
        {
            field: 'hard_disk_id',
            headerName: 'Hard Disk',
            width: 150,
            editable: true,
            type: 'singleSelect',
            valueOptions: hardDisks.map((hardDisk) => ({
                value: hardDisk.id,
                label: hardDisk.name,
            })),
            renderCell: (params: GridRenderCellParams) => {
                const hardDisk = hardDisks.find((h) => h.id == params.row.hard_disk_id);
                return hardDisk ? hardDisk.name : '';
            },
        },
        {
            field: 'ssd_id',
            headerName: 'SSD',
            width: 150,
            editable: true,
            type: 'singleSelect',
            valueOptions: ssds.map((ssd) => ({
                value: ssd.id,
                label: ssd.name,
            })),
            renderCell: (params: GridRenderCellParams) => {
                const ssd = ssds.find((s) => s.id == params.row.ssd_id);
                return ssd ? ssd.name : '';
            },
        },
        {
            field: 'screen_size_id',
            headerName: 'Screen Size',
            width: 150,
            editable: true,
            type: 'singleSelect',
            valueOptions: screenSizes.map((size) => ({
                value: size.id,
                label: size.name,
            })),
            renderCell: (params: GridRenderCellParams) => {
                const size = screenSizes.find((s) => s.id == params.row.screen_size_id);
                return size ? size.name : '';
            },
        },
        {
            field: 'screen_resolution_id',
            headerName: 'Screen Resolution',
            width: 150,
            editable: true,
            type: 'singleSelect',
            valueOptions: screenResolutions.map((resolution) => ({
                value: resolution.id,
                label: resolution.name,
            })),
            renderCell: (params: GridRenderCellParams) => {
                const resolution = screenResolutions.find((r) => r.id == params.row.screen_resolution_id);
                return resolution ? resolution.name : '';
            },
        },
        {
            field: 'screen_spot_id',
            headerName: 'Screen Spot',
            width: 150,
            editable: true,
            type: 'singleSelect',
            valueOptions: screenSpots.map((spot) => ({
                value: spot.id,
                label: spot.name,
            })),
            display:'text',
            renderCell: (params: GridRenderCellParams) => {
                const spot = screenSpots.find((s) => s.id == params.row.screen_spot_any_id);
                return spot ? spot.name : '';
            },
        },
        {
            field: 'physical_condition_id',
            headerName: 'Physical Condition',
            width: 150,
            editable: true,
            type: 'singleSelect',
            valueOptions: physicalConditions.map((condition) => ({
                value: condition.id,
                label: condition.name,
            })),
            renderCell: (params: GridRenderCellParams) => {
                const condition = physicalConditions.find((c) => c.id == params.row.physical_condition_id);
                return condition ? condition.name : '';
            },
        },
        {
            field: 'functional_condition_id',
            headerName: 'Functional Condition',
            width: 150,
            editable: true,
            type: 'singleSelect',
            valueOptions: functionalConditions.map((condition) => ({
                value: condition.id,
                label: condition.name,
            })),
            renderCell: (params: GridRenderCellParams) => {
                const condition = functionalConditions.find((c) => c.id == params.row.functional_condition_id);
                return condition ? condition.name : '';
            },
        },
        {
            field: 'age_of_laptop_id',
            headerName: 'Age of Laptop',
            width: 150,
            editable: true,
            type: 'singleSelect',
            valueOptions: ageOfLaptops.map((age) => ({
                value: age.id,
                label: age.name,
            })),
            renderCell: (params: GridRenderCellParams) => {
                const age = ageOfLaptops.find((a) => a.id == params.row.age_of_laptop_id);
                return age ? age.name : '';
            },
        },
        {
            field: 'processor_gigaHeartz_id',
            headerName: 'Processor GigaHeartz',
            width: 150,
            editable: true,
            type: 'singleSelect',
            valueOptions: processorGigaHeartzs.map((pgigaHeartz) => ({
                value: pgigaHeartz.id,
                label: pgigaHeartz.name,
            })),
            renderCell: (params: GridRenderCellParams) => {
                const gigaHeartz = processorGigaHeartzs.find((a) => a.id == params.row.processor_gigaHeartz_id);
                return gigaHeartz ? gigaHeartz.name : '';
            },
        },
        {
            field: 'graphicBrands_id',
            headerName: 'Graphic Brand',
            width: 150,
            editable: true,
            type: 'singleSelect',
            valueOptions: graphicBrands.map((brand) => ({
                value: brand.id,
                label: brand.name,
            })),
            renderCell: (params: GridRenderCellParams) => {
                const brand = graphicBrands.find((b) => b.id == params.row.graphic_brand_id);
                return brand ? brand.name : '';
            },
        },
        {
            field: 'graphic_series_id',
            headerName: 'Graphic Series',
            width: 150,
            editable: true,
            type: 'singleSelect',
            valueOptions: graphicSeries.map((graphicSeries) => ({
                value: graphicSeries.id,
                label: graphicSeries.name,
            })),
            valueFormatter: (params: GridRenderCellParams) => {
                const graphicSerie = graphicSeries.find((gs) => gs.id == params);
                return graphicSeries ? graphicSerie?.name : '';
            },
        },
        {
            field: 'graphic_model_id',
            headerName: 'Graphic Model',
            width: 150,
            editable: true,
            type: 'singleSelect',
            valueOptions: graphicModels.map((graphicModel) => ({
                value: graphicModel.id,
                label: graphicModel.name,
            })),
            valueFormatter: (params: GridRenderCellParams) => {
                const graphicModel = graphicModels.find((gm) => gm.id == params);
                return graphicModel ? graphicModel.name : '';
            },
        },
        {
            field: 'graphic_size_id',
            headerName: 'Graphic Size',
            width: 150,
            editable: true,
            type: 'singleSelect',
            valueOptions: graphicSizes.map((graphicSize) => ({
                value: graphicSize.id,
                label: graphicSize.name,
            })),
            valueFormatter: (params: GridRenderCellParams) => {
                const graphicSize = graphicSizes.find((gs) => gs.id == params);
                return graphicSize ? graphicSize.name : '';
            },
        },
    ];

    const handleProcessRowUpdateError = (error: any) => {
        toast.error("Error updating data");
        console.error("Error updating data:", error);
    };


    return (
        <div className="">
            <PageTitleCard title="Engineer Tasks">
                <div style={{ marginTop: "3px" }}></div>
                <div style={{ display: "flex", justifyContent: "flex-start", gap: "6px", width: "100%" }} className="flex-wrap">
                    <TextField
                     size="small"
                        placeholder="Shelf No"
                        value={filterShelfNo}
                        onChange={(e) => setFilterShelfNo(e.target.value)}
                        className="w-28 h-6 text-black rounded-md px-2 border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-zinc-50 text-sm"
                    />
                    <TextField
                         size="small"
                        placeholder="Price"
                        value={filterPrice}
                        onChange={(e) => setFilterPrice(e.target.value)}
                        className="h-8 rounded-md px-2 border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-zinc-50 text-sm"
                    />
                    <FormControl  className="w-[160px] h-8" >
                        <InputLabel id="status-filter-label" className="text-sm">Status</InputLabel>
                        <Select
                            labelId="status-filter-label"
                            id="status-filter-select"
                            value={filterStatus}
                            onChange={(e) => setFilterStatus(e.target.value as string)}
                            className="h-8"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value="spare_status">Spare</MenuItem>
                            <MenuItem value="fabric_status">Fabric</MenuItem>
                            <MenuItem value="repair_status">Repair</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                         size="small"
                        placeholder="Lsn_No"
                        value={filterLsnNo}
                        onChange={(e) => setFilterLsnNo(e.target.value)}
                        className="h-8 rounded-md px-2 border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-zinc-50 text-sm"
                    />
                </div>

            </PageTitleCard>

            <Paper className="w-full h-[660px] ">
                <DataGrid
                    rows={purchaseData.filter(
                        (row) =>
                            (!filterShelfNo || (row.shelf_no && row.shelf_no.toLowerCase().includes(filterShelfNo.toLowerCase()))) &&
                            (!filterPrice || (row.price && row.price.toString().includes(filterPrice))) &&
                            (!filterStatus || row.status === filterStatus) &&
                            (!filterLsnNo || (row.ls_no && row.ls_no.toLowerCase().includes(filterLsnNo.toLowerCase())))
                    )}
                    columns={columns}
                    editMode="row"
                    paginationMode="server"
                    rowCount={totalRows}
                    loading={isLoading}
                    pageSizeOptions={[5, 10, 25, 50]}
                    paginationModel={paginationModel}
                    onPaginationModelChange={(model) => setPaginationModel(model)}
                    getRowId={(row) => row.id || row.ls_no}
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    onProcessRowUpdateError={handleProcessRowUpdateError}
                    sx={{
                        '& .MuiDataGrid-root': {
                            fontSize: '0.8rem',
                        },
                        '& .MuiDataGrid-row': {
                            maxHeight: '42px !important',
                            minHeight: '42px !important',
                        },
                    }}
                />
            </Paper>

            {selectedRowId !== null && (
                <DeleteModal
                    showModal={showModal}
                    setShowModal={() => setShowModal(!showModal)}
                    paramId={selectedRowId}
                    scrapData={purchaseData.find((row) => row.id === selectedRowId)}
                />
            )}

            {showAllOkModal && (
                <Dialog
                    open={showAllOkModal}
                    onClose={() => setShowAllOkModal(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                        style: {
                            backgroundColor: "white",
                            padding: "50px",
                            borderRadius: "10px",
                            // Add any other styles you want
                        },
                    }}
                >
                    <DialogTitle id="alert-dialog-title">{"Select Sales Type"}</DialogTitle>
                    <DialogContent>
                        <EditPurchaseSelectItem
                            onValueChange={(e: any) => {
                                const target = e.target as HTMLInputElement;
                                setIsValueSelected(target.value !== "");
                                const updatedRow = purchaseData.find((row) => row.id === selectedRowId);
                                if (updatedRow) {
                                    updatedRow.all_ok_type = target.value;
                                    setPurchaseData([...purchaseData]);
                                }
                            }}
                            title={"Sales Type"}
                            formName={"all_ok_type"}
                            formValue={purchaseData.find((row) => row.id === selectedRowId)?.all_ok_type || ""}
                            items={[
                                { id: 0, name: "B2B" },
                                { id: 1, name: "B2C" },
                            ]}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setShowAllOkModal(false)} color="primary">
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                if (!isValueSelected) {
                                    toast.error("Please select a value before submitting.");
                                } else {
                                    handleSubmit("all_ok_status", purchaseData.find((row) => row.id === selectedRowId)?.all_ok_type);
                                    setShowAllOkModal(false);
                                }
                            }}
                            color="primary"
                            autoFocus
                            disabled={!isValueSelected}
                        >
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
};

export default UpdatePurchase;
