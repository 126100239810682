import axios from "axios"
const serverImgUrl = "https://aipl.lapshop.in"
const localImgUrl = "http://localhost:4000"
const http = axios.create({
    baseURL:
        process.env.NODE_ENV !== "production"
            ? "http://localhost:4000/api"
            : "https://aipl.lapshop.in/api",
})
// "http://localhost:4000/api"

http.interceptors.request.use((req: any) => {
    let token = localStorage.getItem("AUTH_TOKEN")
    if (token && req.headers) {
        req.headers.Authorization = `Bearer ${token}`
    }
    return req
})

export { http, serverImgUrl, localImgUrl }
