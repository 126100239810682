import * as React from "react"
import Box from "@mui/material/Box"
import { useState, useEffect } from "react"
import { http, serverImgUrl } from "../../utils/http"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import EditPurchaseSelectItem from "../../components/ui/EditPurchaseSelectItem"
import EditPurchaseImageItem from "../../components/ui/EditPurchaseImageItem"
import PurchaseFormBlockRow from "../../components/ui/PurchaseFormBlockRow"
import FormRow from "../../components/ui/FormRow"
import FormTextEngineer from "../../components/ui/FormTextEngineer"
import Typography from "@mui/material/Typography"

import { useNavigate } from "react-router-dom"

import {
    FormGroup,
    FormControl,
    Grid,
    Button,
    styled,
    OutlinedInput,
    FormHelperText,
    TextField,
    Modal,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material"
import { create } from "@mui/material/styles/createTransitions"
import EngineerDashboardRow from "../../components/ui/EngineerDashboardRow"
import { differenceInMilliseconds, formatDistance } from "date-fns"
import DeleteModal from "../../components/ui/DeleteModal"
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"

const Container = styled(FormGroup)`
    width: 100%;
`
const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
}
{
    /* <Typography variant="h4">Add New Employee</Typography> */
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const UpdatePurchase = () => {
    const [brandMasters, setBrandMaster] = useState([])
    const [seriesMasters, setSeriesMaster] = useState([])

    const [processorBrands, setProcessorBrand] = useState([])
    const [processorSeries, setProcessorSeries] = useState([])
    const [processorGenerations, setProcessorGeneration] = useState([])
    const [processorGigaHeartzs, setProcessorGigaHeartz] = useState([])

    const [graphicBrands, setGraphicBrand] = useState([])
    const [graphicSeries, setGraphicSeries] = useState([])
    const [graphicModels, setGraphicModel] = useState([])
    const [graphicSizes, setGraphicSize] = useState([])

    const [screenSizes, setScreenSize] = useState([])
    const [screenResolutions, setScreenResolution] = useState([])
    const [screenSpots, setScreenSpot] = useState([])

    const [allspec, setAllSpecs] = useState([])

    const [rams, setram] = useState([])
    const [hardDisks, setHardDisk] = useState([])
    const [externalGraphics, setExternalGraphics] = useState([])
    const [ssds, setSSD] = useState([])
    const [ageOfLaptops, setAgeOfLaptop] = useState([])
    const [physicalConditions, setPhysicalConditions] = useState([])
    const [functionalConditions, setfunctionalCondition] = useState([])
    const [employees, setEmployees] = useState([])
    const [tatcal, setTatCal] = useState<string | undefined>()
    const [tat, setTat] = useState<string | undefined>()

    const [showModal, setShowModal] = useState<boolean>(false)
    const [isValueSelected, setIsValueSelected] = useState(false)

    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/purchase/specs")

            const result = data.data.result

            setBrandMaster(result.brandMasters)
            setSeriesMaster(result.seriesMasters)

            setProcessorBrand(result.processorBrands)
            setProcessorSeries(result.processorSeries)
            setProcessorGeneration(result.processorGenerations)
            setProcessorGigaHeartz(result.processorGigaHeartzs)

            setGraphicBrand(result.graphicBrands)
            setGraphicSeries(result.graphicSeries)
            setGraphicModel(result.graphicModels)
            setGraphicSize(result.graphicSizes)

            setScreenSize(result.screenSizes)
            setScreenResolution(result.screenResolutions)
            setScreenSpot(result.screenSpots)

            setEmployees(result.employees)
            setram(result.rams)
            setHardDisk(result.hardDisks)
            setExternalGraphics(result.externalGraphics)
            setSSD(result.ssds)
            setAgeOfLaptop(result.ageOfLaptops)
            setPhysicalConditions(result.physicalConditions)
            setfunctionalCondition(result.functionalConditions)
        })()
    }, [])
    const [selectedAllOkType, setSelectedAllOkType] = useState<string>("")
    const [showallokModal, setShowallokModal] = useState(false)
    const [isActive, setIsActive] = useState(false)
    interface UpdatePayload {
        status: string
        tat?: string
        all_ok_type: string
    }   

    const handleSubmit = (statusName: any, tat?: string) => {
        console.log(data.all_ok_type)

        setTimeout(() => {
            setIsActive(true)
            let updatePayload: UpdatePayload = {
                status: statusName,
                all_ok_type: data.all_ok_type,
            }

            // If the status is "all_ok_status", include the passed tat value
            if (statusName === "all_ok_status" && tat) {
                updatePayload = { ...updatePayload, tat: tat }
            }

            // Proceed to update data
            updateData(updatePayload)
        }, 500)
    }

    let params = useParams()
    const navigate = useNavigate()
    const imgs = "https://picsum.photos/200"

    const [data, setPurchase] = useState({
        brand_master_id: "",
        series_master_id: "",
        model_no: "",
        processor_brand_id: "",
        processor_series_id: "",
        processor_generation_id: "",
        processor_gigaHeartz_id: "",
        graphic_brand_id: "",
        graphic_series_id: "",
        graphic_model_id: "",
        graphic_size_id: "",
        screen_size_id: "",
        screen_resolution_id: "",
        screen_spot_any_id: "",
        ips: false,
        num_lock: false,
        back_lit: false,
        ls_no: "",
        product_type: false,
        processor_id: "",
        processor_image: "",
        ram_image: "",
        general_image: "",
        hard_disk_image: "",
        ssd_image: "",
        external_graphic_image: "",
        screen_size_image: "",
        screen_size_image2: "",
        screen_size_image3: "",
        screen_size_image4: "",
        screen_size_image5: "",
        age_of_laptop_image: "",
        physical_condition_image: "",
        physical_condition_image2: "",
        physical_condition_image3: "",
        physical_condition_image4: "",
        physical_condition_image5: "",
        functional_condition_image: "",
        functional_condition_image2: "",
        functional_condition_image3: "",
        functional_condition_image4: "",
        functional_condition_image5: "",
        ram_id: "",
        hard_disk_id: "",
        ssd_id: "",
        external_graphics_id: "",
        age_of_laptop_id: "",
        physical_condition_id: "",
        functional_condition_id: "",
        date: "",
        price: "",
        vendor_name: "",
        shelf_no: "",
        pickup_person: "",
        charger_type: "",
        problem: "",
        payment_mode: false,
        description: "",
        touchpad_working: false,
        touchpad_button: false,
        keyboard: false,
        webcam: false,
        wifi: false,
        usb_working: false,
        blutooth: false,
        lan: false,
        hdmi: false,
        vga: false,
        mic: false,
        biometric_sensor: false,
        speaker_left: false,
        speaker_right: false,
        battery: false,
        is_graphic: false,
        createdAt: "",
        tat: "",
        id: params.id,
        rt: "2",
        received_type: "",
        status: "",
        qc1_date: "",
        rtd_date: "",
        part_price: "",
        all_ok_type: "",
    })

    useEffect(() => {
        ;(async function () {
            try {
                const salDataRes: any = await http.get("/PurchaseReportToEngineer/" + params.id)
                // console.log("purchase 1", salDataRes);

                setPurchase(salDataRes.data.result)
                setTatCal(salDataRes.data.result.date)

                // Calculate the TAT here and set the state
                const currentDate = new Date()
                const differenceInMilliseconds =
                    currentDate.getTime() - new Date(salDataRes.data.result.date).getTime()
                const days = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24))

                // Make sure that 'tat' is properly set
                const tatValue = days.toLocaleString()
                setTat(tatValue)
            } catch (error) {
                console.error("Error fetching data:", error)
            }
        })()
    }, [params.id])

    const onValueChange = (e: any, name?: string) => {
        if (e instanceof Date) {
            // Handle DatePicker change
            const dateString = e.toISOString()
            if (name) {
                setPurchase({ ...data, [name]: dateString })
            }
        } else if (e instanceof File) {
            // Handle file input
            const file = e
            const imageUrl = URL.createObjectURL(file)
            if (name) {
                setPurchase({ ...data, [name]: imageUrl })
            }
        } else if (e) {
            // Handle other form inputs
            if (name === "all_ok_type") {
                console.log("allokty")
                setIsValueSelected(e.target.value !== "")
            }
            const target = e.target as HTMLInputElement
            setPurchase({ ...data, [target.name]: target.value })
        }
    }

    const updateData = async (forceUpdateData: any = {}) => {
        // submit data
        ;(async function () {
            console.log("Sending to backend:", data.tat, tatcal, tat)
            // console.log({...forceUpdateData });

            let dataCopy: any = { ...data, ...forceUpdateData }
            console.log("daatacopy", dataCopy)

            const formData: any = new FormData()
            var endpoint = "/PurchaseReportToEngineer"

            if (dataCopy["status"] === "all_ok_status") {
                endpoint = "/purchase-sales"
            }

            for (let itemKey in dataCopy) {
                formData.append(itemKey, dataCopy[itemKey])
            }

            try {
                if (endpoint === "/PurchaseReportToEngineer") {
                    const res = await http.patch(`${endpoint}/${params.id}`, formData)
                    toast.success("Purchase Report To Engineer Updated Successfully")
                    navigate("/engineer-report-dashboard")
                } else {
                    const res = await http.patch(`/PurchaseReportToEngineer/${params.id}`, formData)
                    const ressales = await http.post(`${endpoint}`, formData)
                    toast.success("Sell created Successfully")
                    navigate("/sells")
                }
            } catch (err: any) {
                toast.error(err.response.data && err.response.data.message)
                // console.log("error in loading details", err);
            }
        })()
    }

    const scrapData = {
        ls_no: data.ls_no,
        purchase_id: data.id,
        date: data.date,
        price: data.price,
        tat: data.tat,
        product_type: data.product_type,
        received_type: data.received_type,
        status: data.status,
        qc1_date: data.qc1_date,
        rtd_date: data.rtd_date,
    }

    const TATDate = new Date(tatcal ?? new Date())
    const currentDate = new Date()
    const differenceInMilliseconds = currentDate.getTime() - TATDate.getTime()
    const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000)
    const seconds = differenceInSeconds % 60
    const differenceInMinutes = Math.floor(differenceInSeconds / 60)
    const minutes = differenceInMinutes % 60
    const differenceInHours = Math.floor(differenceInMinutes / 60)
    const hours = differenceInHours % 24
    const days = Math.floor(differenceInHours / 24)

    // setTat(days)

    const label = { inputProps: { "aria-label": "Checkbox demo" } }

    return (
        <div>
            {/* <div>ALL OK-{isActive}</div> */}
            <Typography
                sx={{
                    fontSize: "1.0em",
                    fontWeight: "bold",
                    marginTop: 1,
                    marginBottom: 1,
                }}
            >
                Pickup Person: {data.pickup_person}
            </Typography>

            <form onSubmit={(e) => e.preventDefault()}>
                <FormGroup>
                    <Grid container spacing={1}>
                        <Grid item xs={9} spacing={1} sx={{ pr: 3, borderRight: "2px solid #ccc" }}>
                            <EngineerDashboardRow>
                                <Box>
                                    {/* First column content */}
                                    <FormRow
                                        onValueChange={onValueChange}
                                        label={"LS No"}
                                        formName={"ls_no"}
                                        formValue={data.ls_no}
                                        textInputMode
                                        readonly={true}
                                    />
                                    <FormRow
                                        onValueChange={onValueChange}
                                        label={"Shelf No"}
                                        formName={"shelf_no"}
                                        formValue={data.shelf_no}
                                        textInputMode
                                        readonly={true}
                                    />
                                </Box>
                                <Box>
                                    {/* Second column content */}
                                    <FormRow
                                        label="Pickup Date"
                                        textInputMode
                                        formValue={new Date(data.date).toLocaleDateString("en-IN", {
                                            day: "numeric",
                                            month: "long",
                                            year: "numeric",
                                        })}
                                        formName="date"
                                        onValueChange={onValueChange}
                                        disabled
                                        readonly={true}
                                    />
                                    <FormRow
                                        label="Pickup Time"
                                        textInputMode
                                        formValue={new Date(data.date).toLocaleTimeString("en-IN", {
                                            hour: "2-digit",
                                            minute: "2-digit",
                                        })}
                                        formName="pickup time"
                                        onValueChange={onValueChange}
                                        disabled
                                        readonly={true}
                                    />
                                </Box>
                                <Box>
                                    {/* Third column content */}
                                    <FormRow
                                        label="Arrival Date"
                                        textInputMode
                                        formValue={new Date(data.createdAt).toLocaleDateString(
                                            "en-IN",
                                            { day: "numeric", month: "long", year: "numeric" }
                                        )}
                                        formName="date"
                                        onValueChange={onValueChange}
                                        disabled
                                        readonly={true}
                                    />
                                    <FormRow
                                        label="Arrival time"
                                        textInputMode
                                        formValue={new Date(data.createdAt).toLocaleTimeString(
                                            "en-IN",
                                            { hour: "2-digit", minute: "2-digit" }
                                        )}
                                        formName="date"
                                        onValueChange={onValueChange}
                                        disabled
                                        readonly={true}
                                    />
                                </Box>
                            </EngineerDashboardRow>

                            <PurchaseFormBlockRow gridnum={1}>
                                <Box className="mx-16">
                                    <FormRow
                                        label="TAT Date"
                                        textInputMode
                                        formValue={`${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`}
                                        formName="TAT_date"
                                        onValueChange={onValueChange}
                                        disabled
                                        readonly={true}
                                    ></FormRow>
                                </Box>
                            </PurchaseFormBlockRow>

                            {/* <Box sx={{ mb: 2 }}>
                                <span style={{ marginRight: 13 }}>ID :</span>
                                <OutlinedInput
                                    sx={{ background: "white" }}
                                    onChange={(e) => onValueChange(e)}
                                    name="purchase_id"
                                    value={data.id}
                                    size="small"
                                    disabled
                                />
                            </Box> */}
                            <PurchaseFormBlockRow>
                                <EditPurchaseSelectItem
                                    onValueChange={onValueChange}
                                    title={"Brand Masters"}
                                    formName={"brand_master_id"}
                                    formValue={data.brand_master_id}
                                    items={brandMasters}
                                    editable={false}
                                />
                                <EditPurchaseSelectItem
                                    onValueChange={onValueChange}
                                    title={"Series Masters"}
                                    formName={"series_master_id"}
                                    formValue={data.series_master_id}
                                    items={seriesMasters}
                                    editable={false}
                                />

                                <EditPurchaseSelectItem
                                    onValueChange={onValueChange}
                                    title={"Model No."}
                                    formName={"model_no"}
                                    formValue={data.model_no}
                                    textInputMode
                                    editable={false}
                                />
                                <EditPurchaseSelectItem
                                    onValueChange={onValueChange}
                                    title={"Price Of Parts"}
                                    formName={"part_price"}
                                    formValue={data.part_price}
                                    textInputMode
                                    editable={false}
                                />
                                <EditPurchaseImageItem
                                    title="General Image"
                                    formName={"general_image"}
                                    onValueChange={onValueChange}
                                    imageData={data.general_image}
                                />
                            </PurchaseFormBlockRow>

                            <PurchaseFormBlockRow title="Processor">
                                <EditPurchaseSelectItem
                                    onValueChange={onValueChange}
                                    title={"Brand"}
                                    formName={"processor_brand_id"}
                                    formValue={data.processor_brand_id}
                                    items={processorBrands}
                                />
                                <EditPurchaseSelectItem
                                    onValueChange={onValueChange}
                                    title={"Series"}
                                    formName={"processor_series_id"}
                                    formValue={data.processor_series_id}
                                    items={processorSeries}
                                />
                                <EditPurchaseSelectItem
                                    onValueChange={onValueChange}
                                    title={"Generation"}
                                    formName={"processor_generation_id"}
                                    formValue={data.processor_generation_id}
                                    items={processorGenerations}
                                />
                                <EditPurchaseSelectItem
                                    onValueChange={onValueChange}
                                    title={"GigaHeartz"}
                                    formName={"processor_gigaHeartz_id"}
                                    formValue={data.processor_gigaHeartz_id}
                                    items={processorGigaHeartzs}
                                />
                                <EditPurchaseImageItem
                                    title="Processor Image"
                                    formName={"processor_image"}
                                    onValueChange={onValueChange}
                                    imageData={data.processor_image}
                                />
                            </PurchaseFormBlockRow>

                            <PurchaseFormBlockRow title="Configruation">
                                <EditPurchaseSelectItem
                                    onValueChange={onValueChange}
                                    title={"Ram"}
                                    formName={"ram_id"}
                                    formValue={data.ram_id}
                                    items={rams}
                                />
                                <EditPurchaseSelectItem
                                    onValueChange={onValueChange}
                                    title={"Hard Disks"}
                                    formName={"hard_disk_id"}
                                    formValue={data.hard_disk_id}
                                    items={hardDisks}
                                />
                                <EditPurchaseSelectItem
                                    onValueChange={onValueChange}
                                    title={"SSD"}
                                    formName={"ssd_id"}
                                    formValue={data.ssd_id}
                                    items={ssds}
                                />
                                <EditPurchaseImageItem
                                    title="Ram Image"
                                    formName={"ram_image"}
                                    onValueChange={onValueChange}
                                    imageData={data.ram_image}
                                />
                                <EditPurchaseImageItem
                                    title="Hard Disk Image"
                                    formName={"hard_disk_image"}
                                    onValueChange={onValueChange}
                                    imageData={data.hard_disk_image}
                                />
                                {/* <p>{data.ssd_image}</p> */}
                                <EditPurchaseImageItem
                                    title="SSD Image"
                                    formName={"ssd_image"}
                                    onValueChange={onValueChange}
                                    imageData={data.ssd_image}
                                />
                            </PurchaseFormBlockRow>
                            {data.is_graphic ? (
                                <PurchaseFormBlockRow title="Dedicated Graphics">
                                    <EditPurchaseSelectItem
                                        onValueChange={onValueChange}
                                        title={"Brand"}
                                        formName={"graphic_brand_id"}
                                        formValue={data.graphic_brand_id}
                                        items={graphicBrands}
                                    />
                                    <EditPurchaseSelectItem
                                        onValueChange={onValueChange}
                                        title={"Series"}
                                        formName={"graphic_series_id"}
                                        formValue={data.graphic_series_id}
                                        items={graphicSeries}
                                    />
                                    <EditPurchaseSelectItem
                                        onValueChange={onValueChange}
                                        title={"Model"}
                                        formName={"graphic_model_id"}
                                        formValue={data.graphic_model_id}
                                        items={graphicModels}
                                    />
                                    <EditPurchaseSelectItem
                                        onValueChange={onValueChange}
                                        title={"Size"}
                                        formName={"graphic_size_id"}
                                        formValue={data.graphic_size_id}
                                        items={graphicSizes}
                                    />
                                    <EditPurchaseImageItem
                                        title="Graphics Image"
                                        onValueChange={onValueChange}
                                        formName={"external_graphic_image"}
                                        imageData={data.external_graphic_image}
                                    />
                                </PurchaseFormBlockRow>
                            ) : (
                                ""
                            )}

                            <PurchaseFormBlockRow title="Screen/Display">
                                <EditPurchaseSelectItem
                                    onValueChange={onValueChange}
                                    title={"Size"}
                                    formName={"screen_size_id"}
                                    formValue={data.screen_size_id}
                                    items={screenSizes}
                                />
                                <EditPurchaseSelectItem
                                    onValueChange={onValueChange}
                                    title={"Resolution"}
                                    formName={"screen_resolution_id"}
                                    formValue={data.screen_resolution_id}
                                    items={screenResolutions}
                                />
                                <EditPurchaseSelectItem
                                    onValueChange={onValueChange}
                                    title={"Spot Any"}
                                    formName={"screen_spot_any_id"}
                                    formValue={data.screen_spot_any_id}
                                    items={screenSpots}
                                />
                                <EditPurchaseImageItem
                                    title="Screen Size Image"
                                    onValueChange={onValueChange}
                                    formName={"screen_size_image"}
                                    imageData={data.screen_size_image}
                                />
                                <EditPurchaseImageItem
                                    title="Screen Size Image 2"
                                    onValueChange={onValueChange}
                                    formName={"screen_size_image2"}
                                    imageData={data.screen_size_image2}
                                />
                                <EditPurchaseImageItem
                                    title="Screen Size Image 3"
                                    onValueChange={onValueChange}
                                    formName={"screen_size_image3"}
                                    imageData={data.screen_size_image3}
                                />
                                <EditPurchaseImageItem
                                    title="Screen Size Image 4"
                                    formName={"screen_size_image4"}
                                    onValueChange={onValueChange}
                                    imageData={data.screen_size_image4}
                                />
                                <EditPurchaseImageItem
                                    title="Screen Size Image 5"
                                    formName={"screen_size_image5"}
                                    onValueChange={onValueChange}
                                    imageData={data.screen_size_image5}
                                />
                            </PurchaseFormBlockRow>

                            <PurchaseFormBlockRow>
                                <EditPurchaseSelectItem
                                    onValueChange={onValueChange}
                                    title={"Select Charger"}
                                    formName={"charger_type"}
                                    formValue={data.charger_type}
                                    items={[
                                        {
                                            id: 0,
                                            name: "Without Charger",
                                        },
                                        {
                                            id: 1,
                                            name: "With Carger",
                                        },
                                    ]}
                                />
                                <EditPurchaseSelectItem
                                    onValueChange={onValueChange}
                                    title={" Age Of Laptop"}
                                    formName={"age_of_laptop_id"}
                                    formValue={data.age_of_laptop_id}
                                    items={ageOfLaptops}
                                />
                                <EditPurchaseImageItem
                                    formName={"age_of_laptop_image"}
                                    title="Age Of Laptop Image"
                                    onValueChange={onValueChange}
                                    imageData={data.age_of_laptop_image}
                                />
                            </PurchaseFormBlockRow>

                            {/* <PurchaseFormBlockRow>
                <EditPurchaseSelectItem
                  onValueChange={onValueChange}
                  title={"Physical Condition"}
                  formName={"physical_condition_id"}
                  formValue={data.physical_condition_id}
                  items={physicalConditions}
                />
                <EditPurchaseSelectItem
                  onValueChange={onValueChange}
                  title={"Functional Condition"}
                  formName={"functional_condition_id"}
                  formValue={data.functional_condition_id}
                  items={functionalConditions}
                />

                <EditPurchaseImageItem
                  title="Physical Condition Image"
                  onValueChange={onValueChange}
                  imageData={data.physical_condition_image}
                />
                <EditPurchaseImageItem
                  title="Functional Condition Image"
                  onValueChange={onValueChange}
                  imageData={data.functional_condition_image}
                />
              </PurchaseFormBlockRow> */}

                            <PurchaseFormBlockRow>
                                <EditPurchaseSelectItem
                                    onValueChange={onValueChange}
                                    title={"Physical Condition"}
                                    formName={"physical_condition_id"}
                                    formValue={data.physical_condition_id}
                                    items={physicalConditions}
                                />

                                <EditPurchaseImageItem
                                    title="Physical Condition Image"
                                    onValueChange={onValueChange}
                                    formName={"physical_condition_image"}
                                    //  formValue={data.physical_condition_image}
                                    imageData={data.physical_condition_image}
                                />
                                <EditPurchaseImageItem
                                    title="Physical Condition Image 2"
                                    formName={"physical_condition_image2"}
                                    //  formValue={data.physical_condition_image2}
                                    onValueChange={onValueChange}
                                    imageData={data.physical_condition_image2}
                                />
                                <EditPurchaseImageItem
                                    title="Physical Condition Image 3"
                                    formName={"physical_condition_image3"}
                                    // formValue={data.physical_condition_image3}
                                    onValueChange={onValueChange}
                                    imageData={data.physical_condition_image3}
                                />
                                <EditPurchaseImageItem
                                    title="Physical Condition Image 4"
                                    formName={"physical_condition_image4"}
                                    // formValue={data.physical_condition_image4}
                                    onValueChange={onValueChange}
                                    imageData={data.physical_condition_image4}
                                />
                                <EditPurchaseImageItem
                                    title="Physical Condition Image 5"
                                    formName={"physical_condition_image5"}
                                    onValueChange={onValueChange}
                                    imageData={data.physical_condition_image5}
                                />
                            </PurchaseFormBlockRow>

                            <PurchaseFormBlockRow>
                                <EditPurchaseSelectItem
                                    onValueChange={onValueChange}
                                    title={"Functional Condition"}
                                    formName={"functional_condition_id"}
                                    formValue={data.functional_condition_id}
                                    items={functionalConditions}
                                />
                                <EditPurchaseImageItem
                                    title="Functional Condition Image"
                                    formName={"functional_condition_image"}
                                    //formValue={data.functional_condition_image}
                                    onValueChange={onValueChange}
                                    imageData={data.functional_condition_image}
                                />

                                <EditPurchaseImageItem
                                    title="Functional Condition Image 2"
                                    formName={"functional_condition_image2"}
                                    // formValue={data.functional_condition_image2}
                                    onValueChange={onValueChange}
                                    imageData={data.functional_condition_image2}
                                />
                                <EditPurchaseImageItem
                                    title="Functional Condition Image 3"
                                    formName={"functional_condition_image3"}
                                    // formValue={data.functional_condition_image3}
                                    onValueChange={onValueChange}
                                    imageData={data.functional_condition_image3}
                                />
                                <EditPurchaseImageItem
                                    title="Functional Condition Image 4"
                                    formName={"functional_condition_image4"}
                                    // formValue={data.functional_condition_image4}
                                    onValueChange={onValueChange}
                                    imageData={data.functional_condition_image4}
                                />
                                <EditPurchaseImageItem
                                    title="Functional Condition Image 5"
                                    formName={"functional_condition_image5"}
                                    // formValue={data.functional_condition_image5}
                                    onValueChange={onValueChange}
                                    imageData={data.functional_condition_image5}
                                />
                            </PurchaseFormBlockRow>
                            <PurchaseFormBlockRow gridnum={2}>
                                <FormControl sx={{ width: "80%" }}>
                                    <FormHelperText>
                                        <h3 className="font-black text-black">QC1 Date</h3>
                                    </FormHelperText>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            value={data.qc1_date ? new Date(data.qc1_date) : null}
                                            onChange={(newDate) =>
                                                onValueChange(newDate, "qc1_date")
                                            }
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                                <FormControl sx={{ width: "80%" }}>
                                    <FormHelperText>
                                        <h3 className="font-black text-black">RTD Date</h3>
                                    </FormHelperText>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            value={data.rtd_date ? new Date(data.rtd_date) : null}
                                            onChange={(newDate) =>
                                                onValueChange(newDate, "rtd_date")
                                            }
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </PurchaseFormBlockRow>
                            <Button
                                variant="contained"
                                size="small"
                                sx={{
                                    background: "red",
                                    mr: 2,
                                    maxWidth: "auto",
                                }}
                                onClick={() => handleSubmit("spare_status")}
                            >
                                PENDING FOR SPARE
                            </Button>
                            <Button
                                variant="contained"
                                size="small"
                                sx={{
                                    background: "red",
                                    mr: 2,
                                    maxWidth: "auto",
                                }}
                                onClick={() => handleSubmit("repair_status")}
                            >
                                PENDING FOR REPAIR
                            </Button>

                            <Button
                                variant="contained"
                                size="small"
                                sx={{
                                    background: "red",
                                    mr: 2,
                                    maxWidth: "auto",
                                }}
                                onClick={() => handleSubmit("fabric_status")}
                            >
                                PENDING FOR FABRICATION
                            </Button>
                            <div className="my-3"></div>
                            <Button
                                variant="contained"
                                size="small"
                                sx={{
                                    background: "blue",
                                    minWidth: "150px",
                                    mr: "15px",
                                }}
                                onClick={() => setShowModal(!showModal)}
                            >
                                Scrap
                            </Button>
                            {showModal && (
                                <DeleteModal
                                    showModal={showModal}
                                    setShowModal={() => setShowModal(!showModal)}
                                    paramId={params.id}
                                    scrapData={scrapData}
                                />
                            )}
                            <Button
                                variant="contained"
                                size="small"
                                sx={{
                                    background: "green",
                                    minWidth: "150px",
                                }}
                                onClick={() => setShowallokModal(true)}
                            >
                                All Ok
                            </Button>
                            <div style={{ height: 50 }}></div>
                            {showallokModal && (
                                <Dialog
                                    open={showallokModal}
                                    onClose={() => setShowallokModal(false)}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    PaperProps={{
                                        style: {
                                            backgroundColor: "white",
                                            padding: "50px",
                                            borderRadius: "10px",
                                            // Add any other styles you want
                                        },
                                    }}
                                >
                                    <DialogTitle id="alert-dialog-title">
                                        {"Select Sales Type"}
                                    </DialogTitle>
                                    <DialogContent>
                                        <EditPurchaseSelectItem
                                            onValueChange={(e: any) =>
                                                onValueChange(e, "all_ok_type")
                                            }
                                            title={"Sales Type"}
                                            formName={"all_ok_type"}
                                            formValue={data.all_ok_type}
                                            items={[
                                                {
                                                    id: 0,
                                                    name: "B2B",
                                                },
                                                {
                                                    id: 1,
                                                    name: "B2C",
                                                },
                                            ]}
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            onClick={() => setShowallokModal(false)}
                                            color="primary"
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                if (!isValueSelected) {
                                                    toast.error(
                                                        "Please select a value before submitting."
                                                    )
                                                } else {
                                                    handleSubmit("all_ok_status", data.all_ok_type)
                                                    setShowallokModal(false)
                                                }
                                            }}
                                            color="primary"
                                            autoFocus
                                            disabled={!isValueSelected} // Disable the button if no value is selected
                                        >
                                            Submit
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            )}
                        </Grid>

                        <Grid item xs={3} spacing={1}>
                            <FormTextEngineer
                                label="TouchPad Working"
                                formValue={data.touchpad_working}
                                formName="touchpad_working"
                                onValueChange={onValueChange}
                            ></FormTextEngineer>

                            <FormTextEngineer
                                label="TouchPad Button"
                                formValue={data.touchpad_button}
                                formName="touchpad_button"
                                onValueChange={onValueChange}
                            ></FormTextEngineer>
                            <FormTextEngineer
                                label="Keyboard"
                                formName="keyboard"
                                formValue={data.keyboard}
                                onValueChange={onValueChange}
                            ></FormTextEngineer>

                            <FormTextEngineer
                                label="Webcam"
                                formName="webcam"
                                formValue={data.webcam}
                                onValueChange={onValueChange}
                            ></FormTextEngineer>
                            <FormTextEngineer
                                label="Wifi"
                                formName="wifi"
                                formValue={data.wifi}
                                onValueChange={onValueChange}
                            ></FormTextEngineer>
                            <FormTextEngineer
                                label="Usb Working"
                                formName="usb_working"
                                formValue={data.usb_working}
                                onValueChange={onValueChange}
                            ></FormTextEngineer>

                            <FormTextEngineer
                                label="LAN"
                                formName="lan"
                                formValue={data.lan}
                                onValueChange={onValueChange}
                            ></FormTextEngineer>
                            <FormTextEngineer
                                label="HDMI"
                                formName="hdmi"
                                formValue={data.hdmi}
                                onValueChange={onValueChange}
                            ></FormTextEngineer>

                            <FormTextEngineer
                                label="VGA"
                                formName="vga"
                                formValue={data.vga}
                                onValueChange={onValueChange}
                            ></FormTextEngineer>

                            <FormTextEngineer
                                label="MIC"
                                formName="mic"
                                formValue={data.mic}
                                onValueChange={onValueChange}
                            ></FormTextEngineer>

                            <FormTextEngineer
                                label="BioMetric Sensor"
                                formName="biometric_sensor"
                                formValue={data.biometric_sensor}
                                onValueChange={onValueChange}
                            ></FormTextEngineer>
                            <FormTextEngineer
                                label="Battery"
                                formName="battery"
                                formValue={data.touchpad_working}
                                onValueChange={onValueChange}
                            ></FormTextEngineer>

                            <FormTextEngineer
                                label="Speaker Left"
                                formName="speaker_left"
                                formValue={data.speaker_left}
                                onValueChange={onValueChange}
                            ></FormTextEngineer>

                            <FormTextEngineer
                                label="Speaker Right"
                                formName="speaker_right"
                                formValue={data.speaker_right}
                                onValueChange={onValueChange}
                            ></FormTextEngineer>

                            <FormTextEngineer
                                label="is graphic"
                                formName="is_graphic"
                                formValue={data.is_graphic}
                                onValueChange={onValueChange}
                            ></FormTextEngineer>
                        </Grid>
                    </Grid>

                    {/* <FormControl sx={{ my: 4 }}>
            <Button variant="contained" sx={{ maxWidth: 300 }} type="submit">
              Submit to Sell
            </Button>
          </FormControl> */}
                </FormGroup>
            </form>
        </div>
    )
}
export default UpdatePurchase
