import { useState, useEffect } from "react"
import { http, serverImgUrl, localImgUrl } from "../../utils/http"
import { useParams } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import CardMedia from "@mui/material/CardMedia"
import * as React from "react"
import Zoom from "react-medium-image-zoom"
import "react-medium-image-zoom/dist/styles.css"

import {
    FormGroup,
    FormControl,
    Grid,
    Button,
    styled,
    OutlinedInput,
    FormHelperText,
    TextField,
    Box,
} from "@mui/material"
import { width } from "@mui/system"
import PurchasePDFDownloadBtn from "./purchase-pdf-download-btn"

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    overflow: "scroll",
    p: 4,
}
const Container = styled(FormGroup)`
    width: 100%;
`
{
    /* <Typography variant="h4">Add New Employee</Typography> */
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const initialValues = {
    brand_master_id: "",
    series_master_id: "",
    model_no: "",
    processor_brand_id: "",
    processor_series_id: "",
    processor_generation_id: "",
    processor_gigaHeartz_id: "",

    graphic_brand_id: "",
    graphic_series_id: "",
    graphic_model_id: "",
    graphic_size_id: "",

    screen_size_id: "",
    screen_resolution_id: "",
    screen_spot_any_id: "",
    ips: false,
    num_lock: false,
    back_lit: false,

    product_type: false,
    processor_id: "",
    processor_image: "",
    ram_image: "",
    general_image: "",
    hard_disk_image: "",
    ssd_image: "",
    external_graphic_image: "",
    screen_size_image: "",
    age_of_laptop_image: "",
    physical_condition_image: "",
    functional_condition_image: "",

    ram_id: "",
    hard_disk_id: "",
    ssd_id: "",
    external_graphics_id: "",

    age_of_laptop_id: "",
    physical_condition_id: "",
    functional_condition_id: "",
    date: "",
    price: "",
    vendor_name: "",
    shelf_no: "",
    pickup_person: "",
    charger_type: "",
    problem: "",
    payment_mode: false,
    description: "",

    touchpad_working: false,
    touchpad_button: false,
    keyboard: false,
    webcam: false,
    wifi: false,
    usb_working: false,
    blutooth: false,
    lan: false,
    hdmi: false,
    vga: false,
    mic: false,
    biometric_sensor: false,
    speaker_left: false,
    speaker_right: false,
    battery: false,
}

const GreenCheck = () => <span className="text-green-500 text-xl">Yes</span>

const RedCross = () => <span className="text-red-500 text-xl">No</span>

interface Employee {
    id: string
    firstName: string
    lastName: string
}
const AddSalary = () => {
    const [loading, setLoading] = useState(true)
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(!open)

    const [brandMasters, setBrandMaster] = useState([])
    const [seriesMasters, setSeriesMaster] = useState([])

    const [processorBrands, setProcessorBrand] = useState([])
    const [processorSeries, setProcessorSeries] = useState([])
    const [processorGenerations, setProcessorGeneration] = useState([])
    const [processorGigaHeartzs, setProcessorGigaHeartz] = useState([])

    const [graphicBrands, setGraphicBrand] = useState([])
    const [graphicSeries, setGraphicSeries] = useState([])
    const [graphicModels, setGraphicModel] = useState([])
    const [graphicSizes, setGraphicSize] = useState([])

    const [screenSizes, setScreenSize] = useState([])
    const [screenResolutions, setScreenResolution] = useState([])
    const [screenSpots, setScreenSpot] = useState([])

    const [allspec, setAllSpecs] = useState([])

    const [rams, setram] = useState([])
    const [hardDisks, setHardDisk] = useState([])
    const [externamdraphics, setExternamdraphics] = useState([])
    const [ssds, setSSD] = useState([])
    const [ageOfLaptops, setAgeOfLaptop] = useState([])
    const [physicalConditions, setPhysicalConditions] = useState([])
    const [functionalConditions, setfunctionalCondition] = useState([])
    const [employees, setEmployees] = useState<Employee[]>([])

    const [res, setRes] = useState()
    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/purchase/specs")
            setRes(data.data.result)
            const result = data.data.result

            setBrandMaster(result.brandMasters)
            setSeriesMaster(result.seriesMasters)

            setProcessorBrand(result.processorBrands)
            setProcessorSeries(result.processorSeries)
            setProcessorGeneration(result.processorGenerations)
            setProcessorGigaHeartz(result.processorGigaHeartzs)

            setGraphicBrand(result.graphicBrands)
            setGraphicSeries(result.graphicSeries)
            setGraphicModel(result.graphicModels)
            setGraphicSize(result.graphicSizes)

            setScreenSize(result.screenSizes)
            setScreenResolution(result.screenResolutions)
            setScreenSpot(result.screenSpots)

            setEmployees(result.employees)
            setram(result.rams)
            setHardDisk(result.hardDisks)
            setExternamdraphics(result.externamdraphics)
            setSSD(result.ssds)
            setAgeOfLaptop(result.ageOfLaptops)
            setPhysicalConditions(result.physicalConditions)
            setfunctionalCondition(result.functionalConditions)

            setLoading(false)
        })()
    }, [])

    let params = useParams()
    const navigate = useNavigate()

    const [Salary, setPurchaseData] = useState({
        ...initialValues,
        emp_id: params.id,
    })
    const [data, setPurchase] = useState({ ...initialValues, ls_no: params.id })
    useEffect(() => {
        ;(async function () {
            const salDataRes = await http.get("purchase/first/" + params.id)
            console.log(salDataRes.data.result)
            setPurchase(salDataRes.data.result)
        })()
    }, [])

    const onValueChange = (e: any) => {
        e.preventDefault(e)
        setPurchaseData({ ...Salary, [e.target.name]: e.target.value })
        // console.log(e.target.name, 'is', e.target.value);
    }
    if (loading) {
        return <div>Loading...</div>
    }

    interface DataInterface {
        id: string
        name: string
        createdAt: string
        updatedAt: string
        label: string
        value: number
    }

    const matchedBrandMaster1 = brandMasters.filter(
        (brandMaster: DataInterface) => data.brand_master_id == brandMaster.id
    )
    console.log(data.brand_master_id, "tt", matchedBrandMaster1[0])

    const matchedBrandMaster = brandMasters.filter(
        (brandMaster: DataInterface) => data.brand_master_id == brandMaster.id
    )[0] as DataInterface | undefined
    const matchedseriesMaster = seriesMasters.filter(
        (seriesMaster: DataInterface) => data.series_master_id == seriesMaster.id
    )[0] as DataInterface | undefined
    const matchedprocessorBrands = processorBrands.filter(
        (processorBrands: DataInterface) => data.processor_brand_id == processorBrands.id
    )[0] as DataInterface | undefined
    const matchedproseries = processorSeries.filter(
        (proseries: DataInterface) => data.processor_series_id == proseries.id
    )[0] as DataInterface | undefined
    const matchedprocessorGeneration = processorGenerations.filter(
        (processorGeneration: DataInterface) =>
            data.processor_generation_id == processorGeneration.id
    )[0] as DataInterface | undefined
    const matchedprocessorGigaHeartz = processorGigaHeartzs.filter(
        (processorGigaHeartz: DataInterface) =>
            data.processor_gigaHeartz_id == processorGigaHeartz.id
    )[0] as DataInterface | undefined
    const matchedram = rams.filter((ram: DataInterface) => data.ram_id == ram.id)[0] as
        | DataInterface
        | undefined
    const matchedhardDisk = hardDisks.filter(
        (hardDisk: DataInterface) => data.hard_disk_id == hardDisk.id
    )[0] as DataInterface | undefined
    const matchedgraphicBrand = graphicBrands.filter(
        (graphicBrand: DataInterface) => data.graphic_brand_id == graphicBrand.id
    )[0] as DataInterface | undefined
    const matchedssd = ssds.filter((ssd: DataInterface) => data.ssd_id == ssd.id)[0] as
        | DataInterface
        | undefined
    const matchedGraphSeries = graphicSeries.filter(
        (GraphSeries: DataInterface) => data.graphic_series_id == GraphSeries.id
    )[0] as DataInterface | undefined
    const matchedgraphicModel = graphicModels.filter(
        (graphicModel: DataInterface) => data.graphic_model_id == graphicModel.id
    )[0] as DataInterface | undefined
    const matchedgraphicSize = graphicSizes.filter(
        (graphicSize: DataInterface) => data.graphic_size_id == graphicSize.id
    )[0] as DataInterface | undefined
    const matchedscreenSize = screenSizes.filter(
        (screenSize: DataInterface) => data.screen_size_id == screenSize.id
    )[0] as DataInterface | undefined
    const matchedscreenResolution = screenResolutions.filter(
        (screenResolution: DataInterface) => data.screen_resolution_id == screenResolution.id
    )[0] as DataInterface | undefined
    const matchedscreenSpot = screenSpots.filter(
        (screenSpot: DataInterface) => data.screen_spot_any_id == screenSpot.id
    )[0] as DataInterface | undefined
    const matchedageoflaptop = ageOfLaptops.filter(
        (ageoflaptop: DataInterface) => data.age_of_laptop_id == ageoflaptop.id
    )[0] as DataInterface | undefined
    const matchedpysicalCondition = physicalConditions.filter(
        (pysicalCondition: DataInterface) => data.physical_condition_id == pysicalCondition.id
    )[0] as DataInterface | undefined
    const matchedfunctionalCondition = functionalConditions.filter(
        (functionalCondition: DataInterface) =>
            data.functional_condition_id == functionalCondition.id
    )[0] as DataInterface | undefined
    const matchedemployee = employees.filter(
        (employee: Employee) => data.pickup_person == employee.id
    )[0]

    return (
        <div>
            <Box sx={{ my: 3 }}>
                <PurchasePDFDownloadBtn purchaseId={params.id} />
            </Box>
            <div className="grid grid-cols-3  gap-5 bg-[#2774AE] p-8 shadow-md rounded-md ">
                <div className="">
                    <h3 className="text-md font-semibold text-white">LS No</h3>
                    <p className="text-md text-gray-200">{data.ls_no}</p>
                </div>

                <div className=" mt-2">
                    <h3 className="text-md font-semibold text-white">Purchase Type</h3>
                    <p className="text-md text-gray-200">
                        {data.product_type ? "On Approval" : "On Purchase"}
                    </p>
                </div>

                <div className=" mt-2">
                    <h3 className="text-md font-semibold text-white">Brand Masters</h3>
                    <p className="text-md text-gray-200">
                        {matchedBrandMaster !== undefined && (
                            <span className="text-blue-300">{matchedBrandMaster.name}</span>
                        )}
                    </p>
                </div>

                <div className=" mt-2">
                    <h3 className="text-md font-semibold text-white">Series Masters</h3>
                    <p className="text-md text-gray-200">
                        {matchedseriesMaster !== undefined && (
                            <span className="text-green-300">{matchedseriesMaster.name}</span>
                        )}
                    </p>
                </div>

                <div className=" mt-2">
                    <h3 className="text-md font-semibold text-white">Model No.</h3>
                    <p className="text-md text-gray-200">{data.model_no}</p>
                </div>

                <div className=" mt-2">
                    <h3 className="text-md font-semibold text-white">General Image</h3>
                    <Zoom>
                        <img
                            className="cursor-pointer w-28 mt-2 rounded-md"
                            src={
                                data.general_image
                                    ? serverImgUrl + data.general_image
                                    : "/public/images/not-found.png"
                            }
                            alt="General Image"
                        />
                    </Zoom>
                </div>
            </div>
            <div className="grid grid-cols-3 mt-5 gap-5 bg-[#2774AE] p-8 rounded-md shadow-md">
                <div className="col-span-3 ">
                    <h4 className="text-3xl  font-semibold text-white">Processor</h4>
                </div>
                <div className="">
                    <h3 className="text-md font-semibold text-white">Processor's Brand</h3>
                    {matchedprocessorBrands !== undefined && (
                        <p className="text-md text-gray-200">{matchedprocessorBrands.name}</p>
                    )}
                </div>

                <div className=" mt-2">
                    <h3 className="text-md font-semibold text-white">Processor's Series</h3>
                    {matchedproseries !== undefined && (
                        <p className="text-md text-gray-200">{matchedproseries.name}</p>
                    )}
                </div>

                <div className=" mt-2">
                    <h3 className="text-md font-semibold text-white">Processor's Generation</h3>
                    {matchedprocessorGeneration !== undefined && (
                        <p className="text-md text-gray-200">{matchedprocessorGeneration.name}</p>
                    )}
                </div>

                <div className=" mt-2">
                    <h3 className="text-md font-semibold text-white">Processor's GigaHeartz</h3>
                    {matchedprocessorGigaHeartz !== undefined && (
                        <p className="text-md text-gray-200">{matchedprocessorGigaHeartz.name}</p>
                    )}
                </div>

                <div className=" mt-2">
                    <h3 className="text-md font-semibold text-white">Processor Image</h3>
                    <Zoom>
                        <img
                            className="w-28 mt-2 rounded-md"
                            src={
                                data.processor_image
                                    ? serverImgUrl + data.processor_image
                                    : "/public/images/not-found.png"
                            }
                            alt="Processor Image"
                        />
                    </Zoom>
                </div>
            </div>
            <div className="grid grid-cols-4 mt-5 gap-5 bg-[#2774AE] p-8 rounded-md shadow-md">
                <div className="col-span-4 ">
                    <h4 className="text-2xl  font-semibold text-white">Configuration</h4>
                </div>

                <div className=" mt-2">
                    <h3 className="text-md font-semibold text-white">Ram</h3>
                    {matchedram !== undefined && (
                        <p className="text-md text-gray-200">{matchedram.name}</p>
                    )}
                </div>

                <div className="col-span-1 mt-2">
                    <h3 className="text-md font-semibold text-white">Ram Image</h3>
                    <Zoom>
                        <img
                            className="w-28 mt-2 rounded-md"
                            src={
                                data.ram_image
                                    ? serverImgUrl + data.ram_image
                                    : "/public/images/not-found.png"
                            }
                            alt="Ram Image"
                        />
                    </Zoom>
                </div>

                <div className=" mt-2">
                    <h3 className="text-md font-semibold text-white">Hard Disks</h3>
                    {matchedhardDisk !== undefined && (
                        <p className="text-md text-gray-200">{matchedhardDisk.name}</p>
                    )}
                </div>

                <div className="col-span-1 mt-2">
                    <h3 className="text-md font-semibold text-white">Hard Disk Image</h3>
                    <Zoom>
                        <img
                            className="w-28 mt-2 rounded-md"
                            src={
                                data.hard_disk_image
                                    ? serverImgUrl + data.hard_disk_image
                                    : "/public/images/not-found.png"
                            }
                            alt="Hard Disk Image"
                        />
                    </Zoom>
                </div>

                <div className="col-span-2 mt-2">
                    <h3 className="text-md font-semibold text-white">SSD</h3>
                    {matchedssd !== undefined && (
                        <p className="text-md text-gray-200">{matchedssd.name}</p>
                    )}
                </div>

                <Zoom zoomMargin={30}>
                    <img
                        className="w-28 mt-2 rounded-md"
                        src={
                            data.ssd_image
                                ? serverImgUrl + data.ssd_image
                                : "/public/images/not-found.png"
                        }
                        alt="SSD Image"
                        style={{ maxWidth: "90%", maxHeight: "90%" }}
                    />
                </Zoom>
            </div>
            <div className="grid grid-cols-3 mt-5 gap-5 bg-[#2774AE] p-8 rounded-md shadow-md">
                <div className="col-span-3">
                    <h4 className="text-2xl font-semibold text-white">Dedicated Graphics</h4>
                </div>

                <div className="mt-2">
                    <h3 className="text-md font-semibold text-white">Graphic's Brand</h3>
                    {matchedgraphicBrand !== undefined && (
                        <p className="text-md text-gray-200">{matchedgraphicBrand.name}</p>
                    )}
                </div>

                <div className="mt-2">
                    <h3 className="text-md font-semibold text-white">Graphic's Series</h3>
                    {matchedGraphSeries !== undefined && (
                        <p className="text-md text-gray-200">{matchedGraphSeries.name}</p>
                    )}
                </div>

                <div className="mt-2">
                    <h3 className="text-md font-semibold text-white">Graphic's Model</h3>
                    {matchedgraphicModel !== undefined && (
                        <p className="text-md text-gray-200">{matchedgraphicModel.name}</p>
                    )}
                </div>

                <div className="mt-2">
                    <h3 className="text-md font-semibold text-white">Graphic's Size</h3>
                    {matchedgraphicSize !== undefined && (
                        <p className="text-md text-gray-200">{matchedgraphicSize.name}</p>
                    )}
                </div>

                <div className="mt-2">
                    <h3 className="text-md font-semibold text-white">External Graphics Image</h3>
                    <Zoom>
                        <img
                            className="w-28 mt-2 rounded-md"
                            src={
                                data.external_graphic_image
                                    ? serverImgUrl + data.external_graphic_image
                                    : "/public/images/not-found.png"
                            }
                            alt="External Graphics Image"
                        />
                    </Zoom>
                </div>
            </div>
            <div className="grid grid-cols-4 mt-5 gap-5 bg-[#2774AE] p-8 rounded-md shadow-md">
                <div className="col-span-4">
                    <h4 className="text-xl font-semibold text-white">Screen/Display</h4>
                </div>

                <div className="mt-2">
                    <h3 className="text-md font-semibold text-white">Screen Size</h3>
                    {matchedscreenSize !== undefined && (
                        <p className="text-md text-gray-200">{matchedscreenSize.name}</p>
                    )}
                </div>

                <div className="mt-2">
                    <h3 className="text-md font-semibold text-white">Select Screen Resolution</h3>
                    {matchedscreenResolution !== undefined && (
                        <p className="text-md text-gray-200">{matchedscreenResolution.name}</p>
                    )}
                </div>

                <div className="mt-2">
                    <h3 className="text-md font-semibold text-white">Spot Any</h3>
                    {matchedscreenSpot !== undefined && (
                        <p className="text-md text-gray-200">{matchedscreenSpot.name}</p>
                    )}
                </div>

                <div className="col-span-1 mt-2">
                    <h3 className="text-md font-semibold text-white">Screen Size Image</h3>
                    <Zoom>
                        <img
                            className="w-28 mt-2 rounded-md"
                            src={
                                data.screen_size_image
                                    ? serverImgUrl + data.screen_size_image
                                    : "/public/images/not-found.png"
                            }
                            alt="Screen Size Image"
                        />
                    </Zoom>
                </div>
            </div>
            <div className="grid grid-cols-4 gap-5 mt-5 p-8 bg-[#2774AE] rounded-md shadow-md text-white">
                <div className="col-span-4 mt-2">
                    <h4 className="text-2xl font-bold">Num Lock</h4>
                    <p className="text-md">{data.num_lock}</p>
                </div>

                <div className="mt-2">
                    <h3 className="text-xl font-bold">BackLit</h3>
                    <p className="text-md">{data.back_lit}</p>
                </div>

                <div className="col-span-1 mt-2">
                    <h3 className="text-xl font-bold">Age Of Laptop</h3>
                    {matchedageoflaptop !== undefined && (
                        <p className="text-md">{matchedageoflaptop.name}</p>
                    )}
                </div>

                <div className="mt-2 col-span-1">
                    <h3 className="text-xl font-bold">Select Charger</h3>
                    <p className="text-md">
                        {data.charger_type ? "With Charger" : "With Out Charger"}
                    </p>
                </div>

                <div className="col-span-1 mt-2">
                    <h3 className="text-xl font-bold">Physical Condition</h3>
                    {matchedpysicalCondition !== undefined && (
                        <p className="text-md">{matchedpysicalCondition.name}</p>
                    )}
                </div>

                <div className="col-span-1 mt-2">
                    <h3 className="text-xl font-bold">Physical Condition Image</h3>
                    <Zoom>
                        <img
                            className="w-28 mt-2 rounded-md"
                            src={
                                data.physical_condition_image
                                    ? serverImgUrl + data.physical_condition_image
                                    : "/public/images/not-found.png"
                            }
                            alt="Physical Condition Image"
                        />
                    </Zoom>
                </div>

                <div className="col-span-1 mt-2">
                    <h3 className="text-xl font-bold">Functional Condition</h3>
                    {matchedfunctionalCondition !== undefined && (
                        <p className="text-md">{matchedfunctionalCondition.name}</p>
                    )}
                </div>

                <div className="col-span-1 mt-2">
                    <h3 className="text-xl font-bold">Functional Condition Image</h3>
                    <Zoom>
                        <img
                            className="w-28 mt-2 rounded-md"
                            src={
                                data.functional_condition_image
                                    ? serverImgUrl + data.functional_condition_image
                                    : "/public/images/not-found.png"
                            }
                            alt="Functional Condition Image"
                        />
                    </Zoom>
                </div>

                <div className="col-span-1 mt-2">
                    <h3 className="text-xl font-bold">Date</h3>
                    <p className="text-md">{data.date}</p>
                </div>

                <div className="col-span-1 mt-2">
                    <h3 className="text-xl font-bold">Price</h3>
                    <p className="text-md">{data.price}</p>
                </div>

                <div className="col-span-1 mt-2">
                    <h3 className="text-xl font-bold">Vendor Name</h3>
                    <p className="text-md">{data.vendor_name}</p>
                </div>

                <div className="col-span-1 mt-2">
                    <h3 className="text-xl font-bold">Shelf No.</h3>
                    <p className="text-md">{data.shelf_no}</p>
                </div>

                <div className="col-span-1 mt-2">
                    <h3 className="text-xl font-bold">Pickup Person</h3>
                    {matchedemployee !== undefined && (
                        <p className="text-md">
                            {matchedemployee.firstName} {matchedemployee.lastName}
                        </p>
                    )}
                </div>

                <div className="col-span-1 mt-2">
                    <h3 className="text-xl font-bold">Problem (if any)</h3>
                    <p className="text-md">{data.problem}</p>
                </div>

                <div className="col-span-1 mt-2">
                    <h3 className="text-xl font-bold"> Payment Mode</h3>
                    <p className="text-md">{data.payment_mode ? "Online" : "Cash"}</p>
                </div>

                <div className="col-span-1 mt-2">
                    <h3 className="text-xl font-bold">IPS</h3>
                    <p className="text-md">{data.ips}</p>
                </div>

                <div className="col-span-4 mt-2">
                    <h3 className="text-xl font-bold">Description/Comments</h3>
                    <p className="text-md">{data.description}</p>
                </div>
            </div>
            <div>
                <div className="bg-[#2774AE] shadow-md mx-auto mt-8 p-8 w-full rounded-md">
                    <div className="grid grid-cols-4 gap-4">
                        {/* Touchpad Working */}
                        <div className="col-span-1">
                            <h3 className="text-lg font-semibold mb-2">TouchPad Working</h3>
                            {data.touchpad_working ? <GreenCheck /> : <RedCross />}
                        </div>

                        {/* Touchpad Button */}
                        <div className="col-span-1">
                            <h3 className="text-lg font-semibold mb-2">TouchPad Button</h3>
                            {data.touchpad_button ? <GreenCheck /> : <RedCross />}
                        </div>

                        {/* Keyboard */}
                        <div className="col-span-1">
                            <h3 className="text-lg font-semibold mb-2">Keyboard</h3>
                            {data.keyboard ? <GreenCheck /> : <RedCross />}
                        </div>

                        {/* Wifi */}
                        <div className="col-span-1">
                            <h3 className="text-lg font-semibold mb-2">Wifi</h3>
                            {data.wifi ? <GreenCheck /> : <RedCross />}
                        </div>

                        {/* USB Working */}
                        <div className="col-span-1">
                            <h3 className="text-lg font-semibold mb-2">USB Working</h3>
                            {data.usb_working ? <GreenCheck /> : <RedCross />}
                        </div>

                        {/* Bluetooth */}
                        <div className="col-span-1">
                            <h3 className="text-lg font-semibold mb-2">Bluetooth</h3>
                            {data.blutooth ? <GreenCheck /> : <RedCross />}
                        </div>

                        {/* LAN */}
                        <div className="col-span-1">
                            <h3 className="text-lg font-semibold mb-2">LAN</h3>
                            {data.lan ? <GreenCheck /> : <RedCross />}
                        </div>

                        {/* HDMI */}
                        <div className="col-span-1">
                            <h3 className="text-lg font-semibold mb-2">HDMI</h3>
                            {data.hdmi ? <GreenCheck /> : <RedCross />}
                        </div>

                        {/* VGA */}
                        <div className="col-span-1">
                            <h3 className="text-lg font-semibold mb-2">VGA</h3>
                            {data.vga ? <GreenCheck /> : <RedCross />}
                        </div>

                        {/* MIC */}
                        <div className="col-span-1">
                            <h3 className="text-lg font-semibold mb-2">MIC</h3>
                            {data.mic ? <GreenCheck /> : <RedCross />}
                        </div>

                        {/* Biometric Sensor */}
                        <div className="col-span-1">
                            <h3 className="text-lg font-semibold mb-2">Biometric Sensor</h3>
                            {data.biometric_sensor ? <GreenCheck /> : <RedCross />}
                        </div>
                        {/* Age Of Laptop Image */}
                        <div className="col-span-1">
                            <h3 className="text-lg font-semibold mb-2">Age OF Laptop Image</h3>
                            {data.age_of_laptop_image ? (
                                <Zoom>
                                    <img
                                        className="cursor-pointer w-28 mt-2 rounded-md"
                                        src={
                                            data.age_of_laptop_image
                                                ? serverImgUrl + data.age_of_laptop_image
                                                : "/public/images/not-found.png"
                                        }
                                        alt="Age OF Laptop Image"
                                    />
                                </Zoom>
                            ) : (
                                <p className="text-sm text-gray-500">No Image Available</p>
                            )}
                        </div>
                        {/* Speaker Left */}
                        <div className="col-span-1">
                            <h3 className="text-lg font-semibold mb-2">Speaker Left</h3>
                            {data.speaker_left ? <GreenCheck /> : <RedCross />}
                        </div>

                        {/* Speaker Right */}
                        <div className="col-span-1">
                            <h3 className="text-lg font-semibold mb-2">Speaker Right</h3>
                            {data.speaker_right ? <GreenCheck /> : <RedCross />}
                        </div>

                        {/* Battery */}
                        <div className="col-span-1">
                            <h3 className="text-lg font-semibold mb-2">Battery</h3>
                            {data.battery ? <GreenCheck /> : <RedCross />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AddSalary
