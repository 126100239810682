import * as React from "react"
import { http } from "../../utils/http"
import { useState, useEffect } from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import PageTitleCard from "../../components/layout/page-title-card"
import { FormControl, InputLabel, Select, Button, MenuItem } from "@mui/material"
import { NavLink, Link, useNavigate, useLocation } from "react-router-dom"

function createData(name: string, calories: number, fat: number, carbs: number) {
    return { name, calories, fat, carbs }
}

export default function BasicTable() {
    const [reports, setAllReports] = useState([])
    const [currentYear] = useState(new Date().getFullYear())

    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const defaultYear = queryParams.get("year") || ""

    const [selectedYear, setSelectedYear] = useState(
        Number(defaultYear || new Date().getFullYear())
    )

    useEffect(() => {
        ;(async function () {
            const reportsData = await http.get(`/all-reports?year=${selectedYear}`)
            setAllReports(reportsData.data.result)
            console.log("reports", reportsData.data.result)
        })()
    }, [])

    return (
        <div>
            <PageTitleCard title={`All Purchase Reports (FOR YEAR ${selectedYear})`}>
                <div>
                    <form style={{ width: "300px" }} method="GET" action="">
                        <FormControl size="small" fullWidth variant="standard">
                            <InputLabel id="year">Year</InputLabel>
                            <Select
                                labelId="year"
                                name="year"
                                value={selectedYear}
                                onChange={(e: any) => setSelectedYear(e.target.value)}
                            >
                                <MenuItem value={currentYear}>{currentYear}</MenuItem>
                                <MenuItem value={currentYear - 1}>{currentYear - 1}</MenuItem>
                                <MenuItem value={currentYear - 2}>{currentYear - 2}</MenuItem>
                            </Select>
                        </FormControl>
                        <Button
                            type="submit"
                            className=""
                            variant="outlined"
                            style={{ marginTop: 10 }}
                        >
                            Load Data
                        </Button>
                    </form>
                </div>
            </PageTitleCard>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" sx={{ fontWeight: 900 }}>
                                Months
                            </TableCell>
                            <TableCell align="center" sx={{ fontWeight: 900 }}>
                                Purchase Reports
                            </TableCell>
                            <TableCell align="center" sx={{ fontWeight: 900 }}>
                                Engineer Report
                            </TableCell>
                            <TableCell align="center" sx={{ fontWeight: 900 }}>
                                Sales Report
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reports.map((row: any) => (
                            <TableRow
                                key={row.month}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            >
                                <TableCell component="th" scope="row" align="center">
                                    {row.month}
                                </TableCell>
                                <TableCell align="center">{row.purchaseReportCount}</TableCell>
                                <TableCell align="center">{row.engineerReportCount}</TableCell>
                                <TableCell align="center">{row.salesReportCount}</TableCell>
                                {/* <TableCell align="right">{row.protein}</TableCell> */}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
