import { useState, useEffect, useRef } from "react";
import { serverImgUrl } from "../../utils/http";
import Zoom from 'react-medium-image-zoom';
import { formatDistanceToNow } from "date-fns";

export default function ImageSelector({ title, onValueChange, imageData, formName }: any) {
    const [showModal, setShowModal] = useState(false);
    const [previewImage, setPreviewImage] = useState<string | null>(null); // New state for preview image
    const modalRef = useRef<HTMLDivElement>(null); // Reference to the modal

    // Close modal when clicking outside
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                setShowModal(false);
            }
        }

        // Add when the component mounts
        document.addEventListener("mousedown", handleClickOutside);
        // Remove when the component unmounts
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    // Handler for file selection
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];

        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                onValueChange(e, reader.result as string);
                setPreviewImage(reader.result as string); // Set the preview image
            };

            reader.readAsDataURL(file);
            setShowModal(false); // Close the modal after file selection
        }
    };

    return (
        <div className="relative">
            <p className="text-sm font-bold text-gray-700 mb-1 uppercase">{title}</p>
            <Zoom>
                <img
                    src={previewImage || (imageData ? serverImgUrl + imageData : "/public/images/not-found.png")}
                    alt="Selected Image"
                    className="w-52 h-28 object-cover rounded cursor-pointer"
                    onClick={() => setShowModal(true)}
                />
            </Zoom>

            <label className="block mb-2 cursor-pointer mt-2 text-xs bg-blue-400 w-fit p-2 rounded  text-gray-900 dark:text-white" htmlFor={`file-input-${formName}`}>
                Choose {title}
                <input
                    type="file"
                    name={formName}
                    onChange={handleChange}
                    accept="image/*"
                    style={{ display: 'none' }}
                    id={`file-input-${formName}`}
                />
            </label>

            {showModal && (
                <div>
                    <div ref={modalRef} className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-64 bg-white border-2 border-gray-300 rounded p-4">
                        <Zoom>
                            <img src={previewImage || (imageData ? serverImgUrl + imageData : "/public/images/not-found.png")} alt="Selected Image" className="mb-1" />
                        </Zoom>
                    </div>
                </div>
            )}
        </div>
    );
}