import * as React from "react";
import { useState, useEffect } from "react";
import { http } from "../../utils/http";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

type Props = {
  children: any;
  background: string;
};

const bull = (
  <Box
    component="span"
    sx={{
      display: "inline-block",
      mx: "2px",
      background: "background",
      transform: "scale(0.8)",
    }}
  >
    •
  </Box>
);

export default function BasicCard() {
  const [employees, setEmployees] = useState([]);
  const [present, setPresent] = useState([]);
  const [absent, setAbsent] = useState([]);
  const [halfDay, sethalfDay] = useState([]);
  useEffect(() => {
    (async function () {
      const employeesDataRes = await http.get("/employees");
      const presentRes = await http.get("/attendance/present");
      const absentRes = await http.get("/attendance/absent");
      const halfDayRes = await http.get("/attendance/half-day");
      setEmployees(employeesDataRes.data.result);
      setPresent(presentRes.data.result)
      setAbsent(absentRes.data.result)
      sethalfDay(halfDayRes.data.result)
      const count= employees.length;
    // console.log('count',count);
    })();
  }, []);
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={6} sx={{ paddingRight: "80px" }}>
        <Card
          sx={{
            display: "grid",
            my: 1,

            background: "#757be8",
            textAlign: "center",
            color: "white",
          }}
        >
          <CardContent>
            <Typography variant="h5" component="div">
              Total Employees
            </Typography>
            <Typography variant="h5" sx={{ color: "black" }}>
              {employees.length}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={12} md={6} sx={{ paddingRight: "80px" }}>
        <Card
          sx={{
            my: 1,

            background: "#e57373",
            textAlign: "center",
            color: "white",
          }}
        >
          <CardContent>
            <Typography variant="h5" component="div" sx={{}}>
              Present Employees
            </Typography>
            <Typography variant="h5" sx={{ color: "black" }}>
              {present}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={12} md={6} sx={{ paddingRight: "80px" }}>
        <Card
          sx={{
            my: 1,

            background: "#64b5f6",
            textAlign: "center",
            color: "white",
          }}
        >
          <CardContent>
            <Typography variant="h5" component="div">
              Absent Employees
            </Typography>
            <Typography variant="h5" sx={{ color: "black" }}>
              {absent}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={12} md={6} sx={{ paddingRight: "80px" }}>
        <Card
          sx={{
            my: 1,

            background: "#c0ca33",
            textAlign: "center",
            color: "white",
          }}
        >
          <CardContent>
            <Typography variant="h5" component="div">
              Half-Day Employees
            </Typography>
            <Typography variant="h5" sx={{ color: "black" }}>
              {halfDay}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
