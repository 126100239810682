import React from "react"
import { useState, useEffect } from "react"
import { http } from "../../utils/http"
import TextField from "@mui/material/TextField"
import { Box, Stack, Typography } from "@mui/material"
import CardBox from "../../components/layout/card-box"
import AttendanceList from "../../components/attendance/attendance-list"
import SearchIcon from "@mui/icons-material/Search"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"

import {
    FormGroup,
    FormControl,
    Grid,
    Button,
    styled,
    OutlinedInput,
    FormHelperText,
} from "@mui/material"

const defaultStats: any = {
    mode_present: 0,
    mode_absent: 0,
    mode_fine: 0,
    mode_halfDay: 0,
    mode_overTime: 0,
    mode_paidLeave: 0,
}

type StatItemProps = {
    title: string
    value: string | number
}

function StatItem({ title, value }: StatItemProps) {
    return (
        <Box sx={{ fontSize: "0.8em", borderRight: "1px solid #ddd" }}>
            <Typography
                sx={{
                    color: "#888",
                    mx: 1,
                    fontWeight: "bold",
                    fontSize: "0.8em",
                }}
            >
                {title}
            </Typography>
            <Typography sx={{ mx: 1, fontWeight: "bold", fontSize: "0.8em" }}>{value}</Typography>
        </Box>
    )
}

export default function Attendance() {
    const [employees, setEmployees] = useState([])
    const [today, setToday] = useState("")
    const [stats, setStats] = useState(defaultStats)
    const [searchStr, setSearchStr] = useState("")
    const [prevDateExists, setPrevDateExists] = useState(false)
    const [nextDateExists, setNextDateExists] = useState(false)
    const [currentTimeStamp, setCurrentTimeStamp] = useState(0)

    async function initTime() {
        const timeRes: any = await http.get("/auth/time")
        const { timestamp, date, month, year } = timeRes.data.result
        setCurrentTimeStamp(timestamp)
    }

    async function fetchGeneralStats() {
        // fetch time info from server based on current timestamp
        const timeRes: any = await http.get(`/auth/time?timestamp=${currentTimeStamp}`)
        const { timestamp, date, month, year } = timeRes.data.result
        setToday(new Date(timestamp).toDateString())
        const data: any = await http.get(
            `/attendance/general-stats?date=${date}&month=${month}&year=${year}`
        )
        const result = data.data.result
        setPrevDateExists(result.attendanceDataPrevDateExists)
        setNextDateExists(result.attendanceDataNextDateExists)
        setEmployees(result.employees)
        setStats(result.stats)
    }

    function addOneDay() {
        if (nextDateExists) {
            console.log("add one day")
            setCurrentTimeStamp(currentTimeStamp + 86400 * 1000)
        }
    }
    function subtractOneDay() {
        if (prevDateExists) {
            console.log("subtract one day")
            setCurrentTimeStamp(currentTimeStamp - 86400 * 1000)
        }
    }

    useEffect(() => {
        ;(async () => {
            await initTime()
        })()
    }, [])

    useEffect(() => {
        ;(async () => {
            await fetchGeneralStats()
        })()
    }, [currentTimeStamp])

    return (
        <div>
            <CardBox>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <ChevronLeftIcon
                            onClick={subtractOneDay}
                            sx={{ cursor: "pointer", color: prevDateExists ? "#222" : "#aaa" }}
                        />
                        <Typography sx={{ mx: 5, fontSize: "1.2em", fontWeight: "bold" }}>
                            {today}

                            {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
  <DatePicker
    label="Basic example"
    value={value}
    onChange={(newValue) => {
      setValue(newValue);
    }}
    renderInput={(params) => <TextField {...params} />}
  />
</LocalizationProvider> */}
                        </Typography>
                        <ChevronRightIcon
                            onClick={addOneDay}
                            sx={{ cursor: "pointer", color: nextDateExists ? "#222" : "#aaa" }}
                        />
                        <Box mx={2} sx={{ display: "flex", alignItems: "center" }}>
                            <CalendarMonthIcon />
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", color: "green" }}>
                        <CheckCircleIcon />
                        <Typography sx={{ mx: 1, fontWeight: "bold", fontSize: "0.8em" }}>
                            All Approved
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ display: "grid", gridTemplateColumns: "repeat(7, 1fr)", my: 5 }}>
                    <StatItem title="Total Staff" value={employees.length} />
                    <StatItem title="Present Staff" value={stats.mode_present} />
                    <StatItem title="Absent Staff" value={stats.mode_absent} />
                    <StatItem title="Half-Day Staff" value={stats.mode_halfDay} />
                    <StatItem title="Overtime Hour" value={stats.mode_overTime} />
                    <StatItem title="Fine" value={stats.mode_fine} />
                    <StatItem title="Paid Leaves" value={stats.mode_paidLeave} />
                </Box>
            </CardBox>
            <CardBox>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <SearchIcon />{" "}
                    <input
                        style={{
                            border: "none",
                            outline: "none",
                            width: "100%",
                            marginLeft: 15,
                        }}
                        value={searchStr}
                        onChange={(e) => setSearchStr(e.target.value)}
                        placeholder="Search Staff by Name, Phone Number or Employee Id"
                        type="text"
                    />
                </Box>
            </CardBox>
            <h4 style={{ color: "#666" }}>Regular</h4>
            {/* {[1].map((item) => ( */}
            <AttendanceList
                fetchGeneralStats={fetchGeneralStats}
                employees={employees.filter(
                    (emp: any) =>
                        emp.firstName.includes(searchStr) ||
                        emp.lastName.includes(searchStr) ||
                        emp.email.includes(searchStr)
                )}
            />
            {/* ))}  */}
        </div>
    )
}
