import { useState, useEffect } from "react"
import { http } from "../../utils/http"

import { useNavigate } from "react-router-dom"

import {
    FormGroup,
    FormControl,
    Grid,
    Button,
    styled,
    OutlinedInput,
    FormHelperText,
} from "@mui/material"
import { width } from "@mui/system"

const Container = styled(FormGroup)`
    width: 100%;
`
{
    /* <Typography variant="h4">Add New Employee</Typography> */
}

const initialValues = {
    name: "",
}

const AddIdProof = () => {
    const navigate = useNavigate()
    const [idProofs, setIdProofs] = useState(initialValues)

    const onValueChange = (e: any) => {
        e.preventDefault(e)
        setIdProofs({ ...idProofs, [e.target.name]: e.target.value })
    }
    const handleSubmit = async (e: any) => {
        e.preventDefault()
        try {
            await http.post("/id-proofs", idProofs)
            navigate("/id-proof")
            return
        } catch (error: any) {
            console.log("error while calling api", error.message)
        }
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <FormGroup>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <FormControl sx={{ width: "80%" }}>
                                <FormHelperText>
                                    <h3>Name</h3>{" "}
                                </FormHelperText>
                                <OutlinedInput
                                    sx={{ background: "white" }}
                                    placeholder="Please enter name"
                                    onChange={(e) => onValueChange(e)}
                                    name="name"
                                    required
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <FormControl sx={{ my: 4 }}>
                        <Button variant="contained" sx={{ maxWidth: 200 }} type="submit">
                            Add
                        </Button>
                    </FormControl>
                </FormGroup>
            </form>
        </div>
    )
}
export default AddIdProof
