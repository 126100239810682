import * as React from "react"
import { useState, useEffect } from "react"
import { http } from "../../utils/http"
import Button from "@mui/material/Button"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { NavLink, Link, useNavigate } from "react-router-dom"
import PageTitleCard from "../../components/layout/page-title-card"

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    p: 3,
}
export default function ScrapTable() {
    const [salesData, setSalesData] = useState([])
    useEffect(() => {
        ;(async function () {
            const scrapDataRes = await http.get("/scrap")
            console.log(scrapDataRes)

            setSalesData(scrapDataRes.data.result)
        })()
    }, [])

    const [open, setOpen] = React.useState(false)
    const navigate = useNavigate()

    return (
        <div>
            <PageTitleCard title="Engineer's Scrapped Task"></PageTitleCard>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow sx={{ background: "skyBlue" }}>
                            <TableCell>ID</TableCell>
                            <TableCell>LS No.</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>TAT</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {salesData &&
                            salesData.map((row: any) => (
                                <TableRow
                                    key={row.id}
                                    sx={{
                                        "&:hover": {
                                            backgroundColor: "#f5f5f5",
                                        },
                                        cursor: "pointer",
                                        "&:last-child td, &:last-child th": { border: 0 },
                                    }}
                                    onClick={() => navigate(`/view-scraps/${row.id}`)}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.id}
                                    </TableCell>
                                    <TableCell>{row.ls_no}</TableCell>
                                    <TableCell>₹{row.price}</TableCell>
                                    <TableCell>
                                        {row.status
                                            ? row.status.split("_").slice(0, -1).join(" ")
                                            : ""}
                                    </TableCell>
                                    {/* <TableCell >{row.rtd_date  }</TableCell>
                  <TableCell >{row.qc1_date}</TableCell> */}
                                    <TableCell>{row.tat ? row.tat : 0} days</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
