import React, { useState } from "react"
import TextField from "@mui/material/TextField"
import { http } from "../utils/http"
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import { useLocation, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { useDispatch, useSelector } from "react-redux"
import { setAuth, AuthState } from "../redux/auth/authSlice"
import { RootState } from "../redux/store"

import { Container, Button, Grid, Paper, IconButton, InputAdornment } from "@mui/material"

const Login = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const auth: AuthState = useSelector((state: RootState) => state.auth)
    const location: any = useLocation()
    let from: any = location.state?.from?.pathname || "/"
    from = from === "/login" ? "/" : from

    const [values, setValues] = useState({
        email: "",
        pass: "",
        showPass: false,
    })

    if (auth.authenticated) {
        navigate(from)
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        try {
            const loginValues = await http.post("/auth/login", {
                email: values.email,
                password: values.pass,
            })
            const result = loginValues.data.result
            if (result.token) {
                toast.success(loginValues.data.message)
                dispatch(setAuth({ user: result.user, token: result.token  }))
                navigate(from, { replace: true })
            }
        } catch (err: any) {
            toast.error(err.response.data.message)
            console.log("error in getting login value", err)
        }
    }
    const handlePassVisibility = () => {
        setValues({
            ...values,
            showPass: !values.showPass,
        })
    }
    return (
        <div className="">
            <div className="App">
                <div style={{ fontSize: 25, color: "#444", marginTop: 30 }}>Admin Login</div>
            </div>
            <Container maxWidth="sm">
                <Grid
                    container
                    spacing={2}
                    direction="column"
                    justifyContent="center"
                    style={{ minHeight: "10vh" }}
                ></Grid>

                <Paper elevation={2} sx={{ padding: 5 }}>
                    <form>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <TextField
                                    type="email"
                                    name="email"
                                    fullWidth={true}
                                    label="Enter your Email"
                                    placeholder="Email Address"
                                    variant="outlined"
                                    required
                                    onChange={(e) =>
                                        setValues({ ...values, email: e.target.value })
                                    }
                                />
                            </Grid>

                            <Grid item>
                                <TextField
                                    type={values.showPass ? "text" : "password"}
                                    name="password"
                                    fullWidth={true}
                                    label="Enter your password"
                                    placeholder="Password"
                                    variant="outlined"
                                    onChange={(e) => setValues({ ...values, pass: e.target.value })}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={handlePassVisibility}
                                                    aria-label="toggle password"
                                                    edge="end"
                                                >
                                                    {values.showPass ? (
                                                        <VisibilityOffIcon />
                                                    ) : (
                                                        <VisibilityIcon />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <Button variant="contained" onClick={handleSubmit}>
                                    Sign In
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Container>
        </div>
    )
}
export default Login
