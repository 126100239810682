import { useState, useEffect } from "react";
import { http,serverImgUrl,localImgUrl } from "../../utils/http";
import { useParams } from "react-router-dom";
import Hidden from "@mui/material/Hidden";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate } from "react-router-dom";
import CardMedia from '@mui/material/CardMedia';

import {
  FormGroup,
  FormControl,
  Grid,
  Button,
  styled,
  OutlinedInput,
  FormHelperText,
  TextField,
} from "@mui/material";
import { width } from "@mui/system";

const Container = styled(FormGroup)`
  width: 100%;
`;
{
  /* <Typography variant="h4">Add New Employee</Typography> */
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const initialValues = {
 
  isCheckIn:false,
  photo:"",
  latitude: "",
  longitude: "",
  locality:"",
  date: "",
  time: "",
  street: "",
  administrativeArea: "",
  subAdministrativeArea: "",
};
const EditHardDisk = () => {


  let params = useParams();
  const navigate = useNavigate();

  const [CheckInOutsUpdate, setCheckInOutsUpdate] = useState({ ...initialValues });
  
  useEffect(() => {
      (async () => {
          const data: any = await http.get("/check-in-outs/" + params.id);
          setCheckInOutsUpdate(data.data.result);
        })();
    }, []);
    
       
  const onValueChange = (e: any) => {
    e.preventDefault(e);
    setCheckInOutsUpdate({ ...CheckInOutsUpdate, [e.target.name]: e.target.value });
console.log('name is ', e.target.name, 'value is', e.target.value);
  };
  const EditHardDiskdetail = async (e: any) => {
    e.preventDefault();
    try {
      //  console.log('data',CheckInOutsUpdate);
      await http.patch("/check-in-outs/"+params.id, CheckInOutsUpdate);
      navigate("/check-in-out");
      return;
    } catch (error: any) {
      console.log("error while calling api", error.message);
    }
  };
//   const Image = require('http://localhost:4000/'+ CheckInOutsUpdate.photo);


  return (
    <div>
      <h2>CheckInOut ID: {params.id}</h2>
      <form onSubmit={EditHardDiskdetail}>
        <FormGroup>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>Photo </h3>{" "}
                </FormHelperText>
                <CardMedia 
                component="img"
                image={serverImgUrl+CheckInOutsUpdate.photo}
                 
                 />
                {/* <OutlinedInput
                  sx={{ background: "white" }}
                  onChange={(e) => onValueChange(e)}
                  name="photo"
                //   value={CheckInOutsUpdate.photo}
                  type="file"
                /> */}
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>Time</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  sx={{ background: "white" }}
                  onChange={(e) => onValueChange(e)}
                  name="yime"
                  value={CheckInOutsUpdate.time && CheckInOutsUpdate.time.toString().slice(0,-3)}
                  
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl>
                <FormHelperText>
                  <h3> IsCheckIn</h3>{" "}
                </FormHelperText>
                <RadioGroup
                  name="isCheckIn"
                  onChange={(e) => onValueChange(e)}
                  aria-labelledby="demo-radio-buttons-group-label"
            
                >
                    <FormControlLabel
                    value="1"
                    onChange={(e) => onValueChange(e)}
                    control={<Radio required={true}  checked={CheckInOutsUpdate.isCheckIn}/>}
                    label="Yes"
                    
                  />
                  <FormControlLabel
                    value="0"
                    onChange={(e) => onValueChange(e)}
                    control={<Radio required={true}  checked={!CheckInOutsUpdate.isCheckIn} />}
                    label="No"
                  />
                  
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>Date</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  sx={{ background: "white" }}
                  onChange={(e) => onValueChange(e)}
                  name="date"
                  type="date"
                  value={CheckInOutsUpdate.date}
                  
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>Latitude</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  sx={{ background: "white" }}
                  onChange={(e) => onValueChange(e)}
                  name="latitude"
                  value={CheckInOutsUpdate.latitude}
                  
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>Longitude</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  sx={{ background: "white" }}
                  onChange={(e) => onValueChange(e)}
                  name="longitude"
                  value={CheckInOutsUpdate.longitude}
                  
                />
              </FormControl>
            </Grid>
           
            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>Street</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  sx={{ background: "white" }}
                  onChange={(e) => onValueChange(e)}
                  name="street"
                  value={CheckInOutsUpdate.street}
                  
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>Locality</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  sx={{ background: "white" }}
                  onChange={(e) => onValueChange(e)}
                  name="locality"
                  value={CheckInOutsUpdate.locality}
                  
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>Administrative Area</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  sx={{ background: "white" }}
                  onChange={(e) => onValueChange(e)}
                  name="administrativeArea"
                  value={CheckInOutsUpdate.administrativeArea}
                  
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ width: "80%" }}>
                <FormHelperText>
                  <h3>Sub Administrative Area</h3>{" "}
                </FormHelperText>
                <OutlinedInput
                  sx={{ background: "white" }}
                  onChange={(e) => onValueChange(e)}
                  name="subAdministrativeArea"
                  value={CheckInOutsUpdate.subAdministrativeArea}
                  
                />
              </FormControl>
            </Grid>
          </Grid>

          {/* <FormControl sx={{ my: 4 }}>
            <Button variant="contained" sx={{ maxWidth: 200 }} type="submit">
              Update
            </Button>
          </FormControl> */}
        </FormGroup>
      </form>
    </div>
  );
};
export default EditHardDisk;
