import React, { useState, useEffect } from "react"
import { Button, OutlinedInput, Modal, FormHelperText, Box, Typography } from "@mui/material"
import SummarizeIcon from "@mui/icons-material/Summarize"
import CardMembershipIcon from "@mui/icons-material/CardMembership"
import ContentCutIcon from "@mui/icons-material/ContentCut"
import { http } from "../../utils/http"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"

import { NavLink, Link } from "react-router-dom"

const modalStyle = {
    background: "white",
    maxWidth: 600,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "150px",
    padding: 5,
    border: "none",
    outline: "none",
}

export default function SalaryExtraModal({
    title,
    icon,
    recordName,
    isDeduction = false,
    useSimpleBtn,
    onUpdate = () => {},
    defaultAmount = 0,
    defaultPlaceholder = "",
}: any) {
    const [open, setOpen] = useState(false)
    const handleClose = () => setOpen(false)
    const handleOpen = () => setOpen(true)
    const [isLoading, setIsLoading] = useState(false)
    let params = useParams()
    const [amount, setAmount] = useState("")

    useEffect(() => {
        if (defaultAmount) {
            setAmount(String(defaultAmount))
        }
    }, [open])

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        if (Number(amount) <= 0) {
            return toast.error(`${title} amount must be greater than 0!`)
        }
        try {
            setIsLoading(true)
            const res: any = await http.patch(`/salary-records/${params.id}`, {
                record_name: recordName,
                is_deduction: isDeduction,
                amount: Number(amount),
            })
            onUpdate(res.data.result)
            toast.success(`Added ${title} to salary record!`)
            handleClose()
        } catch (error: any) {
            console.log("error in updating salary records", error)
            toast.error(error.response && error.response.data && error.response.data.message)
        }
        setIsLoading(false)
    }

    return (
        <div>
            {useSimpleBtn ? (
                <Button variant="outlined" onClick={handleOpen}>
                    Pay Salary
                </Button>
            ) : (
                <FormHelperText
                    onClick={handleOpen}
                    sx={{ fontSize: 15, textAlign: "center", cursor: "pointer" }}
                >
                    {icon === "SummarizeIcon" ? (
                        <SummarizeIcon fontSize="small"></SummarizeIcon>
                    ) : null}
                    {icon === "ContentCutIcon" ? (
                        <ContentCutIcon fontSize="small"></ContentCutIcon>
                    ) : null}
                    {icon === "CardMembershipIcon" ? (
                        <CardMembershipIcon fontSize="small"></CardMembershipIcon>
                    ) : null}
                    <h3>
                        <span style={{ textDecoration: "none", color: "#444" }}>{title}</span>
                    </h3>
                </FormHelperText>
            )}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <form onSubmit={handleSubmit} style={{ outline: "none" }}>
                    <Box sx={modalStyle}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {title}
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            <OutlinedInput
                                placeholder={
                                    defaultPlaceholder ? defaultPlaceholder : "Enter amount"
                                }
                                onChange={(e) => setAmount(String(e.target.value))}
                                name="note"
                                value={amount}
                                type="number"
                                sx={{ background: "white", width: "100%", height: "100%" }}
                                required
                            />
                        </Typography>
                        <Box sx={{ my: 2 }}>
                            <Button type="submit" variant="contained">
                                {isLoading ? `Please wait...` : `Add ${title}`}
                            </Button>
                        </Box>
                    </Box>
                </form>
            </Modal>
        </div>
    )
}
