import * as React from "react"
import { useState, useEffect } from "react"
import { http } from "../../utils/http"
import Button from "@mui/material/Button"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { NavLink, Link } from "react-router-dom"
import AddIcon from "@mui/icons-material/Add"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"
import DeleteIcon from "@mui/icons-material/Delete"
import Box from "@mui/material/Box"
import { setAuth, AuthState } from "../../redux/auth/authSlice"
import { useSelector } from "react-redux"
import { RootState } from "../../redux/store"
import { isAllowed } from "../../utils/common"
import PageTitleCard from "../../components/layout/page-title-card"

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    p: 3,
}
export default function BasicTable() {
    const [employees, setEmployees] = useState([])
    useEffect(() => {
        ;(async function () {
            const employeesDataRes = await http.get("/employees")
            setEmployees(employeesDataRes.data.result)

            // console.log('emps',employees.length);
        })()
    }, [])

    async function deleteData(dataId: any) {
        await http.delete("/employees/" + dataId)
        setEmployees(employees.filter((item: any) => item.id !== dataId))
    }
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const auth: AuthState = useSelector((state: RootState) => state.auth)

    return (
        <div>
            <PageTitleCard title="Employee List" flexMode>
                {isAllowed(auth, "create_allowed") ? (
                    <NavLink to="/add-employee">
                        <Button variant="contained" sx={{ mb: 0 }}>
                            <AddIcon /> Add Employee
                        </Button>
                    </NavLink>
                ) : (
                    ""
                )}
            </PageTitleCard>
            <TableContainer component={Paper} style={{ overflowX: "auto" }}>
                <Table sx={{ minWidth: 950 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ minWidth: 250 }} align="center">
                                Actions
                            </TableCell>
                            <TableCell>First Name</TableCell>
                            <TableCell align="right">Last Name</TableCell>
                            <TableCell align="right">Employee Code</TableCell>
                            <TableCell align="center">Email</TableCell>
                            <TableCell align="right">Contact No.</TableCell>
                            <TableCell align="right">Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {employees &&
                            employees.map((row: any) => (
                                <TableRow
                                    key={row.id}
                                    sx={{
                                        "&:last-child td, &:last-child th": { border: 0 },
                                    }}
                                >
                                    <TableCell align="right">
                                        <NavLink to={`/view-employee/${row.id}`} key={row.id}>
                                            <Button variant="outlined" size="small" sx={{ mb: 2 }}>
                                                View-EMP
                                            </Button>
                                        </NavLink>

                                        {isAllowed(auth, "delete_allowed") ? (
                                            <Button onClick={() => deleteData(row.id)}>
                                                <DeleteIcon sx={{ color: "red" }} />
                                            </Button>
                                        ) : (
                                            ""
                                        )}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.firstName}
                                    </TableCell>
                                    <TableCell align="right">{row.lastName}</TableCell>
                                    <TableCell align="right">{row.employeeCode}</TableCell>
                                    <TableCell align="right">{row.email}</TableCell>
                                    <TableCell align="right">{row.phone}</TableCell>
                                    <TableCell align="right">
                                        {row.status ? "Active" : "Inactive"}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
