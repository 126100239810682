import { useState, useEffect } from "react"
import { http } from "../../utils/http"
import { useParams } from "react-router-dom"
import Hidden from "@mui/material/Hidden"

import { useNavigate } from "react-router-dom"

import {
    FormGroup,
    FormControl,
    Grid,
    Button,
    styled,
    OutlinedInput,
    FormHelperText,
    TextField,
} from "@mui/material"
import { width } from "@mui/system"

const initialValues = {
    id: "",
    name: "",
}

const EditGraphicModel = () => {
    let params = useParams()
    const navigate = useNavigate()

    const [GraphicModelUpdate, setGraphicModelUpdate] = useState({
        ...initialValues,
        id: params.id,
    })

    useEffect(() => {
        ;(async () => {
            const data: any = await http.get("/graphic-model/" + params.id)
            setGraphicModelUpdate(data.data.result)
        })()
    }, [])

    const onValueChange = (e: any) => {
        e.preventDefault(e)
        setGraphicModelUpdate({ ...GraphicModelUpdate, [e.target.name]: e.target.value })
    }
    const EditGraphicModelDetail = async (e: any) => {
        e.preventDefault()
        try {
            await http.patch("/graphic-model/" + params.id, GraphicModelUpdate)
            navigate("/graphic-model")
            return
        } catch (error: any) {
            console.log("error while calling api", error.message)
        }
    }

    return (
        <div>
            <h2> Graphic ID: {params.id}</h2>
            <form onSubmit={EditGraphicModelDetail}>
                <FormGroup>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <FormControl sx={{ width: "80%" }}>
                                <FormHelperText>
                                    <h3>Graphic Model ID</h3>{" "}
                                </FormHelperText>
                                <OutlinedInput
                                    disabled
                                    sx={{ background: "white" }}
                                    value={GraphicModelUpdate.id}
                                    onChange={(e) => onValueChange(e)}
                                    name="id"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl sx={{ width: "80%" }}>
                                <FormHelperText>
                                    <h3>Name</h3>{" "}
                                </FormHelperText>
                                <OutlinedInput
                                    sx={{ background: "white" }}
                                    onChange={(e) => onValueChange(e)}
                                    name="name"
                                    value={GraphicModelUpdate.name}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <FormControl sx={{ my: 4 }}>
                        <Button variant="contained" sx={{ maxWidth: 200 }} type="submit">
                            Update
                        </Button>
                    </FormControl>
                </FormGroup>
            </form>
        </div>
    )
}
export default EditGraphicModel
