import { useState, useEffect } from "react"
import { Button, Card, Box, Stack, Modal, Grid, Typography } from "@mui/material"
import { http } from "../../utils/http"
import { toast } from "react-toastify"

const style = {
    background: "white",
    maxWidth: 600,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "150px",
    padding: 5,
    border: "none",
    outline: "none",
}

type AttendanceActionProps = {
    attendance: any
    modeName: string
    title: string
    removeMode?: boolean
    modalMsg?: string
    color?: string
    fetchGeneralStats: Function
}

function AttendanceAction({
    attendance,
    modeName,
    title,
    removeMode = false,
    modalMsg = "",
    fetchGeneralStats,
    color = "green",
}: AttendanceActionProps) {
    const [open, setOpen] = useState(false)
    function handleClose() {
        setOpen(false)
    }
    async function handleConfirm() {
        try {
            await http.patch(`/attendance/${attendance.id}`, {
                [modeName]: removeMode ? false : true,
            })
            toast.success("Attendance Updated Successfully", { autoClose: 1000 })
            await fetchGeneralStats()
        } catch (err) {
            console.log("error in confirming attendance", err)
            toast.error("Error! Try later", { autoClose: 1000 })
        }
        handleClose()
    }
    return (
        <Box>
            <Modal
                onClick={(e: any) => e.target.stopPropagation()}
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Confirm Changes
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {modalMsg ||
                            (removeMode
                                ? `Unmark employee as ${title}?`
                                : `Mark employee as ${title}?`)}
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                        <Button variant="outlined" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant="contained" sx={{ mx: 2 }} onClick={handleConfirm}>
                            Confirm
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <Card
                sx={{
                    padding: 1,
                    border: "1px solid #ddd",
                    "&:hover": { cursor: "pointer", background: "#ddd" },
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: color,
                }}
                onClick={() => setOpen(true)}
            >
                <Typography sx={{ fontSize: "0.8em" }}>{title} </Typography>
            </Card>
        </Box>
    )
}

export default AttendanceAction
