import * as React from "react";
import { useState, useEffect } from "react";
import { http } from "../../utils/http";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { NavLink } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

export default function BasicTable() {
  const [datas, setCheckInOuts] = useState([]);
  useEffect(() => {
    (async function () {
      const conditionsDataRes = await http.get("/check-in-outs");
      setCheckInOuts(conditionsDataRes.data.result);
    })();
  }, []);

  async function deleteCheckInOuts(designationId: any) {
    await http.delete("/check-in-outs/" + designationId);
    setCheckInOuts(datas.filter((item: any) => item.id !== designationId));
  }
  return (
    <div>
        <h4>Check-In-Out Data</h4>
      {/* <NavLink to="/add">
        <Button variant="contained" sx={{ mb: 2 }}>
          <AddIcon /> Add check-in-outs
        </Button>
      </NavLink> */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell align="center">Is CheckIn</TableCell>
              <TableCell align="center">Employee Name</TableCell>
              <TableCell align="center">Date</TableCell>
              <TableCell align="center">Street</TableCell>
              <TableCell align="center">Locality</TableCell>
              <TableCell align="center">Time</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {datas &&
              datas.map((row: any) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell align="center">{row.isCheckIn ? "Yes" :"No"}</TableCell>
                  <TableCell align="center">{row.employee.firstName} {row.employee.lastName}</TableCell>
                  <TableCell align="center">{row.date}</TableCell>
                  <TableCell align="center">{row.street}</TableCell>
                  <TableCell align="center">{row.locality}</TableCell>
                  <TableCell align="center">{row.time && row.time.toString().slice(0,-3)}</TableCell>
                  <TableCell align="center">

                      <NavLink to={`/view-check-in-out/${row.id}`} key={row.id} >
                      <Button variant="outlined" size="small" sx={{ mb: 2 }}>
                        View
                      </Button>
                    </NavLink>


                  {/* <NavLink to={`/view-check-in-out/${row.id}`} key={row.id}>
                      <EditIcon sx={{ color: "green" }}></EditIcon>
                    </NavLink> */}
                    <Button onClick={() => deleteCheckInOuts(row.id)}>
                      <DeleteIcon sx={{ color: "red" }} />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
